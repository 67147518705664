import React, { Component } from "react";
import {
  loaderState,
  getAdjustments,
  addAdjustments,
  updateAdjustments,
  deleteAdjustments,
  onUpdateOrDeleteAdjustment,
} from "@actions";
import swal from "sweetalert";
import { connect } from "react-redux";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import AdjustmentReasonsComp from "@components/setting/system/adjustmentReasons";

class AdjustmentReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addValue: null,
      editValue: null,
    };
  }
  componentDidMount() {
    initilizeDataTable("adjustment-reasons-table");
    this.props.loaderState(true);
    this.props.getAdjustments();
  }

  componentDidUpdate() {
    initilizeDataTable("adjustment-reasons-table");
  }

  onChange = ({ target }) => {
    this.setState({ addValue: target.value });
  };

  onChangeEdit = ({ target }) => {
    this.setState({ editValue: target.value });
  };

  onUpdate = () => {
    const { selectedReason, user } = this.props;
    const { editValue } = this.state;

    if (!isEmptyOrNull(editValue)) {
      this.setState({ editValue: null });
      this.props.loaderState(true);
      this.props.updateAdjustments({
        reason: editValue,
        id: selectedReason.id,
        updatedBy: user.id,
      });
    }
  };

  onDelete = () => {
    const { selectedReason } = this.props;
    this.props.loaderState(true);
    this.props.deleteAdjustments(selectedReason.id);
  };

  onAdd = () => {
    const { user } = this.props;
    const addValue = this.state.addValue;

    if (!isEmptyOrNull(addValue)) {
      this.setState({ addValue: "" });
      this.props.loaderState(true);
      this.props.addAdjustments({
        reason: addValue,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter a reason value", "info");
    }
  };

  onReasonUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteAdjustment(data);
  };

  render() {
    destroyDataTable("adjustment-reasons-table");

    const { addValue, editValue } = this.state;
    const { adjustments, selectedReason } = this.props;
    return (
      <AdjustmentReasonsComp
        adjustments={adjustments}
        addValue={addValue}
        editValue={
          editValue != null
            ? editValue
            : selectedReason
            ? selectedReason.reason
            : null
        }
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onReasonUpdateOrDelete={(data) => this.onReasonUpdateOrDelete(data)}
        onDelete={() => this.onDelete()}
        onAdd={() => this.onAdd()}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adjustments: state.system.adjustments.allAdjustments,
    user: state.auth.loggedInUser,
    selectedReason: state.system.adjustments.selectedReason,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getAdjustments,
  addAdjustments,
  deleteAdjustments,
  updateAdjustments,
  onUpdateOrDeleteAdjustment,
})(AdjustmentReasons);
