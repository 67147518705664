import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import TransactionComp from "@components/reports/inventory/transaction";

export default class Transaction extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <TransactionComp />;
  }
}
