import React from "react";
import Sidenav from "@containers/sidenav";
import Header from "@containers/header";
import Footer from "@components/common/footer";
import Routes from "@routes";

export default function AppWrapper({ sideManu, activeNav, onNavClick }) {
  return (
    <div className="wrapper">
      <Sidenav
        manus={sideManu}
        onNavClick={(nav) => onNavClick(nav)}
        nav={activeNav}
      />
      <div id="content">
        <Header />
        <div className="main-body">
          <Routes />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
