import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import { removeNullValues } from "@helpers";
import {
  getPrefixes,
  loaderState,
  addPrefixes,
  updatePrefixes,
} from "@actions";
import PrefixesComp from "@components/setting/system/prefixes";

class Prefixes extends Component {
  componentDidMount() {
    this.props.loaderState(true);
    this.props.getPrefixes();
  }

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onUpdate = () => {
    this.props.loaderState(true);
    const { user } = this.props;
    let data = removeNullValues(this.state);
    this.props.updatePrefixes({
      ...data,
      createdBy: user.id,
      updatedBy: user.id,
    });
  };

  render() {
    const { prefixes } = this.props;
    let stateData = this.state;
    return (
      <PrefixesComp
        prefixes={prefixes ? prefixes[0] : null}
        stateData={stateData}
        onUpdate={() => this.onUpdate()}
        onChange={(e) => this.onChange(e)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prefixes: state.system.prefixes.allPrefixes,
    user: state.auth.loggedInUser,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getPrefixes,
  addPrefixes,
  updatePrefixes,
})(Prefixes);
