import React from "react";
import StockCountTable from "./stockCountTable";
import Select from "react-select";

export default function StockCount({ goto, warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-sm-6">
            <label>Stock Count</label>
          </div>
          <div className="col-sm-6">
            <button
              className="btn btn-default btn-sm"
              onClick={() => goto("/import stock counts")}
            >
              Import Count
            </button>
            <button
              onClick={() => goto("/stockTakes/createCount")}
              className="btn btn-success btn-sm"
            >
              Add Count
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="stock-count">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="stock-count-status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                  { label: "Deleted", value: "Deleted" },
                ]}
              />
              {/* <select className="form-control" id="stock-count-status">
								<option>All</option>
								<option>In Progress</option>
								<option>Completed</option>
								<option>Deleted</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="stock-count-reason">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="stock-count-reason">
								<option></option>
								<option></option>
								<option>Head Office</option>
								<option>Main Warehouse</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <StockCountTable />
        </div>
      </div>
    </>
  );
}
