import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable, goto } from "@helpers";
import ReceiptPurchaseComp from "@components/purchases/receiptPurchase";

export default class ReceiptPurchase extends Component {
  componentDidMount() {
    document.title = "Receipt Purchase";
    initilizeDataTable("receipt-purchases-table");
  }
  componentDidUpdate() {
    initilizeDataTable("receipt-purchases-table");
  }
  render() {
    destroyDataTable("receipt-purchases-table");
    return <ReceiptPurchaseComp goto={(path) => goto(path)} />;
  }
}
