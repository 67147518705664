import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import SupplierReturnsTable from "./supplierReturnsTable";

export default function AddSupplierReturn({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Add Supplier Return</label>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                link={true}
                path="/purchases/purchase orders/view purchases"
                id="purchase-order-number"
                value=""
                name="Purchase Order Number"
              />
              <InlineInputForm
                disable="disabled"
                link={true}
                path="/suppliers/view suppliers"
                id="supplier-name"
                value=""
                name="Supplier Name"
              />
              <InlineInputForm
                id="supplier-reference"
                value=""
                name="Supplier Reference"
              />
              <InlineDropdown
                link={true}
                path="/settings/system/warehouses"
                id="warehouse"
                name="Warehouse"
                values={warehouses}
                searchAble={true}
              />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="purchase-order-date"
                value=""
                name="Purchase Order Date"
                type="date"
                disable="disabled"
              />
              <InlineInputForm
                id="return-date"
                value=""
                name="Return Date"
                type="date"
              />
              <InlineDropdown
                searchAble={true}
                id="template"
                name="Template"
                values={[
                  { value: "Globus Deafult", label: "Globus Deafult" },
                  {
                    value: "Default Supplier Return",
                    label: "Default Supplier Return",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <SupplierReturnsTable />
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4">
              <label className="input-label" htmlFor="comments">
                Comments
              </label>
              <textarea disabled id="comments" rows="10" />
            </div>
            <div className="col-md-6 col-sm-2 col-xs-6"></div>
            <div className="col-md-2 col-xs-6 stat-div">
              <small className="stat-label">SUB TOTAL (PKR)</small>
              <div className="stat-value">0.00</div>
              <small className="stat-label">TAX TOTAL (PKR)</small>
              <div className="stat-value">0.00</div>
              <hr />
              <small className="stat-label">TOTAL (PKR)</small>
              <div className="stat-value">0.00</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
