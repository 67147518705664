import React from "react";
import Select from "react-select";

export default function Product({
  onChange,
  products,
  onClickAdd,
  handleChange,
  stateData,
}) {
  return (
    <div className="row" id="settings-field">
      <div className="col-md-3 padding-1-right">
        <label className="input-label" htmlFor="product">
          Product
        </label>
        <Select
          id="product"
          name="product"
          isClearable
          isSearchable
          options={products}
          onChange={(value) => handleChange(value, "product")}
        />
        {/* <input
          type="text"
          className="form-control"
          id="product"
          name="product"
        /> */}
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="availability">
          Availability
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          id="availability"
          name="availability"
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="1uantity">
          * Quantity
        </label>
        <input
          type="text"
          className="form-control"
          id="quantity"
          name="quantity"
          value={stateData && stateData.quantity ? stateData.quantity : ""}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="col-md-6 padding-1">
        <label className="input-label" htmlFor="productComment">
          Comments
        </label>
        <input
          type="text"
          className="form-control"
          id="productComment"
          name="productComment"
          value={
            stateData && stateData.productComment
              ? stateData.productComment
              : ""
          }
          onChange={(e) => onChange(e)}
        />
      </div>

      <div className="col-md-1" id="add-btn">
        <label className="input-label">Add</label>
        <button
          type="button"
          className="btn btn-success btn-sm"
          onClick={() => onClickAdd()}
        >
          Add
        </button>
      </div>
    </div>
  );
}
