import React from "react";
import AttributeSetTable from "./attributeSetTable";
import ConfirmationModal from "@components/common/confirmationModal";
import TwoInputEditModal from "@components/common/twoInputEditModal";

export default function AttributeSets({
  attributeSets,
  attributeSetName,
  type,
  name,
  name1,
  editAttribute,
  editType,
  onAttributeSelect,
  onUpdate,
  onChange,
  onAdd,
  onDelete,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Attribute Set Name</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="attribute-set-name">
                * Attribute Set Name
              </label>
              <input
                onChange={(e) => onChange(e)}
                value={attributeSetName}
                name="attributeSetName"
                className="form-control"
                id="attribute-set-name"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="attribute-type">
                * Type
              </label>
              <input
                onChange={(e) => onChange(e)}
                value={type}
                name="type"
                className="form-control"
                id="attribute-type"
              />
            </div>
            <div className="col-md-1" id="add-btn">
              <label className="input-label">Add</label>
              <button
                onClick={() => onAdd()}
                className="btn btn-success btn-sm"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <AttributeSetTable
            attributeSets={attributeSets}
            onAttributeSelect={(data) => onAttributeSelect(data)}
          />
        </div>
      </div>
      <ConfirmationModal onDelete={() => onDelete()} item="Attribute Set" />
      <TwoInputEditModal
        label="Attribute Set Name"
        label1="Type"
        name={name}
        name1={name1}
        value={editAttribute}
        value1={editType}
        onChange={(e) => onChange(e)}
        onUpdate={() => onUpdate()}
      />
    </>
  );
}
