import React from "react";
import Tabs from "@components/common/tabs";
import Details from "./details";
import Address from "@components/common/address";
import Contacts from "./contacts";
import DeliveryAddress from "./deliveryAddress";
import Quotes from "./quotes";
import CustomerPricing from "./customerPricing";
import Orders from "./orders";
import Credits from "./credits";

export default function AddCustomer({
  stateData,
  onChange,
  handleChange,
  onClickSave,
  currencies,
  edit,
  customerTypesData,
  salesGroupsData,
  salesPersonData,
  warehousesData,
  paymentTermsData,
  sellPriceTiersData,
  currencyRatesData,
  taxesData,
  deliveryMethodData,
  countries,
  onClickSaveDeliveryAddress,
  onClickSaveContact,
  contacts,
  onClickContactAction,
  deliveryAddresses,
  getSelectedValue,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>
              {edit ? "Update Customer" : ""}
              {edit
                ? stateData && stateData.customerData
                  ? " [" + stateData.customerData.customerCode + "]"
                  : ""
                : "Add Customer"}
            </label>
          </div>
          <div className="col-lg-5 col-md-2 col-1"></div>
          <div
            className="col-lg-3 col-md-3 col-3"
            style={{ textAlign: "right" }}
          >
            <button
              className="btn btn-success btn-sm"
              onClick={() => onClickSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={stateData.nav} />
          <div className="tab-content">
            <Details
              customerTypesData={customerTypesData}
              salesGroupsData={salesGroupsData}
              salesPersonData={salesPersonData}
              warehousesData={warehousesData}
              paymentTermsData={paymentTermsData}
              sellPriceTiersData={sellPriceTiersData}
              currencyRatesData={currencyRatesData}
              taxesData={taxesData}
              deliveryMethodData={deliveryMethodData}
              edit={edit}
              stateData={stateData.customerData}
              currencies={currencies}
              onChange={(e) => onChange(e, "customer")}
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "customer")
              }
              getSelectedValue={(value, options) =>
                getSelectedValue(value, options)
              }
            />
            <Address
              countries={countries}
              postal={
                stateData ? (stateData.postal ? stateData.postal : null) : null
              }
              physical={
                stateData
                  ? stateData.physical
                    ? stateData.physical
                    : null
                  : null
              }
              onChange={(e, obj) => onChange(e, obj)}
              handleChange={(newValue, actionMeta, obj) =>
                handleChange(newValue, actionMeta, obj)
              }
            />
            <Contacts
              onClickSaveContact={() => onClickSaveContact()}
              edit={edit}
              contacts={contacts}
              onChange={(e) => onChange(e, "contacts")}
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "contacts")
              }
              onClickContactAction={(action, id) =>
                onClickContactAction(action, id)
              }
            />
            <DeliveryAddress
              onClickSaveDeliveryAddress={() => onClickSaveDeliveryAddress()}
              onChange={(e) => onChange(e, "deliveryAddress")}
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "deliveryAddress")
              }
              countries={countries}
              edit={edit}
              deliveryAddresses={deliveryAddresses}
            />
            <Quotes />
            <Orders />
            <Credits />
            <CustomerPricing />
          </div>
        </div>
      </div>
    </>
  );
}
