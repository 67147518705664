import React, { Component } from "react";
import { loaderState, getPriceTiers, addTiers, updateTier } from "@actions";
import { connect } from "react-redux";
import _ from "lodash";
import { removeNullValues } from "@helpers";
import SellPriceTierComp from "@components/setting/system/sellPriceTier";

class SellPriceTier extends Component {
  componentDidMount() {
    this.props.loaderState(true);
    this.props.getPriceTiers();
    const { tiers } = this.props;
    this.setState({ ...tiers });
  }

  onUpdate = () => {
    let data = removeNullValues(this.state);
    const { tiers, user } = this.props;
    if (JSON.stringify(data) != "{}") {
      _.forEach(Object.keys(data), (key) => {
        let updatedTier = _.findLast(tiers, (tier) => {
          return "tier_" + tier.id === key;
        });
        if (updateTier) {
          this.props.updateTier({
            name: data[key],
            id: updatedTier.id,
            updatedBy: user.id,
          });
        }
        // this.props.loaderState(true);
      });
    }
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  render() {
    const { tiers } = this.props;
    let data = this.state;
    return (
      <SellPriceTierComp
        data={data}
        tiers={tiers}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tiers: state.system.sellPriceTiers.allTiers,
    user: state.auth.loggedInUser,
  };
};
export default connect(mapStateToProps, {
  loaderState,
  getPriceTiers,
  addTiers,
  updateTier,
})(SellPriceTier);
