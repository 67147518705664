import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getRolls,
  loaderState,
  getAppFunctionalities,
  getRolePermissions,
  saveRolePermissions,
} from "@actions";
import RolePermissonsComp from "@components/setting/security/rolePermissions";

class RolePermissons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appFunctionalities: [],
      permissions: [],
      role: null,
    };
  }

  componentDidMount() {
    const { roles } = this.props;
    this.props.getAppFunctionalities().then((res) => {
      this.setFunctionalityData(res);
      if (roles && roles.length > 0) {
        this.setState({ role: roles[0] });
        this.getRolePermissions(roles[0].id);
      }
      if (!roles) {
        this.props.loaderState(true);
        this.props.getRolls().then((res) => {
          if (res.length > 0) {
            this.setState({ roles: res, role: res[0] });
            this.getRolePermissions(res[0].id);
          }
        });
      }
    });
  }

  setFunctionalityData = (functionalites) => {
    let data = [];
    let parents = [];
    if (functionalites.length > 0) {
      _.forEach(functionalites, (func) => {
        if (!func.child_of) {
          parents.push(func);
        }
      });
      _.forEach(parents, (p) => {
        let childrens = [];
        _.forEach(functionalites, (func1) => {
          if (p.id != func1.id && p.id == func1.child_of) {
            childrens.push(func1);
          }
        });
        data.push({
          ...p,
          childrens,
        });
        childrens = [];
      });
    }
    this.setState({ appFunctionalities: data });
  };

  isPermissionExist = (permissions, functionality_id) => {
    let exist = null;
    _.forEach(permissions, (p) => {
      if (p.functionality_id == functionality_id) {
        exist = p;
      }
    });

    return exist;
  };

  getRolePermissions = (role_id) => {
    this.props.loaderState(true);
    const { appFunctionalities } = this.state;
    this.props.getRolePermissions({ role_id }).then((permissions) => {
      let updatedPermissions = [];
      _.forEach(appFunctionalities, (func) => {
        let perm = this.isPermissionExist(permissions, func.id);
        console.log("perm", perm);
        let permisionObj = {
          role_id,
          create: false,
          read: false,
          update: false,
          delete: false,
        };
        if (!perm) {
          updatedPermissions.push({
            ...permisionObj,
            functionality_id: func.id,
            child_of: func.child_of,
          });
        } else {
          updatedPermissions.push({
            ...perm,
            child_of: func.child_of,
          });
        }
        if (func.childrens && func.childrens.length > 0) {
          _.forEach(func.childrens, (c) => {
            let perm_c = this.isPermissionExist(permissions, c.id);
            if (!perm_c) {
              updatedPermissions.push({
                ...permisionObj,
                functionality_id: c.id,
                child_of: c.child_of,
              });
            } else {
              updatedPermissions.push({
                ...perm_c,
                child_of: c.child_of,
              });
            }
          });
        }
      });
      this.setState({ permissions: updatedPermissions });
    });
  };

  handleChangePermission = (functionality_id, permission, value) => {
    const { permissions } = this.state;
    let updatedPermissions = [];
    _.forEach(permissions, (p) => {
      if (
        p.functionality_id == functionality_id ||
        p.child_of == functionality_id
      ) {
        updatedPermissions.push({
          ...p,
          [permission]: value,
        });
      } else {
        updatedPermissions.push(p);
      }
    });
    this.setState({ permissions: updatedPermissions });
  };

  getFunctionalityPermisson = (functionality_id) => {
    const { permissions } = this.state;
    let func = null;
    _.forEach(permissions, (p) => {
      if (p.functionality_id == functionality_id) {
        func = p;
      }
    });

    return func;
  };

  onChangeRole = (role) => {
    this.setState({ role });
    this.getRolePermissions(role.id);
  };

  onClickSave = () => {
    const { permissions } = this.state;
    this.props.loaderState(true);
    let updatedPermissions = [];
    _.forEach(permissions, (p) => {
      let u = p;
      delete u.child_of;
      delete u.name;
      updatedPermissions.push(u);
    });

    this.props.saveRolePermissions(updatedPermissions).then((res) => {
      this.setState({ permissions: res });
    });
  };

  render() {
    const { roles } = this.props;
    const { appFunctionalities, role } = this.state;

    return (
      <RolePermissonsComp
        roles={roles}
        appFunctionalities={appFunctionalities}
        selectedRole={role}
        onChangeRole={this.onChangeRole}
        handleChangePermission={this.handleChangePermission}
        onClickSave={this.onClickSave}
        getFunctionalityPermisson={this.getFunctionalityPermisson}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.system.roles.allRoles,
  };
};

const mapDispatchToProps = {
  loaderState,
  getRolls,
  getAppFunctionalities,
  getRolePermissions,
  saveRolePermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolePermissons);
