import React from "react";
import CurrencyRatesTable from "./currencyRatesTable";
import ConfirmationModal from "@components/common/confirmationModal";
import EditModal from "./editModal";
import Select from "react-select";

export default function CurrencyRates({
  sellRate,
  buyRate,
  onUpdate,
  onChange,
  editBuyRate,
  editSellRate,
  countries,
  currencies,
  editCurr,
  handleChange,
  selectedBase,
  selectedSelection,
  handleChangeCurrencySelection,
  handleChangeEdit,
  onAdd,
  baseCur,
  onCurrencySelect,
  onDelete,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>Currency Rates</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-3 padding-1-right">
              {/* <div className="form-group"> */}
              <label className="input-label" htmlFor="base-currency">
                Base Currency
              </label>
              <Select
                value={baseCur}
                id="baseCurrecny"
                disabled
                isClearable
                onChange={handleChange}
                options={countries}
              />
              {/* </div> */}
              <div style={{ marginTop: "10px" }}>
                <input
                  value={selectedBase ? selectedBase.value : null}
                  disabled
                  className="form-control"
                  id="base-currency-country"
                />
              </div>
            </div>
            <div className="col-md-3 padding-1">
              {/* <div className="form-group"> */}
              <label className="input-label" htmlFor="currency-selection">
                * Currency Selection
              </label>
              <Select
                isClearable
                onChange={handleChangeCurrencySelection}
                options={countries}
              />
              {/* </div> */}
              <div style={{ marginTop: "10px" }}>
                <input
                  value={selectedSelection ? selectedSelection.value : null}
                  disabled
                  className="form-control"
                  id="selected-currency-country"
                />
              </div>
            </div>
            <div className="col-md-2 padding-1">
              {/* <div className="form-group"> */}
              <label className="input-label" htmlFor="buyRate">
                * Buy Rate
              </label>
              <input
                value={buyRate}
                onChange={(e) => onChange(e)}
                type="number"
                className="form-control"
                id="buyRate"
              />
              {/* </div> */}
            </div>
            <div className="col-md-2 col-xs-9 padding-1">
              {/* <div className="form-group"> */}
              <label className="input-label" htmlFor="sellRate">
                * Sell Rate
              </label>
              <input
                value={sellRate}
                onChange={(e) => onChange(e)}
                type="number"
                className="form-control"
                id="sellRate"
              />
              {/* </div> */}
            </div>
            <div className="col-xs-2" id="system-add-btn">
              <button
                onClick={() => onAdd()}
                className="btn btn-success btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CurrencyRatesTable
            currencies={currencies}
            onCurrencySelect={(data) => onCurrencySelect(data)}
          />
        </div>
      </div>
      <ConfirmationModal onDelete={() => onDelete()} item="Currency Rate" />
      <EditModal
        value={editCurr}
        value1={editBuyRate}
        value2={editSellRate}
        countries={countries}
        handleChangeEdit={(value) => handleChangeEdit(value)}
        onChange={(e) => onChange(e)}
        onUpdate={() => onUpdate()}
      />
    </>
  );
}
