import { common } from "@constants/actionTypes";

const initialState = {
  isLoading: false,
  url: "/",
  countriesData: null,
  quickLinks: null,
  codeChanges: null,
  selectedCodeChange: null,
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case common.LOADER:
      return { ...state, isLoading: action.payload };
    case common.SELECTED_URL:
      return { ...state, url: action.payload };
    case common.COUNTRIES_DATA:
      return { ...state, countriesData: action.payload };
    case common.QUICK_LINKS:
      return { ...state, quickLinks: action.payload };
    case common.CODE_CHANGES:
      return { ...state, codeChanges: action.payload };
    case common.SELECTED_CODE_CHANGE:
      return { ...state, selectedCodeChange: action.payload };
    default:
      return state;
  }
}
export default commonReducer;
