import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InLineCheckbox from "@components/common/inLineCheckbox";

export default function Purchasing({ stateData, onChange }) {
  return (
    <div id="Purchasing" className="tab-pane fade">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="inline-form">
            <div className="field">
              <label htmlFor="purchasingFooterDetails">Footer Details</label>
              <textarea
                onChange={(e) => onChange(e)}
                value={
                  stateData
                    ? stateData.purchasingFooterDetails
                      ? stateData.purchasingFooterDetails
                      : ""
                    : ""
                }
                rows="7"
                type="text"
                id="purchasingFooterDetails"
              />
            </div>
          </div>
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.purchaseOrderReportPrintName
                  ? stateData.purchaseOrderReportPrintName
                  : ""
                : ""
            }
            id="purchaseOrderReportPrintName"
            name="Purchase Order Report Print Name"
          />
          <InLineCheckbox
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.purchaseEnable || stateData.purchaseEnable === 1
                  ? "checked"
                  : ""
                : ""
            }
            id="purchaseEnable"
            name="Enable"
          />
        </div>
      </div>
    </div>
  );
}
