import React from "react";

export default function Product({ goto }) {
  return (
    <div className="row" id="settings-field">
      <div className="col-md-2 padding-1-right">
        <label className="input-label" htmlFor="product">
          <a onClick={() => goto("/inverntory/products/view products")}>
            Product
          </a>
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          id="product"
          name="product"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="quantity">
          *Quantity
        </label>
        <input
          disabled
          type="number"
          className="form-control"
          id="quantity"
          name="quantity"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="price">
          Price
        </label>
        <input
          disabled
          type="number"
          className="form-control"
          id="price"
          name="price"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="discount">
          Discount
        </label>
        <input
          disabled
          type="number"
          className="form-control"
          id="discount"
          name="discount"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="availability">
          Availability
        </label>
        <input
          disabled
          type="number"
          className="form-control"
          id="availability"
          name="availability"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="sub-total">
          Sub Total
        </label>
        <input
          disabled
          type="number"
          className="form-control"
          id="sub-total"
          name="sub-total"
        />
      </div>
      <div className="col-md-4 padding-1">
        <label className="input-label" htmlFor="comments">
          Comments
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          id="comments"
          name="comments"
        />
      </div>

      <div className="col-md-1" id="add-btn">
        <label className="input-label">Add</label>
        <button disabled type="button" className="btn btn-success btn-sm">
          Add
        </button>
      </div>
    </div>
  );
}
