import React from "react";
import TableActions from "@components/common/tableActions";

export default function QuotesTable() {
  return (
    <div className="table-view table-responsive">
      <table id="quotes-table" className="table">
        <thead>
          <tr>
            {/* <th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th> */}
            <th>Quote No.</th>
            <th>Quote Date</th>
            <th>Quote Expiry Date </th>
            <th>Customer Code</th>
            <th>Customer Name</th>
            <th>Reference</th>
            <th>Warehouse</th>
            <th>Status</th>
            <th>Currency</th>
            <th>Cost</th>
            <th>Margin</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>sad</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>
              <TableActions
                icon="fa fa-cog icon"
                data={["Edit", "Email", "Clone", "Accept", "Cancel", "Delete"]}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
