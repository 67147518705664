import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import BatchNumberComp from "@components/reports/inventory/batchNumber";

export default class BatchNumber extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <BatchNumberComp />;
  }
}
