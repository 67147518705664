import React from "react";
import UnitsTable from "./unitsTable";
import ConfirmationModal from "@components/common/confirmationModal";
import OneInputEditModal from "@components/common/OneInputEditModal";

export default function UnitOfMeasure({
  addValue,
  editValue,
  onChange,
  onUpdate,
  units,
  onAdd,
  onChangeEdit,
  onUnitUpdateOrDelete,
  onDelete,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Units Of Measure</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 col-xs-9">
              <label className="input-label" htmlFor="unit-of-measure">
                * Unit Of Measure Name
              </label>
              <input
                value={addValue}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="unit-of-measure"
              />
            </div>
            <div className="col-xs-2" id="system-add-btn">
              <button
                onClick={() => onAdd()}
                className="btn btn-success btn-sm"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <UnitsTable
            onUnitUpdateOrDelete={(data) => onUnitUpdateOrDelete(data)}
            units={units}
          />
        </div>
      </div>
      <ConfirmationModal onDelete={() => onDelete()} item="Unit of Measure" />
      <OneInputEditModal
        label="Unit Of Measure Name"
        value={editValue}
        onChange={(e) => onChangeEdit(e)}
        onUpdate={() => onUpdate()}
      />
    </>
  );
}
