import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getPriceTiers = () => (dispatch) => {
  api
    .get("/sellPriceTier")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.SELL_PRICE_TIERS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addTiers = ({ name, createdBy }) => (dispatch, getState) => {
  var allTiers = getState().system.sellPriceTiers.allTiers;
  api
    .post("/sellPriceTier", {
      name,
      createdBy,
      updatedBy: createdBy,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.SELL_PRICE_TIERS,
          payload: [...allTiers, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateTier = ({ name, id, updatedBy }) => (dispatch, getState) => {
  var allTiers = getState().system.sellPriceTiers.allTiers;
  api
    .post(`/sellPriceTier/update/${id}`, { name, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedTier = _.findLast(allTiers, (tier) => {
          return Number(tier.id) === Number(id);
        });
        updatedTier.name = name;
        let updatedTiers = _.map(allTiers, (role) => {
          return role.id === id ? updatedTier : role;
        });
        dispatch({ type: settings.ROLES, payload: updatedTiers });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};
