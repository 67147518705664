import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loaderState,
  getWarehouses,
  deleteWarehouse,
  onSelectWarehouse,
} from "@actions";
import { goto, initilizeDataTable, destroyDataTable } from "@helpers";
import WarehousesComp from "@components/setting/system/warehouses";

class Warehouses extends Component {
  componentDidMount() {
    initilizeDataTable("warehouses-table");
    this.props.loaderState(true);
    this.props.getWarehouses();
  }

  componentDidUpdate() {
    initilizeDataTable("warehouses-table");
  }

  onDelete = () => {
    const { selectedWarehouse } = this.props;
    this.props.loaderState(true);
    this.props.deleteWarehouse(selectedWarehouse.id);
  };

  onWarehouseUpdateOrDelete = (data) => {
    this.props.onSelectWarehouse(data);
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  render() {
    destroyDataTable("warehouses-table");

    const { warehouses } = this.props;
    return (
      <WarehousesComp
        onWarehouseUpdateOrDelete={(data) =>
          this.onWarehouseUpdateOrDelete(data)
        }
        warehouses={warehouses}
        onDelete={() => this.onDelete()}
        goto={(path) => goto(path)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
    user: state.auth.loggedInUser,
    selectedWarehouse: state.system.warehouses.selectedWarehouse,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getWarehouses,
  onSelectWarehouse,
  deleteWarehouse,
})(Warehouses);
