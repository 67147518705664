import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import AssembliesTable from "./assembliesTable";

export default function AddAssembly({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>AddAssembly</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="source_warehouse"
                name="Source Warehouse"
                values={warehouses}
              />
              <InlineDropdown
                searchAble={true}
                id="destination_warehouse"
                name="Destination Warehouse"
                values={warehouses}
              />
              <InlineInputForm
                id="product_code"
                value=""
                name="*Product Code"
              />
              <InlineInputForm
                id="product_description"
                value=""
                name="*Product Description"
              />
              <InlineInputForm
                disable="disabled"
                id="assembled_quantity"
                value="0"
                name="Assembled Quantity"
                type="number"
              />
              <InlineInputForm
                disable="disabled"
                id="can_assemble_qty"
                value="0"
                name="Can Assemble Qty"
                type="number"
              />
              <InlineInputForm
                disable="disabled"
                id="can_assemble_qty_ll_levels"
                value="0"
                name="Can Assemble Qty (All levels)"
                type="number"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                disable="disabled"
                id="total_cost"
                value="0"
                name="Total Cost"
                type="number"
              />
              <InlineInputForm
                disable="disabled"
                id="assemble_by"
                value=""
                type="date"
                name="Assemble By"
              />
              <InlineDropdown
                searchAble={true}
                disable="disabled"
                id="assembly_template"
                name="Assembly Template"
                values={[
                  { label: "Globuss Default", value: "Globuss Default" },
                  { label: "Default Assembly", value: "Default Assembly" },
                ]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="inline-form">
                <div className="field">
                  <label htmlFor="comments">Comments</label>
                  <textarea rows="7" type="text" id="comments" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-6 padding-1-right">
              <label className="input-label" htmlFor="component_product">
                *Component Product
              </label>
              <input
                disabled
                type="text"
                className="form-control"
                id="component_product"
                name="component_product"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="qauntity">
                *Quantity
              </label>
              <input
                disabled
                type="number"
                className="form-control"
                id="qauntity"
                name="qauntity"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="wastage_qauntity">
                Wastage Qty
              </label>
              <input
                disabled
                type="number"
                className="form-control"
                id="wastage_qauntity"
                name="wastage_qauntity"
              />
            </div>
            <div className="col-md-2" id="system-add-btn">
              <button disabled type="button" className="btn btn-success btn-sm">
                Add
              </button>
            </div>
          </div>
          <br />
          <AssembliesTable />
        </div>
      </div>
    </>
  );
}
