import React, { Component } from "react";
import { goto, initilizeDataTable, destroyDataTable } from "@helpers";
import {
  getSuppliers,
  loaderState,
  selectedNav,
  deleteSupplier,
  updateSupplier,
  onSelectSupplier,
} from "@actions";
import { connect } from "react-redux";
import ViewSupplierComp from "@components/suppliers/view";

class ViewSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: true,
    };
  }
  componentDidMount() {
    this.props.loaderState(true);
    this.props.getSuppliers();
    initilizeDataTable("suppliers-table");
  }

  componentDidUpdate() {
    initilizeDataTable("suppliers-table");
  }

  onClickAction = (action) => {
    const { selectedSupplier } = this.props;
    if (action === "Delete") {
      this.props.loaderState(true);
      this.props.deleteSupplier(selectedSupplier.supplierData.id);
    } else if (action === "Edit") {
      this.props.selectedNav("/suppliers/add suppliers");
      goto(`/suppliers/update/${selectedSupplier.supplierData.id}`);
    } else if (action === "Add Purchase") {
    } else if (action === "View Transactions") {
    } else if (action === "Obsolete") {
      let data = { obsolete: 1 };
      this.props.loaderState(true);
      this.props.updateSupplier(data);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { rerender } = this.state;
    if (rerender === nextState.rerender) {
      return true;
    } else {
      return false;
    }
  }

  onSupplierSelect = (supplier) => {
    this.setState({ rerender: !this.state.rerender });
    this.props.onSelectSupplier(supplier);
  };

  gotoSupplier = async (supplier) => {
    await this.onSupplierSelect(supplier);
    await this.props.selectedNav("/suppliers/add suppliers");
    goto(`/suppliers/update/${supplier.supplierData.id}`);
  };

  render() {
    destroyDataTable("suppliers-table");
    const { suppliers } = this.props;

    return (
      <ViewSupplierComp
        suppliers={suppliers}
        goto={(path) => goto(path)}
        gotoSupplier={(supplier) => this.gotoSupplier(supplier)}
        onSupplierSelect={(supplier) => this.onSupplierSelect(supplier)}
        onClickAction={(action) => this.onClickAction(action)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.suppliers.allSuppliers,
    selectedSupplier: state.suppliers.selectedSupplier,
  };
};

export default connect(mapStateToProps, {
  getSuppliers,
  loaderState,
  selectedNav,
  deleteSupplier,
  updateSupplier,
  onSelectSupplier,
})(ViewSupplier);
