import React from 'react';

export default function inLineCheckbox({ id, name, value, onChange, disable }) {
	return (
		<div className="inline-form">
			<div className="field">
				<label htmlFor={id}>{name}</label>
				{/* <div className="container-d">
					<input
						type="checkbox"
						disabled={disable ? 'disabled' : ''}
						onChange={e => onChange(e)}
						id={id}
						checked={value}
					/>
					<span onClick={() => fireOnChange(id)} className="checkmark"></span>
				</div> */}
				<input
					disabled={disable ? 'disabled' : ''}
					onChange={e => onChange(e)}
					id={id}
					type="checkbox"
					style={{ width: '20px', height: '20px' }}
					checked={value}
				/>
			</div>
		</div>
	);
}
