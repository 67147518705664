import React from "react";
import SelectedManus from "./selectedManus";
import Select from "react-select";

export default function ShortcutsNav({
  manus,
  handleChange,
  SelectedLinks,
  onClickAddOrRemove,
  options,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>Customize Shortcuts</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 manus">
          <div className="row" id="settings-field">
            <div className="col-md-6 padding-1-right">
              <label className="input-label" htmlFor="manu">
                Manu
              </label>
              <Select
                id="unitId"
                name="unitId"
                isClearable
                onChange={handleChange}
                options={options}
                isSearchable
              />
            </div>
          </div>
          <br />
          <div className="available-manus">
            <div className="manu-items">
              {manus
                ? manus.map((manu) =>
                    manu.name.toLowerCase() !== "dashboard" ? (
                      <div key={manu.name}>
                        <label className="heading">{manu.name}</label>
                        <hr />
                        <div className="row">
                          {manu.links
                            ? manu.links.map((link) => (
                                <div key={link.id} className="col-xs-6">
                                  <div className="item">
                                    <i className={link.icon} /> {link.name}
                                    <div className="quickLinks-dropdown">
                                      <i
                                        title="Add to quicklinks"
                                        className="fa fa-check"
                                        onClick={() =>
                                          onClickAddOrRemove(link.id, 1, "add")
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="manu">
            <SelectedManus
              onClickAddOrRemove={(id, status, op) =>
                onClickAddOrRemove(id, status, op)
              }
              SelectedLinks={SelectedLinks}
            />
          </div>
        </div>
      </div>
    </>
  );
}
