import React, { Component } from "react";
import ImportStockCountsComp from "@components/inventory/importStockCounts";

export default class ImportStockCounts extends Component {
  componentDidMount() {
    document.title = "Import Stock Count";
  }
  render() {
    return (
      <div>
        <ImportStockCountsComp />
      </div>
    );
  }
}
