import React from 'react';

export default function Stats() {
	return (
		<div className="row">
			<div className="col-md-4">
				<label className="input-label" htmlFor="comments">
					Comments
				</label>
				<textarea disabled id="comments" rows="10" />
			</div>
			<div className="col-md-2"></div>
			<div className="col-md-3 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Purchase Order Sub Order</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Purchase Order Tax Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Purchase Order Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
			</div>
			<div className="col-md-3 col-xs-6">
				<div className="border-bottom">
					<span className="receipt-stat-label">Costing Sub Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Costing Tax Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Costing Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">
						Total Volume (m <sup>3</sup>)
					</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<div className="border-bottom">
					<span className="receipt-stat-label">Total Weight</span>
					<span className="pull-right receipt-stat-value">0.00</span>
				</div>
				<br />
				<button className="btn btn-light pull-right">Print Label</button>
			</div>
		</div>
	);
}
