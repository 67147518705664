import React, { Component } from "react";
import { connect } from "react-redux";
import { goto } from "@helpers";
import { loaderState, selectedNav, getQuickLinks } from "@actions";
import _ from "lodash";

import HeaderComp from "@components/header";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.loaderState(true);
    this.props.getQuickLinks();
    // setTimeout(() => {
    // 	this.props.getRolePermissions('1');
    // }, 2000);
  }

  goto = (path) => {
    if (path == "/") {
    }
    this.props.selectedNav(path);
    goto(path);
  };

  getSelectedLinks = () => {
    const { quicklinks } = this.props;
    let data = [];
    _.forEach(quicklinks, (link) => {
      if (link.status === 1) {
        data.push(link);
      }
    });

    return data;
  };

  render() {
    const { user } = this.props;
    let selectedLinks = this.getSelectedLinks();
    return (
      <HeaderComp
        quicklinks={selectedLinks}
        user={user}
        goto={(path) => this.goto(path)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.loggedInUser,
    quicklinks: state.common.quickLinks,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  selectedNav,
  getQuickLinks,
})(Header);
