let data = {
  sideManu: [
    {
      name: "Dashboard",
      icon: "fa fa-tachometer",
      url: "/dashboard",
    },
    {
      name: "Purchases",
      icon: "fa fa-shopping-cart",
      subManu: [
        {
          name: "Purchase Orders",
          subSubManu: [
            // {
            //   name: "Add Purchase",
            //   url: "/purchases/purchase orders/add purchase",
            // },
            {
              name: "View Purchases",
              url: "/purchases/purchase orders/view purchases",
            },
            {
              name: "Receipt Purchase",
              url: "/purchases/purchase orders/receipt purchase",
            },
            {
              name: "Import Purchases",
              url: "/purchases/purchase orders/import purchases",
            },
            {
              name: "Export Purchases",
              url: "/purchases/purchase orders/export purchases",
            },
          ],
        },
        {
          name: "Supplier Returns",
          subSubManu: [
            // {
            //   name: "Add Supplier Return",
            //   url: "/purchases/supplier returns/add supplier return",
            // },
            {
              name: "View Supplier Returns",
              url: "/purchases/supplier returns/view supplier returns",
            },
          ],
        },
      ],
    },
    {
      name: "Inventory",
      icon: "fa fa-database",
      subManu: [
        {
          name: "Transactions",
          subSubManu: [
            {
              name: "Stock Adjustments",
              url: "/inventory/transactions/stock adjustments",
            },
            {
              name: "Warehouse Transfers",
              url: "/inventory/transactions/warehouse transfers",
            },
            { name: "Stock Count", url: "/inventory/transactions/stock count" },
            {
              name: "Import Stock Revaluation",
              url: "/inventory/transactions/import stock revaluation",
            },
          ],
        },
        {
          name: "Products",
          subSubManu: [
            // { name: "Add Product", url: "/inventory/products/add product" },
            { name: "View Products", url: "/inventory/products/view products" },
            { name: "ImportExport", url: "/inventory/products/ImportExport" },
            { name: "Change Codes", url: "/inventory/products/change codes" },
            {
              name: "Markup Price Update",
              url: "/inventory/products/markup price update",
            },
          ],
        },
      ],
    },
    // {
    // 	name: 'Productions',
    // 	icon: 'fa fa-building',
    // 	subManu: [
    // 		{
    // 			name: 'Assemblies',
    // 			subSubManu: [
    // 				{ name: 'Add Assembly', url: '/productions/assemblies/add assembly' },
    // 				{ name: 'Add Disassembly', url: '/productions/assemblies/add disassembliy' },
    // 				{ name: 'View Assemblies', url: '/productions/assemblies/view assemblies' },
    // 			],
    // 		},
    // 		{
    // 			name: 'Bill of Materials',
    // 			subSubManu: [
    // 				{ name: 'Add Bill Of Materials', url: '/productions/bill of materials/add bill of materials' },
    // 				{
    // 					name: 'View Bills Of Materials',
    // 					url: '/productions/bill of materials/view bill of materials',
    // 				},
    // 				{
    // 					name: 'Import Bills Of Materials',
    // 					url: '/productions/bill of materials/import bill of materials',
    // 				},
    // 				{
    // 					name: 'Export Bills Of Materials',
    // 					url: '/productions/bill of materials/export bill of materials',
    // 				},
    // 			],
    // 		},
    // 	],
    // },
    {
      name: "Sales",
      icon: "fa fa-usd",
      subManu: [
        {
          name: "Quotes",
          subSubManu: [
            // { name: "Add Quote", url: "/sales/quotes/add quote" },
            { name: "View Quotes", url: "/sales/quotes/view quotes" },
            { name: "Import Quotes", url: "/sales/quotes/import quotes" },
          ],
        },
        {
          name: "Orders",
          subSubManu: [
            // { name: "Add Sales Orders", url: "/sales/orders/add sales orders" },
            {
              name: "View Sales Orders",
              url: "/sales/orders/view sales orders",
            },
            { name: "View Shipments", url: "/sales/orders/view shipments" },
            { name: "Batch Complete", url: "/sales/orders/batch complete" },
            {
              name: "Import Sales Orders",
              url: "/sales/orders/import sales orders",
            },
            {
              name: "Export Sales Orders",
              url: "/sales/orders/export sales orders",
            },
          ],
        },
        {
          name: "Credits",
          subSubManu: [
            // { name: "Add Credit", url: "/sales/credits/add credit" },
            { name: "View Credits", url: "/sales/credits/view credits" },
            { name: "Export Credits", url: "/sales/credits/export credits" },
          ],
        },
      ],
    },
    {
      name: "Customers",
      icon: "fa fa-users",
      subManu: [
        // {
        //   name: "Add Customer",
        //   url: "/customers/add customers",
        // },
        {
          name: "View Customers",
          url: "/customers/view customers",
        },
        {
          name: "ImportExport",
          url: "/customers/importexport",
        },
        {
          name: "Change Codes",
          url: "/customers/change codes",
        },
      ],
    },
    {
      name: "Suppliers",
      icon: "fa fa-truck",
      subManu: [
        // {
        //   name: "Add Supplier",
        //   url: "/suppliers/add suppliers",
        // },
        {
          name: "View Suppliers",
          url: "/suppliers/view suppliers",
        },
        {
          name: "Import Suppliers",
          url: "/suppliers/import suppliers",
        },
        {
          name: "Export Suppliers",
          url: "/suppliers/export suppliers",
        },
        {
          name: "Change Codes",
          url: "/suppliers/change codes",
        },
      ],
    },
    // {
    // 	name: 'Integration',
    // 	icon: 'fa fa-th',
    // 	subManu: [
    // 		{
    // 			name: 'Integration Store',
    // 			url: '#',
    // 		},
    // 		{
    // 			name: 'Data Explorer',
    // 			url: '#',
    // 		},
    // 	],
    // },
    // {
    //   name: "Reports",
    //   icon: "fa fa-pie-chart",
    //   subManu: [
    //     {
    //       name: "Purchase",
    //       subSubManu: [
    //         {
    //           name: "Purchase Equiry",
    //           url: "/reports/purchase/purchase enquiry",
    //         },
    //         {
    //           name: "Costing Analysis Report",
    //           url: "/reports/purchase/costing analysis report",
    //         },
    //         { name: "Reorder Report", url: "/reports/purchase/reorder report" },
    //       ],
    //     },
    //     {
    //       name: "Inventory",
    //       subSubManu: [
    //         {
    //           name: "Product Enquiry",
    //           url: "/reports/inventory/product enquiry",
    //         },
    //         {
    //           name: "Stock On Hand Enquiry",
    //           url: "/reports/inventory/stock on hand enquiry",
    //         },
    //         {
    //           name: "Batch Number Enquiry",
    //           url: "/reports/inventory/batch number enquiry",
    //         },
    //         {
    //           name: "Serial Number Equiry",
    //           url: "/reports/inventory/serial number enquiry",
    //         },
    //         {
    //           name: "Transaction Enquiry",
    //           url: "/reports/inventory/transaction enquiry",
    //         },
    //         {
    //           name: "Product Allocations",
    //           url: "/reports/inventory/product allocations",
    //         },
    //         {
    //           name: "Quict Stock Count",
    //           url: "/reports/inventory/quick stock count",
    //         },
    //         {
    //           name: "Stock Movement Enquiry",
    //           url: "/reports/inventory/stock movement enquiry",
    //         },
    //         {
    //           name: "Price List Enquiry",
    //           url: "/reports/inventory/price list enquiry",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Production",
    //       subSubManu: [
    //         {
    //           name: "Production Enquiry",
    //           url: "/reports/production/production enquiry",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Sale",
    //       subSubManu: [
    //         { name: "Margin Enquiry", url: "/reports/sale/margin enquiry" },
    //         {
    //           name: "Unit Sales Enquiry",
    //           url: "/reports/sale/unit sales enquiry",
    //         },
    //         { name: "Quote Enquiry", url: "/reports/sale/quote enquiry" },
    //         { name: "Sales Enquiry", url: "/reports/sale/sales enquiry" },
    //         {
    //           name: "Back Order Enquiry",
    //           url: "/reports/sale/back order enquiry",
    //         },
    //         { name: "Shipment Enquiry", url: "/reports/sale/shipment enquiry" },
    //         { name: "Invoice Enquiry", url: "/reports/sale/invoice enquiry" },
    //         { name: "Credit Enquiry", url: "/reports/sale/credit enquiry" },
    //       ],
    //     },
    //   ],
    // },
    {
      name: "Settings",
      icon: "fa fa-cog",
      subManu: [
        {
          name: "Organization",
          subSubManu: [
            { name: "Company", url: "/settings/organization/company" },
            { name: "Billing", url: "/settings/organization/billing" },
          ],
        },
        {
          name: "Security",
          subSubManu: [
            { name: "Users", url: "/settings/security/users" },
            { name: "Roles", url: "/settings/security/roles" },
            {
              name: "Role Permissions",
              url: "/settings/security/role permissions",
            },
            { name: "Email Logs", url: "/settings/security/email logs" },
          ],
        },
        {
          name: "System",
          subSubManu: [
            {
              name: "Adjustment Reasons",
              url: "/settings/system/adjsutment reasons",
            },
            { name: "Attribute Sets", url: "/settings/system/attribute sets" },
            { name: "Credit Reason", url: "/settings/system/credit reason" },
            { name: "Currency Rates", url: "/settings/system/currency rates" },
            { name: "Customer Types", url: "/settings/system/customer types" },
            {
              name: "Delivery Methods",
              url: "/settings/system/delivery methods",
            },
            { name: "Prefixes", url: "/settings/system/prefixes" },
            { name: "Product Groups", url: "/settings/system/product groups" },
            { name: "Payment Terms", url: "/settings/system/payment terms" },
            { name: "Sales Groups", url: "/settings/system/sale groups" },
            { name: "Sales Persons", url: "/settings/system/sales persons" },
            {
              name: "Sell Price Tiers",
              url: "/settings/system/sell price tiers",
            },
            {
              name: "Shipping Companies",
              url: "/settings/system/shipping companies",
            },
            {
              name: "Supplier Return Reason",
              url: "/settings/system/supplier return reason",
            },
            { name: "Taxes", url: "/settings/system/taxes" },
            {
              name: "Units Of Measure",
              url: "/settings/system/units of measure",
            },
            { name: "Warehouses", url: "/settings/system/warehouses" },
          ],
        },
        // {
        //   name: "Doc Designer",
        //   subSubManu: [
        //     {
        //       name: "Purchase Order",
        //       url: "/settings/doc designer/purchase order",
        //     },
        //     {
        //       name: "Supplier Returns",
        //       url: "/settings/doc designer/supplier returns",
        //     },
        //     {
        //       name: "Warehouse Transfers",
        //       url: "/settings/doc designer/warehouse transfers",
        //     },
        //     { name: "Assemblies", url: "/settings/doc designer/assemblies" },
        //     {
        //       name: "Sales Quotes",
        //       url: "/settings/doc designer/sales quotes",
        //     },
        //     {
        //       name: "Sales Orders",
        //       url: "/settings/doc designer/sales orders",
        //     },
        //     { name: "Invoices", url: "/settings/doc designer/invoices" },
        //     { name: "Picklists", url: "/settings/doc designer/picklists" },
        //     {
        //       name: "Packing Slips",
        //       url: "/settings/doc designer/packingSlips",
        //     },
        //     { name: "Ship Notes", url: "/settings/doc designer/ship notes" },
        //     {
        //       name: "Credit Notes",
        //       url: "/settings/doc designer/credit notes",
        //     },
        //     { name: "Labels", url: "/settings/doc designer/labels" },
        //   ],
        // },
      ],
    },
  ],
  quicklinks: [
    {
      name: "Dashboard",
      icon: "fa fa-tachometer",
      url: "/dashboard",
      heading: "Dashboard",
    },
    {
      name: "Add Purchase",
      icon: "fa fa-shopping-cart",
      url: "/purchases/purchase orders/add purchase",
      heading: "Purchases",
    },
    {
      name: "View Purchases",
      icon: "fa fa-shopping-cart",
      url: "/purchases/purchase orders/view purchases",
      heading: "Purchases",
    },
    {
      name: "Receipt Purchase",
      icon: "fa fa-shopping-cart",
      url: "/purchases/purchase orders/receipt purchase",
      heading: "Purchases",
    },
    {
      name: "Import Purchases",
      icon: "fa fa-shopping-cart",
      url: "/purchases/purchase orders/import purchases",
      heading: "Purchases",
    },
    {
      name: "Export Purchases",
      icon: "fa fa-shopping-cart",
      url: "/purchases/purchase orders/export purchases",
      heading: "Purchases",
    },
    {
      name: "Add Supplier Return",
      icon: "fa fa-shopping-cart",
      url: "/purchases/supplier returns/add supplier return",
      heading: "Purchases",
    },
    {
      name: "View Supplier Returns",
      icon: "fa fa-shopping-cart",
      url: "/purchases/supplier returns/view supplier returns",
      heading: "Purchases",
    },
    {
      name: "Stock Adjustments",
      icon: "fa fa-database",
      url: "/inventory/transactions/stock adjustments",
      heading: "Inventory",
    },
    {
      name: "Warehouse Transfers",
      icon: "fa fa-database",
      url: "/inventory/transactions/warehouse transfers",
      heading: "Inventory",
    },
    {
      name: "Stock Count",
      icon: "fa fa-database",
      url: "/inventory/transactions/stock count",
      heading: "Inventory",
    },
    {
      name: "Import Stock Revaluation",
      icon: "fa fa-database",
      url: "/inventory/transactions/import stock revaluation",
      heading: "Inventory",
    },
    {
      name: "Add Product",
      icon: "fa fa-database",
      url: "/inventory/products/add product",
      heading: "Inventory",
    },
    {
      name: "View Products",
      icon: "fa fa-database",
      url: "/inventory/products/view products",
      heading: "Inventory",
    },
    {
      name: "Import/Export Product",
      icon: "fa fa-database",
      url: "/inventory/products/ImportExport",
      heading: "Inventory",
    },
    {
      name: "Change Codes",
      icon: "fa fa-database",
      url: "/inventory/products/change codes",
      heading: "Inventory",
    },
    {
      name: "Markup Price Update",
      icon: "fa fa-database",
      url: "/inventory/products/markup price update",
      heading: "Inventory",
    },
    // {
    //   name: "Add Assembly",
    //   icon: "fa fa-building",
    //   url: "/productions/assemblies/add assembly",
    //   heading: "Productions",
    // },
    // {
    //   name: "Add Disassembly",
    //   icon: "fa fa-building",
    //   url: "/productions/assemblies/add disassembliy",
    //   heading: "Productions",
    // },
    // {
    //   name: "View Assemblies",
    //   icon: "fa fa-building",
    //   url: "/productions/assemblies/view assemblies",
    //   heading: "Productions",
    // },
    // {
    //   name: "Add Bill Of Materials",
    //   icon: "fa fa-building",
    //   url: "/productions/bill of material`s/add bill of materials",
    //   heading: "Productions",
    // },
    // {
    //   name: "View Bills Of Materials",
    //   icon: "fa fa-building",
    //   url: "/productions/bill of materials/view bill of materials",
    //   heading: "Productions",
    // },
    // {
    //   name: "Import Bills Of Materials",
    //   icon: "fa fa-building",
    //   url: "/productions/bill of materials/import bill of materials",
    //   heading: "Productions",
    // },
    // {
    //   name: "Export Bills Of Materials",
    //   icon: "fa fa-building",
    //   url: "/productions/bill of materials/export bill of materials",
    //   heading: "Productions",
    // },
    {
      name: "Add Quote",
      icon: "fa fa-usd",
      url: "/sales/quotes/add quote",
      heading: "Sales",
    },
    {
      name: "View Quotes",
      icon: "fa fa-usd",
      url: "/sales/quotes/view quotes",
      heading: "Sales",
    },
    {
      name: "Import Quotes",
      icon: "fa fa-usd",
      url: "/sales/quotes/import quotes",
      heading: "Sales",
    },
    {
      name: "Add Sales Orders",
      icon: "fa fa-usd",
      url: "/sales/orders/add sales orders",
      heading: "Sales",
    },
    {
      name: "View Sales Orders",
      icon: "fa fa-usd",
      url: "/sales/orders/view sales orders",
      heading: "Sales",
    },
    {
      name: "View Shipments",
      icon: "fa fa-usd",
      url: "/sales/orders/view shipments",
      heading: "Sales",
    },
    {
      name: "Batch Complete",
      icon: "fa fa-usd",
      url: "/sales/orders/batch complete",
      heading: "Sales",
    },
    {
      name: "Import Sales Orders",
      icon: "fa fa-usd",
      url: "/sales/orders/import sales orders",
      heading: "Sales",
    },
    {
      name: "Export Sales Orders",
      icon: "fa fa-usd",
      url: "/sales/orders/export sales orders",
      heading: "Sales",
    },
    {
      name: "Add Credit",
      icon: "fa fa-usd",
      url: "/sales/credits/add credit",
      heading: "Sales",
    },
    {
      name: "View Credits",
      icon: "fa fa-usd",
      url: "/sales/credits/view credits",
      heading: "Sales",
    },
    {
      name: "Export Credits",
      icon: "fa fa-usd",
      url: "/sales/credits/export credits",
      heading: "Sales",
    },
    {
      name: "Add Customer",
      icon: "fa fa-users",
      url: "/customers/add customers",
      heading: "Customers",
    },
    {
      name: "View Customers",
      icon: "fa fa-users",
      url: "/customers/view customers",
      heading: "Customers",
    },
    {
      name: "Import/Export Customers",
      icon: "fa fa-users",
      url: "/customers/importexport",
      heading: "Customers",
    },
    {
      name: "Change Codes",
      icon: "fa fa-users",
      url: "/customers/change codes",
      heading: "Customers",
    },
    // {
    //   name: "Integration Store",
    //   icon: "fa fa-th",
    //   url: "/integration/store",
    //   heading: "Integration",
    // },
    // {
    //   name: "Data Explorer",
    //   icon: "fa fa-th",
    //   url: "/integration/data explorer",
    //   heading: "Integration",
    // },
    {
      name: "Margin Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/margin enquiry",
      heading: "Reports",
    },
    {
      name: "Unit Sales Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/unit sales enquiry",
      heading: "Reports",
    },
    {
      name: "Quote Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/quote enquiry",
      heading: "Reports",
    },
    {
      name: "Sales Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/sales enquiry",
      heading: "Reports",
    },
    {
      name: "Back Order Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/back order enquiry",
      heading: "Reports",
    },
    {
      name: "Shipment Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/shipment enquiry",
      heading: "Reports",
    },
    {
      name: "Invoice Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/invoice enquiry",
      heading: "Reports",
    },
    {
      name: "Credit Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/sale/credit enquiry",
      heading: "Reports",
    },
    {
      name: "Production Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/production/production enquiry",
      heading: "Reports",
    },
    {
      name: "Product Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventories/product enquiry",
      heading: "Reports",
    },
    {
      name: "Stock On Hand Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/stock on hand enquiry",
      heading: "Reports",
    },
    {
      name: "Batch Number Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/batch number enquiry",
      heading: "Reports",
    },
    {
      name: "Serial Number Equiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/serial number enquiry",
      heading: "Reports",
    },
    {
      name: "Transaction Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/transaction enquiry",
      heading: "Reports",
    },
    {
      name: "Product Allocations",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/product allocations",
      heading: "Reports",
    },
    {
      name: "Quict Stock Count",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/quick stock count",
      heading: "Reports",
    },
    {
      name: "Stock Movement Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/stock movement enquiry",
      heading: "Reports",
    },
    {
      name: "Price List Enquiry",
      icon: "fa fa-pie-chart",
      url: "/reports/inventory/price list enquiry",
      heading: "Reports",
    },
    {
      name: "Purchase Equiry",
      icon: "fa fa-pie-chart",
      url: "/reports/purchase/purchase enquiry",
      heading: "Reports",
    },
    {
      name: "Costing Analysis Report",
      icon: "fa fa-pie-chart",
      url: "/reports/purchase/costing analysis report",
      heading: "Reports",
    },
    {
      name: "Reorder Report",
      icon: "fa fa-pie-chart",
      url: "/reports/purchase/reorder report",
      heading: "Reports",
    },
    // {
    //   name: "Purchase Order",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/purchase order",
    //   heading: "Settings",
    // },
    // {
    //   name: "Supplier Returns",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/supplier returns",
    //   heading: "Settings",
    // },
    // {
    //   name: "Warehouse Transfers",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/warehouse transfers",
    //   heading: "Settings",
    // },
    // {
    //   name: "Assemblies",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/assemblies",
    //   heading: "Settings",
    // },
    // {
    //   name: "Sales Quotes",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/sales quotes",
    //   heading: "Settings",
    // },
    // {
    //   name: "Sales Orders",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/sales orders",
    //   heading: "Settings",
    // },
    // {
    //   name: "Invoices",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/invoices",
    //   heading: "Settings",
    // },
    // {
    //   name: "Picklists",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/picklists",
    //   heading: "Settings",
    // },
    // {
    //   name: "Packing Slips",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/packingSlips",
    //   heading: "Settings",
    // },
    // {
    //   name: "Ship Notes",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/ship notes",
    //   heading: "Settings",
    // },
    // {
    //   name: "Credit Notes",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/credit notes",
    //   heading: "Settings",
    // },
    // {
    //   name: "Labels",
    //   icon: "fa fa-cog",
    //   url: "/settings/doc designer/labels",
    //   heading: "Settings",
    // },
    {
      name: "Adjustment Reasons",
      icon: "fa fa-cog",
      url: "/settings/system/adjsutment reasons",
      heading: "Settings",
    },
    {
      name: "Attribute Sets",
      icon: "fa fa-cog",
      url: "/settings/system/attribute sets",
      heading: "Settings",
    },
    {
      name: "Credit Reason",
      icon: "fa fa-cog",
      url: "/settings/system/credit reason",
      heading: "Settings",
    },
    {
      name: "Currency Rates",
      icon: "fa fa-cog",
      url: "/settings/system/currency rates",
      heading: "Settings",
    },
    {
      name: "Customer Types",
      icon: "fa fa-cog",
      url: "/settings/system/customer types",
      heading: "Settings",
    },
    {
      name: "Delivery Methods",
      icon: "fa fa-cog",
      url: "/settings/system/delivery methods",
      heading: "Settings",
    },
    {
      name: "Prefixes",
      icon: "fa fa-cog",
      url: "/settings/system/prefixes",
      heading: "Settings",
    },
    {
      name: "Product Groups",
      icon: "fa fa-cog",
      url: "/settings/system/product groups",
      heading: "Settings",
    },
    {
      name: "Payment Terms",
      icon: "fa fa-cog",
      url: "/settings/system/payment terms",
      heading: "Settings",
    },
    {
      name: "Sales Groups",
      icon: "fa fa-cog",
      url: "/settings/system/sale groups",
      heading: "Settings",
    },
    {
      name: "Sales Persons",
      icon: "fa fa-cog",
      url: "/settings/system/sales persons",
      heading: "Settings",
    },
    {
      name: "Sell Price Tiers",
      icon: "fa fa-cog",
      url: "/settings/system/sell price tiers",
      heading: "Settings",
    },
    {
      name: "Shipping Companies",
      icon: "fa fa-cog",
      url: "/settings/system/shipping companies",
      heading: "Settings",
    },
    {
      name: "Supplier Return Reason",
      icon: "fa fa-cog",
      url: "/settings/system/supplier return reason",
      heading: "Settings",
    },
    {
      name: "Taxes",
      icon: "fa fa-cog",
      url: "/settings/system/taxes",
      heading: "Settings",
    },
    {
      name: "Units Of Measure",
      icon: "fa fa-cog",
      url: "/settings/system/units of measure",
      heading: "Settings",
    },
    {
      name: "Warehouses",
      icon: "fa fa-cog",
      url: "/settings/system/warehouses",
      heading: "Settings",
    },
    {
      name: "Users",
      icon: "fa fa-cog",
      url: "/settings/security/users",
      heading: "Settings",
    },
    {
      name: "Roles",
      icon: "fa fa-cog",
      url: "/settings/security/roles",
      heading: "Settings",
    },
    {
      name: "Role Permissions",
      icon: "fa fa-cog",
      url: "/settings/security/role permissions",
      heading: "Settings",
    },
    {
      name: "Email Logs",
      icon: "fa fa-cog",
      url: "/settings/security/email logs",
      heading: "Settings",
    },
    {
      name: "Company",
      icon: "fa fa-cog",
      url: "/settings/organization/company",
      heading: "Settings",
    },
    {
      name: "Billing",
      icon: "fa fa-cog",
      url: "/settings/organization/billing",
      heading: "Settings",
    },
    {
      name: "Add Supplier",
      icon: "fa fa-truck",
      url: "/suppliers/add suppliers",
      heading: "Suppliers",
    },
    {
      name: "View Suppliers",
      icon: "fa fa-truck",
      url: "/suppliers/view suppliers",
      heading: "Suppliers",
    },
    {
      name: "Import Suppliers",
      icon: "fa fa-truck",
      url: "/suppliers/import suppliers",
      heading: "Suppliers",
    },
    {
      name: "Export Suppliers",
      icon: "fa fa-truck",
      url: "/suppliers/export suppliers",
      heading: "Suppliers",
    },
    {
      name: "Change Codes",
      icon: "fa fa-truck",
      url: "/suppliers/change codes",
      heading: "Suppliers",
    },
  ],
  billPlansData: [
    ["Users", "1 Included", "3 Included", "8 Included", "20 Included"],
    ["Products", "Unlimited**", "Unlimited**", "Unlimited**", "Unlimited**"],
    ["Customers", "Unlimited**", "Unlimited**", "Unlimited**", "Unlimited**"],
    ["Suppliers", "Unlimited**", "Unlimited**", "Unlimited**", "Unlimited**"],
    [
      "Transactions",
      "Unlimited**",
      "Unlimited**",
      "Unlimited**",
      "Unlimited**",
    ],
    [
      "Integrations",
      "Accounting only",
      "Unlimited**",
      "Unlimited**",
      "Unlimited**",
    ],
    ["Warehouses", "Single", "Unlimited**", "Unlimited**", "Unlimited**"],
    ["Currencies", "Single", "Unlimited**", "Unlimited**", "Unlimited**"],
    ["Mobile Sales App", "FREE", "FREE", "FREE", "FREE"],
    ["Sandbox Environment", "yes", "yes", "yes", "yes"],
    ["Document Designer", "yes", "yes", "yes", "yes"],
    ["Production Module", "no", "yes", "yes", "yes"],
    ["Serial & Batch Tracking ", "no", "yes", "yes", "yes"],
    ["API Access+", "no", "125,000 calls", "250,000 calls", "500,000 calls"],
    ["B2B Portal", "no", "yes", "yes", "yes"],
    ["Globuss University", "Pay per user", "Pay per user", "FREE", "FREE"],
    ["Assisted Onboarding ", "no", "yes", "yes", "yes"],
    [
      "Support",
      "Email & Community",
      "Email & Community",
      "Email & Community",
      "Email & Community",
    ],
    [
      "Premium Support (optional)",
      "$150 per month",
      "$150 per month",
      "$150 per month",
      "Contact Us",
    ],
  ],
  docDesigner: [
    {
      name: "Basic Components",
      data: [
        { name: "Text", icon: "fa fa-font" },
        { name: "Company Logo", icon: "fa fa-picture-o" },
        { name: "Image", icon: "fa fa-picture-o" },
        { name: "Line", icon: "fa fa-pencil" },
        { name: "Footer", icon: "fa fa-chevron-down" },
        { name: "Header", icon: "fa fa-chevron-up" },
        { name: "Page Number", icon: "fa fa-hashtag" },
        { name: "Puchase Lines Grid", icon: "fa fa-table" },
      ],
    },
    {
      name: "Company Data",
      data: [
        "Company Name",
        "Fax",
        "GST VAT Number",
        "Mobile",
        "Phone",
        "Physical Address 1",
        "Physical Address 2",
        "Physical City",
        "Physical Country",
        "Physical Postal Code",
        "Physical Region",
        "Physical Suburb",
        "Postal Address Line 1",
        "Postal Address Line 2",
        "Postal City",
        "Postal Country",
        "Postal Post Code",
        "Postal Region",
        "Postal Suburb",
        "Purchase Email",
        "Trading Name",
        "Web Site",
      ],
    },
    {
      name: "Delivery Address Data",
      data: [
        "Delivery Address Line 1",
        "Delivery Address Line 2",
        "Delivery City",
        "Delivery Country",
        "Delivery Name",
        "Delivery Post Code",
        "Delivery State/Region",
        "Delivery Suburb",
      ],
    },
    {
      name: "Supplier Data",
      data: [
        "Address Line 1",
        "Address Line 2",
        "Address Name",
        "City",
        "Country",
        "GST VAT Number",
        "Notes",
        "PostCode",
        "State/Region",
        "Suburb",
        "Supplier Code",
        "Supplier Name",
        "Supplier Ref",
      ],
    },
    {
      name: "Contact Data",
      data: [
        "DDI Number",
        "Email",
        "Fax Number",
        "First Name",
        "Last Name",
        "Mobile Number",
        "Office Number",
        "Phone Number",
        "Toll Free Number",
        "Website",
      ],
    },
    {
      name: "Audit Data",
      data: [
        "Created By",
        "Created On",
        "Last Modified by",
        "Last Modified On",
        "Last Printed By",
        "Last Printed On",
        "Todays Date",
      ],
    },
    {
      name: "Other Data",
      data: [
        "Comments",
        "Completed Data",
        "Currency Code",
        "Delivery Data",
        "Footer Details",
        "Item Count",
        "Order Date",
        "Order Number",
        "Order Status",
        "Payment Details",
        "Payment Order Report Name",
        "Payment Term",
        "Print Name",
        "Purchare Lines Grid",
        "Receipt Date",
        "Sales Order Number",
        "Sub Total",
        "Tax Total",
        "Total",
        "Total Volume",
        "Total Weight",
        "Warehouse Name",
      ],
    },
  ],
  billingPlans: [
    {
      name: "SMALL",
      perMonth: "135",
      saved: "15",
      users: "01",
      payMonthly: "150.oo",
    },
    {
      name: "MEDIUM",
      perMonth: "200",
      saved: "20",
      users: "03",
      payMonthly: "220.00",
    },
    {
      name: "LARGE",
      perMonth: "400",
      saved: "40",
      users: "08",
      payMonthly: "440.00",
    },
    {
      name: "LARGE (PLUS)",
      perMonth: "710",
      saved: "65",
      users: "20",
      payMonthly: "775.00",
    },
  ],
  CompanyConfiguration: [
    {
      heading: "Multi Warehouse",
      id: "multiWarehouse",
      body: "Manage stock with different physical or virtual locations.",
      warning: "Once this setting is enabled, you cannot disable it.",
      switch: "on",
      disable: true,
    },
    {
      heading: "Order Management",
      id: "orderManagement",
      body: "Advanced order fulfillment process.",
      warning: "This setting cannot be disabled if there are open shipments.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Serialized Products",
      id: "serializedProducts",
      body: "Track individual products from supplier to customer.",
      warning: "Once this setting is enabled, you cannot disable it.",
      switch: "off",
      disable: false,
    },
    {
      heading: "Per Warehouse Controls",
      id: "perWarehouseControls",
      body:
        "Manage bin locations and stock level alerts on a per warehouse basis.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Auto Allocation",
      id: "autoAllocation",
      body: "Reserve or unreserve stock for Sales Orders.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Batch Tracking",
      id: "batchTracking",
      body: "Track batches/lots from supplier to customer.",
      warning: "Once this setting is enabled, you cannot disable it.",
      switch: "off",
      disable: false,
    },
    {
      heading: "Production",
      id: "Production",
      body:
        "Build finished goods from components using a Bill Of Materials and Assembly Orders.",
      warning: "Once this setting is enabled, you cannot disable it.",
      switch: "on",
      disable: true,
    },
    {
      heading: "Markup Price Update",
      id: "markupPriceUpdate",
      body:
        "Advanced price setting. You can calculate from the average landed cost, last cost, default purchase price or default sell price.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Auto Set Receipt Quantity",
      id: "autoSetReceiptQuantity",
      body:
        "Automatically set the receipt quantity to match the order quantity on the Purchase Order receipt.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Auto Assembly",
      id: "autoAssembly",
      body: "Sell bundled products without having to pre-assemble them.",
      tip: "Production setting must be enabled.",
      warning: "Once this setting is enabled, you cannot disable it.",
      switch: "on",
      disable: true,
    },
    {
      heading: "Sales Quotes",
      id: "salesQuotes",
      body:
        "Maximize your sales process by offering your customers a Sales Quote before they commit to buy.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "on",
      disable: false,
    },
    {
      heading: "Require Approval for Purchase Orders",
      id: "requireApprovalForPurchaseOrders",
      body: "Enforce an approval process for all Purchase Orders.",
      tip: "This setting can be enabled or disabled at any time.",
      switch: "off",
      disable: false,
    },
    {
      heading: "Use Shipment Quantities for Sales",
      id: "useShipmentQuantitiesForSales",
      body:
        "For Sales Shipments, the Ship Quantity will not include quantities that are already on other open shipments.",
      tip:
        "Order Management must be enabled. This setting can be enabled or disabled at any time.",
      switch: "off",
      disable: false,
    },
  ],
};

export default data;
