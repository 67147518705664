import React from "react";
import TableActions from "@components/common/tableActions";

export default function CustomersTable({ contacts, edit, onClickAction }) {
  return (
    <div className="table-view table-responsive">
      <table id="customers-table" className="table">
        <thead>
          <tr>
            <th>Primary</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Delivery Address</th>
            <th>Website</th>
            <th>Ordering</th>
            <th>Invoicing</th>
            <th>Shipping</th>
            <th>Toll Free No</th>
            <th>Phone</th>
            <th>Fax</th>
            <th>Mobile</th>
            <th>DDI</th>
            {/* <th>
							<i className="fa fa-comments-o icon" aria-hidden="true" />
						</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {contacts && edit
            ? contacts.map((contact) => (
                <tr key={contact.id}>
                  <td style={{ textAlign: "center" }}>
                    {contact.primary === 1 ? (
                      <i
                        className="fa fa-check-circle"
                        style={{ color: "green", fontSize: "1.5em" }}
                      />
                    ) : null}
                  </td>
                  <td>{contact.firstName}</td>
                  <td>{contact.lastName}</td>
                  <td>{contact.email}</td>
                  <td>{contact.deliveryAddressId}</td>
                  <td>{contact.website}</td>
                  <td>
                    <div className="checkbox">
                      <input
                        disabled
                        checked={contact.ordering === 1 ? "checked" : ""}
                        type="checkbox"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="checkbox">
                      <input
                        disabled
                        checked={contact.invoicing === 1 ? "checked" : ""}
                        type="checkbox"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="checkbox">
                      <input
                        disabled
                        checked={contact.shipping === 1 ? "checked" : ""}
                        type="checkbox"
                      />
                    </div>
                  </td>
                  <td>{contact.tollFreeNumber}</td>
                  <td>{contact.phoneNumber}</td>
                  <td>{contact.faxNumber}</td>
                  <td>{contact.mobileNumber}</td>
                  <td>{contact.DDINumber}</td>
                  <td>
                    <TableActions
                      icon="fa fa-cog icon"
                      data={["Set as primary", "Delete"]}
                      onClick={(action) => onClickAction(action, contact.id)}
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <small>Please Save your Customer before adding contacts</small>
    </div>
  );
}
