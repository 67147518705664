import React, { Component } from "react";
import Details from "./details";
import Inventory from "./inventory";
import Sale from "./sale";
import Transaction from "./transaction";
import References from "./references";
import Costs from "./costs";
import Attachments from "./attachments";
import Purchase from "./purchase";
import Tabs from "@components/common/tabs";
import CloneModal from "./cloneModal";

function AddProduct({
  onFileDivClick,
  nav,
  units,
  attributes,
  productGroups,
  goto,
  onSaveClick,
  onChange,
  stateData,
  handleChange,
  propsData,
  selectedUnit,
  selectedGroup,
  selectedAttribute,
  warehouses,
  onClickHideOrView,
  hideOrShow,
  edit,
  suppliers,
  onSaveProductSupplier,
  purchaseTaxes,
  productSuppliers,
  onDeleteProductSupplier,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>
              {propsData.match.params.id ? (
                <>
                  Edit Product{" "}
                  {stateData.productCode ? (
                    <>({stateData.productCode})</>
                  ) : null}{" "}
                </>
              ) : (
                <>Add Product</>
              )}
            </label>
          </div>
          <div className="col-lg-5 col-md-2 col-1"></div>
          <div
            className="col-lg-3 col-md-3 col-3"
            style={{ textAlign: "right" }}
          >
            <button
              onClick={() => onSaveClick()}
              className="btn btn-success btn-sm"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={nav} />
          <div className="tab-content">
            <Details
              edit={edit}
              handleChange={(v, m) => handleChange(v, m)}
              stateData={stateData}
              onChange={(e) => onChange(e)}
              units={units}
              goto={(path) => goto(path)}
              productGroups={productGroups}
              attributes={attributes}
              selectedUnit={(name) => selectedUnit(name)}
              selectedGroup={(name) => selectedGroup(name)}
              selectedAttribute={(name) => selectedAttribute(name)}
            />
            <Inventory warehouses={warehouses} />
            <Purchase
              edit={edit}
              onChange={(e) => onChange(e)}
              suppliers={suppliers}
              stateData={stateData}
              handleChange={(v, m) => handleChange(v, m)}
              onSaveProductSupplier={() => onSaveProductSupplier()}
              purchaseTaxes={purchaseTaxes}
              productSuppliers={productSuppliers}
              onDeleteProductSupplier={(id) => onDeleteProductSupplier(id)}
            />
            <Sale
              onClickHideOrView={() => onClickHideOrView()}
              hideOrShow={hideOrShow}
            />
            <Transaction />
            <References />
            <Costs />
            <Attachments onFileDivClick={() => onFileDivClick()} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProduct;
