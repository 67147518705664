import React, { Component } from "react";
import AssembliesComp from "@components/setting/docDesigner/assemblies/index";

export default class Assemblies extends Component {
  state = {
    templates: [{ label: "Default Assembly", value: "Default Assembly" }],
  };
  render() {
    const { templates } = this.state;
    return <AssembliesComp templates={templates} name="Assemblies" />;
  }
}
