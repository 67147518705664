import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import CostAnalysisComp from "@components/reports/purchase/costingAnalysis";

export default class CostAnalysis extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <CostAnalysisComp />;
  }
}
