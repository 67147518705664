import React from 'react';

export default function InventoryTable({ warehouses }) {
	return (
		<form>
			<div className="table-view table-responsive">
				<table id="inventory-table" className="table">
					<thead>
						<tr>
							<th>WareHouse</th>
							<th>Bin Location</th>
							<th>Min Stock Level</th>
							<th>Max Stock Level</th>
							<th>Availabke Qty</th>
							<th>On Purchase</th>
							<th>Stock On Hand</th>
							<th>Alert</th>
							<th>Stock Value</th>
							{/* <th>Action</th> */}
						</tr>
					</thead>
					<tbody>
						{warehouses
							? warehouses.map(warehouse => (
									<tr key={warehouse.warehouseName}>
										<td>{warehouse.warehouseName}</td>
										<td></td>
										<td></td>
										<td></td>
										<td align="right">0.0000</td>
										<td align="right">0.0000</td>
										<td align="right">0.0000</td>
										<td align="right"></td>
										<td align="right">0.00</td>
										{/* <td>
											<i className="fa fa-pencil-square-o icon" />
										</td> */}
									</tr>
							  ))
							: null}
					</tbody>
				</table>
			</div>
		</form>
	);
}
