import React from 'react';
import InlineInputForm from './inlineInputForm';
import InlineDropdown from './inlineDropDown';

export default function Address({
	countries,
	handleChange,
	onChange,
	updateData,
	postal,
	physical,
	updatedPostal,
	updatedPhysical,
}) {
	return (
		<div id="Address" className="tab-pane fade">
			<div className="row">
				<div className="col-md-4 col-sm-6">
					<b>
						<h4>Postal Address:</h4>
					</b>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="addressName"
						value={
							postal
								? postal.addressName
									? postal.addressName
									: updatedPostal
									? updatedPostal.addressName
										? updatedPostal.addressName
										: ''
									: ''
								: updatedPostal
								? updatedPostal.addressName
									? updatedPostal.addressName
									: ''
								: ''
						}
						name="Address Name"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="addressLine1"
						value={
							postal
								? postal.addressLine1
									? postal.addressLine1
									: updatedPostal
									? updatedPostal.addressLine1
										? updatedPostal.addressLine1
										: ''
									: ''
								: updateData
								? updatedPostal.addressLine1
									? updatedPostal.addressLine1
									: ''
								: ''
						}
						name="Address Line 1"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="addressLine2"
						value={
							postal
								? postal.addressLine2
									? postal.addressLine2
									: updatedPostal
									? updatedPostal.addressLine2
										? updatedPostal.addressLine2
										: ''
									: ''
								: updateData
								? updatedPostal.addressLine2
									? updatedPostal.addressLine2
									: ''
								: ''
						}
						name="Address Line 2"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="suburb"
						value={
							postal
								? postal.suburb
									? postal.suburb
									: updatedPostal
									? updatedPostal.suburb
										? updatedPostal.suburb
										: ''
									: ''
								: updateData
								? updatedPostal.suburb
									? updatedPostal.suburb
									: ''
								: ''
						}
						name="Suburb"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="city"
						value={
							postal
								? postal.city
									? postal.city
									: updatedPostal
									? updatedPostal.city
										? updatedPostal.city
										: ''
									: ''
								: updateData
								? updatedPostal.city
									? updatedPostal.city
									: ''
								: ''
						}
						name="City"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="state_Region"
						value={
							postal
								? postal.state_Region
									? postal.state_Region
									: updatedPostal
									? updatedPostal.state_Region
										? updatedPostal.state_Region
										: ''
									: ''
								: updateData
								? updatedPostal.state_Region
									? updatedPostal.state_Region
									: ''
								: ''
						}
						name="State/Region"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'postal')}
						id="postalCode"
						value={
							postal
								? postal.postalCode
									? postal.postalCode
									: updatedPostal
									? updatedPostal.postalCode
										? updatedPostal.postalCode
										: ''
									: ''
								: updateData
								? updatedPostal.postalCode
									? updatedPostal.postalCode
									: ''
								: ''
						}
						name="Postal Code"
					/>
					<InlineDropdown
						searchAble={true}
						id="country"
						name="Country"
						values={countries}
						onChange={(newValue, actionMeta) => handleChange(newValue, actionMeta, 'postal')}
						value={
							postal
								? postal.country
									? { label: postal.country, value: postal.country }
									: updatedPostal
									? updatedPostal.country
										? {
												label: updatedPostal.country,
												value: updatedPostal.country,
										  }
										: null
									: null
								: updateData
								? updatedPostal.country
									? { label: updatedPostal.country, value: updatedPostal.country }
									: null
								: null
						}
					/>
				</div>
				<div className="col-md-1"></div>
				<div className="col-md-4 col-sm-6">
					<b>
						<h4>Physical Address:</h4>
					</b>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="addressName"
						value={
							physical
								? physical.addressName
									? physical.addressName
									: updatedPhysical
									? updatedPhysical.addressName
										? updatedPhysical.addressName
										: ''
									: ''
								: updateData
								? updatedPhysical.addressName
									? updatedPhysical.addressName
									: ''
								: ''
						}
						name="Address Name"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="addressLine1"
						value={
							physical
								? physical.addressLine1
									? physical.addressLine1
									: updatedPhysical
									? updatedPhysical.addressLine1
										? updatedPhysical.addressLine1
										: ''
									: ''
								: updateData
								? updatedPhysical.addressLine1
									? updatedPhysical.addressLine1
									: ''
								: ''
						}
						name="Address Line 1"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="addressLine2"
						value={
							physical
								? physical.addressLine2
									? physical.addressLine2
									: updatedPhysical
									? updatedPhysical.addressLine2
										? updatedPhysical.addressLine2
										: ''
									: ''
								: updateData
								? updatedPhysical.addressLine2
									? updatedPhysical.addressLine2
									: ''
								: ''
						}
						name="Address Line 2"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="suburb"
						value={
							physical
								? physical.suburb
									? physical.suburb
									: updatedPhysical
									? updatedPhysical.suburb
										? updatedPhysical.suburb
										: ''
									: ''
								: updateData
								? updatedPhysical.suburb
									? updatedPhysical.suburb
									: ''
								: ''
						}
						name="Suburb"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="city"
						value={
							physical
								? physical.city
									? physical.city
									: updatedPhysical
									? updatedPhysical.city
										? updatedPhysical.city
										: ''
									: ''
								: updateData
								? updatedPhysical.city
									? updatedPhysical.city
									: ''
								: ''
						}
						name="City"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="state_Region"
						value={
							physical
								? physical.state_Region
									? physical.state_Region
									: updateData
									? updatedPhysical.state_Region
										? updatedPhysical.state_Region
										: ''
									: ''
								: updateData
								? updatedPhysical.state_Region
									? updatedPhysical.state_Region
									: ''
								: ''
						}
						name="State/Region"
					/>
					<InlineInputForm
						onChange={e => onChange(e, 'physical')}
						id="postalCode"
						value={
							physical
								? physical.postalCode
									? physical.postalCode
									: updatedPhysical
									? updatedPhysical.postalCode
										? updatedPhysical.postalCode
										: ''
									: ''
								: updateData
								? updatedPhysical.postalCode
									? updatedPhysical.postalCode
									: ''
								: ''
						}
						name="Postal Code"
					/>
					<InlineDropdown
						searchAble={true}
						id="country"
						name="Country"
						values={countries}
						onChange={(newValue, actionMeta) => handleChange(newValue, actionMeta, 'physical')}
						value={
							physical
								? physical.country
									? { label: physical.country, value: physical.country }
									: updatedPhysical
									? updatedPhysical.country
										? {
												label: updatedPhysical.country,
												value: updatedPhysical.country,
										  }
										: null
									: null
								: updateData
								? updatedPhysical.country
									? { label: updatedPhysical.country, value: updatedPhysical.country }
									: null
								: null
						}
					/>
				</div>
			</div>
		</div>
	);
}
