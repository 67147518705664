import React from "react";

export default function CostLines() {
  return (
    <>
      <h5>Cost Lines</h5>
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="product">
            {/* <a onClick={() => goto('/inverntory/products/view products')}>Product</a> */}
            Supplier
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="product"
            name="product"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="cost">
            Cost
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="cost"
            name="cost"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="exchange-rate">
            Exchange Rate
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="exchange-rate"
            name="exchange-rate"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="cost-date">
            Cost Date
          </label>
          <input
            disabled
            type="date"
            className="form-control"
            id="cost-date"
            name="cost-date"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="reference">
            Reference
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="reference"
            name="reference"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="currency">
            Currency
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="currency"
            name="currency"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="tax">
            Tax
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="tax"
            name="tax"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="taxable">
            Taxable
          </label>
          <br />
          <input
            disabled
            type="checkbox"
            style={{ width: "18px", height: "18px" }}
            id="taxable"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="comments">
            Comments
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="comments"
            name="comments"
          />
        </div>

        <div className="col-md-1" id="add-btn">
          <label className="input-label">Add</label>
          <button disabled type="button" className="btn btn-success btn-sm">
            Add
          </button>
        </div>
      </div>
    </>
  );
}
