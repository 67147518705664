import React, { Component } from "react";
import {
  loaderState,
  getSalesGroups,
  addSalesGroup,
  onUpdateOrDeleteSalesGroup,
  deleteSalesGroup,
  updateSalesGroup,
} from "@actions";
import swal from "sweetalert";
import { connect } from "react-redux";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import SalesGroupsComp from "@components/setting/system/salesGroup";

class SalesGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addValue: null,
      editValue: null,
      obsolete: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("sales-group-table");
    this.props.loaderState(true);
    this.props.getSalesGroups();
  }

  componentDidUpdate() {
    initilizeDataTable("sales-group-table");
  }

  onUpdate = () => {
    const { selectedSalesGroup, user } = this.props;
    const { editValue, obsolete } = this.state;

    if (isEmptyOrNull(editValue) && isEmptyOrNull(obsolete)) {
    } else {
      let data = { id: selectedSalesGroup.id, updatedBy: user.id };
      if (!isEmptyOrNull(editValue)) {
        data.groupName = editValue;
      }
      if (!isEmptyOrNull(obsolete)) {
        data.obsolete = obsolete;
      }
      this.setState({ editValue: null, obsolete: null });
      this.props.loaderState(true);
      this.props.updateSalesGroup({
        ...data,
      });
    }
  };

  onAdd = () => {
    const { user } = this.props;
    const { addValue } = this.state;

    if (!isEmptyOrNull(addValue)) {
      this.setState({ addValue: "" });
      this.props.loaderState(true);
      this.props.addSalesGroup({
        groupName: addValue,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter a group name", "info");
    }
  };

  onChange = ({ target }) => {
    this.setState({ addValue: target.value });
  };

  onChangeEdit = ({ target }) => {
    const { type, value } = target;
    if (type === "checkbox") {
      this.setState({ obsolete: target.checked ? "1" : "-1" });
    } else {
      this.setState({ editValue: value });
    }
  };

  onGroupUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteSalesGroup(data);
  };

  onDelete = () => {
    const { selectedSalesGroup } = this.props;
    this.props.loaderState(true);
    this.props.deleteSalesGroup(selectedSalesGroup.id);
  };

  render() {
    destroyDataTable("sales-group-table");

    const { addValue, editValue, obsolete } = this.state;
    const { salesGroups, selectedSalesGroup } = this.props;
    return (
      <SalesGroupsComp
        salesGroups={salesGroups}
        onAdd={() => this.onAdd()}
        editValue={
          editValue
            ? editValue
            : selectedSalesGroup
            ? selectedSalesGroup.groupName
            : null
        }
        addValue={addValue}
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onDelete={() => this.onDelete()}
        onGroupUpdateOrDelete={(data) => this.onGroupUpdateOrDelete(data)}
        obsolete={obsolete}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    salesGroups: state.system.salesGroups.allSalesGroups,
    user: state.auth.loggedInUser,
    selectedSalesGroup: state.system.salesGroups.selectedSalesGroup,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getSalesGroups,
  addSalesGroup,
  onUpdateOrDeleteSalesGroup,
  deleteSalesGroup,
  updateSalesGroup,
})(SalesGroups);
