import React from "react";

import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";

export default function ProductPricing({ onProductPricingUpload }) {
  return (
    <div id="ProductPricing" className="tab-pane fade">
      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading="Importing Product Pricing"
            message="For further information on importing data, including required field definitions, please"
            link="visit our help pages."
          />
          <UploadFiles
            onFileDivClick={() => onProductPricingUpload()}
            id="Product Pricing"
          />

          <div className="product-pricing">
            <p>
              In order to make amendments to the Quantity Pricing for products,
              you will first need to delete all product prices and then
              re-import.
            </p>
            <button className="btn btn-danger btn-sm">
              Delete All Product Prices
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
