import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCreditReasons,
  addCredit,
  loaderState,
  deleteCredit,
  onUpdateOrDeleteCredit,
  updateCredit,
} from "@actions";
import swal from "sweetalert";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import CreditReasonsComp from "@components/setting/system/creditReasons";

class CreditReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addvalue: "",
      editValue: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("credit-reason-table");
    this.props.loaderState(true);
    this.props.getCreditReasons();
  }

  componentDidUpdate() {
    initilizeDataTable("credit-reason-table");
  }

  onChange = ({ target }) => {
    this.setState({ addvalue: target.value });
  };

  onChangeEdit = ({ target }) => {
    this.setState({ editValue: target.value });
  };

  onAdd = () => {
    const { addvalue } = this.state;
    const { user } = this.props;
    if (!isEmptyOrNull(addvalue)) {
      this.setState({ addValue: "" });
      this.props.loaderState(true);
      this.props.addCredit({
        reason: addvalue,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter a reason value", "info");
    }
  };

  onUpdate = () => {
    const { selectedReason, user } = this.props;
    const { editValue } = this.state;

    if (!isEmptyOrNull(editValue)) {
      this.setState({ editValue: null });
      this.props.loaderState(true);
      this.props.updateCredit({
        reason: editValue,
        id: selectedReason.id,
        updatedBy: user.id,
      });
    }
  };

  onDelete = () => {
    const { selectedReason } = this.props;
    this.props.loaderState(true);
    this.props.deleteCredit(selectedReason.id);
  };

  onReasonUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteCredit(data);
  };

  render() {
    destroyDataTable("credit-reason-table");

    const { addvalue, editValue } = this.state;
    const { creditReasons, selectedReason } = this.props;
    return (
      <CreditReasonsComp
        creditReasons={creditReasons}
        addvalue={addvalue}
        editValue={
          editValue != null
            ? editValue
            : selectedReason
            ? selectedReason.reason
            : null
        }
        onReasonUpdateOrDelete={(data) => this.onReasonUpdateOrDelete(data)}
        onAdd={() => this.onAdd()}
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onDelete={() => this.onDelete()}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    creditReasons: state.system.creditReasons.allCreditReasons,
    selectedReason: state.system.creditReasons.selectedReason,
    user: state.auth.loggedInUser,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  getCreditReasons,
  loaderState,
  onUpdateOrDeleteCredit,
  deleteCredit,
  addCredit,
  updateCredit,
})(CreditReasons);
