import React from "react";
import TableActions from "@components/common/tableActions";

export default function SuppplierTable({
  suppliers,
  onClickAction,
  onSupplierSelect,
  gotoSupplier,
}) {
  return (
    <div className="table-view table-responsive">
      <table id="suppliers-table" className="table">
        <thead>
          <tr>
            {/* <th>
							<i className="fa fa-cog icon" aria-hidden="true" />
						</th> */}
            <th>Supplier Code</th>
            <th>Supplier Name</th>
            <th>City</th>
            <th>Country</th>
            <th>Currency</th>
            <th>Obsolete</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {suppliers
            ? suppliers.map((supplier) =>
                supplier.supplierData ? (
                  <tr key={supplier.supplierData.id}>
                    <td>
                      <a onClick={() => gotoSupplier(supplier)}>
                        {supplier.supplierData.supplierCode}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => gotoSupplier(supplier)}>
                        {supplier.supplierData.supplierName}
                      </a>
                    </td>
                    <td>{supplier.physical ? supplier.physical.city : null}</td>
                    <td>
                      {supplier.physical ? supplier.physical.country : null}
                    </td>
                    <td>{supplier.supplierData.currencyCode}</td>
                    <td>
                      <input
                        disabled
                        type="checkbox"
                        checked={supplier.supplierData.obsolete}
                      />
                    </td>
                    <td onMouseEnter={() => onSupplierSelect(supplier)}>
                      <TableActions
                        icon="fa fa-cog icon"
                        data={[
                          "Edit",
                          "Add Purchase",
                          "View Transactions",
                          "Obsolete",
                          "Delete",
                        ]}
                        onClick={(action) => onClickAction(action)}
                      />
                    </td>
                  </tr>
                ) : null
              )
            : null}
        </tbody>
      </table>
    </div>
  );
}
