import React, { Component } from "react";
import ImportPurchasesComp from "@components/purchases/importPurchases";

export default class ImportPurchases extends Component {
  componentDidMount() {
    document.title = "Import Purchases";
  }
  render() {
    return <ImportPurchasesComp />;
  }
}
