import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import CreditComp from "@components/reports/sales/credit";
export default class Credit extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <CreditComp />;
  }
}
