import React from "react";
import data from "../../../../data";
import _ from "lodash";

export default function PlansDescTable() {
  return (
    <div className="table-view table-responsive">
      <table className="table table-striped" id="billing-plans-table">
        <thead>
          <tr>
            <td></td>
            <td>SMALL</td>
            <td>MEDIUM</td>
            <td>LARGE</td>
            <td>LARGE (PLUS)</td>
          </tr>
        </thead>
        <tbody>
          {data.billPlansData.map((tr, index) => (
            <tr key={index}>
              {tr.map((td, i) =>
                index === 0 ? (
                  <td key={index + i}>
                    {td === "Users" ? (
                      td
                    ) : (
                      <span className="label label-success">{td}</span>
                    )}
                  </td>
                ) : (
                  <td key={index + i}>
                    {td === "FREE" ? (
                      <b className="green">{td}</b>
                    ) : td === "no" ? (
                      <i className="fa fa-times icon red" />
                    ) : td === "yes" ? (
                      <i className="fa fa-check icon green" />
                    ) : td.includes("calls") ? (
                      <b className="green">{td}</b>
                    ) : td === "Contact Us" ? (
                      <a className="billing-a" href="#">
                        {td}
                      </a>
                    ) : (
                      td
                    )}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
