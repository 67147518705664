import React from "react";
import DeliveryAddressesTable from "./deliveryAddressesTable";
import Select from "react-select";

export default function Delivery({
  edit,
  countries,
  onChange,
  handleChange,
  onClickSaveDeliveryAddress,
  deliveryAddresses,
}) {
  return (
    <div id="Delivery" className="tab-pane fade searchable-dropdown">
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="addressName">
            Address Name
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="addressName"
            name="addressName"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="addressLine1">
            Address Line 1
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="addressLine1"
            name="addressLine1"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="addressLine2">
            Address Line 2
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="addressLine2"
            name="addressLine2"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="suburb">
            Suburb
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="suburb"
            name="suburb"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="city">
            Town/City
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="city"
            name="city"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="state_Region">
            State/Region
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="state_Region"
            name="state_Region"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="postalCode">
            Postal Code
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="postalCode"
            name="postalCode"
          />
        </div>
      </div>
      <br />
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="country">
            Country
          </label>
          <Select
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            isClearable
            // isDisabled={!edit ? 'disabled' : ''}
            name="country"
            id="country"
            options={countries}
          />
        </div>
        <div className="col-md-6 padding-1">
          <label className="input-label" htmlFor="deliveryInstruction">
            Delivery Instruction
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="deliveryInstruction"
            name="deliveryInstruction"
          />
        </div>
        <div className="col-md-2 padding-1" id="system-add-btn">
          <button
            // disabled={!edit ? 'disabled' : ''}
            type="button"
            onClick={() => onClickSaveDeliveryAddress()}
            className="btn btn-success btn-sm"
          >
            Add
          </button>
          <i
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="top"
            title="please save customer before adding delivery address"
            className="fa fa-question-circle"
          />
        </div>
      </div>
      <br />
      <DeliveryAddressesTable
        deliveryAddresses={deliveryAddresses}
        edit={edit}
      />
    </div>
  );
}
