import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import ReceiptPurchaseTable from "./receiptPurchaseTable";
import Stats from "./stats";

export default function ReceiptPurchase({ goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-md-4">
            <label>Receipt Purchase</label>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-3 padding-1" style={{ textAlign: "right" }}>
            <button
              onClick={() => goto("/purchases/purchase orders/view purchases")}
              className="btn btn-light btn-sm"
            >
              View Purchases
            </button>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm id="order-number" value="" name="Order Number" />
              <InlineInputForm
                id="supplier-reference"
                value=""
                name="Supplier Reference"
              />
              <InlineInputForm
                id="supplier-invoice-date"
                value=""
                name="Supplier Invoice Date"
                type="date"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="supplier-code"
                value=""
                name="Supplier Code"
                disable="disabled"
              />
              <InlineInputForm
                id="supplier-name"
                value=""
                name="Supplier Name"
                disable="disabled"
              />
              <InlineInputForm
                id="currency-code"
                value=""
                name="Currency Code"
                disable="disabled"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="order-date"
                value=""
                name="Order Date"
                type="date"
                disable="disabled"
              />
              <InlineInputForm
                id="delivery-date"
                value=""
                name="Delivery Date"
                type="date"
                disable="disabled"
              />
              <InlineInputForm
                id="warehouse"
                value=""
                name="Warehouse"
                disable="disabled"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <ReceiptPurchaseTable />
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Stats />
        </div>
      </div>
    </>
  );
}
