import React from "react";

import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";

export default function StockOnHand({ onStockOnHandUpload }) {
  return (
    <div id="StockOnHand" className="tab-pane fade">
      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading="Importing Stock On Hand"
            message="If you have no stock on hand available, then do not enter any values into the .csv file (spreadsheet). Only enter the quantity and value if there are quantities on hand available."
            link="Click for more information."
          />
          <UploadFiles
            onFileDivClick={() => onStockOnHandUpload()}
            id="Stock On Hand"
          />
        </div>
      </div>
    </div>
  );
}
