import React from "react";
import Chart from "./chart";
import Info from "./info";

export default function Dashboard({
  probablityOptions,
  activityOptions,
  marginOptions,
  warehouses,
  products,
  suppliers,
  customers,
}) {
  return (
    <div className="dashboard">
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>Dashboard</label>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-md-3 col-sm-6">
          <div className="wrimagecard wrimagecard-topimage">
            <div
              className="wrimagecard-topimage_header">
              <center>
                <i className="fa fa-users" style={{ color: "#8ec8fa" }}></i>
              </center>
            </div>
            <div className="wrimagecard-topimage_title">
              <h4>
                Customers
                <div className="pull-right badge">
                  {customers ? customers.length : 0}
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="wrimagecard wrimagecard-topimage">
            <div
              className="wrimagecard-topimage_header">
              <center>
                <i className="fa fa-truck" style={{ color: "#64ea90" }}></i>
              </center>
            </div>
            <div className="wrimagecard-topimage_title">
              <h4>
                Suppliers
                <div className="pull-right badge">
                  {suppliers ? suppliers.length : 0}
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="wrimagecard wrimagecard-topimage">
            <div
              className="wrimagecard-topimage_header">
              <center>
                <i
                  className="glyphicon glyphicon-object-align-right"
                  style={{ color: "#d897eb" }}
                ></i>
              </center>
            </div>
            <div className="wrimagecard-topimage_title">
              <h4>
                Products
                <div className="pull-right badge">
                  {products ? products.length : 0}
                </div>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="wrimagecard wrimagecard-topimage">
            <div
              className="wrimagecard-topimage_header"
            >
              <center>
                <i
                className="glyphicon glyphicon glyphicon-tower"
                style={{ color: "#f79799" }}
                ></i>
              </center>
            </div>
            <div className="wrimagecard-topimage_title">
              <h4>
                Warehouses
                <div className="pull-right badge">
                  {warehouses ? warehouses.length : 0}
                </div>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-lg-6">
          <div className="panel panel-default">
            <div className="panel-body">
              <strong>Profitability</strong>
              <hr className="dashboard-hr" />
              <div className="row">
                <div className="col-sm-7">
                  <Chart data={probablityOptions} height={250} />
                </div>
                <div className="col-sm-5">
                  <Info
                    label="Sales Revenue"
                    percentage="70"
                    number="71396.76"
                    icon="down"
                    color="red"
                  />
                  <br />
                  <Info
                    label="Sales Margin"
                    percentage="26"
                    number="67%"
                    icon="up"
                    color="green"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="panel panel-default">
            <div className="panel-body">
              <strong>Activity</strong>
              <hr className="dashboard-hr" />
              <div className="row">
                <div className="col-sm-7">
                  <Chart data={activityOptions} height={250} />
                </div>
                <div className="col-sm-5">
                  <Info
                    label="Number of sales"
                    percentage="76"
                    number="5"
                    icon="down"
                    color="red"
                  />
                  <br />
                  <Info
                    label="Items per sales"
                    percentage="9"
                    number="1.40"
                    icon="up"
                    color="green"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-lg-12">
          <div className="panel panel-default">
            <div className="panel-body">
              <strong>Margin</strong>
              <hr className="dashboard-hr" />
              <Chart
                data={marginOptions}
                height={200}
                width={600}
                line={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-lg-12">
          <div className="panel panel-default" id="transaction">
            <div className="panel-body">
              <strong>Transactions</strong>
              <hr className="dashboard-hr" />
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-lg-6">
          <div className="panel panel-default" id="stock">
            <div className="panel-body">
              <strong>Stock</strong>
              <hr className="dashboard-hr" id="second-last-hr" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="panel panel-default" id="kpis">
            <div className="panel-body">
              <strong>KPIs</strong>
              <hr className="dashboard-hr" id="second-last-hr" />
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="dashboard-row">
        <div className="col-lg-12">
          <div className="panel panel-default" id="batch-expiry">
            <div className="panel-body">
              <strong>Batch Expiry</strong>
              <hr className="dashboard-hr" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
