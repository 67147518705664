import { settings } from "@constants/actionTypes";
const initialState = {
  allWarehouses: null,
  selectedWarehouse: null,
};

function warehousesReducer(state = initialState, action) {
  switch (action.type) {
    case settings.WAREHOUSES:
      return { ...state, allWarehouses: action.payload };
    case settings.SELECTED_WAREHOUSE:
      return { ...state, selectedWarehouse: action.payload };
    default:
      return state;
  }
}
export default warehousesReducer;
