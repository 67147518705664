import React from "react";
import Select from "react-select";

export default function Details({
  attributes,
  units,
  productGroups,
  goto,
  onChange,
  stateData,
  handleChange,
  edit,
  selectedUnit,
  selectedGroup,
  selectedAttribute,
}) {
  return (
    <div id="Details" className="tab-pane active">
      <div className="row">
        <div className="col-lg-8 col-md-8" id="details-parent-dev">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="input-label">*Product Code</td>
                <td className="table-input">
                  <input
                    className="form-control"
                    required
                    disabled={edit ? "disabled" : ""}
                    id="productCode"
                    name="productCode"
                    type="text"
                    value={
                      stateData
                        ? stateData.productCode
                          ? stateData.productCode
                          : ""
                        : ""
                    }
                    onChange={(e) => onChange(e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">*Product Description</td>
                <td className="table-input">
                  <textarea
                    onChange={(e) => onChange(e)}
                    required
                    value={
                      stateData
                        ? stateData.productDescription
                          ? stateData.productDescription
                          : ""
                        : ""
                    }
                    id="productDescription"
                    name="productDescription"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Barcode</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData
                        ? stateData.barcode
                          ? stateData.barcode
                          : ""
                        : ""
                    }
                    id="barcode"
                    name="barcode"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">
                  <a onClick={() => goto("/settings/system/units of measure")}>
                    Unit Of Measure
                  </a>
                </td>
                <td className="table-input">
                  <Select
                    id="unitId"
                    name="unitId"
                    isClearable
                    value={
                      stateData
                        ? stateData.unitId
                          ? selectedUnit(stateData.unitId)
                          : null
                        : null
                    }
                    onChange={handleChange}
                    options={units}
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">
                  <a onClick={() => goto("/settings/system/product groups")}>
                    Product Group
                  </a>
                </td>
                <td className="table-input">
                  <Select
                    name="productGroupId"
                    id="productGroupId"
                    isClearable
                    value={
                      stateData
                        ? stateData.productGroupId
                          ? selectedGroup(stateData.productGroupId)
                          : null
                        : null
                    }
                    onChange={handleChange}
                    options={productGroups}
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">
                  <a href="#">Default Label Template</a>
                </td>
                <td className="table-input">
                  <Select
                    name="labelTemplate"
                    id="labelTemplate"
                    isClearable
                    value={
                      stateData
                        ? stateData.labelTemplate
                          ? {
                              label: stateData.labelTemplate,
                              value: stateData.labelTemplate,
                            }
                          : null
                        : null
                    }
                    onChange={handleChange}
                    options={[
                      { value: "globussDefault", label: "Globuss Default" },
                      { value: "defaultLabel", label: "Default Label" },
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="product-section-heading">Attributes</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <hr className="my-hr" />
                </td>
              </tr>

              <tr>
                <td className="input-label">
                  <a onClick={() => goto("/settings/system/attribute sets")}>
                    Attribute Set
                  </a>
                </td>
                <td className="table-input">
                  <Select
                    name="attributeSetsId"
                    id="attributeSetsId"
                    isClearable
                    value={
                      stateData
                        ? stateData.attributeSetsId
                          ? selectedAttribute(stateData.attributeSetsId)
                          : null
                        : null
                    }
                    onChange={handleChange}
                    options={attributes}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className="product-section-heading">Dimensions</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <hr className="my-hr" />
                </td>
              </tr>
              <tr>
                <td className="input-label">Pack Size</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData
                        ? stateData.packSize
                          ? stateData.packSize
                          : ""
                        : ""
                    }
                    id="packSize"
                    name="packSize"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Weight(kg)</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData
                        ? stateData.weight
                          ? stateData.weight
                          : ""
                        : ""
                    }
                    id="weight"
                    name="weight"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Width(metre)</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData ? (stateData.width ? stateData.width : "") : ""
                    }
                    id="width"
                    name="width"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Height(metre)</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData
                        ? stateData.height
                          ? stateData.height
                          : ""
                        : ""
                    }
                    id="height"
                    name="height"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Depth(metre)</td>
                <td className="table-input">
                  <input
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    required
                    value={
                      stateData ? (stateData.depth ? stateData.depth : "") : ""
                    }
                    id="depth"
                    name="depth"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <td className="input-label">Cubic Total(metre)</td>
                <td className="table-input">
                  <input
                    className="form-control"
                    disabled
                    id="cubicCotal"
                    name="cubicCotal"
                    type="number"
                    value={
                      Number(
                        stateData ? (stateData.width ? stateData.width : 0) : 0
                      ) *
                      Number(
                        stateData
                          ? stateData.height
                            ? stateData.height
                            : 0
                          : 0
                      ) *
                      Number(
                        stateData ? (stateData.depth ? stateData.depth : 0) : 0
                      )
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-4 col-md-4">
          <form>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td className="input-label">
                    <a
                      onClick={() =>
                        goto("/reports/inventory/stock on hand enquiry")
                      }
                    >
                      Stock On Hand
                    </a>
                  </td>
                  <td className="table-input">
                    <span>0.0000</span>
                  </td>
                </tr>
                <tr>
                  <td className="input-label">
                    <a
                      onClick={() =>
                        goto("/reports/inventory/product allocations")
                      }
                    >
                      Allocated Qty
                    </a>
                  </td>
                  <td className="table-input">
                    <span>0.0000</span>
                  </td>
                </tr>
                <tr>
                  <td className="input-label">
                    <a
                      onClick={() =>
                        goto("/reports/inventory/stock on hand enquiry")
                      }
                    >
                      Available Qty
                    </a>
                  </td>
                  <td className="table-input">
                    <span>0.0000</span>
                  </td>
                </tr>
                <tr>
                  <td className="input-label">
                    <a onClick={() => goto("/inventory/products/add product")}>
                      On Purchase Qty
                    </a>
                  </td>
                  <td className="table-input">
                    <span>0.0000</span>
                  </td>
                </tr>
                <tr>
                  <td className="input-label">
                    <a
                      onClick={() =>
                        goto("/reports/inventory/stock on hand enquiry")
                      }
                    >
                      Stock Value
                    </a>
                  </td>
                  <td className="table-input">
                    <span>0.0000</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="product-section-heading">Types</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr className="my-hr1" />
                  </td>
                </tr>
                <tr>
                  <td className="input-label">Never Diminishing</td>
                  <td className="table-input">
                    <label className="switch">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={
                          stateData
                            ? stateData.neverDiminishing === 1
                              ? "checked"
                              : ""
                            : ""
                        }
                        id="neverDiminishing"
                        name="neverDiminishing"
                      />
                      <span className="slider round"></span>
                    </label>
                    <i
                      className={`${
                        stateData
                          ? stateData.neverDiminishing === 1
                            ? "fa fa-check"
                            : "fa fa-times"
                          : "fa fa-times"
                      } pull-right`}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="input-label">
                    <a
                      onClick={() =>
                        goto(
                          "/productions/bill of materials/view bill of materials"
                        )
                      }
                    >
                      Assembled Product
                    </a>
                  </td>
                  <td className="table-input">
                    <label className="switch">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={
                          stateData
                            ? stateData.assembledProduct === 1
                              ? "checked"
                              : ""
                            : ""
                        }
                        id="assembledProduct"
                        name="assembledProduct"
                      />
                      <span className="slider round"></span>
                    </label>
                    <i
                      className={`${
                        stateData
                          ? stateData.assembledProduct === 1
                            ? "fa fa-check"
                            : "fa fa-times"
                          : "fa fa-times"
                      } pull-right`}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="input-label">Component Product</td>
                  <td className="table-input">
                    <label className="switch">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={
                          stateData
                            ? stateData.component === 1
                              ? "checked"
                              : ""
                            : ""
                        }
                        name="component"
                        id="component"
                      />
                      <span className="slider round"></span>
                    </label>
                    <i
                      className={`${
                        stateData
                          ? stateData.component === 1
                            ? "fa fa-check"
                            : "fa fa-times"
                          : "fa fa-times"
                      } pull-right`}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="input-label">Obsolete</td>
                  <td className="table-input">
                    <label className="switch">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={
                          stateData
                            ? stateData.obsolete === 1
                              ? "checked"
                              : ""
                            : ""
                        }
                        name="obsolete"
                        id="obsolete"
                      />
                      <span className="slider round"></span>
                    </label>
                    <i
                      className={`${
                        stateData
                          ? stateData.obsolete === 1
                            ? "fa fa-check"
                            : "fa fa-times"
                          : "fa fa-times"
                      } pull-right`}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="input-label">Sellable</td>
                  <td className="table-input">
                    <label className="switch">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={
                          stateData
                            ? stateData.sellable === 1
                              ? "checked"
                              : ""
                            : ""
                        }
                        id="sellable"
                        name="sellable"
                      />
                      <span className="slider round"></span>
                    </label>
                    <i
                      className={`${
                        stateData
                          ? stateData.sellable === 1
                            ? "fa fa-check"
                            : "fa fa-times"
                          : "fa fa-times"
                      } pull-right`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="product-section-heading">Notes</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr className="my-hr1" />
                  </td>
                </tr>
                <tr>
                  <td className="notes-textarea">
                    <textarea
                      onChange={(e) => onChange(e)}
                      required
                      style={{ height: "200px" }}
                      value={
                        stateData
                          ? stateData.notes
                            ? stateData.notes
                            : ""
                          : ""
                      }
                      id="notes"
                      name="notes"
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
}
