import React, { Component } from "react";
import {
  loaderState,
  getCountriesData,
  getWarehouses,
  getProducts,
  addQuicklLinks,
  getCustomers,
  getSuppliers,
  getCount,
  getUserPermissions,
} from "@actions";
import { connect } from "react-redux";
import DashboardComp from "@components/dashboard";

class Dashboard extends Component {
  componentDidMount() {
    document.title = "Dashboard";
    // this.props.getCount();
    // this.props.loaderState(true);
    // this.props.addQuicklLinks();
    const { countries } = this.props;
    if (!countries) {
      this.props.loaderState(true);
      this.props.getCountriesData();
    }
    this.props.getUserPermissions();
    this.props.getWarehouses();
    this.props.getProducts();
    this.props.getCustomers();
    this.props.getSuppliers();
  }

  render() {
    const probablityOptions = {
      labels: ["Red", "Blue", "Yellow"],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    const activityOptions = {
      labels: ["Red", "Blue", "Yellow"],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };

    const marginOptions = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "First dataset",
          // fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          // pointRadius: 1,
          // pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "Second dataset",
          // fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,182,182,0.4)",
          borderColor: "rgba(75,182,182,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,182,182,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,182,182,1)",
          pointHoverBorderColor: "rgba(220,210,210,1)",
          pointHoverBorderWidth: 2,
          // pointRadius: 1,
          // pointHitRadius: 10,
          data: [60, 65, 82, 77, 60, 59, 43],
        },
      ],
    };

    const { warehouses, products, suppliers, customers } = this.props;
    return (
      <DashboardComp
        probablityOptions={probablityOptions}
        activityOptions={activityOptions}
        marginOptions={marginOptions}
        warehouses={warehouses}
        products={products}
        suppliers={suppliers}
        customers={customers}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countriesData,
    warehouses: state.system.warehouses.allWarehouses,
    products: state.inventory.products.allProducts,
    suppliers: state.suppliers.allSuppliers,
    customers: state.customers.allCustomers,
  };
};

const mapDispatchToProps = {
  loaderState,
  getCountriesData,
  getWarehouses,
  getProducts,
  addQuicklLinks,
  getCustomers,
  getSuppliers,
  getCount,
  getUserPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
