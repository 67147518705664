import { customers } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getContacts = (data) => (dispatch) => {
  api
    .post("/contacts/filter", data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: customers.CUSTOMER_CONTACTS,
          payload: res.data.data,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addContact = (data) => (dispatch, getState) => {
  let { contacts } = getState().customers;
  const { loggedInUser } = getState().auth;
  data.createdBy = loggedInUser.id;
  data.updatedBy = loggedInUser.id;
  if (!contacts) {
    contacts = [];
  }
  api
    .post("/contacts", data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: customers.CUSTOMER_CONTACTS,
          payload: [...contacts, res.data.data],
        });
        swal("", "Contact added successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateContact = (data) => (dispatch, getState) => {
  const { contacts } = getState().customers;
  const { loggedInUser } = getState().auth;
  data.updatedBy = loggedInUser.id;
  api
    .post(`/contacts/update/${data.id}`, data)
    .then((res) => {
      if (res.data.code === 200) {
        let updatedContacts = _.map(contacts, (contact) => {
          return contact.id === data.id ? res.data.data : contact;
        });
        dispatch({
          type: customers.CUSTOMER_CONTACTS,
          payload: updatedContacts,
        });
        swal("", "Contact Updated successfully", "success");
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteContact = (id) => (dispatch, getState) => {
  const { contacts } = getState().customers;
  api
    .delete(`/contacts/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(contacts, (contact) => {
          return contact.id === id;
        });
        swal("", "Contact deleted successfully", "success");
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};
