import React, { Component } from "react";
import {
  initilizeDataTable,
  destroyDataTable,
  removeNullValues,
  isEmptyOrNull,
  prepareSearchAbleDropdownData,
} from "@helpers";
import AddCustomerComp from "@components/customers/add";
import {
  addCustomer,
  loaderState,
  updateCustomer,
  getCustomerTypes,
  getSalesPerson,
  getSalesGroups,
  getWarehouses,
  getPaymentTerms,
  getDeliveryMethods,
  getPriceTiers,
  getCurrencyRates,
  getTaxes,
  getContacts,
  addContact,
  updateContact,
  deleteContact,
  addDeliveryAddress,
  getDeliveryAddresses,
  updateDeliveryAddress,
} from "@actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        "Details",
        "Address",
        "Contacts",
        "Delivery",
        "Quotes",
        "Orders",
        "Credits",
        "Customer Pricing",
      ],
    };
  }

  componentDidMount() {
    document.title = "Add Customer";
    let id = this.props.match.params.id;
    if (id) {
      const { customers } = this.props;
      let selectedCustomer = _.findLast(customers, (customer) => {
        return Number(customer.customerData.id) === Number(id);
      });
      this.setState({ ...selectedCustomer });
      this.props.getContacts({ customerId: Number(id) });
      this.props.getDeliveryAddresses({ customerId: Number(id) });
    }
    const {
      customerTypes,
      salesGroups,
      salesPerson,
      warehouses,
      paymentTerms,
      sellPriceTiers,
      currencyRates,
      taxes,
      deliveryMethod,
    } = this.props;
    if (!customerTypes) {
      this.props.getCustomerTypes();
    }
    if (!salesGroups) {
      this.props.getSalesGroups();
    }
    if (!salesPerson) {
      this.props.getSalesPerson();
    }
    if (!warehouses) {
      this.props.getWarehouses();
    }
    if (!paymentTerms) {
      this.props.getPaymentTerms();
    }
    if (!sellPriceTiers) {
      this.props.getPriceTiers();
    }
    if (!currencyRates) {
      this.props.getCurrencyRates();
    }
    if (!taxes) {
      this.props.getTaxes();
    }
    if (!deliveryMethod) {
      this.props.getDeliveryMethods();
    }

    initilizeDataTable("customers-table");
    initilizeDataTable("delivery-address-table");
    initilizeDataTable("quotes-table");
    initilizeDataTable("orders-table");
    initilizeDataTable("credits-table");
  }

  componentDidUpdate() {
    initilizeDataTable("customers-table");
    initilizeDataTable("delivery-address-table");
    initilizeDataTable("quotes-table");
    initilizeDataTable("orders-table");
    initilizeDataTable("credits-table");
  }

  handleChange = (newValue, actionMeta, obj) => {
    const { name } = actionMeta;
    if (obj === "postal") {
      let postal = { ...this.state.postal };
      if (newValue) {
        postal[name] = newValue.value;
        this.setState({ postal });
      } else {
        postal[name] = newValue;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      let physical = { ...this.state.physical };
      if (newValue) {
        physical[name] = newValue.value;
        this.setState({ physical });
      } else {
        physical[name] = newValue;
        this.setState({ physical });
      }
    } else if (obj === "deliveryAddress") {
      let deliveryAddress = { ...this.state.deliveryAddress };
      if (newValue) {
        deliveryAddress[name] = newValue.value;
        this.setState({ deliveryAddress });
      } else {
        deliveryAddress[name] = newValue;
        this.setState({ deliveryAddress });
      }
    } else if (obj === "contacts") {
      let contacts = { ...this.state.contacts };
      if (newValue) {
        contacts[name] = newValue.value;
        this.setState({ contacts });
      } else {
        contacts[name] = newValue;
        this.setState({ contacts });
      }
    } else {
      let customerData = { ...this.state.customerData };
      if (newValue) {
        customerData[name] = newValue.value;
        this.setState({ customerData });
      } else {
        customerData[name] = newValue;
        this.setState({ customerData });
      }
    }
  };

  onChange = (e, obj) => {
    const { id, type } = e.target;
    if (obj === "postal") {
      var postal = { ...this.state.postal };
      if (type === "checkbox") {
        if (e.target.checked) {
          postal[id] = 1;
        } else {
          postal[id] = -1;
        }
        this.setState({ postal });
      } else {
        postal[id] = e.target.value;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      var physical = { ...this.state.physical };
      if (type === "checkbox") {
        if (e.target.checked) {
          physical[id] = 1;
        } else {
          physical[id] = -1;
        }
        this.setState({ physical });
      } else {
        physical[id] = e.target.value;
        this.setState({ physical });
      }
    } else if (obj === "deliveryAddress") {
      var deliveryAddress = { ...this.state.deliveryAddress };
      deliveryAddress[id] = e.target.value;
      this.setState({ deliveryAddress });
    } else if (obj === "contacts") {
      var contacts = { ...this.state.contacts };
      if (type === "checkbox") {
        if (e.target.checked) {
          contacts[id] = 1;
        } else {
          contacts[id] = -1;
        }
        this.setState({ contacts });
      } else {
        contacts[id] = e.target.value;
        this.setState({ contacts });
      }
    } else {
      var customerData = { ...this.state.customerData };
      if (type === "checkbox") {
        if (e.target.checked) {
          customerData[id] = 1;
        } else {
          customerData[id] = -1;
        }
        this.setState({ customerData });
      } else {
        customerData[id] = e.target.value;
        this.setState({ customerData });
      }
    }
  };

  onClickSave = () => {
    let state = { ...this.state };
    delete state.nav;
    if (this.props.match.params.id) {
      this.onUpdateCustomer();
    } else {
      let customerData = removeNullValues(state.customerData);
      let physical = removeNullValues(state.physical);
      let postal = removeNullValues(state.postal);
      if (
        customerData.customerCode &&
        !isEmptyOrNull(customerData.customerCode) &&
        customerData.customerName &&
        !isEmptyOrNull(customerData.customerName)
      ) {
        this.props.loaderState(true);
        this.props.addCustomer(customerData, postal, physical);
      } else {
        swal("", "Customer Code and Customer Name are required fields", "info");
      }
    }
  };

  onClickSaveDeliveryAddress = () => {
    let custID = this.props.match.params.id;
    if (custID) {
      let state = { ...this.state };
      let deliveryAddress = removeNullValues(state.deliveryAddress);
      deliveryAddress.customerId = this.props.match.params.id;
      if (isEmptyOrNull(deliveryAddress.addressName)) {
        swal("", "Address name is required field", "info");
      } else {
        this.props.loaderState(true);
        this.props.addDeliveryAddress(deliveryAddress);
      }
    } else {
      swal("", "Please save customer before adding delivery address", "info");
    }
  };

  onClickSaveContact = () => {
    let custID = this.props.match.params.id;
    if (custID) {
      let state = { ...this.state };
      let contacts = removeNullValues(state.contacts);
      contacts.customerId = Number(custID);
      if (
        isEmptyOrNull(contacts.firstName) &&
        isEmptyOrNull(contacts.lastName) &&
        isEmptyOrNull(contacts.email)
      ) {
        swal(
          "",
          "At least one of: First Name, Last Name, Email fields needs to be populated",
          "info"
        );
      } else {
        this.props.loaderState(true);
        this.props.addContact(contacts);
      }
    } else {
      swal("", "Please save customer before adding contacts", "info");
    }
  };

  onUpdateCustomer = () => {
    let stateData = { ...this.state };
    if (
      isEmptyOrNull(stateData.customerData.customerCode) &&
      isEmptyOrNull(stateData.customerData.customerName)
    ) {
      swal("", "Customer Code and Customer Name are required fields", "info");
    } else {
      let customerData = removeNullValues(stateData.customerData);
      delete customerData.customerTypeName;
      delete customerData.currencyCode;
      delete customerData.salesPersonName;
      delete customerData.salesGroupName;
      delete customerData.warehouseName;
      delete customerData.deliveryMethodName;
      delete customerData.taxName;
      delete customerData.paymentTermName;
      delete customerData.sellPriceTierName;

      let physical = removeNullValues(stateData.physical);
      let postal = removeNullValues(stateData.postal);
      delete stateData.nav;
      this.props.loaderState(true);
      this.props.updateCustomer(customerData, postal, physical);
    }
  };

  perpareCountriesData = () => {
    const { countriesData } = this.props;
    let data = [];
    _.forEach(countriesData, (country) => {
      data.push({ value: country.name, label: country.name });
    });

    return data;
  };

  onClickContactAction = (action, id) => {
    if (action === "Delete") {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.props.loaderState(true);
          this.props.deleteContact(Number(id));
        }
      });
    } else if (action === "Set as primary") {
      let data = { id: Number(id), primary: 1 };
      this.props.loaderState(true);
      this.props.updateContact(data);
    }
  };

  getSelectedValue = (value, options) => {
    let data = {};
    _.forEach(options, (option) => {
      if (option.value === value) {
        data.value = option.value;
        data.label = option.label;
      }
    });

    return data;
  };

  render() {
    destroyDataTable("customers-table");
    destroyDataTable("delivery-address-table");
    destroyDataTable("quotes-table");
    destroyDataTable("orders-table");
    destroyDataTable("credits-table");

    const stateData = { ...this.state };

    const {
      customerTypes,
      salesGroups,
      salesPerson,
      warehouses,
      paymentTerms,
      sellPriceTiers,
      currencyRates,
      taxes,
      deliveryMethod,
      contacts,
      deliveryAddresses,
    } = this.props;

    let customerTypesData = prepareSearchAbleDropdownData(
      customerTypes,
      "typeName",
      "id"
    );
    let salesGroupsData = prepareSearchAbleDropdownData(
      salesGroups,
      "groupName",
      "id"
    );
    let salesPersonData = prepareSearchAbleDropdownData(
      salesPerson,
      "fullName",
      "id"
    );
    let warehousesData = prepareSearchAbleDropdownData(
      warehouses,
      "warehouseName",
      "id"
    );
    let paymentTermsData = prepareSearchAbleDropdownData(
      paymentTerms,
      "name",
      "id"
    );
    let sellPriceTiersData = prepareSearchAbleDropdownData(
      sellPriceTiers,
      "name",
      "id"
    );
    let currencyRatesData = prepareSearchAbleDropdownData(
      currencyRates,
      "currencyCode",
      "id"
    );
    let taxesData = prepareSearchAbleDropdownData(taxes, "taxName", "id");
    let deliveryMethodData = prepareSearchAbleDropdownData(
      deliveryMethod,
      "methodName",
      "id"
    );
    let countries = this.perpareCountriesData();

    return (
      <AddCustomerComp
        deliveryMethodData={deliveryMethodData}
        customerTypesData={customerTypesData}
        salesGroupsData={salesGroupsData}
        salesPersonData={salesPersonData}
        warehousesData={warehousesData}
        paymentTermsData={paymentTermsData}
        sellPriceTiersData={sellPriceTiersData}
        currencyRatesData={currencyRatesData}
        taxesData={taxesData}
        countries={countries}
        edit={this.props.match.params.id}
        stateData={stateData}
        onChange={(e, obj) => this.onChange(e, obj)}
        handleChange={(newValue, actionMeta, obj) =>
          this.handleChange(newValue, actionMeta, obj)
        }
        onClickSave={() => this.onClickSave()}
        onClickSaveDeliveryAddress={() => this.onClickSaveDeliveryAddress()}
        onClickSaveContact={() => this.onClickSaveContact()}
        contacts={contacts}
        onClickContactAction={(action, id) =>
          this.onClickContactAction(action, id)
        }
        deliveryAddresses={deliveryAddresses}
        getSelectedValue={(value, options) =>
          this.getSelectedValue(value, options)
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customers.allCustomers,
    countriesData: state.common.countriesData,
    customerTypes: state.system.customerTypes.allCustomerTypes,
    salesPerson: state.system.salesPerson.allSalesPerson,
    salesGroups: state.system.salesGroups.allSalesGroups,
    warehouses: state.system.warehouses.allWarehouses,
    paymentTerms: state.system.paymentTerms.allTerms,
    sellPriceTiers: state.system.sellPriceTiers.allTiers,
    currencyRates: state.system.currencyRates.allCurrencies,
    taxes: state.system.taxes.allTaxes,
    deliveryMethod: state.system.deliveryMethods.allMethods,
    contacts: state.customers.contacts,
    deliveryAddresses: state.customers.deliveryAddresses,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  addCustomer,
  updateCustomer,
  getSalesPerson,
  getCustomerTypes,
  getSalesGroups,
  getWarehouses,
  getPaymentTerms,
  getDeliveryMethods,
  getPriceTiers,
  getCurrencyRates,
  getTaxes,
  getContacts,
  addContact,
  updateContact,
  deleteContact,
  addDeliveryAddress,
  getDeliveryAddresses,
  updateDeliveryAddress,
})(AddCustomer);
