import React from "react";
import CustomerContactsTable from "./customerContactsTable";
import Select from "react-select";

export default function Contacts({
  edit,
  onChange,
  handleChange,
  deliveryAddresses,
  onClickSaveContact,
  contacts,
  onClickContactAction,
}) {
  return (
    <div id="Contacts" className="tab-pane fade searchable-dropdown">
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="firstName">
            First Name
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="lastName">
            Last Name
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="email">
            Email
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="email"
            name="email"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="deliveryAddressId">
            Delivery Address
          </label>
          <Select
            // isDisabled={!edit ? 'disabled' : ''}
            isClearable
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            className="contact-delivery-address"
            name="deliveryAddressId"
            id="deliveryAddressId"
            options={deliveryAddresses}
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="website">
            Website
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="website"
            name="website"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="tollFreeNumber">
            Toll Free No
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="tollFreeNumber"
            name="tollFreeNumber"
          />
        </div>
      </div>
      <br />
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="phoneNumber">
            Phone
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="faxNumber">
            Fax
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="faxNumber"
            name="faxNumber"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="mobileNumber">
            Mobile
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="mobileNumber"
            name="mobileNumber"
          />
        </div>
        <div className="col-md-1 padding-1">
          <label className="input-label" htmlFor="DDINumber">
            DDI
          </label>
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="text"
            className="form-control"
            id="DDINumber"
            name="DDINumber"
          />
        </div>
        <div className="col-md-1  col-xs-4">
          <label className="input-label" htmlFor="ordering">
            Ordering
          </label>
          <br />
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="checkbox"
            style={{ width: "18px", height: "18px" }}
            id="ordering"
            name="ordering"
          />
        </div>
        <div className="col-md-1  col-xs-4">
          <label className="input-label" htmlFor="invoicing">
            Invoicing
          </label>
          <br />
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="checkbox"
            style={{ width: "18px", height: "18px" }}
            id="invoicing"
            name="invoicing"
          />
        </div>
        <div className="col-md-1 col-xs-4">
          <label className="input-label" htmlFor="shipping">
            Shipping
          </label>
          <br />
          <input
            onChange={(e) => onChange(e)}
            // disabled={!edit ? 'disabled' : ''}
            type="checkbox"
            style={{ width: "18px", height: "18px" }}
            id="shipping"
            name="shipping"
          />
        </div>
        <div className="col-md-2" id="system-add-btn">
          <button
            // disabled={!edit ? 'disabled' : ''}
            onClick={() => onClickSaveContact()}
            type="button"
            className="btn btn-success btn-sm"
          >
            Add
          </button>
          <i
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="top"
            title="please save customer before adding contacts"
            className="fa fa-question-circle"
          />
        </div>
      </div>
      <br />
      <CustomerContactsTable
        contacts={contacts}
        edit={edit}
        onClickAction={(action, id) => onClickContactAction(action, id)}
      />
    </div>
  );
}
