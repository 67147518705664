import { suppliers } from "@constants/actionTypes";
import api from "@api/api";
import { prepareSupplierData } from "@helpers";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getCount = () => (dispatch, getState) => {
  const { organizationInfo } = getState().auth;
  // { organizationId: organizationInfo.id }
  api
    .post("/suppliers/count")
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const getSuppliers = () => (dispatch) => {
  api
    .post("/suppliers/filter")
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;

        let data = [];
        await _.forEach(response, (resdata) => {
          data.push(prepareSupplierData(resdata));
        });
        dispatch({ type: suppliers.SUPPLIERS, payload: data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getSupplierByCode = (supplier_code) => (dispatch) => {
  console.log("get by code");
  let filter = { supplierCode: supplier_code };
  console.log(supplier_code);

  api
    .post("/suppliers/filter", filter)
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;

        // let data = [];
        // await _.forEach(response, (resdata) => {
        // 	data.push(prepareSupplierData(resdata));
        // });
        dispatch({ type: suppliers.SELECTED_SUPPLIER, payload: response });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addSupplier = (supplierData, postal, physical) => (
  dispatch,
  getState
) => {
  let allSuppliers = getState().suppliers.allSuppliers;
  const { loggedInUser } = getState().auth;
  let data = {};
  supplierData.createdBy = loggedInUser.id;
  supplierData.updatedBy = loggedInUser.id;
  data.supplierData = supplierData;

  if (postal) {
    postal.createdBy = loggedInUser.id;
    postal.updatedBy = loggedInUser.id;
    data.postalAddress = postal;
  }
  if (physical) {
    physical.createdBy = loggedInUser.id;
    physical.updatedBy = loggedInUser.id;
    data.physicalAddress = physical;
  }

  api
    .post("/suppliers", data)
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;
        data.supplierData = { ...supplierData, ...response.supplierData };
        data.postal = { ...postal, ...response.postalAddress };
        data.physical = { ...physical, ...response.physicalAddress };
        await dispatch({
          type: suppliers.SUPPLIERS,
          payload: [...allSuppliers, data],
        });
        swal("", "Supplier added successfully", "success");
        // goto(`/suppliers/update/${data.supplierData.id}`);
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateSupplier = (supplierData, postal, physical) => (
  dispatch,
  getState
) => {
  const { allSuppliers, selectedSupplier } = getState().suppliers;
  const { loggedInUser } = getState().auth;
  let data = {};
  let userId = loggedInUser.id;
  if (supplierData) {
    supplierData.updatedBy = userId;
    data.supplierData = supplierData;
  }
  if (postal) {
    postal.updatedBy = userId;
    data.postalAddress = postal;
  }
  if (physical) {
    physical.updatedBy = userId;
    data.physicalAddress = physical;
  }

  api
    .post(`/suppliers/update/${selectedSupplier.supplierData.id}`, data)
    .then((res) => {
      if (res.data.code === 200) {
        let response = res.data.data;
        let updatedSupplier = {
          supplierData: { ...data.supplierData },
          postal: { ...data.postalAddress },
          physical: { ...data.physicalAddress },
        };
        if (data.supplierData) {
          updatedSupplier.supplierData = {
            ...selectedSupplier.supplierData,
            ...data.supplierData,
          };
        } else {
          updatedSupplier.supplierData = { ...selectedSupplier.supplierData };
        }
        if (data.postalAddress) {
          updatedSupplier.postal = {
            ...selectedSupplier.postal,
            ...data.postalAddress,
          };
        } else {
          updatedSupplier.postal = { ...selectedSupplier.postal };
        }
        if (data.physicalAddress) {
          updatedSupplier.physical = {
            ...selectedSupplier.physical,
            ...data.physicalAddress,
          };
        } else {
          updatedSupplier.physical = { ...selectedSupplier.physical };
        }
        dispatch(onSelectSupplier(updatedSupplier));
        let updatedSuppliers = _.map(allSuppliers, (supplier) => {
          return supplier.supplierData.id === selectedSupplier.supplierData.id
            ? updatedSupplier
            : supplier;
        });
        dispatch({ type: suppliers.SUPPLIERS, payload: updatedSuppliers });
        swal("", "Supplier updated successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteSupplier = (id) => (dispatch, getState) => {
  const { allSuppliers } = getState().suppliers;
  api
    .delete(`/suppliers/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allSuppliers, (supplier) => {
          return supplier.id === id;
        });
        swal("", "Supplier delete successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectSupplier = (supplier) => (dispatch) => {
  dispatch({ type: suppliers.SELECTED_SUPPLIER, payload: supplier });
};
