import React from "react";
import CustomersTable from "./customersTable";
import Select from "react-select";

export default function ViewCustomers({
  goto,
  customers,
  gotoCustomer,
  onCustomerSelect,
  onClickAction,
  custTypes,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-md-4">
            <label>View Customers</label>
          </div>
          <div className="col-md-8 padding-1" style={{ textAlign: "right" }}>
            <button className="btn btn-light btn-sm">Import</button>
            <button className="btn btn-light btn-sm">Export</button>
            <button
              onClick={() => goto("/customers/add customers")}
              className="btn btn-success btn-sm"
            >
              Add Customer
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="search-customer">
                Customer
              </label>
              <input
                type="text"
                className="form-control"
                id="search-customer"
                name="search-customer"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="search-customer-type">
                Customer Type
              </label>
              <Select
                isClearable
                name="Order Status"
                id="order_status"
                options={custTypes}
                // options={[
                //   { label: "All", value: "All" },
                //   { label: "Cash", value: "Cash" },
                //   { label: "General", value: "General" },
                //   { label: "On Account", value: "On Account" },
                //   { label: "Wholesale", value: "Wholesale" },
                // ]}
              />
              {/* <select className="form-control" id="search-customer-type">
								<option>All</option>
								<option>Cash</option>
								<option>General</option>
								<option>On Account</option>
								<option>Wholesale</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="search-obsolete">
                Obsolete
              </label>
              <Select
                isClearable
                name="Order Status"
                id="order_status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {/* <select className="form-control" id="search-obsolete">
								<option>All</option>
								<option>Yes</option>
								<option>No</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CustomersTable
            gotoCustomer={(customer) => gotoCustomer(customer)}
            onCustomerSelect={(customer) => onCustomerSelect(customer)}
            customers={customers}
            goto={(path) => goto(path)}
            onClickAction={(action) => onClickAction(action)}
          />
        </div>
      </div>
    </>
  );
}
