import React from "react";
import SplitButton from "@components/common/splitButton";
import WarehouseTable from "./warehouseTable";
import Select from "react-select";

export default function WarehouseTransfers({ goto, transfers, onClickAction }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Warehouse Transfers</label>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export to PDF",
                "Export to CSV",
                "Export to XLSX",
                "Export to XLS",
              ]}
            />
            <button
              className="btn btn-light btn-sm"
              style={{ marginRight: "5px" }}
            >
              Import
            </button>
            <button
              onClick={() => goto("/warehouse transfer/add")}
              className="btn btn-success btn-sm"
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="warehouse-transfers">
            <div className="col-md-2">
              <label className="input-label" htmlFor="adjustments-status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "Open", value: "Open" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                  { label: "All", value: "All" },
                ]}
              />
              {/* <select className="form-control" id="adjustments-status">
								<option>Open</option>
								<option>Parked</option>
								<option>Completed</option>
								<option>All</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <WarehouseTable
            transfers={transfers}
            onClickAction={(action) => onClickAction(action)}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-8"></div>
        <div
          className="col-md-4"
          id="stock-price-count"
          style={{ float: "right" }}
        >
          <div className="transfers-label">STOCK TRANSFER COUNT</div>
          <br />
          <div className="transfers-count">
            {transfers ? transfers.length : 0}
          </div>
        </div>
      </div>
    </>
  );
}
