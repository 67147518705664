import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import StockMovementComp from "@components/reports/inventory/stockMovement";

export default class StockMovement extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <StockMovementComp />;
  }
}
