import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import InvoicesComp from "@components/reports/sales/invoice";

export default class Invoices extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <InvoicesComp />;
  }
}
