import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import Switch from "@components/common/switch";
import InlineDropdown from "@components/common/inlineDropDown";

export default function Details({
  onChange,
  handleChange,
  taxes,
  stateData,
  edit,
  currencyRates,
  paymentTerms,
  getSelectedValue,
}) {
  return (
    <div id="Details" className="tab-pane active">
      <br />
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            value={
              stateData
                ? stateData.supplierCode
                  ? stateData.supplierCode
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="supplierCode"
            name="*Supplier Code"
            disable={edit ? "disabled" : ""}
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.supplierName
                  ? stateData.supplierName
                  : ""
                : ""
            }
            id="supplierName"
            name="*Supplier Name"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.gstVatNumber
                  ? stateData.gstVatNumber
                  : ""
                : ""
            }
            id="gstVatNumber"
            name="GST/VAT Number"
          />
          <div className="inline-form">
            <div className="field">
              <label htmlFor="notes">Notes</label>
              <textarea
                onChange={(e) => onChange(e)}
                value={
                  stateData ? (stateData.notes ? stateData.notes : "") : ""
                }
                rows="7"
                type="text"
                id="notes"
              />
            </div>
          </div>
          <Switch
            value={
              stateData
                ? stateData.texable === true || stateData.texable === 1
                  ? "checked"
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="texable"
            name="Taxable"
            margin="yes"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            disable={
              stateData
                ? stateData.texable === true || stateData.texable === 1
                  ? ""
                  : "disabled"
                : "disabled"
            }
            id="taxId"
            name="Tax Rate"
            values={taxes}
            link={true}
            value={
              stateData
                ? stateData.taxId
                  ? getSelectedValue(taxes, stateData.taxId)
                  : null
                : null
            }
          />
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-4 col-sm-6">
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="currencyRateId"
            name="Currency"
            values={currencyRates}
            link={true}
            value={
              stateData
                ? stateData.currencyRateId
                  ? getSelectedValue(currencyRates, stateData.currencyRateId)
                  : null
                : null
            }
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData ? (stateData.bankName ? stateData.bankName : "") : ""
            }
            id="bankName"
            name="bank-name"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.bankBranch
                  ? stateData.bankBranch
                  : ""
                : ""
            }
            id="bankBranch"
            name="Bank Branch"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            value={
              stateData
                ? stateData.bankAccount
                  ? stateData.bankAccount
                  : ""
                : ""
            }
            id="bankAccount"
            name="Bank Account"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="paymentTermsId"
            name="Payment Term Description"
            values={paymentTerms}
            value={
              stateData
                ? stateData.paymentTermsId
                  ? getSelectedValue(paymentTerms, stateData.paymentTermsId)
                  : null
                : null
            }
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="purchaseOrderPrintTemplate"
            name="Purchase Order Print Template"
            value={
              stateData
                ? stateData.purchaseOrderPrintTemplate
                  ? {
                      value: stateData.purchaseOrderPrintTemplate,
                      label: stateData.purchaseOrderPrintTemplate,
                    }
                  : null
                : null
            }
            values={[
              { label: "Globuss Default", value: "Globuss Default" },
              { label: "Formal Purchase", value: "Formal Purchase" },
              { label: "Classic Purchase", value: "Classic Purchase" },
            ]}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="supplierReturnPrintTemplate"
            name="Supplier Return Print Template"
            value={
              stateData
                ? stateData.supplierReturnPrintTemplate
                  ? {
                      value: stateData.supplierReturnPrintTemplate,
                      label: stateData.supplierReturnPrintTemplate,
                    }
                  : null
                : null
            }
            values={[
              { label: "Globuss Default", value: "Globuss Default" },
              {
                label: "Default Supplier Return",
                value: "Default Supplier Return",
              },
            ]}
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.deliveryLeadTime
                  ? stateData.deliveryLeadTime
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="deliveryLeadTime"
            name="Delivery Lead Time (days)"
          />
        </div>
      </div>
    </div>
  );
}
