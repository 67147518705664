import React from "react";
import Tabs from "@components/common/tabs";
import Details from "./details";
import Address from "@components/common/address";
import Contact from "./contact";
import Purchases from "./purchases";
import Returns from "./returns";
import Costings from "./costings";

export default function AddSupplier({
  stateData,
  onChange,
  handleChange,
  onClickSave,
  currencies,
  edit,
  goto,
  paymentTerms,
  currencyRates,
  taxes,
  getSelectedValue,
  countries,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Add Supplier</label>
          </div>
          <div className="col-lg-5 col-md-2 col-1"></div>
          <div
            className="col-lg-3 col-md-3 col-3"
            style={{ textAlign: "right" }}
          >
            <button
              className="btn btn-light btn-sm"
              onClick={() => goto("/purchases/purchase orders/add purchase")}
            >
              Add Purchase
            </button>
            <button
              className="btn btn-success btn-sm"
              onClick={() => onClickSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={stateData.nav} />
          <div className="tab-content">
            <Details
              getSelectedValue={(options, value) =>
                getSelectedValue(options, value)
              }
              edit={edit}
              stateData={stateData ? stateData.supplierData : null}
              currencies={currencies}
              paymentTerms={paymentTerms}
              currencyRates={currencyRates}
              taxes={taxes}
              onChange={(e) => onChange(e, "supplier")}
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "supplier")
              }
            />
            <Address
              postal={
                stateData ? (stateData.postal ? stateData.postal : null) : null
              }
              physical={
                stateData
                  ? stateData.physical
                    ? stateData.physical
                    : null
                  : null
              }
              onChange={(e, obj) => onChange(e, obj)}
              handleChange={(newValue, actionMeta, obj) =>
                handleChange(newValue, actionMeta, obj)
              }
              countries={countries}
            />
            <Contact
              stateData={
                stateData
                  ? stateData.supplierData
                    ? stateData.supplierData
                    : null
                  : null
              }
              onChange={(e) => onChange(e, "supplier")}
            />
            <Purchases />
            <Returns />
            <Costings />
          </div>
        </div>
      </div>
    </>
  );
}
