import api from "@api/api";
import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getRolls = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api
      .get("/roles")
      .then((res) => {
        let data = res.data;
        if (data.code === 200) {
          let rolesList = [];
          _.forEach(data.data, (role) => {
            rolesList.push({
              label: role.roleName,
              value: role.id,
              id: role.id,
            });
          });
          dispatch({ type: settings.ROLES, payload: rolesList });
          resolve(data.data);
        } else {
          swal("", data.message, "error");
          reject(data);
        }
        dispatch(loaderState(false));
      })
      .catch((err) => {
        swal("", err.message, "error");
        dispatch(loaderState(false));
        reject(err);
      });
  });
};

export const addRole = ({ roleName, createdBy }) => (dispatch, getState) => {
  var roles = getState().system.roles.allRoles;
  const { organizationInfo } = getState().auth;
  let newRole = {
    roleName,
    createdBy,
    updatedBy: createdBy,
    // organizationId: organizationInfo.id,
  };
  api
    .post("/roles", newRole)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch(getRolls());
        // dispatch({ type: settings.ROLES, payload: [...roles, newRole] });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteRole = (id) => (dispatch, getState) => {
  const { allRoles } = getState().system.roles;
  api
    .delete(`/roles/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allRoles, (role) => {
          return Number(id) === Number(role.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateRole = ({ roleName, id, updatedBy }) => (
  dispatch,
  getState
) => {
  var roles = getState().system.roles.allRoles;
  api
    .post(`/roles/update/${id}`, { roleName, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedRole = _.findLast(roles, (role) => {
          return Number(role.id) === Number(id);
        });
        updatedRole.roleName = roleName;
        let updatedRoles = _.map(roles, (role) => {
          return role.id === id ? updatedRole : role;
        });

        dispatch({ type: settings.ROLES, payload: updatedRoles });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onUpdateOrDeleteRole = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_ROLE, payload: data });
};
