import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import ProductEnquiryTable from "./productEnquiryTable";
import RunReport from "@components/common/runReport";

export default function ProductionEnquiry({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Production Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={warehouses}
              />
              <InlineInputForm id="assembly" value="" name="Assembly" />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
              <InlineDropdown
                searchAble={true}
                id="product-group"
                name="Product Group"
                values={[
                  { label: "product 1", value: "product 1" },
                  { label: "product 2", value: "product 2" },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="status"
                name="Status"
                values={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                ]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="assembly-type"
                name="Assembly Type"
                values={[
                  { label: "All", value: "All" },
                  { label: "Assembly", value: "Assembly" },
                  { label: "Disassembly", value: "Disassembly" },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="status"
                name="Status"
                values={[
                  { label: "All", value: "All" },
                  { label: "Manual", value: "Manual" },
                  { label: "Auto", value: "Auto" },
                ]}
              />
              <InlineInputForm
                id="assembly-by-from"
                type="date"
                value=""
                name="Assembly By From"
              />
              <InlineInputForm
                id="assembly-by-to"
                type="date"
                value=""
                name="Assembly By To"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <ProductEnquiryTable />
          <br />
          <RunReport />
        </div>
      </div>
    </>
  );
}
