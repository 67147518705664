import React, { Component } from "react";
import PackingSlipsComp from "@components/setting/docDesigner/packingSlips/index";

export default class PackingSlips extends Component {
  state = {
    templates: [
      { label: "Formal Packing Slip", value: "Formal Packing Slip" },
      { label: "Classic Packing Slip", value: "Classic Packing Slip" },
    ],
  };
  render() {
    const { templates } = this.state;
    return <PackingSlipsComp templates={templates} name="Packing Slip" />;
  }
}
