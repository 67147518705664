import api from "@api/api";
import { users } from "@constants/actionTypes";
import { loaderState, selectedNav } from "@actions";
import _ from "lodash";
import history from "../../history";
import swal from "sweetalert";
import { prepareUserData } from "@helpers";
import { GetRolePermissions } from "@services";

export const userSignIn = ({ email, password }) => (dispatch) => {
  api
    .post("/users/login", { email, password })
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;
        await dispatch({ type: users.LOGGED_IN_USER_DATA, payload: response });

        setTimeout(() => {
          dispatch(selectedNav("/dashboard"));
          history.push("/dashboard");
          history.go("/dashboard");
        }, 1000);
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      console.log(err);
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getUserPermissions = () => (dispatch, getState) => {
  const { loggedInUser } = getState().auth;
  GetRolePermissions(loggedInUser.roleId)
    .then((res) => {
      if (res.code === 200) {
        dispatch({
          type: users.PERMISSIONS,
          payload: res.data,
        });
      } else {
        swal("", res.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getUsers = () => (dispatch) => {
  api
    .get("/users")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: users.USERS,
          payload: res.data.data,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const setSuperUserInfo = (data) => {
  return {
    type: users.USER_INFO,
    payload: data,
  };
};

export const superUserSignUp = (data) => (dispatch, getState) => {
  // const { userInfo } = getState().auth;
  // let credentials = { email: userInfo.email, password: userInfo.password };
  // let userInformation = {
  //   first_name: userInfo.first_name,
  //   last_name: userInfo.last_name,
  //   phone_number: userInfo.phone_number,
  // };
  // let organizationData = { ...data };
  // let organizatoionPostalAddress = {
  //   addressName: "",
  //   type: "Postal",
  // };
  // let organizatoionPhysicalAddress = {
  //   addressName: "",
  //   type: "Physical",
  // };
  api
    .post("/users/registerUser", {
      data,
    })
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;
        let userData = prepareUserData(res.data);
        // let userInfo = userData.user;
        // let orgData = userData.organization;
        // let organizatoionPostalAddress = userData.postal;
        // let organizatoionPhysicalAddress = userData.physical;

        await dispatch({ type: users.LOGGED_IN_USER_DATA, payload: res.data });
        // await dispatch({ type: users.ORGANIZATION_INFO, payload: orgData });
        // dispatch({ type: users.PERMISSIONS, payload: permissions });
        // await dispatch({
        //   type: users.ORGANIZATION_POSTAL_ADDRESSES,
        //   payload: organizatoionPostalAddress,
        // });
        // await dispatch({
        //   type: users.ORGANIZATION_PHYSICAL_ADDRESSES,
        //   payload: organizatoionPhysicalAddress,
        // });
        // await dispatch(setSuperUserInfo(null));
        dispatch(selectedNav("/dashboard"));
        history.push("/dashboard");
        history.go("/dashboard");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateOrganization = (data) => (dispatch, getState) => {
  const { organizationInfo, loggedInUser } = getState().auth;
  delete data.id;
  data.updatedBy = loggedInUser.id;
  api
    .post(`/organizations/update/${organizationInfo.id}`, { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: users.ORGANIZATION_INFO, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteUser = (id) => (dispatch, getState) => {
  const { allUsers } = getState().auth;
  api
    .post(`/users/update/${id}`, { isActive: -1 })
    .then((res) => {
      _.remove(allUsers, (user) => {
        return user.id === id;
      });
      dispatch({ type: users.USERS, payload: allUsers });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onDeleteUser = (user) => (dispatch) => {
  dispatch({ type: users.DELETE_USER, payload: user });
};
