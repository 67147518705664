import React, { Component } from "react";
import StockRevaluationComp from "@components/inventory/importStockRevaluation";
class StockRevaluation extends Component {
  componentDidMount() {
    document.title = "Import Stock Revaluation";
  }
  render() {
    return <StockRevaluationComp />;
  }
}

export default StockRevaluation;
