import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import ProductAllocationComp from "@components/reports/inventory/productAllocation";

export default class ProductAllocation extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <ProductAllocationComp />;
  }
}
