import React from "react";
import QuickStockCountTable from "./quickStockCountTable";
import Select from "react-select";

export default function QuickStockCount({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Quick Stock Count</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <button className="btn btn-success btn-sm">Print</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="warehouse">
                Warehouse
              </label>
              <Select
                isClearable
                id="warehouse"
                name="Warehouse"
                options={warehouses}
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="product-group">
                Product Group
              </label>
              <Select
                isClearable
                id="product-group"
                name="Product Group"
                options={[{ label: "Group 1", value: "Group 1" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <QuickStockCountTable />
        </div>
      </div>
    </>
  );
}
