import React, { Component } from "react";
import { isEmptyOrNull } from "@helpers";
import { connect } from "react-redux";
import _ from "lodash";
import swal from "sweetalert";
import { loaderState, addWarehouse, getUsers } from "@actions";
import AddWarehouseComp from "@components/setting/system/warehouses/addOrEditWarehouse";

class Warehouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      warehouseName: null,
      contactName: null,
      email: null,
      phoneNumber: null,
      mobileNumber: null,
      DDInumber: null,
      suburb: null,
      addressName: null,
      defaultWarehouse: -1,
      obsolete: -1,
      addressLine1: null,
      addressLine2: null,
      town: null,
      state: null,
      postalCode: null,
      country: null,
      userCheck: false,
      userAccess: null,
      userAccesses: [],
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getUsers();
  }

  onChange = ({ target }) => {
    const { id, value, type } = target;
    if (type === "checkbox") {
      this.setState({ [id]: target.checked ? 1 : -1 });
    } else {
      this.setState({ [id]: value });
    }
  };

  onAdd = () => {
    const { user } = this.props;
    const { code, warehouseName } = this.state;
    if (!isEmptyOrNull(code) || !isEmptyOrNull(warehouseName)) {
      this.props.loaderState(true);
      let data = { ...this.state, createdBy: user.id, updatedBy: user.id };
      delete data.userCheck;
      delete data.userAccesses;
      this.props.addWarehouse(data);
    } else {
      swal("", "Warehoues code and Warehouse name should not be empty", "info");
    }
  };

  onUserSelect = (id) => {
    const { userCheck, userAccesses } = this.state;

    let userAccessess = userAccesses ? userAccesses : [];
    if (userCheck === true || userCheck === 1) {
      if (userAccessess.indexOf(id) === -1) {
        userAccessess.push(id);
      }
    } else {
      _.remove(userAccessess, (access) => {
        return access === id;
      });
    }
    this.setState({ userAccess: userAccessess.toString(), userAccesses });
  };

  render() {
    const { users } = this.props;

    return (
      <AddWarehouseComp
        onUserSelect={(id) => this.onUserSelect(id)}
        users={users}
        onAdd={() => this.onAdd()}
        data={this.state}
        onChange={(e) => this.onChange(e)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.auth.allUsers,
    user: state.auth.loggedInUser,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  addWarehouse,
  getUsers,
})(Warehouses);
