import React from "react";
import Info from "@components/common/info";
import StepsInfo from "@components/common/stepsInfo";

export default function ImportQuotes() {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Import Quotes</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading=" Importing Sales Quotes"
            message="We recommend that you only import the data that you need to in batches, i.e. no more than 1000 rows at one time."
            link="Click for more information."
          />
          <h2 className="stock-revaluation-steps">
            Import your Sales Quotes into Globuss in three easy steps
          </h2>
          <StepsInfo
            heading="Download the Sales Quotes template file"
            step="1"
            link="Download Sales Quotes template"
            message="Start by downloading our Sales Quote Excel template file. This file has the correct column headings Unleashed needs to import your sales data."
          />
          <StepsInfo
            heading="Add your Sales Quotes to the template file"
            step="2"
            link="Click for Sales template (Field Definitions)"
            message="Using Excel or another spreadsheet editor, enter your sales data into the Unleashed template. Make sure the data you enter matches the column headings in the template. See the field definitions links below for details on what to enter in each column."
          />
          <StepsInfo
            heading="Upload the updated template file"
            step="3"
            message="The file you import must be a valid Excel file in .CSV format."
          />
          <button style={{ marginLeft: "5px" }} className="btn btn-success">
            Upload Sales Quotes
          </button>
        </div>
      </div>
    </>
  );
}
