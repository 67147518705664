import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import RunReport from "@components/common/runReport";
import TransactionTable from "./transactionTable";

export default function TransactionEnquiry({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Transaction Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineDropdown
                searchAble={true}
                id="transaction-type"
                name="Transaction Type"
                values={[
                  { label: "Type 1", value: "Type 1" },
                  { label: "Type 2", value: "Type 2" },
                ]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={[
                  { label: "Select All", value: "Select All" },
                  { ...warehouses },
                ]}
              />
              <InlineInputForm id="reference" value="" name="Reference" />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <TransactionTable />
          <br />
          <RunReport />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <div className="panel panel-default" id="panel-margin-right">
            <div className="panel-body">
              <div className="grid-container">
                <div className="grid-item">
                  <div className="border-bottom">
                    <span className="receipt-stat-label">Total Count</span>
                    <span className="pull-right receipt-stat-value"></span>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="border-bottom">
                    <span className="receipt-stat-label">Total Value</span>
                    <span className="pull-right receipt-stat-value"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
