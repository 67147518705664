import React from "react";
import Pricing from "./pricing";
import PlansDescTable from "./plansDescTable";

export default function Billing({ onChangePlan, selectedPlan }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Subscribe to Globuss Now</label>
          </div>
        </div>
      </div>

      <Pricing
        selectedPlan={selectedPlan}
        onChangePlan={(plan) => onChangePlan(plan)}
      />
      <br />
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="billing-desc">
            <label>
              Based on your location, all prices are in USD and exclude
              applicable taxes.
            </label>
            <div className="heading">What's included in each plan</div>
          </div>
          <PlansDescTable />
        </div>
      </div>
    </>
  );
}
