import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState, getSuppliers } from "@actions";
import {
  initilizeDataTable,
  destroyDataTable,
  warehousesData,
  goto,
  suppliersNameData,
  getSupplierByName,
  supplierCodeData,
  getSupplierByCode,
} from "@helpers";
import AddPurchaseComp from "@components/purchases/addPurchase";
import { suppliers } from "@constants/actionTypes";

class AddPurchase extends Component {
  componentDidMount() {
    document.title = "Add Purchase";
    const { warehouses, suppliers, selected_supplier } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }

    if (suppliers && suppliers.length === 0) {
      this.props.loaderState(true);
      this.props.getSuppliers();
    }
    initilizeDataTable("add-purchases-table");
    initilizeDataTable("purchases-cost-lines-table");
  }
  componentDidUpdate() {
    initilizeDataTable("add-purchases-table");
    initilizeDataTable("purchases-cost-lines-table");
  }

  onSupplierChange = (v) => {
    const { suppliers } = this.props;
    if (v !== null) {
      this.setState(getSupplierByCode(suppliers, v.value));
    }

    //console.log(getSupplierByCode(suppliers, v.value));
    //console.log(this.state);
  };

  onWarehouseChange = (v) => {
    if (v !== null) {
      console.log(v);
      this.setState({
        warehouseId: v.value,
      });
    }

    //console.log(getSupplierByCode(suppliers, v.value));
    //console.log(this.state);
  };

  onChange = ({ target }) => {
    const { value, id } = target;

    this.setState({
      [id]: value,
    });
  };
  onSupplierNameChange = (v) => {
    console.log("name change");
    const { suppliers } = this.props;
    if (v !== null) {
      this.setState(getSupplierByName(suppliers, v.value));
    }
  };

  onClickSave = () => {
    console.log("onclicksve");

    const { supplierData, warehouseId } = this.state;
    console.log(supplierData);
  };

  onClickPlace = () => {
    console.log("onclicksve");
    const { userInfo } = this.props;

    const {
      supplierData,
      warehouseId,
      po_date,
      delivery_date,
      delivery_name,
      po_discount,
    } = this.state;
    console.log(supplierData);
    const data = {
      supplierId_FK: supplierData.id,
      warehouseId_FK: warehouseId,
      userId_FK: userInfo.id,
      po_date: po_date,
      delivery_date: delivery_date,
      delivery_name: delivery_name,
      po_discount: po_discount,
    };
  };

  render() {
    destroyDataTable("purchases-cost-lines-table");
    destroyDataTable("add-purchases-table");

    const { warehouses, suppliers } = this.props;
    //console.log(this.state);
    //console.log(suppliers[0].supplierData.supplierName);
    return (
      <AddPurchaseComp
        goto={(path) => goto(path)}
        warehouses={warehousesData(warehouses)}
        suppliersName={suppliersNameData(suppliers)}
        onSupplierChange={(v) => this.onSupplierChange(v)}
        onSupplierNameChange={(v) => this.onSupplierNameChange(v)}
        stateData={this.state}
        suppliersCode={supplierCodeData(suppliers)}
        onClickSave={() => this.onClickSave()}
        onClickPlace={() => this.onClickPlace()}
        onWarehouseChange={(v) => this.onWarehouseChange(v)}
        onChange={(v) => this.onChange(v)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
    suppliers: state.suppliers.allSuppliers,
    selected_supplier: state.suppliers.selectedSupplier,
    userInfo: state.auth.loggedInUser,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getWarehouses,
  getSuppliers,
  getSupplierByCode,
})(AddPurchase);
