import { settings } from "@constants/actionTypes";
const initialState = {
  allUnits: null,
  selectedUnit: null,
};

function adjustmentsReducer(state = initialState, action) {
  switch (action.type) {
    case settings.ALL_UNITS:
      return { ...state, allUnits: action.payload };
    case settings.SELECTED_UNIT:
      return { ...state, selectedUnit: action.payload };
    default:
      return state;
  }
}
export default adjustmentsReducer;
