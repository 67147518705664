import React from "react";
import { goto } from "@helpers";
import Select from "react-select";

export default function InlineDropDown({
  id,
  name,
  values,
  value,
  onChange,
  disable,
  link,
  path,
  noEmpty,
  searchAble,
  placeholder,
}) {
  return (
    <div className="inline-form" id="form-inline">
      <div className="field">
        {link ? (
          <a onClick={() => goto(path)}>{name}</a>
        ) : (
          <label htmlFor={id}>{name}</label>
        )}
        {!searchAble ? (
          <select
            disabled={disable}
            onChange={(e) => onChange(e)}
            value={value}
            className="form-control"
            id={id}
          >
            {!noEmpty ? <option></option> : null}
            {values
              ? values.map((value) => <option key={value}>{value}</option>)
              : null}
          </select>
        ) : (
          <Select
            isDisabled={disable ? true : false}
            placeholder={placeholder}
            isClearable
            options={values}
            name={id}
            value={value}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
}
