import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getAttributeSets = () => (dispatch) => {
  api
    .get("/attributeSet")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.ATTRIBUTE_SETS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addAttributeSet = ({ attributeSetName, type, createdBy }) => (
  dispatch,
  getState
) => {
  var attributeSets = getState().system.attributeSets.allAttributeSets;
  const { organizationInfo } = getState().auth;
  api
    .post("/attributeSet", {
      attributeSetName,
      type,
      createdBy,
      updatedBy: createdBy,
      // organizationId: organizationInfo.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.ATTRIBUTE_SETS,
          payload: [...attributeSets, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteAttribute = (id) => (dispatch, getState) => {
  const { allAttributeSets } = getState().system.attributeSets;
  api
    .delete(`/attributeSet/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allAttributeSets, (attribute) => {
          return Number(id) === Number(attribute.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateAttributeSet = ({
  attributeSetName,
  type,
  id,
  updatedBy,
}) => (dispatch, getState) => {
  const {
    allAttributeSets,
    selectedAttribute,
  } = getState().system.attributeSets;
  api
    .post(`/attributeSet/update/${id}`, { attributeSetName, type, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedAttribute = _.findLast(allAttributeSets, (attribute) => {
          return Number(attribute.id) === Number(id);
        });
        updatedAttribute.attributeSetName = attributeSetName
          ? attributeSetName
          : selectedAttribute.attributeSetName;
        updatedAttribute.type = type ? type : selectedAttribute.type;
        let updatedAttributes = _.map(allAttributeSets, (attribute) => {
          return attribute.id === id ? updatedAttribute : attribute;
        });

        dispatch({ type: settings.ATTRIBUTE_SETS, payload: updatedAttributes });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectedAttribute = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_ATTRIBUTE, payload: data });
};
