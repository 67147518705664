import React from "react";
import Tabs from "@components/common/tabs";
import Document from "./document";
import Preview from "./preview";
import Template from "./template";
import Toolbox from "./toobox";

export default function DocTabHolder({
  nav,
  onClickNewOrDuplicate,
  templates,
  newOrDuplicate,
  toolBoxData,
}) {
  return (
    <div className="doc-designer">
      <Tabs nav={nav} />
      <div className="tab-content">
        <Document
          templates={templates}
          onClickNewOrDuplicate={(d) => onClickNewOrDuplicate(d)}
          newOrDuplicate={newOrDuplicate}
        />
        <Template />
        <Toolbox toolBoxData={toolBoxData} />
        <Preview />
      </div>
    </div>
  );
}
