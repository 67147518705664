export const EMAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const dev = {
	API_URL: "https://api.priyo.co.uk/api/v1",
};

const production = {
	API_URL: "https://api.priyo.co.uk/api/v1",
};

const config = process.env.REACT_APP_ENV === "dev" ? dev : dev;

export const API_URL = config.API_URL;

export const SALES_API_URL = "http://84.212.92.227:9090";

export const VERSION = "V-2";
