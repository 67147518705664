import React from "react";
import AssembliesTable from "./assembliesTable";
import ExportButton from "@components/common/exportButton";
import Select from "react-select";

export default function ViewAssemblies({ goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Assemblies</label>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-5" style={{ textAlign: "right" }}>
            <ExportButton />
            <button
              onClick={() => goto("/productions/assemblies/add disassembliy")}
              className="btn btn-success btn-sm"
            >
              Add DisAssembly
            </button>
            <button
              onClick={() => goto("/productions/assemblies/add assembly")}
              className="btn btn-success btn-sm"
            >
              Add Assembly
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                ]}
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="assembly_number">
                Assembly Number
              </label>
              <input
                type="text"
                className="form-control"
                id="assembly_number"
                name="assembly_number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="product">
                Product
              </label>
              <input
                type="text"
                className="form-control"
                id="product"
                name="product"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="assemble_by_from">
                Assemble By From
              </label>
              <input
                type="date"
                className="form-control"
                id="assemble_by_from"
                name="assemble_by_from"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="assembly_type">
                Assembly Type
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Assembly Type"
                id="assembly_type"
                options={[
                  { label: "All", value: "All" },
                  { label: "Assembly", value: "Assembly" },
                  { label: "DisAssembly", value: "DisAssembly" },
                ]}
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="auto_assembly">
                Auto Assembly
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Assembly Type"
                id="auto_assembly"
                options={[
                  { label: "All", value: "All" },
                  { label: "Manual", value: "Manual" },
                  { label: "Auto", value: "Auto" },
                ]}
              />
            </div>
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="printed">
                Printed
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Printed"
                id="printed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <AssembliesTable />
        </div>
      </div>
    </>
  );
}
