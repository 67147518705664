import React from "react";
import CostingsTable from "./costingsTable";
import Select from "react-select";

export default function Constings() {
  return (
    <div id="Costings" className="tab-pane fade">
      <div className="row" id="settings-field">
        <div className="col-md-2">
          <label className="input-label" htmlFor="order-status">
            Order Status
          </label>
          <Select
            isClearable
            name="Order Status"
            id="order_status"
            options={[
              { label: "All", value: "All" },
              { label: "Open", value: "Open" },
              { label: "Parked", value: "Parked" },
              { label: "Placed", value: "Placed" },
              { label: "Costed", value: "Costed" },
              { label: "Completed", value: "Completed" },
            ]}
          />
        </div>
      </div>
      <br />
      {/* <div className="panel panel-default">
				<div className="panel-body"> */}
      <CostingsTable />
      {/* </div>
			</div> */}
    </div>
  );
}
