import React, { Component } from "react";
import { goto, initilizeDataTable, destroyDataTable } from "@helpers";
import StockCountComp from "@components/inventory/stockCount";

class StockCount extends Component {
  componentDidMount() {
    document.title = "View Stock Count";
    initilizeDataTable("stock-count-table");
  }

  componentDidUpdate() {
    initilizeDataTable("stock-count-table");
  }
  render() {
    destroyDataTable("stock-count-table");

    return <StockCountComp goto={(path) => goto(path)} />;
  }
}

export default StockCount;
