import React from "react";
import TableActions from "@components/common/tableActions";

export default function SupplierReturnsTable() {
  return (
    <div className="table-view table-responsive">
      <table id="view-supplier-retutn-table" className="table">
        <thead>
          <tr>
            <th>Supplier Return No.</th>
            <th>Return Date</th>
            <th>Supplier</th>
            <th>Order Number</th>
            <th>Warehouse</th>
            <th>Status</th>
            <th>Currency</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>
              <TableActions
                icon="fa fa-cog icon"
                data={["View", "Print", "Email"]}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
