import React, { Component } from "react";
import ExportPurchasesComp from "@components/purchases/exportPurchases";

class ExportPurchases extends Component {
  render() {
    return <ExportPurchasesComp />;
  }
}

export default ExportPurchases;
