import { permissions } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";
import {
  GetAppFunctionalities,
  GetRolePermissions,
  SaveRolePermissions,
} from "@services";

export const getAppFunctionalities = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    GetAppFunctionalities()
      .then((res) => {
        if (res.code === 200) {
          dispatch({
            type: permissions.APP_FUNCTIONALITIES,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          swal("", res.message, "error");
          reject(res.data);
        }
        dispatch(loaderState(false));
      })
      .catch((err) => {
        swal("", err.message, "error");
        dispatch(loaderState(false));
        reject(err);
      });
  });
};

export const getRolePermissions = (filter) => (dispatch) => {
  return new Promise((resolve, reject) => {
    GetRolePermissions(filter.role_id)
      .then((res) => {
        if (res.code === 200) {
          dispatch({
            type: permissions.ROLE_PERMISSIONS,
            payload: res.data,
          });
        } else {
          swal("", res.message, "error");
        }
        dispatch(loaderState(false));
        resolve(res.data);
      })
      .catch((err) => {
        swal("", err.message, "error");
        dispatch(loaderState(false));
        reject(err);
      });
  });
};

export const saveRolePermissions = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    SaveRolePermissions(data)
      .then((res) => {
        if (res.code === 200) {
          dispatch({
            type: permissions.ROLE_PERMISSIONS,
            payload: res.data,
          });
        } else {
          swal("", res.message, "error");
        }
        dispatch(loaderState(false));
        resolve(res.data);
      })
      .catch((err) => {
        swal("", err.message, "error");
        dispatch(loaderState(false));
        reject(err);
      });
  });
};
