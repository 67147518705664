import React from "react";
import Select from "react-select";

export default function Purchase({
  edit,
  suppliers,
  handleChange,
  onChange,
  stateData,
  onSaveProductSupplier,
  purchaseTaxes,
  productSuppliers,
  onDeleteProductSupplier,
}) {
  return (
    <div id="Purchase" className="tab-pane fade">
      <form>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="">
              <div className="row" id="settings-field">
                <div className="col-md-2 padding-1-right">
                  <label className="input-label" htmlFor="defaultPurchasePrice">
                    Default Purchase Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="defaultPurchasePrice"
                    name="defaultPurchasePrice"
                    onChange={(e) => onChange(e)}
                    value={
                      stateData
                        ? stateData.defaultPurchasePrice
                          ? stateData.defaultPurchasePrice
                          : ""
                        : ""
                    }
                  />
                </div>
                <div className="col-md-2 padding-1">
                  <label className="input-label" htmlFor="purchaseTaxRate">
                    <a href="#" target="_blank">
                      Purchase Tax Rate
                    </a>
                  </label>
                  <Select
                    searchAble={true}
                    isClearable
                    name="purchaseTaxRate"
                    id="purchaseTaxRate"
                    options={purchaseTaxes}
                    onChange={handleChange}
                    value={
                      stateData
                        ? stateData.purchaseTaxRate
                          ? {
                              label: stateData.purchaseTaxRate,
                              value: stateData.purchaseTaxRate,
                            }
                          : ""
                        : ""
                    }
                  />
                </div>
                <div className="col-md-2 padding-1">
                  <label className="input-label" htmlFor="minimumOrderQuantity">
                    Minimum Order Quantity
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="minimumOrderQuantity"
                    name="minimumOrderQuantity"
                    value={
                      stateData
                        ? stateData.minimumOrderQuantity
                          ? stateData.minimumOrderQuantity
                          : ""
                        : ""
                    }
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            <h6>Supplier</h6>
            <hr className="purchase-hr" />
            <div className="row" id="settings-field">
              <div className="col-md-2 padding-1-right">
                <label className="input-label" htmlFor="supplierid">
                  <a href="#" target="_blank">
                    Supplier Code
                  </a>
                </label>
                <Select
                  isDisabled={edit ? false : true}
                  searchAble={true}
                  isClearable
                  name="supplierid"
                  id="supplierid"
                  options={suppliers}
                  onChange={handleChange}
                  value={
                    stateData
                      ? stateData.supplierCode
                        ? {
                            label: stateData.supplierCode,
                            value: `${stateData.supplierName}(${stateData.supplierid})`,
                          }
                        : ""
                      : ""
                  }
                />
                {/* <input
                  disabled={edit ? false : true}
                  type="text"
                  className="form-control"
                  id="supplier-code"
                  name="supplier-code"
                /> */}
              </div>
              <div className="col-md-2 padding-1">
                <label className="input-label" htmlFor="supplierName">
                  <a href="#" target="_blank">
                    Supplier Name
                  </a>
                </label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="supplierName"
                  name="supplierName"
                  onChange={(e) => onChange(e)}
                  value={
                    stateData
                      ? stateData.supplierName
                        ? stateData.supplierName
                        : ""
                      : ""
                  }
                />
              </div>
              <div className="col-md-2 padding-1">
                <label className="input-label" htmlFor="supplierproductcode">
                  Supplier Product Code
                </label>
                <input
                  disabled={edit ? false : true}
                  type="text"
                  className="form-control"
                  id="supplierproductcode"
                  name="supplierproductcode"
                  onChange={(e) => onChange(e)}
                  value={
                    stateData
                      ? stateData.supplierproductcode
                        ? stateData.supplierproductcode
                        : ""
                      : ""
                  }
                />
              </div>
              <div className="col-md-2 padding-1">
                <label
                  className="input-label"
                  htmlFor="supplierproductdescription"
                >
                  Supplier Product Description
                </label>
                <input
                  disabled={edit ? false : true}
                  type="text"
                  className="form-control"
                  id="supplierproductdescription"
                  name="supplierproductdescription"
                  onChange={(e) => onChange(e)}
                  value={
                    stateData
                      ? stateData.supplierproductdescription
                        ? stateData.supplierproductdescription
                        : ""
                      : ""
                  }
                />
              </div>
              <div className="col-md-2 padding-1">
                <label className="input-label" htmlFor="purchaseprice">
                  Purchase Price
                </label>
                <input
                  disabled={edit ? false : true}
                  type="number"
                  className="form-control"
                  id="purchaseprice"
                  name="purchaseprice"
                  onChange={(e) => onChange(e)}
                  value={
                    stateData
                      ? stateData.purchaseprice
                        ? stateData.purchaseprice
                        : ""
                      : ""
                  }
                />
              </div>
              <div className="col-md-1 padding-1">
                <label className="input-label" htmlFor="minqty">
                  Min. Order Qty
                </label>
                <input
                  disabled={edit ? false : true}
                  type="number"
                  className="form-control"
                  id="minqty"
                  name="minqty"
                  onChange={(e) => onChange(e)}
                  value={
                    stateData ? (stateData.minqty ? stateData.minqty : "") : ""
                  }
                />
              </div>
              <div className="col-md-1 padding-1" id="add-btn">
                <label className="input-label">Add button</label>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={() => onSaveProductSupplier()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="table-view table-responsive">
            <table id="purchases-table" className="table">
              <thead>
                <tr>
                  {/* <th>Default</th> */}
                  <th>Supplier Code</th>
                  <th>Supplier Name</th>
                  <th>Supplier Product Code</th>
                  <th>Supplier Product Description</th>
                  <th>Purchase Price</th>
                  <th>Min. Order Qty</th>
                  <th>Supplier Currency</th>
                  <th>Lead Time (days)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {productSuppliers
                  ? productSuppliers.map((supplier) => (
                      <tr key={supplier.id}>
                        <td>{supplier.supplierCode}</td>
                        <td>{supplier.supplierName}</td>
                        <td>{supplier.supplierproductcode}</td>
                        <td>{supplier.supplierproductdescription}</td>
                        <td>{supplier.purchaseprice}</td>
                        <td>{supplier.minqty}</td>
                        <td>{supplier.supplierCurrency}</td>
                        <td>{supplier.deliveryLeadTime}</td>
                        <td>
                          <i
                            onClick={() => onDeleteProductSupplier(supplier.id)}
                            className="fa fa-trash-o icon"
                          />
                          <i
                            // data-toggle="modal"
                            // data-target="#twoInputEditModal"
                            className="fa fa-pencil-square-o icon"
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <small>Please Save your Product before adding any Suppliers</small>
          </div>
        </div>
      </div>
    </div>
  );
}
