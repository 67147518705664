import React from "react";
import ExportBtn from "@components/common/exportButton";
import CreditsTable from "./creditsTable";
import Select from "react-select";

export default function ViewCredits({ warehouses, goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Credits</label>
          </div>
          <div className="col-lg-5 col-md-2 col-1"></div>
          <div
            className="col-lg-3 col-md-3 col-3"
            style={{ textAlign: "right" }}
          >
            <ExportBtn />
            <button
              onClick={() => goto("/sales/credits/add credit")}
              className="btn btn-success btn-sm"
            >
              Add Credit
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="credit-status">
                Credit Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                ]}
              />
              {/* <select className="form-control" id="credit-status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="credit-note_number">
                Credit Note Number
              </label>
              <input
                type="text"
                className="form-control"
                id="credit-note_number"
                name="credit-note_number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="customer">
                Customer
              </label>
              <input
                type="text"
                className="form-control"
                id="customer"
                name="customer"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="sales-invoice-number">
                Sales Invoice Number
              </label>
              <input
                type="text"
                className="form-control"
                id="sales-invoice-number"
                name="sales-invoice-number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="warehouse">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="printed">
                Printed
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Printed"
                id="printed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {/* <select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CreditsTable />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-3 col-xs-6"></div>
        <div className="col-md-3 col-xs-6">
          <div className="panel panel-default" id="panel-margin-right">
            <div className="panel-body">
              <div className="border-bottom">
                <span className="receipt-stat-label">Credit Note Count</span>
                <span className="pull-right receipt-stat-value">6</span>
              </div>
              <div className="border-bottom">
                <span className="receipt-stat-label">Total Credit Value</span>
                <span className="pull-right receipt-stat-value">56467</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
