import React from "react";

export default function TableActions({ data, onClick, icon }) {
  return (
    // <div className="my-dropdown">
    // 	<i style={{ fontSize: '1.2em' }} className={icon} />
    // 	<div className="my-dropdown-content">
    // 		{data
    // 			? data.map(d => (
    // 					<a key={d} onClick={() => onClick(d)}>
    // 						{d}
    // 					</a>
    // 			  ))
    // 			: null}
    // 	</div>
    // </div>
    <div className="dropdown" style={{ position: "absolute" }}>
      <i
        style={{ fontSize: "1.2em" }}
        className={icon + " dropdown-toggle"}
        data-toggle="dropdown"
      />
      <ul className="dropdown-menu pull-right">
        {data
          ? data.map((d) => (
              <li>
                <a key={d} onClick={() => onClick(d)}>
                  {d}
                </a>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}
