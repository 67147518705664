import React, { Component } from "react";
import {
  initilizeDataTable,
  destroyDataTable,
  warehousesData,
  goto,
} from "@helpers";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState, getStockAdjustments } from "@actions";
import StockAdjustmentsComp from "@components/inventory/stockAdjustments";

class StockAdjustments extends Component {
  componentDidMount() {
    document.title = "Stock Adjustments";
    const { warehouses } = this.props;
    this.props.loaderState(true);
    this.props.getStockAdjustments();
    if (warehouses) {
      this.props.getWarehouses();
    }
    initilizeDataTable("stock-adjustments-table");
  }

  componentDidUpdate() {
    initilizeDataTable("stock-adjustments-table");
  }

  render() {
    destroyDataTable("stock-adjustments-table");

    const { warehouses } = this.props;
    return (
      <StockAdjustmentsComp
        warehouses={warehousesData(warehouses)}
        goto={(path) => goto(path)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getWarehouses,
  getStockAdjustments,
})(StockAdjustments);
