import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import RunReport from "@components/common/runReport";
import ShipmenytEnquiryTable from "./shipmenytEnquiryTable";

export default function ShipmentEnquiry({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Shipment Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="transaction-date"
                name="Transaction Date"
                values={[
                  { label: "Required Date", value: "Required Date" },
                  { label: "Dispatched date", value: "Dispatched date" },
                ]}
              />
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="sales-order-number"
                value=""
                name="Sales Order Number"
              />
              <InlineInputForm
                id="shipment-number"
                value=""
                name="Shipment Number"
              />
              <InlineDropdown
                searchAble={true}
                id="shipment-status"
                name="Shipment Status"
                values={[
                  { label: "Open", value: "Open" },
                  { label: "Parked", value: "Parked" },
                  { label: "Placed", value: "Placed" },
                  { label: "Picking", value: "Picking" },
                  { label: "Picked", value: "Picked" },
                  { label: "Packed", value: "Packed" },
                  { label: "Dispatched", value: "Dispatched" },
                  { label: "Deleted", value: "Deleted" },
                  { label: "All", value: "All" },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={[
                  { label: "Select All", value: "Select All" },
                  { ...warehouses },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="printed"
                name="Printed"
                values={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="shipping-company"
                name="Shipping Company"
                values={[]}
              />
              <InlineInputForm
                id="tracking-number"
                value=""
                name="Tracking Number"
              />
              <InlineInputForm
                id="customer-code"
                value=""
                name="Customer Code"
              />
              <InlineInputForm
                id="customer-name"
                value=""
                name="Customer Name"
              />
              <InlineInputForm
                id="customer-reference"
                value=""
                name="Customer Reference"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <ShipmenytEnquiryTable />
          <br />
          <RunReport />
        </div>
      </div>
    </>
  );
}
