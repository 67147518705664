import React from "react";
import TableActions from "@components/common/tableActions";

export default function CustomersTable({
  customers,
  onClickAction,
  gotoCustomer,
  onCustomerSelect,
}) {
  return (
    <div className="table-view table-responsive">
      <table id="view-customers-table" className="table">
        <thead>
          <tr>
            <th>Customer Code</th>
            <th>Customer Name</th>
            <th>Type</th>
            <th>Currency</th>
            <th>Website</th>
            <th>Phone</th>
            <th>Mobile</th>
            <th>Email Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers
            ? customers.map((customer) => (
                <tr key={customer.customerData.customerCode}>
                  <td>
                    <label className="input-label">
                      <a onClick={() => gotoCustomer(customer)}>
                        {customer.customerData.customerCode}
                      </a>
                    </label>
                  </td>
                  <td>
                    <label className="input-label">
                      <a onClick={() => gotoCustomer(customer)}>
                        {customer.customerData.customerName}
                      </a>
                    </label>
                  </td>
                  <td> {customer.customerData.customerTypeName}</td>
                  <td> {customer.customerData.currencyCode}</td>
                  <td> {customer.customerData.website}</td>
                  <td> {customer.customerData.phoneNumber}</td>
                  <td> {customer.customerData.mobileNumber}</td>
                  <td> {customer.customerData.email}</td>
                  <td onMouseEnter={() => onCustomerSelect(customer)}>
                    <TableActions
                      icon="fa fa-cog icon"
                      data={[
                        "Edit",
                        "Add Quote",
                        "Add Order",
                        "Add Credit",
                        "View Transaction",
                        "Obsolete",
                        "Delete",
                      ]}
                      onClick={(action) => onClickAction(action)}
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
