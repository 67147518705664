import React from 'react';

export default function Switch({ name, id, onChange, value, margin }) {
	return (
		<div className="inline-form" style={{ textAlign: margin ? 'center' : '' }}>
			<div className="field">
				<label htmlFor={id}>{name}</label>
				<span className="switch">
					<input type="checkbox" onChange={e => onChange(e)} checked={value} id={id} />
					<span className="slider round"></span>
				</span>
			</div>
		</div>
	);
}
