import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import SplitButton from "@components/common/splitButton";
import Product from "./product";
import CreateStockCountTable from "./createStockCountTable";

var currentDate = new Date();

export default function CreateStockCount({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-sm-6">
            <label>Create Stock Count</label>
          </div>
          <div className="col-sm-6" style={{ textAlign: "right" }}>
            <button className="btn btn-light btn-sm">Import</button>
            <SplitButton
              label="Export"
              type="light"
              data={["Export to PDF", "Export to XLS", "Export to CSV"]}
            />
            <button className="btn btn-light btn-sm">Complete</button>
            <button className="btn btn-success btn-sm">Save</button>
            <SplitButton
              label="Print"
              type="light"
              data={["Print Stock Count"]}
            />
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="stock-count-name"
                value=""
                disable="disabled"
                name="Stock Count Name"
              />
              <InlineInputForm
                id="create_stock_start_date"
                value={currentDate}
                disable="disabled"
                type="date"
                name="Start Date"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="warehouse"
                value=""
                disable="disabled"
                name="Warehouse"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Product />
          <br />
          <CreateStockCountTable />
          <br />
          <br />
          <div className="row">
            <div className="col-xs-12" style={{ textAlign: "right" }}>
              <SplitButton
                label="Print Variance"
                type="light"
                data={["Export to PDF", "Export to CSV"]}
              />
              <button className="btn btn-danger btn-sm">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
