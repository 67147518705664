import React from "react";
import EmailsTable from "./emailsTable";

export default function EmailLogs() {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Email Logs</label>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-3" style={{ textAlign: "right" }}>
            <button
              className="btn btn-light btn-sm"
              data-toggle="modal"
              data-target="#invite-user-modal"
            >
              Export
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <EmailsTable />
        </div>
      </div>
    </>
  );
}
