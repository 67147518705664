import React from "react";
import StockTable from "./stockTable";
import ExportButton from "@components/common/exportButton";
import Select from "react-select";

export default function StockAdjustments({ warehouses, goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-sm-6">
            <label>Stock Adjustments</label>
          </div>
          <div className="col-sm-6">
            <ExportButton />
            <button
              onClick={() => goto("/stock adjustment/add")}
              className="btn btn-success btn-sm"
            >
              Add Adjustments
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="stock-adjustments">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="adjustments-status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                  { label: "Deleted", value: "Deleted" },
                ]}
              />
              {/* <select className="form-control" id="adjustments-status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
								<option>Deleted</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="adjustments-reason">
                Reason
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Reason"
                id="reason"
                options={[
                  { label: "End Of Season", value: "End Of Season" },
                  { label: "Sample", value: "Sample" },
                  { label: "Stolen", value: "Stolen" },
                ]}
              />
              {/* <select className="form-control" id="adjustments-reason">
								<option></option>
								<option>End Of Season</option>
								<option>Sample</option>
								<option>Stolen</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="adjustments-warehouse">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="adjustments-warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <StockTable />
        </div>
      </div>
    </>
  );
}
