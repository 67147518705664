import React from "react";
import { Route, Switch } from "react-router-dom";

// Containers
import Dashboard from "@containers/dashboard";
import QuickLinks from "@containers/header/quickLinks";
// Purchases
import AddPurchase from "@containers/purchases/addPurchase";
import ExportPurchases from "@containers/purchases/exportPurchases";
import ImportPurchases from "@containers/purchases/importPurchases";
import ReceiptPurchase from "@containers/purchases/receiptPurchase";
import ViewPurchases from "@containers/purchases/viewPurchases";
import AddSupplierReturn from "@containers/purchases/addSupplierReturn";
import ViewSupplierReturns from "@containers/purchases/viewSupplierReturn";

// Customers
import AddCustomer from "@containers/customers/add";
import ChangeCodesCustomers from "@containers/customers/changeCodes";
import ImportExport from "@containers/customers/importExport";
import ViewCustomers from "@containers/customers/view";

//Inventory
import AddProduct from "@containers/inventory/addProduct";
import changeCodesProduct from "@containers/inventory/changeCode";
import ImportExportProduct from "@containers/inventory/importExport";
import ViewProduct from "@containers/inventory/viewProduct";
import MarkupPriceUpdate from "@containers/inventory/marketPriceUpdate";
import ImportStockRevaluation from "@containers/inventory/importStockRevaluation";
import StockAdjustments from "@containers/inventory/stockAdjustments";
import StockCount from "@containers/inventory/stockCount";
import WarehouseTransfers from "@containers/inventory/warehouseTransfers";
import AddAdjustment from "@containers/inventory/stockAdjustments/addAdjustment";
import AddWarehouseTransfer from "@containers/inventory/warehouseTransfers/addWarehouseTransfer";
import CreateStockCount from "@containers/inventory/stockCount/createStockCount";
import ImportStockCounts from "@containers/inventory/importStockCounts";

// Production
import AddAssembly from "@containers/production/addAssembly";
import AddBillOfMaterial from "@containers/production/addBillOfMaterial";
import AddDisAssembly from "@containers/production/addDisAssemblies";
import ExportBillOfMaterial from "@components/production/exportBillOfMaterial";
import ImportBillOfMaterial from "@components/production/importBillOfMaterial";
import ViewBillOfMaterial from "@containers/production/viewBillsOfMaterial";
import ViewAssemblies from "@containers/production/viewAssemblies";

// Sales
import AddCreditSales from "@containers/sales/credit/add";
import AddOrderSales from "@containers/sales/order/add";
import AddQuoteSales from "@containers/sales/quotes/add";
import BatchCompleteOrderSales from "@containers/sales/order/batchComplete";
import ExportCreditSales from "@containers/sales/credit/export";
import ExportOrderSales from "@containers/sales/order/export";
import ImportOrderSales from "@containers/sales/order/import";
import ImportQuotesSales from "@containers/sales/quotes/import";
import ViewCreditSales from "@containers/sales/credit/view";
import ViewOrderSales from "@containers/sales/order/view";
import ViewQuotesSales from "@containers/sales/quotes/view";
import ViewShipmentsOrderSales from "@containers/sales/order/viewShipments";

// Suppliers
import AddSuppier from "@containers/supplier/add";
import ViewSuppliers from "@containers/supplier/view";
import ChangeCodesSuppliers from "@containers/supplier/changeCodes";
import ImportSuppliers from "@containers/supplier/import";
import ExportSuppliers from "@containers/supplier/export";

// Reports
import BackOrderQEnquiry from "@containers/reports/sales/backOrder";
import BatchNumberEnquiry from "@containers/reports/inventory/batchNumber";
import CostingAnalysisReport from "@containers/reports/purchase/costingAnalysis";
import CreditEnquiry from "@containers/reports/sales/credit";
import InvoiceEnquiry from "@containers/reports/sales/invoice";
import MarginEnquiry from "@containers/reports/sales/margin";
import PriceListEnquiry from "@containers/reports/inventory/priceList";
import ProductionAllocations from "@containers/reports/inventory/productAllocation";
import ProductEnquiry from "@containers/reports/inventory/product";
import ProductionEnquiryReport from "@containers/reports/production/productionEnquiry";
import PurchaseEnquiryReport from "@containers/reports/purchase/purchaseEnquiry";
import QuickStockCount from "@containers/reports/inventory/quickStockCount";
import QuotesEnquiry from "@containers/reports/sales/quotes";
import ReOrderReport from "@containers/reports/purchase/reorder";
import SalesEnquiry from "@containers/reports/sales/sales";
import SerialNumberEnquiry from "@containers/reports/inventory/serialNumber";
import ShipmentEnquiry from "@containers/reports/sales/shipment";
import StockMovementEnquiry from "@containers/reports/inventory/stockMovement";
import StockOnHandEnquiry from "@containers/reports/inventory/stockOnHand";
import TransactionEnquiry from "@containers/reports/inventory/transaction";
import UnitSalesEnquiry from "@containers/reports/sales/unitSales";

// Settings
import Assemblies from "@containers/settings/docDesigner/assemblies";
import CreditNotes from "@containers/settings/docDesigner/creditNotes";
import Invoices from "@containers/settings/docDesigner/invoices";
import Labels from "@containers/settings/docDesigner/labels";
import PackingSlips from "@containers/settings/docDesigner/packingSlips";
import PickList from "@containers/settings/docDesigner/pickList";
import PurchaseOrders from "@containers/settings/docDesigner/purchaseOrders";
import SalesOrders from "@containers/settings/docDesigner/salesOrders";
import SalesQuotes from "@containers/settings/docDesigner/salesQuotes";
import ShipNotes from "@containers/settings/docDesigner/shipNotes";
import SupplierReturns from "@containers/settings/docDesigner/supplierReturns";
import WarehouseTransfersSettings from "@containers/settings/docDesigner/warehouseTransfers";
import Billing from "@containers/settings/organization/billing";
import Company from "@containers/settings/organization/company";
import EmailLogs from "@containers/settings/security/emailLogs";
import RolePermissions from "@containers/settings/security/rolePermissions";
import Roles from "@containers/settings/security/roles";
import Users from "@containers/settings/security/users";
import AdjustmentReasons from "@containers/settings/system/adjustmentReasons";
import AttributeSets from "@containers/settings/system/attributeSets";
import CreditReasons from "@containers/settings/system/creditReasons";
import CurrencyRates from "@containers/settings/system/currencyRates";
import CustomerTypes from "@containers/settings/system/customerTypes";
import DeliveryMethods from "@containers/settings/system/deliveryMethod";
import PaymentTerms from "@containers/settings/system/paymentTerms";
import Prefixes from "@containers/settings/system/prefixes";
import ProductGroups from "@containers/settings/system/productGroup";
import SalesGroups from "@containers/settings/system/salesGroups";
import SalesPerson from "@containers/settings/system/salesPerson";
import SellPriceTier from "@containers/settings/system/sellPriceTier";
import ShippingCompanies from "@containers/settings/system/shippingCompanies";
import SupplierReturnReason from "@containers/settings/system/supplierReturnReason";
import Taxes from "@containers/settings/system/taxes";
import UnitsOfMeasure from "@containers/settings/system/unitsOfMeasure";
import Warehouse from "@containers/settings/system/warehouses";
import AddWarehouse from "@containers/settings/system/warehouses/addWarehouse";
import EditWarehouse from "@containers/settings/system/warehouses/EditWarehouse";

const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={Home} /> */}
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/account/customize quick links" component={QuickLinks} />
      <Route
        path="/purchases/purchase orders/add purchase"
        component={AddPurchase}
      />
      <Route
        path="/purchases/purchase orders/export purchases"
        component={ExportPurchases}
      />
      <Route
        path="/purchases/purchase orders/import purchases"
        component={ImportPurchases}
      />
      <Route
        path="/purchases/purchase orders/receipt purchase"
        component={ReceiptPurchase}
      />
      <Route
        path="/purchases/purchase orders/view purchases"
        component={ViewPurchases}
      />
      <Route
        path="/purchases/supplier returns/add supplier return"
        component={AddSupplierReturn}
      />
      <Route
        path="/purchases/supplier returns/view supplier returns"
        component={ViewSupplierReturns}
      />

      <Route path="/customers/add customers" component={AddCustomer} />
      <Route path="/customers/update/:id" component={AddCustomer} />
      <Route path="/customers/view customers" component={ViewCustomers} />
      <Route path="/customers/importexport" component={ImportExport} />
      <Route path="/customers/change codes" component={ChangeCodesCustomers} />

      <Route
        path="/inventory/transactions/stock adjustments"
        component={StockAdjustments}
      />
      <Route
        path="/inventory/transactions/warehouse transfers"
        component={WarehouseTransfers}
      />
      <Route
        path="/inventory/transactions/stock count"
        component={StockCount}
      />
      <Route
        path="/inventory/transactions/import stock revaluation"
        component={ImportStockRevaluation}
      />
      <Route path="/inventory/products/add product" component={AddProduct} />
      <Route path="/inventory/products/update/:id" component={AddProduct} />
      <Route path="/inventory/products/view products" component={ViewProduct} />
      <Route
        path="/inventory/products/ImportExport"
        component={ImportExportProduct}
      />
      <Route
        path="/inventory/products/change codes"
        component={changeCodesProduct}
      />
      <Route
        path="/inventory/products/markup price update"
        component={MarkupPriceUpdate}
      />
      <Route path="/stock adjustment/add" component={AddAdjustment} />
      <Route path="/stock adjustment/update/:id" component={AddAdjustment} />
      <Route path="/warehouse transfer/add" component={AddWarehouseTransfer} />
      <Route
        path="/warehouse transfer/update/:id"
        component={AddWarehouseTransfer}
      />
      <Route path="/stockTakes/createCount" component={CreateStockCount} />
      <Route path="/stockTakes/update/:id" component={CreateStockCount} />
      <Route path="/import stock counts" component={ImportStockCounts} />

      <Route
        path="/productions/assemblies/add assembly"
        component={AddAssembly}
      />
      <Route
        path="/productions/assemblies/add disassembliy"
        component={AddDisAssembly}
      />
      <Route
        path="/productions/assemblies/view assemblies"
        component={ViewAssemblies}
      />
      <Route
        path="/productions/bill of materials/add bill of materials"
        component={AddBillOfMaterial}
      />
      <Route
        path="/productions/bill of materials/view bill of materials"
        component={ViewBillOfMaterial}
      />
      <Route
        path="/productions/bill of materials/import bill of materials"
        component={ImportBillOfMaterial}
      />
      <Route
        path="/productions/bill of materials/export bill of materials"
        component={ExportBillOfMaterial}
      />

      <Route path="/sales/quotes/add quote" component={AddQuoteSales} />
      <Route path="/sales/quotes/view quotes" component={ViewQuotesSales} />
      <Route path="/sales/quotes/import quotes" component={ImportQuotesSales} />
      <Route path="/sales/orders/add sales orders" component={AddOrderSales} />
      <Route
        path="/sales/orders/view sales orders"
        component={ViewOrderSales}
      />
      <Route
        path="/sales/orders/view shipments"
        component={ViewShipmentsOrderSales}
      />
      <Route
        path="/sales/orders/batch complete"
        component={BatchCompleteOrderSales}
      />
      <Route
        path="/sales/orders/import sales orders"
        component={ImportOrderSales}
      />
      <Route
        path="/sales/orders/export sales orders"
        component={ExportOrderSales}
      />
      <Route path="/sales/credits/add credit" component={AddCreditSales} />
      <Route path="/sales/credits/view credits" component={ViewCreditSales} />
      <Route
        path="/sales/credits/export credits"
        component={ExportCreditSales}
      />

      <Route path="/suppliers/add suppliers" component={AddSuppier} />
      <Route path="/suppliers/update/:id" component={AddSuppier} />
      <Route path="/suppliers/view suppliers" component={ViewSuppliers} />
      <Route path="/suppliers/import suppliers" component={ImportSuppliers} />
      <Route path="/suppliers/export suppliers" component={ExportSuppliers} />
      <Route path="/suppliers/change codes" component={ChangeCodesSuppliers} />

      <Route
        path="/reports/purchase/purchase enquiry"
        component={PurchaseEnquiryReport}
      />
      <Route
        path="/reports/purchase/costing analysis report"
        component={CostingAnalysisReport}
      />
      <Route
        path="/reports/purchase/reorder report"
        component={ReOrderReport}
      />
      <Route
        path="/reports/inventory/product enquiry"
        component={ProductEnquiry}
      />
      <Route
        path="/reports/inventory/stock on hand enquiry"
        component={StockOnHandEnquiry}
      />
      <Route
        path="/reports/inventory/batch number enquiry"
        component={BatchNumberEnquiry}
      />
      <Route
        path="/reports/inventory/serial number enquiry"
        component={SerialNumberEnquiry}
      />
      <Route
        path="/reports/inventory/transaction enquiry"
        component={TransactionEnquiry}
      />
      <Route
        path="/reports/inventory/product allocations"
        component={ProductionAllocations}
      />
      <Route
        path="/reports/inventory/quick stock count"
        component={QuickStockCount}
      />
      <Route
        path="/reports/inventory/stock movement enquiry"
        component={StockMovementEnquiry}
      />
      <Route
        path="/reports/inventory/price list enquiry"
        component={PriceListEnquiry}
      />
      <Route
        path="/reports/production/production enquiry"
        component={ProductionEnquiryReport}
      />
      <Route path="/reports/sale/margin enquiry" component={MarginEnquiry} />
      <Route
        path="/reports/sale/unit sales enquiry"
        component={UnitSalesEnquiry}
      />
      <Route path="/reports/sale/quote enquiry" component={QuotesEnquiry} />
      <Route path="/reports/sale/sales enquiry" component={SalesEnquiry} />
      <Route
        path="/reports/sale/back order enquiry"
        component={BackOrderQEnquiry}
      />
      <Route
        path="/reports/sale/shipment enquiry"
        component={ShipmentEnquiry}
      />
      <Route path="/reports/sale/invoice enquiry" component={InvoiceEnquiry} />
      <Route path="/reports/sale/credit enquiry" component={CreditEnquiry} />

      <Route
        path="/settings/doc designer/purchase order"
        component={PurchaseOrders}
      />
      <Route
        path="/settings/doc designer/supplier returns"
        component={SupplierReturns}
      />
      <Route
        path="/settings/doc designer/warehouse transfers"
        component={WarehouseTransfersSettings}
      />
      <Route path="/settings/doc designer/assemblies" component={Assemblies} />
      <Route
        path="/settings/doc designer/sales quotes"
        component={SalesQuotes}
      />
      <Route
        path="/settings/doc designer/sales orders"
        component={SalesOrders}
      />
      <Route path="/settings/doc designer/invoices" component={Invoices} />
      <Route path="/settings/doc designer/picklists" component={PickList} />
      <Route
        path="/settings/doc designer/packingSlips"
        component={PackingSlips}
      />
      <Route path="/settings/doc designer/ship notes" component={ShipNotes} />
      <Route
        path="/settings/doc designer/credit notes"
        component={CreditNotes}
      />
      <Route path="/settings/doc designer/labels" component={Labels} />
      <Route path="/settings/organization/company" component={Company} />
      <Route path="/settings/organization/billing" component={Billing} />
      <Route path="/settings/security/users" component={Users} />
      <Route path="/settings/security/roles" component={Roles} />
      <Route
        path="/settings/security/role permissions"
        component={RolePermissions}
      />
      <Route path="/settings/security/email logs" component={EmailLogs} />

      <Route
        path="/settings/system/adjsutment reasons"
        component={AdjustmentReasons}
      />
      <Route path="/settings/system/attribute sets" component={AttributeSets} />
      <Route path="/settings/system/credit reason" component={CreditReasons} />
      <Route path="/settings/system/currency rates" component={CurrencyRates} />
      <Route path="/settings/system/customer types" component={CustomerTypes} />
      <Route
        path="/settings/system/delivery methods"
        component={DeliveryMethods}
      />
      <Route path="/settings/system/prefixes" component={Prefixes} />
      <Route path="/settings/system/product groups" component={ProductGroups} />
      <Route path="/settings/system/payment terms" component={PaymentTerms} />
      <Route path="/settings/system/sale groups" component={SalesGroups} />
      <Route path="/settings/system/sales persons" component={SalesPerson} />
      <Route
        path="/settings/system/sell price tiers"
        component={SellPriceTier}
      />
      <Route
        path="/settings/system/shipping companies"
        component={ShippingCompanies}
      />
      <Route
        path="/settings/system/supplier return reason"
        component={SupplierReturnReason}
      />
      <Route path="/settings/system/taxes" component={Taxes} />
      <Route
        path="/settings/system/units of measure"
        component={UnitsOfMeasure}
      />
      <Route path="/settings/system/warehouses" component={Warehouse} />
      <Route path="/settings/system/warehouse/add" component={AddWarehouse} />
      <Route
        path="/settings/system/warehouse/update/:id"
        component={EditWarehouse}
      />
    </Switch>
  );
};

export default Routes;
