import React, { Component } from "react";
import {
  loaderState,
  setSuperUserInfo,
  getCountriesData,
  superUserSignUp,
} from "@actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import { EMAIL_FORMAT } from "@constants/global-constant";
import { isEmptyOrNull, goto, industryDropdownData } from "@helpers";
import RegistraionComp from "@components/registration/signup";

class Registraion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      company: null,
    };
  }

  componentDidMount() {
    const { countries } = this.props;
    if (!countries) {
      this.props.loaderState(true);
      this.props.getCountriesData();
    }
  }

  onCreateAccount = () => {
    let state = { ...this.state };
    if (
      isEmptyOrNull(state.companyName) &&
      isEmptyOrNull(state.industry) &&
      isEmptyOrNull(state.tradingName) &&
      isEmptyOrNull(state.timezone) &&
      isEmptyOrNull(state.baseCurrency)
    ) {
      swal("", "Please fill all the fields", "info");
    } else {
      this.props.loaderState(true);
      this.props.superUserSignUp({
        companyName: state.companyName,
        industry: state.industry,
        tradingName: state.tradingName,
        timezone: state.timezone,
        baseCurrency: state.baseCurrency,
      });
    }
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  handleChange = (newValue, actionMeta) => {
    const { name } = actionMeta;
    if (newValue) {
      this.setState({ [name]: newValue.value });
    } else {
      this.setState({ [name]: newValue });
    }
  };

  perpareTimeZoneData = () => {
    const { countries } = this.props;
    let data = [];
    _.forEach(countries, (country) => {
      data.push({
        value: country.timezones[0],
        label: `(${country.timezones[0]}) ${country.name}`,
      });
    });

    return data;
  };

  perpareCurrencyData = () => {
    const { countries } = this.props;
    let data = [];
    _.forEach(countries, (country) => {
      data.push({
        label: `(${country.currencies[0].code}) ${country.name}`,
        value: country.currencies[0].name,
      });
    });
    return data;
  };

  onSignUp = () => {
    const {
      last_name,
      first_name,
      password,
      email,
      confirm_password,
      phone_number,
    } = this.state;
    var email_format = EMAIL_FORMAT;
    if (
      isEmptyOrNull(last_name) ||
      isEmptyOrNull(first_name) ||
      isEmptyOrNull(password) ||
      isEmptyOrNull(confirm_password) ||
      isEmptyOrNull(email)
    ) {
      swal("", "All fields are required", "info");
    } else if (!email.match(email_format)) {
      swal("", "Email is not in correct format", "error");
    } else if (password !== confirm_password) {
      swal("", "Password and confirm password doesn't match", "error");
    } else {
      // goto('/organization');
      let data = {
        first_name,
        last_name,
        email,
        password,
        phone_number,
      };
      this.props.setSuperUserInfo(data);
      this.setState({ company: true });
    }
  };

  render() {
    let timezones = this.perpareTimeZoneData();
    let currencies = this.perpareCurrencyData();
    let industries = industryDropdownData();
    const {
      first_name,
      last_name,
      password,
      confirm_password,
      email,
      company,
    } = this.state;

    return (
      <div>
        <RegistraionComp
          // company={company}
          goto={(path) => goto(path)}
          last_name={last_name}
          first_name={first_name}
          password={password}
          confirm_password={confirm_password}
          email={email}
          onChange={(e) => this.onChange(e)}
          onSignUp={() => this.onSignUp()}
          // currencies={currencies}
          // timezones={timezones}
          onCreateAccount={() => this.onCreateAccount()}
          // industries={industries}
          // handleChange={(v, m) => this.handleChange(v, m)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countriesData,
    user: state.auth.userData,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  setSuperUserInfo,
  getCountriesData,
  superUserSignUp,
})(Registraion);
