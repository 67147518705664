import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";

export default function SellPriceTier({ tiers, onUpdate, onChange, data }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Sell Price Tiers</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                name="Tier 1 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_1
                      ? data.tier_1
                      : tiers
                      ? tiers[0]
                        ? tiers[0].name
                        : null
                      : null
                    : tiers
                    ? tiers[0]
                      ? tiers[0].name
                      : null
                    : null
                }
                id="tier_1"
              />
              <InlineInputForm
                name="Tier 2 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_2
                      ? data.tier_2
                      : tiers
                      ? tiers[1]
                        ? tiers[1].name
                        : null
                      : null
                    : tiers
                    ? tiers[1]
                      ? tiers[1].name
                      : null
                    : null
                }
                id="tier_2"
              />
              <InlineInputForm
                name="Tier 3 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_3
                      ? data.tier_3
                      : tiers
                      ? tiers[2]
                        ? tiers[2].name
                        : null
                      : null
                    : tiers
                    ? tiers[2]
                      ? tiers[2].name
                      : null
                    : null
                }
                id="tier_3"
              />
              <InlineInputForm
                name="Tier 4 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_4
                      ? data.tier_4
                      : tiers
                      ? tiers[3]
                        ? tiers[3].name
                        : null
                      : null
                    : tiers
                    ? tiers[3]
                      ? tiers[3].name
                      : null
                    : null
                }
                id="tier_4"
              />
              <InlineInputForm
                name="Tier 5 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_5
                      ? data.tier_5
                      : tiers
                      ? tiers[4]
                        ? tiers[4].name
                        : null
                      : null
                    : tiers
                    ? tiers[4]
                      ? tiers[4].name
                      : null
                    : null
                }
                id="tier_5"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                name="Tier 6 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_6
                      ? data.tier_6
                      : tiers
                      ? tiers[5]
                        ? tiers[5].name
                        : null
                      : null
                    : tiers
                    ? tiers[5]
                      ? tiers[5].name
                      : null
                    : null
                }
                id="tier_6"
              />
              <InlineInputForm
                name="Tier 7 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_7
                      ? data.tier_7
                      : tiers
                      ? tiers[6]
                        ? tiers[6].name
                        : null
                      : null
                    : tiers
                    ? tiers[6]
                      ? tiers[6].name
                      : null
                    : null
                }
                id="tier_7"
              />
              <InlineInputForm
                name="Tier 8 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_8
                      ? data.tier_8
                      : tiers
                      ? tiers[7]
                        ? tiers[7].name
                        : null
                      : null
                    : tiers
                    ? tiers[7]
                      ? tiers[7].name
                      : null
                    : null
                }
                id="tier_8"
              />
              <InlineInputForm
                name="Tier 9 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_9
                      ? data.tier_9
                      : tiers
                      ? tiers[8]
                        ? tiers[8].name
                        : null
                      : null
                    : tiers
                    ? tiers[8]
                      ? tiers[8].name
                      : null
                    : null
                }
                id="tier_9"
              />
              <InlineInputForm
                name="Tier 10 Name"
                onChange={(e) => onChange(e)}
                value={
                  data
                    ? data.tier_10
                      ? data.tier_10
                      : tiers
                      ? tiers[9]
                        ? tiers[9].name
                        : null
                      : null
                    : tiers
                    ? tiers[9]
                      ? tiers[9].name
                      : null
                    : null
                }
                id="tier_10"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5" id="sell-price-tier">
              <button
                onClick={() => onUpdate()}
                className="btn btn-success btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
