import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import RunReport from "@components/common/runReport";
import PurchaseEnquiryTable from "./purchaseEnquiryTable";

export default function PurchaseEnquiry({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Purchase Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineDropdown
                searchAble={true}
                id="transaction-date"
                name="Transaction Date"
                values={[
                  { label: "Order Date", value: "Order Date" },
                  { label: "Delivery Date", value: "Delivery Date" },
                  { label: "Receipt Date", value: "Receipt Date" },
                ]}
              />
              <InlineInputForm id="order-number" value="" name="Order Number" />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="status"
                name="Status"
                values={[
                  { label: "All", value: "All" },
                  { label: "Unapproved", value: "Unapproved" },
                  { label: "Parked", value: "Parked" },
                  { label: "Placed", value: "Placed" },
                  { label: "Closed", value: "Closed" },
                  { label: "Costed", value: "Costed" },
                  { label: "Receipted", value: "Receipted" },
                  { label: "Completed", value: "Completed" },
                  { label: "Deleted", value: "Deleted" },
                ]}
              />

              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={warehouses}
              />
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="sales-order-number"
                value=""
                name="Sales Order Number"
              />
              <InlineInputForm
                id="supplier-code"
                value=""
                name="Supplier Code"
              />
              <InlineInputForm
                id="supplier-name"
                value=""
                name="Supplier Name"
              />
              <InlineInputForm
                id="supplier-reference"
                value=""
                name="Supplier Reference"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <PurchaseEnquiryTable />
          <br />
          <RunReport />
        </div>
      </div>
    </>
  );
}
