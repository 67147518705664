import React, { Component } from "react";
import { connect } from "react-redux";
import { goto, initilizeDataTable, destroyDataTable } from "@helpers";
import ViewBillOfMaterialComp from "@components/production/viewBillOfMaterial";

class ViewBillOfMaterial extends Component {
  componentDidMount() {
    document.title = "Bills Of Material";
    initilizeDataTable("view-bill-of-material-table");
  }
  componentDidUpdate() {
    initilizeDataTable("view-bill-of-material-table");
  }
  render() {
    destroyDataTable("view-bill-of-material-table");

    return <ViewBillOfMaterialComp goto={(path) => goto(path)} />;
  }
}

export default connect(null, {})(ViewBillOfMaterial);
