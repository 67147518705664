import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getProducts,
  loaderState,
  addCodeChange,
  getCodeChanges,
  deleteCodeChange,
  onSelectCodeChange,
} from "@actions";
import {
  initilizeDataTable,
  destroyDataTable,
  prepareSearchData,
  isEmptyOrNull,
} from "@helpers";
import _ from "lodash";
import ChangeCodesComp from "@components/inventory/changeCode";
import swal from "sweetalert";

class ChangeCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: ["Single", "Batch"],
      searchedData: [],
      oldCode: "",
      newCode: "",
    };
  }

  componentDidMount() {
    document.title = "Product - Code Changes";
    this.props.loaderState(true);
    this.props.getCodeChanges();
    const { products } = this.props;
    if (!products) {
      this.props.getProducts();
    }
    initilizeDataTable("change-code-table");
  }

  componentDidUpdate() {
    initilizeDataTable("change-code-table");
  }

  onSelectProductCode = (code) => {
    this.setState({ oldCode: code, searchedData: [] });
  };

  onChange = (target) => {
    this.setState({ [target.id]: target.value });
  };

  onChangeCode = ({ target }) => {
    const { value } = target;
    const { products } = this.props;
    this.onChange(target);
    let searchedData = [];
    if (products) {
      let code = "productCode";
      let name = "productDescription";
      let data = prepareSearchData(products, code, name);
      _.forEach(data, (d) => {
        if (d.code.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          searchedData.push(d);
        }
      });
    }

    this.setState(
      !isEmptyOrNull(value) ? { searchedData } : { searchedData: [] }
    );
  };

  onClickSave = () => {
    const { oldCode, newCode } = this.state;
    if (!isEmptyOrNull(oldCode) && !isEmptyOrNull(newCode)) {
      if (oldCode.toLocaleLowerCase() === newCode.toLocaleLowerCase()) {
        swal("", "Both codes are the same");
      } else {
        this.props.loaderState(true);
        this.props.addCodeChange({
          oldCode,
          newCode,
          module: "product",
        });
        this.setState({ oldCode: "", newCode: "" });
      }
    } else {
      swal(
        "",
        "Please select an old product code and add new product code",
        "error"
      );
    }
  };

  onDelete = () => {
    this.props.loaderState(true);
    this.props.deleteCodeChange();
  };

  onCodeChangeSelect = (data) => {
    this.props.onSelectCodeChange(data);
  };

  productCodeChanges = () => {
    const { codeChanges } = this.props;
    let data = [];
    _.forEach(codeChanges, (code) => {
      if (code.module === "product") {
        data.push(code);
      }
    });

    return data;
  };

  render() {
    destroyDataTable("change-code-table");

    const { nav, searchedData, oldCode, newCode } = this.state;
    let codeChanges = this.productCodeChanges();

    return (
      <ChangeCodesComp
        nav={nav}
        oldCode={oldCode}
        newCode={newCode}
        onSelectProductCode={(code) => this.onSelectProductCode(code)}
        contents={searchedData}
        data={codeChanges}
        onChange={(target) => this.onChange(target)}
        onChangeCode={(e) => this.onChangeCode(e)}
        onClickSave={() => this.onClickSave()}
        onDelete={() => this.onDelete()}
        onCodeChangeSelect={(data) => this.onCodeChangeSelect(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.inventory.products.allProducts,
    codeChanges: state.common.codeChanges,
  };
};

export default connect(mapStateToProps, {
  getProducts,
  loaderState,
  addCodeChange,
  getCodeChanges,
  deleteCodeChange,
  onSelectCodeChange,
})(ChangeCodes);
