import React from "react";
import Info from "@components/common/info";
import StepsInfo from "@components/common/stepsInfo";

export default function StockRevaluation() {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>Import Stock Revaluation</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading=" Importing a Stock Revaluation"
            message="Be aware of the impact that this revaluation can have on your financial reports. A nil Draft Accounts Payable invoice pushes through to your accounting provider."
            link="Click for more information."
          />
          <div className="row">
            <div className="col-md-7">
              <h2 className="stock-revaluation-steps">
                Import Stock Revaluation into Globuss in three easy steps
              </h2>
              <StepsInfo
                heading="Download your Stock Valuation template file"
                step="1"
                link="Download Stock Valuation template"
                message="Start by downloading the template file. This file has the correct column headings Globuss needs to import your product data. It will also be populated with the current Average Land Cost of each of your products."
              />
              <StepsInfo
                heading="Enter new land costs for your products"
                step="2"
                message="Using Excel or another spreadsheet editor, enter values in the '*New Average Land Cost' field. Make sure the data you copy matches the column headings in the template. Don't enter a value for any products you do not want to change. Don't enter more than 1000 lines for each upload."
              />
              <StepsInfo
                heading="Import the updated template file"
                step="3"
                message="Use the Upload button below to send the file to Globuss. The file you import must be a valid excel file in .CSV format. A stock adjustment will be created for each product to set the new land price."
              />
              <button style={{ marginLeft: "5px" }} className="btn btn-success">
                Upload Stock Revaluation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
