import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import moment from "moment";

export default function Details({
  stateData,
  timezones,
  industries,
  handleChange,
  onChange,
  organizationTypes,
}) {
  return (
    <div id="Details" className="tab-pane active">
      <div className="row">
        <div className="col-md-5 col-sm-6">
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="companyName"
            value={
              stateData
                ? stateData.companyName
                  ? stateData.companyName
                  : ""
                : ""
            }
            name="*Company Name"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="tradingName"
            value={
              stateData
                ? stateData.tradingName
                  ? stateData.tradingName
                  : ""
                : ""
            }
            name="*Trading Name"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="tradingNameForPurchasing"
            value={
              stateData
                ? stateData.tradingNameForPurchasing
                  ? stateData.tradingNameForPurchasing
                  : ""
                : ""
            }
            name="Trading Name (for Purchasing)"
          />
          <InlineDropdown
            searchAble={true}
            id="industry"
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData
                ? { label: stateData.industry, value: stateData.industry }
                : null
            }
            name="*Industry"
            values={industries}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="organisationType"
            name="Organization Type"
            values={organizationTypes}
            value={
              stateData
                ? stateData.organisationType
                  ? {
                      label: stateData.organisationType,
                      value: stateData.organisationType,
                    }
                  : ""
                : ""
            }
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            disable="disabled"
            id="baseCurrency"
            value={
              stateData
                ? stateData.baseCurrency
                  ? stateData.baseCurrency
                  : ""
                : ""
            }
            name="Base Currency"
          />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-md-5 col-sm-6">
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="dashboardDataSource"
            name="Dashboard Data Source"
            value={
              stateData
                ? stateData.dashboardDataSource
                  ? {
                      label: stateData.dashboardDataSource,
                      value: stateData.dashboardDataSource,
                    }
                  : ""
                : ""
            }
            values={[
              { label: "Sales Invoice", value: "Sales Invoice" },
              { label: "Sales Order", value: "Sales Order" },
            ]}
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.gstVatNumber
                  ? stateData.gstVatNumber
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="gstVatNumber"
            name="GST / VAT Number"
          />
          <InlineInputForm
            value={
              stateData ? (stateData.website ? stateData.website : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="website"
            name="Website"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="timezone"
            name="*Timezone"
            value={
              stateData
                ? stateData.timezone
                  ? { label: stateData.timezone, value: stateData.timezone }
                  : ""
                : ""
            }
            values={timezones}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            value={
              stateData
                ? stateData.financialYearEnd
                  ? {
                      label: stateData.financialYearEnd,
                      value: stateData.financialYearEnd,
                    }
                  : null
                : null
            }
            id="financialYearEnd"
            name="Financial Year End"
            values={[
              { label: "31 JAN", value: "31 JAN" },
              { label: "28/29 FEB", value: "28/29 FEB" },
              { label: "31 MAR", value: "31 MAR" },
              { label: "30 APR", value: "30 APR" },
              { label: "31 MAY", value: "31 MAY" },
              { label: "30 JUN", value: "30 JUN" },
              { label: "31 JUL", value: "31 JUL" },
              { label: "30 AUG", value: "30 AUG" },
              { label: "30 SEP", value: "30 SEP" },
              { label: "31 OCT", value: "31 OCT" },
              { label: "30 NOV", value: "30 NOV" },
              { label: "31 DEC", value: "31 DEC" },
            ]}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            value={
              stateData
                ? stateData.measurementUnits
                  ? {
                      label: stateData.measurementUnits,
                      value: stateData.measurementUnits,
                    }
                  : null
                : null
            }
            id="measurementUnits"
            name="Measurement Units"
            values={[
              { label: "Metric", value: "Metric" },
              { label: "Imperial", value: "Imperial" },
            ]}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            value={
              stateData
                ? stateData.dateFormat
                  ? { label: stateData.dateFormat, value: stateData.dateFormat }
                  : null
                : null
            }
            id="dateFormat"
            name="dateFormat"
            values={[
              { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
              { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
              { label: "DD/MMM/YYYY", value: "DD/MMM/YYYY" },
              { label: "MMM/DD/YYYY", value: "MMM/DD/YYYY" },
            ]}
          />
          <small className="pull-right">
            Today is{" "}
            {moment().format(stateData ? stateData.dateFormat : "DD/MM/YYYY")}
          </small>
        </div>
      </div>
    </div>
  );
}
