import React, { Component } from "react";
import ImportSalesOrderComp from "@components/sales/order/import";

class ImportSalesOrder extends Component {
  render() {
    return <ImportSalesOrderComp />;
  }
}

export default ImportSalesOrder;
