import React, { Component } from "react";
import _ from "lodash";
import QuickLinksComp from "@components/quickLinks";
import { connect } from "react-redux";
import { updateQuickLinkStatus, loaderState } from "@actions";
import swal from "sweetalert";

class QuickLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLink: "All",
    };
  }

  // onChange = ({ target }) => {
  // 	this.setState({ selectedLink: target.value });
  // };
  handleChange = (newValue, actionMeta) => {
    if (newValue) {
      this.setState({ selectedLink: newValue.value });
    } else {
      this.setState({ selectedLink: newValue });
    }
  };
  allData = (data) => {
    let newObjArr = [];
    Object.keys(data).forEach(function (key) {
      newObjArr.push({ name: key, links: data[key] });
    });
    return newObjArr;
  };

  getSelectedManuData = (data) => {
    const { selectedLink } = this.state;
    let d = { name: "", links: [] };
    if (selectedLink === "All") {
      let links = this.groupBy(data, "heading");
      links = this.allData(links);
      return links;
    } else {
      _.forEach(data, (link) => {
        if (link.heading === selectedLink) {
          if (d.name !== "") {
            d.name = selectedLink;
          }
          d.links.push(link);
        }
      });
      return [d];
    }
  };

  groupBy = (OurArray, property) => {
    return OurArray.reduce(function (accumulator, object) {
      const key = object[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(object);
      return accumulator;
    }, {});
  };

  getLinks = (status) => {
    const { quicklinks } = this.props;
    let data = [];
    _.forEach(quicklinks, (link) => {
      if (link.status === status) {
        data.push(link);
      }
    });

    return data;
  };

  onClickAddOrRemove = (id, status, op) => {
    let SelectedLinks = this.getLinks(1);
    if (op === "add" && SelectedLinks.length >= 20) {
      swal("", "Selected Quick links reached maximum limit of 20", "info");
    } else {
      this.props.loaderState(true);
      this.props.updateQuickLinkStatus({ id, status });
    }
  };

  getDropdownOptions = () => {
    let data = [
      { label: "All", value: "All" },
      { label: "Purchases", value: "Purchases" },
      { label: "Inventory", value: "Inventory" },
      { label: "Productions", value: "Productions" },
      { label: "Sales", value: "Sales" },
      { label: "Customers", value: "Customers" },
      { label: "Suppliers", value: "Suppliers" },
      { label: "Integration", value: "Integration" },
      { label: "Reports", value: "Reports" },
      { label: "Settings", value: "Settings" },
    ];

    return data;
  };

  render() {
    let SelectedLinks = this.getLinks(1);
    let manusData = this.getSelectedManuData(this.getLinks(-1));
    let options = this.getDropdownOptions();
    return (
      <QuickLinksComp
        onClickAddOrRemove={(id, status, op) =>
          this.onClickAddOrRemove(id, status, op)
        }
        SelectedLinks={SelectedLinks}
        handleChange={(v, m) => this.handleChange(v, m)}
        manus={manusData}
        options={options}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quicklinks: state.common.quickLinks,
  };
};

export default connect(mapStateToProps, { loaderState, updateQuickLinkStatus })(
  QuickLinks
);
