import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "../components/common/loader";
import Registraion from "@containers/registration";
import Login from "@containers/registration/login";
import AppWrapper from "@components/appWrapper";

class App extends Component {
  render() {
    const { activeNav, onNavClick, sideManu, isLoading } = this.props;
    const pathname = window.location.pathname;

    let opacity = isLoading ? 0.5 : 1;
    let pointerEvents = isLoading ? "none" : "";
    return (
      <BrowserRouter>
        <div style={{ pointerEvents, opacity }}>
          <Loader display={isLoading} />
          {pathname === "/signup" ? (
            <Registraion />
          ) : pathname === "/" ? (
            <Login />
          ) : (
            <AppWrapper
              manus={sideManu}
              onNavClick={(nav) => onNavClick(nav)}
              nav={activeNav}
            />
          )}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.common.isLoading,
  };
};

export default connect(mapStateToProps, {})(App);
