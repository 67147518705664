import React, { Component } from "react";
import {
  loaderState,
  getDeliveryMethods,
  updateDeliveryMethods,
  onUpdateOrDeleteDeliveryMethods,
  addDeliveryMethods,
  deleteDeliveryMethods,
} from "@actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import DeliveryMethodsComp from "@components/setting/system/deliveryMethods";

class DeliveryMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addValue: null,
      editValue: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("delivery-methods-table");
    this.props.loaderState(true);
    this.props.getDeliveryMethods();
  }

  componentDidUpdate() {
    initilizeDataTable("delivery-methods-table");
  }

  onChange = ({ target }) => {
    this.setState({ addValue: target.value });
  };

  onChangeEdit = ({ target }) => {
    this.setState({ editValue: target.value });
  };

  onUpdate = () => {
    const { selectedMethod, user } = this.props;
    const { editValue } = this.state;

    if (!isEmptyOrNull(editValue)) {
      this.setState({ editValue: null });
      this.props.loaderState(true);
      this.props.updateDeliveryMethods({
        methodName: editValue,
        id: selectedMethod.id,
        updatedBy: user.id,
      });
    }
  };

  onDelete = () => {
    const { selectedMethod } = this.props;
    this.props.loaderState(true);
    this.props.deleteDeliveryMethods(selectedMethod.id);
  };

  onAdd = () => {
    const { user } = this.props;
    const { addValue } = this.state;

    if (!isEmptyOrNull(addValue)) {
      this.setState({ addValue: "" });
      this.props.loaderState(true);
      this.props.addDeliveryMethods({
        methodName: addValue,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter a delivery method value", "info");
    }
  };

  onMethodUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteDeliveryMethods(data);
  };

  render() {
    destroyDataTable("delivery-methods-table");

    const { addValue, editValue } = this.state;
    const { methods, selectedMethod } = this.props;
    return (
      <DeliveryMethodsComp
        methods={methods}
        addValue={addValue}
        editValue={
          editValue != null
            ? editValue
            : selectedMethod
            ? selectedMethod.methodName
            : null
        }
        onAdd={() => this.onAdd()}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onDelete={() => this.onDelete()}
        onMethodUpdateOrDelete={(data) => this.onMethodUpdateOrDelete(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    methods: state.system.deliveryMethods.allMethods,
    user: state.auth.loggedInUser,
    selectedMethod: state.system.deliveryMethods.selectedMethod,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getDeliveryMethods,
  updateDeliveryMethods,
  onUpdateOrDeleteDeliveryMethods,
  addDeliveryMethods,
  deleteDeliveryMethods,
})(DeliveryMethods);
