import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import SerialNumberComp from "@components/reports/inventory/serialNumber";

export default class SerialNumber extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <SerialNumberComp />;
  }
}
