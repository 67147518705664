import React from "react";
import Tabs from "@components/common/tabs";
import Single from "./single";
import Batch from "./batch";
import CodesTable from "@components/common/codesTable";
import ConfirmationModal from "@components/common/confirmationModal";

export default function ChangeCodes({
  nav,
  data,
  contents,
  onChangeCode,
  onSelectProductCode,
  onChange,
  oldCode,
  newCode,
  onClickSave,
  onDelete,
  onCodeChangeSelect,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Change Codes</label>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-3 padding-1" style={{ textAlign: "right" }}>
            <button className="btn btn-light btn-sm">Export</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={nav} />
          <br />
          <div className="tab-content">
            <Single
              onChange={(target) => onChange(target)}
              oldCode={oldCode}
              newCode={newCode}
              contents={contents}
              onChangeCode={(e) => onChangeCode(e)}
              onSelectProductCode={(code) => onSelectProductCode(code)}
              onClickSave={() => onClickSave()}
            />
            <Batch />
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="table-view table-responsive">
            <CodesTable
              data={data}
              onCodeChangeSelect={(data) => onCodeChangeSelect(data)}
            />
            <ConfirmationModal onDelete={() => onDelete()} item="Code Change" />
          </div>
        </div>
      </div>
    </>
  );
}
