import React, { Component } from "react";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  warehousesData,
} from "@helpers";
import { getWarehouseTransfers } from "@actions";
import { connect } from "react-redux";
import WarehouseTransfersComp from "@components/inventory/warehouseTransfers";
import swal from "sweetalert";

class WarehouseTransfers extends Component {
  componentDidMount() {
    document.title = "Warehouse Transfers";
    this.props.getWarehouseTransfers();
    initilizeDataTable("warehouse-transfers-table");
  }

  componentDidUpdate() {
    initilizeDataTable("warehouse-transfers-table");
  }

  onClickAction = (action) => {
    swal("", "You Clicked " + action, "info");
  };

  render() {
    destroyDataTable("warehouse-transfers-table");

    const { warehouses, transfers } = this.props;

    let warehousesDDL =
      warehouses && warehouses.length > 0 ? warehousesData(warehouses) : [];

    return (
      <WarehouseTransfersComp
        goto={(path) => goto(path)}
        warehouses={warehousesDDL}
        transfers={transfers}
        onClickAction={(action) => this.onClickAction(action)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
    transfers: state.inventory.transactions.warehouseTransfers,
  };
};

export default connect(mapStateToProps, { getWarehouseTransfers })(
  WarehouseTransfers
);
