import React, { Component } from "react";
import {
  initilizeDataTable,
  destroyDataTable,
  goto,
  prepareSearchAbleDropdownData,
} from "@helpers";
import {
  getCustomers,
  loaderState,
  onSelectCustomer,
  selectedNav,
  deleteCustomer,
  updateCustomer,
  getCustomerTypes,
} from "@actions";
import { connect } from "react-redux";
import ViewCustomersComp from "@components/customers/view";

class ViewCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: true,
    };
  }
  componentDidMount() {
    document.title = "View Customers";
    this.props.loaderState(true);
    this.props.getCustomers();
    this.props.getCustomerTypes();
    initilizeDataTable("view-customers-table");
  }

  componentDidUpdate() {
    initilizeDataTable("view-customers-table");
  }

  goto = (path) => {
    this.props.selectedNav(path);
    goto(path);
  };

  gotoCustomer = async (customer) => {
    await this.onCustomerSelect(customer);
    await this.props.selectedNav("/customers/add customers");
    goto(`/customers/update/${customer.customerData.id}`);
  };

  onCustomerSelect = (customer) => {
    this.setState({ rerender: !this.state.rerender });
    this.props.onSelectCustomer(customer);
  };

  onClickAction = (action) => {
    const { selectedCustomer } = this.props;
    if (action === "Delete") {
      this.props.loaderState(true);
      this.props.deleteCustomer(selectedCustomer.customerData.id);
    } else if (action === "Edit") {
      this.props.selectedNav("/customers/add customers");
      goto(`/customers/update/${selectedCustomer.customerData.id}`);
    } else if (action === "Obsolete") {
      let data = { obsolete: 1 };
      this.props.loaderState(true);
      this.props.updateCustomer(data);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { rerender } = this.state;
    if (rerender === nextState.rerender) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    destroyDataTable("view-customers-table");
    const { customers, customerTypes } = this.props;
    console.log(customerTypes);
    let custTypes =
      customerTypes && customerTypes.length > 0
        ? prepareSearchAbleDropdownData(customerTypes, "typeName", "id")
        : [];

    return (
      <ViewCustomersComp
        gotoCustomer={(customer) => this.gotoCustomer(customer)}
        onCustomerSelect={(customer) => this.onCustomerSelect(customer)}
        customers={customers}
        goto={(path) => this.goto(path)}
        onClickAction={(action) => this.onClickAction(action)}
        custTypes={custTypes}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customers.allCustomers,
    selectedCustomer: state.customers.selectedCustomer,
    customerTypes: state.system.customerTypes.allCustomerTypes,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getCustomers,
  onSelectCustomer,
  selectedNav,
  deleteCustomer,
  updateCustomer,
  getCustomerTypes,
})(ViewCustomers);
