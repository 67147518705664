import React from 'react';
import ReturnTable from './returnsTable';
import Select from 'react-select';

export default function Returns() {
	return (
		<div id="Returns" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2">
					<label className="input-label" htmlFor="return-status">
						Return Status
					</label>
					<Select
						isClearable
						name="Return Status"
						id="return_status"
						options={[
							{ label: 'All', value: 'All' },
							{ label: 'Parked', value: 'Parked' },
							{ label: 'Completed', value: 'Completed' },
						]}
					/>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<ReturnTable />
			{/* </div>
			</div> */}
		</div>
	);
}
