import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import InLineCheckbox from "@components/common/inLineCheckbox";
import Switch from "@components/common/switch";

export default function Details({
  onChange,
  handleChange,
  stateData,
  edit,
  customerTypesData,
  salesGroupsData,
  salesPersonData,
  warehousesData,
  paymentTermsData,
  sellPriceTiersData,
  currencyRatesData,
  taxesData,
  deliveryMethodData,
  getSelectedValue,
}) {
  return (
    <div id="Details" className="tab-pane active">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            disable={edit ? "disabled" : ""}
            value={
              stateData
                ? stateData.customerCode
                  ? stateData.customerCode
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="customerCode"
            name="*Cutomer Code"
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.customerName
                  ? stateData.customerName
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="customerName"
            name="*Cutomer Name"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            value={
              stateData && stateData.customerTypeId
                ? getSelectedValue(stateData.customerTypeId, customerTypesData)
                : null
            }
            id="customerTypeId"
            name="Customer Type"
            values={customerTypesData}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.salesPersonId
                ? getSelectedValue(stateData.salesPersonId, salesPersonData)
                : null
            }
            searchAble={true}
            id="salesPersonId"
            name="Sales Person"
            values={salesPersonData}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.salesGroupId
                ? getSelectedValue(stateData.salesGroupId, salesGroupsData)
                : null
            }
            searchAble={true}
            id="salesGroupId"
            name="Sales Order Group"
            values={salesGroupsData}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="deliveryAddressId"
            name="Default Delivery Address"
            values={[]}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.deliveryMethodId
                ? getSelectedValue(
                    stateData.deliveryMethodId,
                    deliveryMethodData
                  )
                : null
            }
            searchAble={true}
            id="deliveryMethodId"
            name="Delivery Method"
            values={deliveryMethodData}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.warehouseId
                ? getSelectedValue(stateData.warehouseId, warehousesData)
                : null
            }
            searchAble={true}
            id="warehouseId"
            name="Default Warehouse"
            values={warehousesData}
          />
          <div className="inline-form">
            <div className="field">
              <label htmlFor="notes">Notes</label>
              <textarea
                value={
                  stateData ? (stateData.notes ? stateData.notes : "") : ""
                }
                onChange={(e) => onChange(e)}
                rows="7"
                type="text"
                id="notes"
              />
            </div>
          </div>
          <Switch
            value={stateData ? (stateData.obsolete === 1 ? "checked" : "") : ""}
            onChange={(e) => onChange(e)}
            id="obsolete"
            name="Obsolete"
          />
        </div>
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            value={
              stateData
                ? stateData.GST_VAT_Number
                  ? stateData.GST_VAT_Number
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="GST_VAT_Number"
            name="GST/VAT Number"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.currencyRateId
                ? getSelectedValue(stateData.currencyRateId, currencyRatesData)
                : null
            }
            searchAble={true}
            id="currencyRateId"
            name="Default Currency"
            values={currencyRatesData}
          />
          <Switch
            value={stateData && stateData.taxable === 1 ? "checked" : ""}
            onChange={(e) => onChange(e)}
            id="taxable"
            name="Taxable"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.taxId
                ? getSelectedValue(stateData.taxId, taxesData)
                : null
            }
            searchAble={true}
            disable={stateData && stateData.taxable === 1 ? "" : "disabled"}
            id="taxId"
            name="Tax Rate"
            values={taxesData}
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.bankAccountName
                  ? stateData.bankAccountName
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="bankAccountName"
            name="Bank Account Name"
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.bankAccountNumber
                  ? stateData.bankAccountNumber
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="bankAccountNumber"
            name="Bank Account Number"
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.paymentTermId
                ? getSelectedValue(stateData.paymentTermId, paymentTermsData)
                : null
            }
            searchAble={true}
            id="paymentTermId"
            name="Payment Terms"
            values={paymentTermsData}
          />
          <InlineDropdown
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData && stateData.sellPriceTierId
                ? getSelectedValue(
                    stateData.sellPriceTierId,
                    sellPriceTiersData
                  )
                : null
            }
            searchAble={true}
            id="sellPriceTierId"
            name="Sell Price Tier"
            values={sellPriceTiersData}
          />
          <InlineInputForm
            value={
              stateData ? (stateData.discount ? stateData.discount : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="discount"
            value="0.00"
            name="Discount (%)"
          />
          <div className="inline-form">
            <div className="field">
              <label htmlFor="reminder">Reminder</label>
              <textarea
                value={
                  stateData
                    ? stateData.reminder
                      ? stateData.reminder
                      : ""
                    : ""
                }
                onChange={(e) => onChange(e)}
                rows="7"
                type="text"
                id="reminder"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <InLineCheckbox
            value={
              stateData
                ? stateData.printQuoteOnSalesQuote === 1
                  ? "checked"
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="printQuoteOnSalesQuote"
            name="Print Quote when accepted on Sales Quote page"
          />
          <InLineCheckbox
            value={
              stateData
                ? stateData.printOrderOnSalesOrder === 1
                  ? "checked"
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="printOrderOnSalesOrder"
            name="Print Order when completed on Sales Order page"
          />
          <InLineCheckbox
            value={
              stateData
                ? stateData.printPackingSlip === 1
                  ? "checked"
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="printPackingSlip"
            name="Print Packing Slip"
          />
          <InlineDropdown
            value={
              stateData
                ? stateData.quotesTemplate
                  ? {
                      label: stateData.quotesTemplate,
                      value: stateData.quotesTemplate,
                    }
                  : null
                : null
            }
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="quotesTemplate"
            name="Quote template"
            values={[
              { label: "Globuss Default", value: "Globuss Default" },
              { label: "Default Sales Quote", value: "Default Sales Quote" },
            ]}
          />
          <InlineDropdown
            value={
              stateData
                ? stateData.orderTemplate
                  ? {
                      label: stateData.orderTemplate,
                      value: stateData.orderTemplate,
                    }
                  : null
                : null
            }
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="orderTemplate"
            name="Order template"
            values={[
              { label: "Globuss Default", value: "Globuss Default" },
              { label: "Formal Order", value: "Formal Order" },
              { label: "Classic Order", value: "Classic Order" },
            ]}
          />
          <InlineDropdown
            value={
              stateData
                ? stateData.invoiceTemplate
                  ? {
                      label: stateData.invoiceTemplate,
                      value: stateData.invoiceTemplate,
                    }
                  : null
                : null
            }
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="invoiceTemplate"
            name="Invoice template"
            values={[
              { label: "Globuss Default", value: "Globuss Default" },
              { label: "Format Invoice", value: "Format Invoice" },
              { label: "Classic Invoice", value: "Classic Invoice" },
            ]}
          />
          <InlineDropdown
            value={
              stateData
                ? stateData.packingSlipTemplate
                  ? {
                      label: stateData.packingSlipTemplate,
                      value: stateData.packingSlipTemplate,
                    }
                  : null
                : null
            }
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            searchAble={true}
            id="packingSlipTemplate"
            name="Packing Slip template"
            values={[
              { label: "Globuss Deafult", value: "Globuss Deafult" },
              { label: "Formal Slip Template", value: "Formal Slip Template" },
              {
                label: "Classic Slip Template",
                value: "Classic Slip Template",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
