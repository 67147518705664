import { permissions } from "@constants/actionTypes";
const initialState = {
  appFunctionalities: null,
  rolePermissions: null,
};

function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case permissions.APP_FUNCTIONALITIES:
      return { ...state, appFunctionalities: action.payload };
    case permissions.ROLE_PERMISSIONS:
      return { ...state, rolePermissions: action.payload };
    default:
      return state;
  }
}
export default permissionsReducer;
