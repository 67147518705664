import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import QuickStockCountComp from "@components/reports/inventory/quickStockCount";

export default class QuickStockCount extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <QuickStockCountComp />;
  }
}
