import React, { Component } from "react";
import {
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  industryDropdownData,
  removeNullValues,
  organizationTypes,
} from "@helpers";
import { connect } from "react-redux";
import {
  updateOrganization,
  loaderState,
  updateOrganizationAddress,
  updateAddress,
} from "@actions";
import CompanyComp from "@components/setting/organization/company";
import swal from "sweetalert";
import _ from "lodash";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        "Details",
        "Configuration",
        "Address",
        "Contact",
        "Images",
        "Invoicing",
        "Purchasing",
      ],
    };
  }

  componentDidMount() {
    this.setState({
      organization: { ...this.props.organization },
      postal: { ...this.props.organizatoionPostalAddress },
      physical: { ...this.props.organizatoionPhysicalAddress },
    });
    initilizeDataTable("upload-images-table");
  }

  componentDidUpdate() {
    initilizeDataTable("upload-images-table");
  }

  handleChange = (newValue, actionMeta, obj) => {
    const { name } = actionMeta;
    if (obj === "postal") {
      let postal = { ...this.state.postal };
      if (newValue) {
        postal[name] = newValue.value;
        this.setState({ postal });
      } else {
        postal[name] = newValue;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      let physical = { ...this.state.physical };
      if (newValue) {
        physical[name] = newValue.value;
        this.setState({ physical });
      } else {
        physical[name] = newValue;
        this.setState({ physical });
      }
    } else {
      let organization = { ...this.state.organization };
      if (newValue) {
        organization[name] = newValue.value;
        this.setState({ organization });
      } else {
        organization[name] = newValue;
        this.setState({ organization });
      }
    }
  };

  onChange = (e, obj) => {
    const { id, type } = e.target;
    if (obj === "postal") {
      var postal = { ...this.state.postal };
      if (type === "checkbox") {
        if (e.target.checked) {
          postal[id] = 1;
        } else {
          postal[id] = -1;
        }
        this.setState({ postal });
      } else {
        postal[id] = e.target.value;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      var physical = { ...this.state.physical };
      if (type === "checkbox") {
        if (e.target.checked) {
          physical[id] = 1;
        } else {
          physical[id] = -1;
        }
        this.setState({ physical });
      } else {
        physical[id] = e.target.value;
        this.setState({ physical });
      }
    } else {
      var organization = { ...this.state.organization };
      if (type === "checkbox") {
        if (e.target.checked) {
          organization[id] = 1;
        } else {
          organization[id] = -1;
        }
        this.setState({ organization });
      } else {
        organization[id] = e.target.value;
        this.setState({ organization });
      }
    }
  };

  onClickSave = () => {
    let state = { ...this.state };
    delete state.nav;
    let data = { ...state.organization };
    const { user } = this.props;
    data.updatedBy = user.id;
    if (
      !isEmptyOrNull(data.companyName) &&
      !isEmptyOrNull(data.tradingName) &&
      !isEmptyOrNull(data.industry) &&
      !isEmptyOrNull(data.timezone)
    ) {
      this.props.loaderState(true);
      let orgData = removeNullValues(state.organization);
      let postal = removeNullValues(state.postal);
      let physical = removeNullValues(state.physical);
      this.props.updateOrganization(orgData);
      this.props.updateAddress(postal, "postal");
      this.props.updateAddress(physical, "physical");
    } else {
      swal("", "fields with * are required fields!");
    }
  };

  perpareTimeZoneData = () => {
    const { countries } = this.props;
    let data = [];
    _.forEach(countries, (country) => {
      data.push({
        value: `(${country.timezones[0]}) ${country.name}`,
        label: `(${country.timezones[0]}) ${country.name}`,
      });
    });

    return data;
  };

  perpareCountriesData = () => {
    const { countries } = this.props;
    let data = [];
    _.forEach(countries, (country) => {
      data.push({ value: country.name, label: country.name });
    });

    return data;
  };

  render() {
    destroyDataTable("upload-images-table");

    let industries = industryDropdownData();
    let timezones = this.perpareTimeZoneData();
    let countries = this.perpareCountriesData();
    let orgTypes = organizationTypes();
    let stateData = { ...this.state };
    return (
      <CompanyComp
        stateData={stateData}
        industries={industries}
        countries={countries}
        timezones={timezones}
        organizationTypes={orgTypes}
        onChange={(e, obj) => this.onChange(e, obj)}
        handleChange={(newValue, actionMeta, obj) =>
          this.handleChange(newValue, actionMeta, obj)
        }
        onClickSave={() => this.onClickSave()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organization: state.auth.organizationInfo,
    user: state.auth.loggedInUser,
    countries: state.common.countriesData,
    organizatoionPhysicalAddress: state.auth.organizatoionPhysicalAddress,
    organizatoionPostalAddress: state.auth.organizatoionPostalAddress,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  updateOrganization,
  updateOrganizationAddress,
  updateAddress,
})(Company);
