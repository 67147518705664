import React from "react";
import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";

export default function customerContacts({ onCustomerContactsUpload }) {
  return (
    <div id="CustomerContacts" className="tab-pane fade">
      <br />
      <Info
        heading="Importing Customer Contacts"
        message="When importing contacts for a customer, if the First Name + Last Name combination already exists, then the existing contact will be updated. For more information please"
        link="visit our help pages."
      />
      <UploadFiles
        onFileDivClick={() => onCustomerContactsUpload()}
        id="Customer Contacts"
      />
    </div>
  );
}
