import { productions } from "@constants/actionTypes";
const initialState = {
  allAssemblies: null,
  selectedAssembly: null,
  allDisAssemblies: null,
  selectedDisAssembly: null,
  billOfMaterials: null,
  selectedBillOfMaterial: null,
};

function productionsReducer(state = initialState, action) {
  switch (action.type) {
    case productions.ASSEMBLIES:
      return { ...state, allAssemblies: action.payload };
    case productions.SELECTED_ASSEMBLY:
      return { ...state, selectedAssembly: action.payload };
    case productions.DISASSEMBLIES:
      return { ...state, allDisAssemblies: action.payload };
    case productions.SELECTED_DISASSEMBLY:
      return { ...state, selectedDisAssembly: action.payload };
    case productions.BILL_OF_MATERIALS:
      return { ...state, billOfMaterials: action.payload };
    case productions.SELECTED_BILL_OF_MATERIAL:
      return { ...state, selectedBillOfMaterial: action.payload };
    default:
      return state;
  }
}
export default productionsReducer;
