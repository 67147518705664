import React from "react";
// import QuickLinksDropdown from "@components/common/quickLinksDropdown";
import "./index.css";

const Header = ({ goto, user, quicklinks }) => {
  return (
    <nav className="navbar navbar-default app-header">
      <div className="navbar-header">
        <button
          type="button"
          id="sidebarCollapse"
          className="navbar-btn navbar-left"
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <button
          type="button"
          className="navbar-toggle collapsed navbar-btn"
          data-toggle="collapse"
          data-target="#navbar-collapse-1"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      {/* <div className="customize-quicklinks">
        <QuickLinksDropdown
          goto={(path) => goto(path)}
          quicklinks={quicklinks}
        />
      </div> */}
      <div className="collapse navbar-collapse" id="navbar-collapse-1">
        <ul className="nav navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
              <i className="fa fa-bell-o"></i>
              <span className="header-icon-name">Notifications</span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-right"
              id="header-dropdown"
            >
              <li className="dropdown-item">
                <a href="#">
                  <span>Notification 1</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#">
                  <span>Notification 2</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#">
                  <span>Notification 2</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a className="dropdown-toggle" data-toggle="dropdown" href="#">
              <i className="fa fa-user" aria-hidden="true" />
              <span className="header-icon-name">
                {user ? user.first_name + " " + user.last_name : "Profile"}
              </span>

              {/* <span className="caret"></span> */}
            </a>
            <ul
              className="dropdown-menu dropdown-menu-right"
              id="header-dropdown"
            >
              <li className="dropdown-item">
                <a href="#">
                  <i
                    className="fa fa-user"
                    id="header-account-icon"
                    aria-hidden="true"
                  />
                  <span>My Account</span>
                </a>
              </li>
              {/* <li className="dropdown-item">
                <a href="#">
                  <i
                    className="fa fa-graduation-cap"
                    id="header-account-icon"
                    aria-hidden="true"
                  />
                  <span>Globuss University</span>
                </a>
              </li> */}
              <li className="dropdown-item">
                <a href="#">
                  <i
                    className="fa fa-pencil-square-o"
                    id="header-account-icon"
                    aria-hidden="true"
                  />
                  <span>Change Password</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#" onClick={() => goto("/")}>
                  <i
                    className="fa fa-sign-out"
                    id="header-account-icon"
                    aria-hidden="true"
                  />
                  <span>Log Out</span>
                </a>
              </li>
            </ul>
          </li>
          {/* <li className="dropdown">
            <a className="nav-link" href="#" data-toggle="dropdown">
              <i className="fa fa-question-circle-o"></i>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-right"
              id="header-dropdown"
            >
              <li className="dropdown-item">
                <a href="#">
                  <span>Get help from this page</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#">
                  <span>Submit a request</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#">
                  <span>Globuss accademy</span>
                </a>
              </li>
              <li className="dropdown-item">
                <a href="#">
                  <span>Take the tour</span>
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Header;
