import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import RunReport from "@components/common/runReport";
import CreditEnquiryTable from "./creditEnquiryTable";

export default function CreditEnquiry({ warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Credit Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineDropdown
                searchAble={true}
                id="transaction-date"
                name="Transaction Date"
                values={[
                  { label: "Credit Date", value: "Credit Date" },
                  { label: "Receipt date", value: "Receipt date" },
                ]}
              />
              <InlineInputForm
                id="credit-number"
                value=""
                name="Credit Number"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="credit-number"
                value=""
                name="Credit Number"
              />
              <InlineDropdown
                searchAble={true}
                id="credit-status"
                name="Credit Status"
                values={[
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                  { label: "Deleted", value: "Deleted" },
                ]}
              />
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="customer-code"
                value=""
                name="Customer Code"
              />
              <InlineInputForm
                id="customer-name"
                value=""
                name="Customer name"
              />
              <InlineDropdown
                searchAble={true}
                id="product-group"
                name="Product Group"
                values={[{ label: "General", value: "General" }]}
              />
              <InlineDropdown
                searchAble={true}
                id="credit-reason"
                name="Credit Reason"
                values={[{ label: "Credit", value: "Credit" }]}
              />
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={[
                  { label: "Select All", value: "Select All" },
                  { ...warehouses },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="sales-person"
                name="Sales Person"
                values={[{ label: "name: email", value: "name: email" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CreditEnquiryTable />
          <br />
          <RunReport />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <div className="panel panel-default" id="panel-margin-right">
            <div className="panel-body">
              <div className="grid-container">
                <div className="grid-item">
                  <div className="border-bottom">
                    <span className="receipt-stat-label">Credit Count</span>
                    <span className="pull-right receipt-stat-value"></span>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="border-bottom">
                    <span className="receipt-stat-label">
                      Total Credit Value{" "}
                    </span>
                    <span className="pull-right receipt-stat-value"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
