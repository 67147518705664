import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import RunReport from "@components/common/runReport";
import CostingAnalysisTable from "./costingAnalysisTable";

export default function CostingAnalysisReport() {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Costing Analysis Report</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="product-code">
                Product Code
              </label>
              <input
                type="text"
                className="form-control"
                id="product-code"
                name="product-code"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="description">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CostingAnalysisTable />
          <br />
          <RunReport />
        </div>
      </div>
    </>
  );
}
