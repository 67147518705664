import React, { Component } from "react";
import SupplierReturnsComp from "@components/setting/docDesigner/supplierReturns/index";

export default class SupplierReturns extends Component {
  state = {
    templates: [
      { label: "Default Supplier Return", value: "Default Supplier Return" },
    ],
  };
  render() {
    const { templates } = this.state;
    return (
      <SupplierReturnsComp templates={templates} name="Supplier Returns" />
    );
  }
}
