import React from "react";
import Customers from "./customers";
import DeliveryAddresses from "./deliveryAddresses";
import CustomerContacts from "./customerContacts";
import CustomerPricing from "./customerPricing";
import Tabs from "@components/common/tabs";
import SplitButton from "@components/common/splitButton";

export default function ImportExport({
  nav,
  onCustomersUpload,
  onCustomerContactsUpload,
  onCustomerPricingUpload,
  onDeliveryAddressUpload,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4 col-md-7 col-8">
            <label>Import/Export</label>
          </div>
          <div className="col-lg-5 col-md-2 col-1"></div>
          <div
            className="col-lg-3 col-md-3 col-3"
            style={{ textAlign: "right" }}
          >
            <SplitButton
              label="Export"
              type="success"
              data={[
                "Customers",
                "Delivery Addresses",
                "Customer Contacts",
                "Customer Pricing",
              ]}
            />
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={nav} />
          <div className="tab-content">
            <Customers onCustomersUpload={() => onCustomersUpload()} />
            <DeliveryAddresses
              onDeliveryAddressUpload={() => onDeliveryAddressUpload()}
            />
            <CustomerContacts
              onCustomerContactsUpload={() => onCustomerContactsUpload()}
            />
            <CustomerPricing
              onCustomerPricingUpload={() => onCustomerPricingUpload()}
            />
          </div>
        </div>
      </div>
    </>
  );
}
