import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  getPaymentTerms,
  loaderState,
  addPaymentTerms,
  deletePaymentTerms,
  onSelectPaymentTerm,
  updatePaymentTerm,
} from "@actions";
import _ from "lodash";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import PaymentTermsComp from "@components/setting/system/paymentTerms";

class PaymentTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      days: null,
      type: null,
      editName: null,
      editDays: null,
      editType: null,
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getPaymentTerms();
    initilizeDataTable("payment-terms-table");
  }

  componentDidUpdate() {
    initilizeDataTable("payment-terms-table");
  }

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  onUpdate = () => {
    console.log(this.state);
  };

  handleChange = (newValue) => {
    if (newValue) {
      this.setState({ type: newValue.value });
    } else {
      this.setState({ type: newValue });
    }
  };

  handleEditChange = (newValue) => {
    if (newValue) {
      this.setState({ editType: newValue.value });
    } else {
      this.setState({ editType: newValue });
    }
  };

  onAdd = () => {
    const { type, days, name } = this.state;
    const { user } = this.props;
    if (!isEmptyOrNull(name) && !isEmptyOrNull(days) && !isEmptyOrNull(type)) {
      this.setState({ name: "", days: "", type: "" });
      let data = {
        name,
        type,
        days,
        createdBy: user.id,
        updatedBy: user.id,
      };
      this.props.loaderState(true);
      this.props.addPaymentTerms(data);
    } else {
      swal("", "Please fill all the fields!", "info");
    }
  };

  onDelete = () => {
    const { selectedTerm } = this.props;
    this.props.loaderState(true);
    this.props.deletePaymentTerms(selectedTerm.id);
  };

  onPaymentTermSelect = (data) => {
    this.props.onSelectPaymentTerm(data);
  };

  findtype = (terms, type) => {
    let typeSelected = {};
    let typeS = null;
    _.forEach(terms, (t) => {
      if (t.type === type) {
        typeS = t;
      }
    });
    if (typeS) {
      typeSelected.label = typeS.type;
      typeSelected.value = typeS.type;
    } else {
      typeSelected = null;
    }
    return typeSelected;
  };

  onUpdate = () => {
    const { selectedTerm, user } = this.props;
    const { editName, editType, editDays } = this.state;

    if (
      isEmptyOrNull(editName) &&
      isEmptyOrNull(editType) &&
      isEmptyOrNull(editDays)
    ) {
    } else {
      let data = { id: selectedTerm.id, updatedBy: user.id };
      if (!isEmptyOrNull(editName)) {
        data.name = editName;
      }
      if (!isEmptyOrNull(editDays)) {
        data.days = editDays;
      }
      if (!isEmptyOrNull(editType)) {
        data.type = editType;
      }
      this.setState({ editName: null, editDays: null, editType: null });
      this.props.loaderState(true);
      this.props.updatePaymentTerm({
        ...data,
      });
    }
  };

  render() {
    destroyDataTable("payment-terms-table");
    const { name, days, type, editName, editDays, editType } = this.state;
    const { terms, selectedTerm } = this.props;
    let types = [
      { label: "Days after", value: "Days after" },
      {
        label: "Days following the end of month",
        value: "Days following the end of month",
      },
      {
        label: "Days of the month following",
        value: "Days of the month following",
      },
      {
        label: "End of the month following",
        value: "End of the month following",
      },
    ];

    let id = editType ? editType : selectedTerm ? selectedTerm.type : -1;
    let editTypeSelected = terms ? this.findtype(terms, id) : null;

    return (
      <PaymentTermsComp
        name={name}
        days={days}
        type={type}
        editName={editName ? editName : selectedTerm ? selectedTerm.name : null}
        editDays={editDays ? editDays : selectedTerm ? selectedTerm.days : null}
        editType={editTypeSelected}
        terms={terms}
        types={types}
        onAdd={() => this.onAdd()}
        handleChange={(value) => this.handleChange(value)}
        handleEditChange={(value) => this.handleEditChange(value)}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onDelete={() => this.onDelete()}
        onPaymentTermSelect={(data) => this.onPaymentTermSelect(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.loggedInUser,
    terms: state.system.paymentTerms.allTerms,
    selectedTerm: state.system.paymentTerms.selectedTerm,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  getPaymentTerms,
  loaderState,
  addPaymentTerms,
  deletePaymentTerms,
  onSelectPaymentTerm,
  updatePaymentTerm,
})(PaymentTerms);
