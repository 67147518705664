import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState } from "@actions";
import { initilizeDataTable, destroyDataTable, warehousesData } from "@helpers";
import ViewShipmentsSalesOrderComp from "@components/sales/order/viewShipments";

class ViewShipmentsSalesOrder extends Component {
  componentDidMount() {
    const { warehouses } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }

    initilizeDataTable("view-shipments-table");
  }

  componentDidUpdate() {
    initilizeDataTable("view-shipments-table");
  }

  render() {
    destroyDataTable("view-shipments-table");

    const { warehouses } = this.props;
    return (
      <ViewShipmentsSalesOrderComp warehouses={warehousesData(warehouses)} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(
  ViewShipmentsSalesOrder
);
