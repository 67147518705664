import React from "react";
import ExportBtn from "@components/common/exportButton";
import SuppplierTable from "./supppliersTable";
import Select from "react-select";

export default function ViewSuppliers({
  goto,
  suppliers,
  onClickAction,
  onSupplierSelect,
  gotoSupplier,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Suppliers</label>
          </div>
          <div className="col-lg-3 col-1"></div>
          <div
            className="col-lg-5 col-md-5 col-3"
            style={{ textAlign: "right" }}
          >
            <button className="btn btn-light btn-sm">Import</button>
            <ExportBtn />
            <button
              onClick={() => goto("/suppliers/add suppliers")}
              className="btn btn-success btn-sm"
            >
              Add Supplier
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="supplier">
                Supplier
              </label>
              <input className="form-control" id="supplier" />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="obsolete">
                Obsolete
              </label>
              <Select
                isClearable
                name="Obsolete"
                id="obsolete"
                options={[
                  { label: "All", value: "All" },
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <SuppplierTable
            gotoSupplier={(supplier) => gotoSupplier(supplier)}
            suppliers={suppliers}
            onClickAction={(action) => onClickAction(action)}
            onSupplierSelect={(supplier) => onSupplierSelect(supplier)}
          />
        </div>
      </div>
    </>
  );
}
