import React from "react";
import InventoryTable from "./inventoryTable";
import Info from "@components/common/info";

export default function Inventory({ warehouses }) {
  return (
    <div id="Inventory" className="tab-pane fade">
      {/* <div className="panel panel-default">
				<div className="panel-body"> */}
      <Info
        heading="Per Warehouse Controls"
        message="Your bin locations and stock level alerts are currently set to Per Warehouse Controls. If you
					would like to use Global Warehouse Controls, please adjust this in your"
        link="Company Settings"
        path="/settings/organization/company"
      />
      <br />
      <InventoryTable warehouses={warehouses} />
    </div>
    // 	</div>
    // </div>
  );
}
