import React from "react";
import data from "../../../../data";

export default function Pricing({ selectedPlan, onChangePlan }) {
  return (
    <section id="bs-pricing-five" className="bs-pricing-five bg-white">
      <div className="bs-five-area bs-radius">
        <div className="row">
          {data.billingPlans.map((plan, index) => (
            <div
              className="col-md-3 no-padding"
              key={plan.name}
              onClick={() => onChangePlan(index)}
            >
              <div
                className={`bs-five ${
                  Number(index) === Number(selectedPlan) ? "active" : ""
                }`}
              >
                <h6 className="text-uppercase">{plan.name}</h6>
                <h1 className="bs-caption">
                  <sup>$</sup>
                  {plan.perMonth}
                </h1>
                <p>per month paid annually</p>
                <b className="saving">(save ${plan.saved} per month)</b>
                <ul>
                  <li>
                    <span>{plan.users}</span> users included
                  </li>
                </ul>
                <a href="#" className="btn btn-success btn-round m-top-40">
                  Buy Now
                </a>

                <div className="pricing-footer">
                  <b>OR</b>
                  <br />
                  <a>${plan.payMonthly} paid monthly</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
