import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loaderState,
  getProductGroups,
  getUnits,
  getAttributeSets,
  addProduct,
  updateProduct,
  getWarehouses,
  selectedNav,
  getSuppliers,
  getProductSupplier,
  addProductSupplier,
  deleteProductSupplier,
} from "@actions";
import {
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  removeNullValues,
  goto,
} from "@helpers";
import _ from "lodash";
import AddProductComp from "@components/inventory/addProduct";
import swal from "sweetalert";
import taxes from "../../settings/system/taxes";

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        "Details",
        "Inventory",
        "Purchase",
        "Sale",
        "Trasactions",
        "References",
        "Costs",
        "Attachments",
      ],
      productCode: null,
      productDescription: null,
      hideOrShow: false,
    };
  }

  componentDidMount() {
    document.title = "Add Product";
    const { units, productGroups, attributes, warehouses } = this.props;
    let id = this.props.match.params.id;
    const { products, suppliers } = this.props;
    if (id) {
      let selectedProduct = _.findLast(products, (product) => {
        return Number(product.id) === Number(id);
      });
      this.setState({ ...selectedProduct });
      this.props.getProductSupplier(id);
    }
    this.props.loaderState(true);
    if (!suppliers) {
      this.props.getSuppliers();
    }
    this.props.getUnits();
    this.props.getProductGroups();
    this.props.getAttributeSets();
    this.props.getWarehouses();

    initilizeDataTable("inventory-table");
    initilizeDataTable("purchases-table");
    initilizeDataTable("sales-table");
    initilizeDataTable("transaction-table");
    initilizeDataTable("references-table");
    initilizeDataTable("cost-table");
    initilizeDataTable("attachments-table");
    initilizeDataTable("quantity-prices-table");
  }

  componentDidUpdate() {
    initilizeDataTable("inventory-table");
    initilizeDataTable("purchases-table");
    initilizeDataTable("sales-table");
    initilizeDataTable("transaction-table");
    initilizeDataTable("references-table");
    initilizeDataTable("cost-table");
    initilizeDataTable("attachments-table");
    initilizeDataTable("quantity-prices-table");
  }

  onFileDivClick = () => {
    document.getElementById("inputFile").click();
  };

  onClickHideOrView = () => {
    this.setState({
      hideOrShow: !this.state.hideOrShow,
    });
  };

  onChange = ({ target }) => {
    const { id, type } = target;
    if (type === "checkbox") {
      this.setState({ [id]: target.checked ? 1 : -1 });
    } else {
      this.setState({ [id]: target.value });
    }
  };

  onSaveClick = () => {
    const { user } = this.props;
    const { productCode, productDescription } = this.state;
    if (this.props.match.params.id) {
      this.onUpdateProduct();
    } else {
      if (isEmptyOrNull(productCode) && isEmptyOrNull(productDescription)) {
        swal(
          "",
          "Product Code and Product Description are required fields",
          "info"
        );
      } else {
        let state = { ...this.state };
        delete state.nav;
        delete state.hideOrShow;
        let data = removeNullValues(state);
        data.createdBy = user.id;
        data.updatedBy = user.id;
        this.props.loaderState(true);
        this.props.addProduct(data);
      }
    }
  };

  onUpdateProduct = () => {
    let stateData = { ...this.state };
    if (
      isEmptyOrNull(stateData.productCode) &&
      isEmptyOrNull(stateData.productDescription)
    ) {
      swal(
        "",
        "Product Code and Product Description are required fields",
        "info"
      );
    } else {
      let data = removeNullValues(stateData);
      delete data.hideOrShow;
      delete data.nav;
      delete data.unit_name;
      delete data.groupName;
      delete data.attributeSetName;
      this.props.loaderState(true);
      this.props.updateProduct(data);
    }
  };

  unitsData = () => {
    const { units } = this.props;
    let data = [];
    _.forEach(units, (unit) => {
      data.push({
        value: unit.id,
        label: unit.unit_name,
      });
    });

    return data;
  };

  productGroupsData = () => {
    const { productGroups } = this.props;
    let data = [];
    _.forEach(productGroups, (group) => {
      data.push({ value: group.id, label: group.groupName });
    });

    return data;
  };

  attributeSetsData = () => {
    const { attributes } = this.props;
    let data = [];
    _.forEach(attributes, (attr) => {
      data.push({
        value: attr.id,
        label: attr.attributeSetName,
      });
    });

    return data;
  };

  handleChange = (newValue, actionMeta) => {
    const { name } = actionMeta;
    if (newValue) {
      if (name === "supplierid") {
        let val = newValue.value;
        let supplierid = val.substring(
          val.lastIndexOf("(") + 1,
          val.lastIndexOf(")")
        );
        let supplierName = val.substring(0, val.lastIndexOf("("));
        this.setState({
          supplierName,
          supplierid,
          supplierCode: newValue.label,
        });
      } else {
        this.setState({ [name]: newValue.value });
      }
    } else {
      if (name === "supplierid") {
        this.setState({ supplierName: "", supplierCode: "" });
      }
      this.setState({ [name]: newValue });
    }
  };

  goto = (path) => {
    this.props.selectedNav(path);
    goto(path);
  };

  prepareSupplierData = (suppliers) => {
    let data = [];
    _.forEach(suppliers, (supplier) => {
      data.push({
        label: supplier.supplierData.supplierCode,
        value: `${supplier.supplierData.supplierName}(${supplier.supplierData.id})`,
      });
    });

    return data;
  };

  preparePurchaseTaxData = (taxes) => {
    let data = [];
    _.forEach(taxes, (tax) => {
      if (tax.purchaseTax === 1) {
        data.push({
          label: tax.taxName,
          // value: tax.id,
          value: tax.taxName,
        });
      }
    });

    return data;
  };

  onSaveProductSupplier = () => {
    let productid = this.props.match.params.id;
    const { user } = this.props;
    const {
      supplierid,
      supplierproductcode,
      supplierproductdescription,
      purchaseprice,
      minqty,
    } = this.state;

    if (
      isEmptyOrNull(supplierid) ||
      isEmptyOrNull(supplierproductcode) ||
      isEmptyOrNull(supplierproductdescription) ||
      isEmptyOrNull(purchaseprice) ||
      isEmptyOrNull(minqty)
    ) {
      swal("", "Please fill all the fields", "info");
    } else {
      this.setState({
        supplierCode: null,
        supplierName: null,
        supplierid: null,
        supplierproductcode: "",
        supplierproductdescription: "",
        purchaseprice: "",
        minqty: "",
      });
      this.props.loaderState(true);
      this.props.addProductSupplier({
        productid,
        supplierid,
        supplierproductcode,
        supplierproductdescription,
        purchaseprice,
        minqty,
        createdBy: user.id,
        updatedBy: user.id,
      });
    }
  };

  onDeleteProductSupplier = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want delete this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.loaderState(true);
        this.props.deleteProductSupplier(id);
      }
    });
  };

  getSelectedUnit = (id) => {
    const { units } = this.props;
    let data = {};
    _.forEach(units, (unit) => {
      if (unit.id === id) {
        data.value = unit.id;
        data.label = unit.unit_name;
      }
    });

    return data;
  };

  getSelectedGroup = (id) => {
    const { productGroups } = this.props;
    let data = {};
    _.forEach(productGroups, (group) => {
      if (group.id === id) {
        data.value = group.id;
        data.label = group.groupName;
      }
    });

    return data;
  };

  getSelectedAttribute = (id) => {
    const { attributes } = this.props;
    let data = {};
    _.forEach(attributes, (attr) => {
      if (attr.id === id) {
        data.value = attr.id;
        data.label = attr.attributeSetName;
      }
    });

    return data;
  };

  render() {
    destroyDataTable("inventory-table");
    destroyDataTable("purchases-table");
    destroyDataTable("sales-table");
    destroyDataTable("transaction-table");
    destroyDataTable("references-table");
    destroyDataTable("cost-table");
    destroyDataTable("attachments-table");
    destroyDataTable("quantity-prices-table");

    const { nav, hideOrShow } = this.state;

    let units = this.unitsData();
    let productGroups = this.productGroupsData();
    let attributes = this.attributeSetsData();

    const { warehouses, suppliers, taxes, productSuppliers } = this.props;

    let suppliersData =
      suppliers && suppliers.length > 0
        ? this.prepareSupplierData(suppliers)
        : [];

    let purchaseTaxesData =
      taxes && taxes.length > 0 ? this.preparePurchaseTaxData(taxes) : [];

    return (
      <div>
        <AddProductComp
          handleChange={(v, m) => this.handleChange(v, m)}
          units={units}
          productGroups={productGroups}
          attributes={attributes}
          onChange={(e) => this.onChange(e)}
          onSaveClick={() => this.onSaveClick()}
          goto={(path) => this.goto(path)}
          propsData={this.props}
          nav={nav}
          stateData={this.state}
          warehouses={warehouses}
          hideOrShow={hideOrShow}
          selectedUnit={(name) => this.getSelectedUnit(name)}
          selectedGroup={(name) => this.getSelectedGroup(name)}
          selectedAttribute={(name) => this.getSelectedAttribute(name)}
          onFileDivClick={() => this.onFileDivClick()}
          onClickHideOrView={() => this.onClickHideOrView()}
          edit={this.props.match.params.id ? true : false}
          suppliers={suppliersData}
          onSaveProductSupplier={() => this.onSaveProductSupplier()}
          purchaseTaxes={purchaseTaxesData}
          productSuppliers={productSuppliers}
          onDeleteProductSupplier={(id) => this.onDeleteProductSupplier(id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.loggedInUser,
    units: state.system.units.allUnits,
    productGroups: state.system.productGroups.allGroups,
    attributes: state.system.attributeSets.allAttributeSets,
    organization: state.auth.organizationInfo,
    products: state.inventory.products.allProducts,
    warehouses: state.system.warehouses.allWarehouses,
    suppliers: state.suppliers.allSuppliers,
    taxes: state.system.taxes.allTaxes,
    productSuppliers: state.inventory.products.productSuppliers,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getAttributeSets,
  getProductGroups,
  getUnits,
  addProduct,
  updateProduct,
  getWarehouses,
  selectedNav,
  getSuppliers,
  getProductSupplier,
  addProductSupplier,
  deleteProductSupplier,
})(AddProduct);
