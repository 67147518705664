import { settings } from "@constants/actionTypes";
const initialState = {
  allSalesPerson: null,
  selectedSalesPerson: null,
};

function salesPersonReducer(state = initialState, action) {
  switch (action.type) {
    case settings.SALES_PERSON:
      return { ...state, allSalesPerson: action.payload };
    case settings.SELECTED_SALES_PERSON:
      return { ...state, selectedSalesPerson: action.payload };
    default:
      return state;
  }
}
export default salesPersonReducer;
