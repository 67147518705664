import { customers } from "@constants/actionTypes";
const initialState = {
  allCustomers: null,
  selectedCustomer: null,
  codeChanges: null,
  postalAddress: null,
  physicalAddress: null,
  deliveryAddresses: null,
  selectedDeliveryAddress: null,
  contacts: null,
};

function customersReducer(state = initialState, action) {
  switch (action.type) {
    case customers.CUSTOMERS:
      return { ...state, allCustomers: action.payload };
    case customers.SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: action.payload };
    case customers.CUSTOMERS_CODE_CHANGES:
      return { ...state, codeChanges: action.payload };
    case customers.CUSTOMERS_POSTAL_ADDRESS:
      return { ...state, postalAddress: action.payload };
    case customers.CUSTOMERS_PHYSICAL_ADDRESS:
      return { ...state, physicalAddress: action.payload };
    case customers.DELIVERY_ADDRESSES:
      return { ...state, deliveryAddresses: action.payload };
    case customers.SELECTED_DELIVERY_ADDRESS:
      return { ...state, selectedDeliveryAddress: action.payload };
    case customers.CUSTOMER_CONTACTS:
      return { ...state, contacts: action.payload };
    default:
      return state;
  }
}
export default customersReducer;
