import api from "@api/api";
import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getProductGroups = () => (dispatch) => {
  api
    .get("/productGroup")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.PRODUCT_GROUPS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addProductGroup = (data) => (dispatch, getState) => {
  var allGroups = getState().system.productGroups.allGroups;
  const { organizationInfo } = getState().auth;
  // organizationId: organizationInfo.id
  api
    .post("/productGroup", { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.PRODUCT_GROUPS,
          payload: [...allGroups, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteProductGroup = (id) => (dispatch, getState) => {
  const { allGroups } = getState().system.productGroups;
  api
    .delete(`/productGroup/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allGroups, (group) => {
          return Number(id) === Number(group.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateProductGroup = ({
  groupName,
  attributeSetId,
  id,
  updatedBy,
}) => (dispatch, getState) => {
  const { allGroups } = getState().system.productGroups;
  api
    .post(`/productGroup/update/${id}`, {
      groupName,
      attributeSetId,
      updatedBy,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedGroup = _.findLast(allGroups, (group) => {
          return Number(group.id) === Number(id);
        });
        if (groupName) {
          updatedGroup.groupName = groupName;
        }
        if (attributeSetId) {
          updatedGroup.attributeSetId = attributeSetId;
        }
        let updatedGroups = _.map(allGroups, (group) => {
          return group.id === id ? updatedGroup : group;
        });

        dispatch({ type: settings.PRODUCT_GROUPS, payload: updatedGroups });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectProductGroup = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_PRODUCT_GROUP, payload: data });
};
