import React, { Component } from "react";
import LabelsComp from "@components/setting/docDesigner/labels/index";

export default class Labels extends Component {
  state = {
    templates: [
      { label: "Default Label", value: "Default Label" },
      { label: "Customer Label", value: "Customer Label" },
    ],
  };
  render() {
    const { templates } = this.state;
    return <LabelsComp templates={templates} name="Labels" />;
  }
}
