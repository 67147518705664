import history from "../history";
import _ from "lodash";

export const paginationData = (dataSent, activePage, perPage) => {
  let actPage = activePage - 1;
  let pageStart = 0,
    pageEnd = perPage;
  if (activePage === 1) {
    pageStart = 0;
    pageEnd = perPage;
  } else {
    pageStart = actPage * perPage;
    pageEnd = pageStart + perPage;
  }

  let newData;
  newData = dataSent.slice(pageStart, pageEnd);

  return newData;
};

export const isEmptyOrNull = (data) => {
  let valid = false;
  if (!data || data === "") {
    valid = true;
  }
  return valid;
};

export const goto = (path) => {
  history.push(path);
  history.go(path);
};

export const removeNullValues = (object) => {
  let newObj = {};
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (!isEmptyOrNull(object[key])) {
        newObj[key] = object[key];
      }
    }
  }
  return newObj;
};

export const initilizeDataTable = (id) => {
  window.$(`#${id}`).DataTable({
    ordering: true,
    select: true,
    searching: true,
    pagingType: "full_numbers",
    language: {
      paginate: {
        next: "&rsaquo;",
        previous: "&lsaquo;",
        first: "&laquo;",
        last: "&raquo;",
      },
    },
    sDom:
      '<"row view-filter"<"col-sm-12"<"pull-left"l><"pull-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
  });
};

export const destroyDataTable = (id) => {
  window.$(`#${id}`).DataTable().destroy();
};

export const warehousesData = (warehouses) => {
  let data = [];
  _.forEach(warehouses, (warehouse) => {
    data.push({
      label: warehouse.warehouseName,
      value: warehouse.id,
    });
  });

  return data;
};

export const suppliersNameData = (suppliers) => {
  let data = [];
  _.forEach(suppliers, (supplier) => {
    data.push({
      label: supplier.supplierData.supplierName,
      value: supplier.supplierData.supplierName,
    });
  });

  return data;
};

export const getSupplierByCode = (suppliers, code) => {
  // console.log(suppliers);
  // console.log(code);
  //x.supplierCode === code
  let data = suppliers.filter((x) => x.supplierData.supplierCode === code);
  return data[0];

  // let data = [];
  // _.forEach(suppliers, (supplier) => {
  // 	data.push({
  // 		label: supplier.supplierData.supplierName,
  // 		value: supplier.supplierData.supplierName,
  // 	});
  // });

  //return data;
};

export const getSupplierByName = (suppliers, name) => {
  console.log(name);
  //console.log(code);
  //x.supplierCode === code
  let data = suppliers.filter((x) => x.supplierData.supplierName === name);

  return data[0];
  // let data = [];
  // _.forEach(suppliers, (supplier) => {
  // 	data.push({
  // 		label: supplier.supplierData.supplierName,
  // 		value: supplier.supplierData.supplierName,
  // 	});
  // });

  //return data;
};
export const supplierCodeData = (suppliers) => {
  let data = [];
  _.forEach(suppliers, (supplier) => {
    data.push({
      label: supplier.supplierData.supplierCode,
      value: supplier.supplierData.supplierCode,
    });
  });
  return data;
};

export const currenciesData = (countriesData) => {
  let data = [];
  _.forEach(countriesData, (country) => {
    data.push({
      label: `(${country.currencies[0].code}) ${country.currencies[0].name}`,
      value: `(${country.currencies[0].code}) ${country.currencies[0].name}`,
    });
  });

  return data;
};

export const countriesData = (cData) => {
  let data = [];
  _.forEach(cData, (country) => {
    data.push({
      label: country.name,
      value: country.name,
    });
  });

  return data;
};

export const industryDropdownData = () => {
  let data = [
    {
      value: "Agriculture, Forestry, Fishing",
      label: "Agriculture, Forestry, Fishing",
    },
    { value: "Animal Products", label: "Animal Products" },
    { value: "Audio and Electronics", label: "Audio and Electronics" },
    {
      value: "Automotive and Automotive Supplies",
      label: "Automotive and Automotive Supplies",
    },
    {
      value: "Beverages (Alcoholic and Non-alcoholic)",
      label: "Beverages (Alcoholic and Non-alcoholic)",
    },
    { value: "Building and Construction", label: "Building and Construction" },
    { value: "Commercial Services", label: "Commercial Services" },
    { value: "Education", label: "Education" },
    {
      value: "Electrical and Electronic Components",
      label: "Electrical and Electronic Components",
    },
    {
      value: "Energy, Chemicals and Petroleum",
      label: "Energy, Chemicals and Petroleum",
    },
    {
      value: "Entertainment and Recreation",
      label: "Entertainment and Recreation",
    },
    {
      value: "Fashion, Cosmetics and Accessories",
      label: "Fashion, Cosmetics and Accessories",
    },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Food", label: "Food" },
    { value: "Furniture and Fixtures", label: "Furniture and Fixtures" },
    { value: "Health and Wellbeing", label: "Health and Wellbeing" },
    {
      value: "Hotel, Restaurants and Bars",
      label: "Hotel, Restaurants and Bars",
    },
    {
      value: "Industrial Equipment and Machinery",
      label: "Industrial Equipment and Machinery",
    },
    { value: "IT Products and Services", label: "IT Products and Services" },
    { value: "Jewellery", label: "Jewellery" },
    {
      value: "Medical Supplies and Equipment",
      label: "Medical Supplies and Equipment",
    },
    { value: "Metals and Fabrication", label: "Metals and Fabrication" },
    { value: "Mining", label: "Mining" },
    {
      value: "Office Equipment and Supplies",
      label: "Office Equipment and Supplies",
    },
    { value: "Other", label: "Other" },
    { value: "Pharmaceutical", label: "Pharmaceutical" },
    {
      value: "Plastic and Rubber Products",
      label: "Plastic and Rubber Products",
    },
    { value: "Printing and Publishing", label: "Printing and Publishing" },
    { value: "Sport and Fitness", label: "Sport and Fitness" },
    { value: "Textiles", label: "Textiles" },
    {
      value: "Tobacco and other Horticulture Products",
      label: "Tobacco and other Horticulture Products",
    },
    {
      value: "Transportation / Logistics",
      label: "Transportation / Logistics",
    },
  ];

  return data;
};

export const organizationTypes = () => {
  let data = [
    { value: "Company", label: "Company" },
    { value: "Person", label: "Person" },
    { value: "Partnership", label: "Partnership" },
    { value: "SoleTrader", label: "SoleTrader" },
    { value: "Trust", label: "Trust" },
    { value: "Charity", label: "Charity" },
    { value: "Club", label: "Club" },
    { value: "Society", label: "Society" },
  ];

  return data;
};

export const prepareUserData = (data) => {
  let user = {
    id: data.userId,
    email: data.email,
    isActive: data.isActive,
    last_login: data.last_login,
    first_name: data.first_name,
    last_name: data.last_name,
    subscriptionName: data.subscriptionName,
    subscriptionId: data.subscriptionId,
  };
  let postal = {
    addressName: data.postalAddressName,
    addressLine1: data.postalAddressLine1,
    addressLine2: data.postalAddressLine2,
    suburb: data.postalSuburb,
    city: data.postalCity,
    state_Region: data.postalState_Region,
    country: data.postalCountry,
    postalcode: data.postalPostalcode,
    type: data.postalType,
    id: data.postalAddressId,
  };
  let physical = {
    addressName: data.physicalAddressName,
    addressLine1: data.physicalAddressLine1,
    addressLine2: data.physicalAddressLine2,
    suburb: data.physicalSuburb,
    city: data.physicalCity,
    state_Region: data.physicalState_Region,
    country: data.physicalCountry,
    postalcode: data.physicalPostalcode,
    type: data.physicalType,
    id: data.physicalAddressId,
  };
  let organization = {
    companyName: data.companyName,
    tradingName: data.tradingName,
    tradingNameForPurchasing: data.tradingNameForPurchasing,
    industry: data.industry,
    organisationType: data.organisationType,
    baseCurrency: data.baseCurrency,
    dashboardDataSource: data.dashboardDataSource,
    gstVatNumber: data.gstVatNumber,
    website: data.website,
    timezone: data.timezone,
    financialYearEnd: data.financialYearEnd,
    measurementUnits: data.measurementUnits,
    dateFormat: data.dateFormat,
    multiWarehouse: data.multiWarehouse,
    perWarehouseControls: data.perWarehouseControls,
    Production: data.Production,
    autoAssembly: data.autoAssembly,
    orderManagement: data.orderManagement,
    autoAllocation: data.autoAllocation,
    markupPriceUpdate: data.markupPriceUpdate,
    salesQuotes: data.salesQuotes,
    useShipmentQuantitiesForSales: data.useShipmentQuantitiesForSales,
    serializedProducts: data.serializedProducts,
    batchTracking: data.batchTracking,
    autoSetReceiptQuantity: data.autoSetReceiptQuantity,
    requireApprovalForPurchaseOrders: data.requireApprovalForPurchaseOrders,
    telephone: data.telephone,
    fax: data.fax,
    mobile: data.mobile,
    DDI: data.DDI,
    tollFree: data.tollFree,
    purchaseEmail: data.purchaseEmail,
    purchaseEmailComment: data.purchaseEmailComment,
    salesEmail: data.salesEmail,
    salesEmailComment: data.salesEmailComment,
    invoiceFooterDetails: data.invoiceFooterDetails,
    purchaseOrderReportPrintName: data.purchaseOrderReportPrintName,
    purchaseEnable: data.purchaseEnable,
    id: data.organizationId,
  };

  let orgData = { user, postal, organization, physical };
  return orgData;
};

export const prepareSupplierData = (data) => {
  let supplierData = {
    id: data.id,
    supplierCode: data.supplierCode,
    supplierName: data.supplierName,
    gstVatNumber: data.gstVatNumber,
    notes: data.notes,
    texable: data.texable,
    taxId: data.taxId,
    taxName: data.taxName,
    bankName: data.bankName,
    bankAccount: data.bankAccount,
    purchaseOrderPrintTemplate: data.purchaseOrderPrintTemplate,
    supplierReturnPrintTemplate: data.supplierReturnPrintTemplate,
    deliveryLeadTime: data.deliveryLeadTime,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    officePhone: data.officePhone,
    website: data.website,
    phoneNumber: data.phoneNumber,
    faxNumber: data.faxNumber,
    mobileNumber: data.mobileNumber,
    DDINumber: data.DDINumber,
    tollFreeNumber: data.tollFreeNumber,
    bankBranch: data.bankBranch,
    currencyRateId: data.currencyRatesId,
    currencyCode: data.currencyCode,
    paymentTermsId: data.paymentTermsId,
    paymentTerm: data.paymentTermsName,
    createdBy: data.createdBy,
    updatedBy: data.updatedBy,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };
  let postal = {
    addressName: data.postalAddressName,
    addressLine1: data.postalAddressLine1,
    addressLine2: data.postalAddressLine2,
    suburb: data.postalSuburb,
    city: data.postalCity,
    state_Region: data.postalState_Region,
    country: data.postalCountry,
    postalCode: data.postalPostalcode,
    type: data.postalType,
    id: data.postalAddressId,
  };
  let physical = {
    addressName: data.physicalAddressName,
    addressLine1: data.physicalAddressLine1,
    addressLine2: data.physicalAddressLine2,
    suburb: data.physicalSuburb,
    city: data.physicalCity,
    state_Region: data.physicalState_Region,
    country: data.physicalCountry,
    postalCode: data.physicalPostalCode,
    type: data.physicalType,
    id: data.physicalAddressId,
  };

  let dataToSend = { supplierData, postal, physical };

  return dataToSend;
};

export const prepareCustomerData = (data) => {
  let customerData = {
    id: data.id,
    customerCode: data.customerCode,
    customerName: data.customerName,
    GST_VAT_Number: data.GST_VAT_Number,
    notes: data.notes,
    taxable: data.taxable,
    obsolete: data.obsolete,
    bankAccountName: data.bankAccountName,
    bankAccountNumber: data.bankAccountNumber,
    discount: data.discount,
    reminder: data.reminder,
    invoiceTemplate: data.invoiceTemplate,
    orderTemplate: data.orderTemplate,
    quotesTemplate: data.quotesTemplate,
    packingSlipTemplate: data.packingSlipTemplate,
    createdBy: data.createdBy,
    updatedBy: data.updatedBy,
    printQuoteOnSalesQuote: data.printQuoteOnSalesQuote,
    printOrderOnSalesOrder: data.printOrderOnSalesOrder,
    printPackingSlip: data.printPackingSlip,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    organizationId: data.organizationId,
    customerTypeId: data.customerTypeId,
    customerTypeName: data.typeName,
    currencyRateId: data.currencyRatesId,
    currencyCode: data.currencyCode,
    salesPersonId: data.salesPersonId,
    salesPersonName: data.salesPersonName,
    salesGroupId: data.salesGroupId,
    salesGroupName: data.groupName,
    warehouseId: data.warehouseId,
    warehouseName: data.warehouseName,
    deliveryMethodId: data.deliveryMethodId,
    deliveryMethodName: data.methodName,
    taxId: data.taxId,
    taxName: data.taxName,
    paymentTermId: data.paymentTermId,
    paymentTermName: data.paymentTermsName,
    sellPriceTierId: data.sellPriceTierId,
    sellPriceTierName: data.sellPriceTiersName,
    deliveryAddressId: data.deliveryAddressId,
    contactId: data.contactId,
    website: data.website,
    email: data.email,
    phoneNumber: data.phoneNumber,
    mobileNumber: data.mobileNumber,
  };
  let postal = {
    addressName: data.postalAddressName,
    addressLine1: data.postalAddressLine1,
    addressLine2: data.postalAddressLine2,
    suburb: data.postalSuburb,
    city: data.postalCity,
    state_Region: data.postalState_Region,
    country: data.postalCountry,
    postalCode: data.postalPostalcode,
    type: data.postalType,
    id: data.postalAddressId,
  };
  let physical = {
    addressName: data.physicalAddressName,
    addressLine1: data.physicalAddressLine1,
    addressLine2: data.physicalAddressLine2,
    suburb: data.physicalSuburb,
    city: data.physicalCity,
    state_Region: data.physicalState_Region,
    country: data.physicalCountry,
    postalCode: data.physicalPostalCode,
    type: data.physicalType,
    id: data.physicalAddressId,
  };

  let dataToSend = { customerData, postal, physical };

  return dataToSend;
};

export const prepareSearchData = (data, code, name) => {
  let contents = [];
  _.forEach(data, (d) => {
    if (code === "supplierCode") {
      contents.push({
        code: d.supplierData[code],
        name: d.supplierData[name],
      });
    } else if (code === "customerCode") {
      contents.push({
        code: d.customerData[code],
        name: d.customerData[name],
      });
    } else {
      contents.push({
        code: d[code],
        name: d[name],
      });
    }
  });

  return contents;
};

export const prepareSearchAbleDropdownData = (data, label, value, third) => {
  let dataToReturn = [];
  _.forEach(data, (d) => {
    if (third) {
      dataToReturn.push({
        label: d[third] + " " + d[label],
        value: d[value],
      });
    } else {
      dataToReturn.push({
        label: d[label],
        value: d[value],
      });
    }
  });

  return dataToReturn;
};
// export const fireOnChange = id => {
// 	if ($(`#${id}`).is(':checked')) {
// 		$(`#${id}`).attr('checked', false);
// 	} else {
// 		$(`#${id}`).attr('checked', true);
// 	}
// 	$(`#${id}`).trigger('change');
// };
