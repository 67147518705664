import React, { Component } from "react";
import AddsupplierComp from "@components/suppliers/Add";
import {
  goto,
  initilizeDataTable,
  destroyDataTable,
  isEmptyOrNull,
  removeNullValues,
  prepareSearchAbleDropdownData,
  countriesData,
} from "@helpers";
import { connect } from "react-redux";
import {
  loaderState,
  addSupplier,
  updateSupplier,
  getPaymentTerms,
  getCurrencyRates,
  getTaxes,
  getCountriesData,
} from "@actions";
import swal from "sweetalert";
import _ from "lodash";

class Addsupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        "Details",
        "Address",
        "Contact",
        "Purchases",
        "Returns",
        "Costings",
      ],
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id) {
      const { suppliers } = this.props;
      let selectedSupplier = _.findLast(suppliers, (supplier) => {
        return Number(supplier.supplierData.id) === Number(id);
      });
      this.setState({ ...selectedSupplier });
    }
    const { countries } = this.props;
    if (!countries) {
      this.props.getCountriesData();
    }
    this.props.loaderState(true);
    this.props.getPaymentTerms();
    this.props.getCurrencyRates();
    this.props.getTaxes();

    initilizeDataTable("purchases-table");
    initilizeDataTable("returns-table");
    initilizeDataTable("costings-table");
  }

  componentDidUpdate() {
    initilizeDataTable("purchases-table");
    initilizeDataTable("returns-table");
    initilizeDataTable("costings-table");
  }

  handleChange = (newValue, actionMeta, obj) => {
    const { name } = actionMeta;
    if (obj === "postal") {
      let postal = { ...this.state.postal };
      if (newValue) {
        postal[name] = newValue.value;
        this.setState({ postal });
      } else {
        postal[name] = newValue;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      let physical = { ...this.state.physical };
      if (newValue) {
        physical[name] = newValue.value;
        this.setState({ physical });
      } else {
        physical[name] = newValue;
        this.setState({ physical });
      }
    } else {
      let supplierData = { ...this.state.supplierData };
      if (newValue) {
        supplierData[name] = newValue.value;
        this.setState({ supplierData });
      } else {
        supplierData[name] = newValue;
        this.setState({ supplierData });
      }
    }
  };

  onChange = (e, obj) => {
    const { id, type } = e.target;
    if (obj === "postal") {
      var postal = { ...this.state.postal };
      if (type === "checkbox") {
        if (e.target.checked) {
          postal[id] = 1;
        } else {
          postal[id] = -1;
        }
        this.setState({ postal });
      } else {
        postal[id] = e.target.value;
        this.setState({ postal });
      }
    } else if (obj === "physical") {
      var physical = { ...this.state.physical };
      if (type === "checkbox") {
        if (e.target.checked) {
          physical[id] = 1;
        } else {
          physical[id] = -1;
        }
        this.setState({ physical });
      } else {
        physical[id] = e.target.value;
        this.setState({ physical });
      }
    } else {
      var supplierData = { ...this.state.supplierData };
      if (type === "checkbox") {
        if (e.target.checked) {
          supplierData[id] = 1;
        } else {
          supplierData[id] = -1;
        }
        this.setState({ supplierData });
      } else {
        supplierData[id] = e.target.value;
        this.setState({ supplierData });
      }
    }
  };

  onClickSave = () => {
    let state = { ...this.state };
    delete state.nav;
    if (this.props.match.params.id) {
      this.onUpdateSupplier();
    } else {
      let supplierData = removeNullValues(state.supplierData);
      let physical = removeNullValues(state.physical);
      let postal = removeNullValues(state.postal);
      if (
        supplierData.supplierCode &&
        !isEmptyOrNull(supplierData.supplierCode) &&
        supplierData.supplierName &&
        !isEmptyOrNull(supplierData.supplierName)
      ) {
        this.props.loaderState(true);
        this.props.addSupplier(supplierData, postal, physical);
      } else {
        swal("", "Supplier Code and Supplier Name are required fields!");
      }
    }
  };

  onUpdateSupplier = () => {
    let state = { ...this.state };
    delete state.nav;

    if (
      isEmptyOrNull(state.supplierData.supplierCode) &&
      isEmptyOrNull(state.supplierData.supplierName)
    ) {
      swal("", "Supplier Code and Supplier Name are required fields", "info");
    } else {
      let supplierData = removeNullValues(state.supplierData);
      delete supplierData.taxName;
      delete supplierData.currencyCode;
      delete supplierData.paymentTerm;
      let physical = removeNullValues(state.physical);
      let postal = removeNullValues(state.postal);
      this.props.loaderState(true);
      this.props.updateSupplier(supplierData, postal, physical);
    }
  };

  getSelectedValue = (options, value) => {
    let data = {};
    _.forEach(options, (option) => {
      if (option.value === value) {
        data.value = option.value;
        data.label = option.label;
      }
    });

    return data;
  };

  render() {
    destroyDataTable("purchases-table");
    destroyDataTable("returns-table");
    destroyDataTable("costings-table");

    let stateData = { ...this.state };
    const { paymentTerms, currencyRates, taxes, countries } = this.props;
    let paymentTermsData = prepareSearchAbleDropdownData(
      paymentTerms,
      "type",
      "id",
      "days"
    );
    let currencyRatesData = prepareSearchAbleDropdownData(
      currencyRates,
      "currencyCode",
      "id"
    );
    let taxesData = prepareSearchAbleDropdownData(taxes, "taxName", "id");

    let cData = countries ? countriesData(countries) : [];
    return (
      <AddsupplierComp
        edit={this.props.match.params.id ? true : false}
        stateData={stateData}
        paymentTerms={paymentTermsData}
        currencyRates={currencyRatesData}
        taxes={taxesData}
        getSelectedValue={(options, value) =>
          this.getSelectedValue(options, value)
        }
        onChange={(e, obj) => this.onChange(e, obj)}
        handleChange={(newValue, actionMeta, obj) =>
          this.handleChange(newValue, actionMeta, obj)
        }
        onClickSave={() => this.onClickSave()}
        goto={(path) => goto(path)}
        countries={cData}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.suppliers.allSuppliers,
    paymentTerms: state.system.paymentTerms.allTerms,
    currencyRates: state.system.currencyRates.allCurrencies,
    taxes: state.system.taxes.allTaxes,
    countries: state.common.countriesData,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  addSupplier,
  updateSupplier,
  getPaymentTerms,
  getCurrencyRates,
  getTaxes,
  getCountriesData,
})(Addsupplier);
