import React from "react";
import SplitButton from "@components/common/splitButton";
import SalesOrderTable from "./salesOrderTable";
import Select from "react-select";

export default function ViewSalesOrders({ warehouses, goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Sales Orders</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <button className="btn btn-light btn-sm">Import</button>
            <SplitButton
              label="Order"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button
              onClick={() => goto("/sales/orders/add sales orders")}
              className="btn btn-success btn-sm"
            >
              Add Order
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Open", value: "Open" },
                  { label: "Parked", value: "Parked" },
                  { label: "Placed", value: "Placed" },
                  { label: "Backorder", value: "Backorder" },
                  { label: "Cancelled", value: "Cancelled" },
                  { label: "Deleted", value: "Deleted" },
                ]}
              />
              {/* <select className="form-control" id="status">
								<option>All</option>
								<option>Open</option>
								<option>Parked</option>
								<option>Placed</option>
								<option>Backorder</option>
								<option>Completed</option>
								<option>Deleted</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="order_number">
                Order Number
              </label>
              <input
                type="text"
                className="form-control"
                id="order_number"
                name="order_number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="customer">
                Customer
              </label>
              <input
                type="text"
                className="form-control"
                id="customer"
                name="customer"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="warehouse">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="order-date-from">
                Order Date From
              </label>
              <input
                type="date"
                className="form-control"
                id="order-date-from"
                name="order-date-from"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="order-date-to">
                Order Date To
              </label>
              <input
                type="date"
                className="form-control"
                id="order-date-to"
                name="order-date-to"
              />
            </div>
          </div>
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="customer-reference">
                Customer Reference
              </label>
              <input
                type="text"
                className="form-control"
                id="customer-reference"
                name="customer-reference"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="delivery-method">
                Delivery Method
              </label>
              {/* <select className="form-control" id="delivery-method">
								<option></option>
							</select> */}
              <Select
                searchAble={true}
                isClearable
                name="Delivery Method"
                id="delivery-method"
                options={[]}
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="printed">
                Printed
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Printed"
                id="printed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {/* <select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <SalesOrderTable />
          <br />
          <div className="row">
            <div className="col-xs-6"></div>
            <div className="col-xs-6 stat-div">
              <small className="stat-label">TOTAL SALES ORDER VALUE</small>
              <div className="stat-value">4121230.20</div>
              <small className="stat-label">TOTAL PROFIT</small>
              <div className="stat-value">1230.20</div>
              <small className="stat-label">AVERAGE PROFIT</small>
              <div className="stat-value">3.25%</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
