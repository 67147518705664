import React, { Component } from "react";

import ImportExportComp from "@components/inventory/importExport";

class ImportExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [
        "Products",
        "Stock On Hand",
        "Serial Number",
        "Batch Number",
        "Product Supplier",
        "Inventory Details",
        "Product Attributes",
        "Product Pricing",
      ],
    };
  }
  componentDidMount() {
    document.title = "Import/Export Products";
  }
  onProductUpload = () => {
    document.getElementById("inputFileProducts").click();
  };
  onStockOnHandUpload = () => {
    document.getElementById("inputFileStockOnHand").click();
  };
  onSerialNumberUpload = () => {
    document.getElementById("inputFileSerialNumber").click();
  };
  onBatchNumberUpload = () => {
    document.getElementById("inputFileBatchNumber").click();
  };
  onProductSupplierUpload = () => {
    document.getElementById("inputFileProductSupplier").click();
  };
  onInventoryDetailsUpload = () => {
    document.getElementById("inputFileInventoryDetails").click();
  };
  onProductAttributesUpload = () => {
    document.getElementById("inputFileProductAttributes").click();
  };
  onProductPricingUpload = () => {
    document.getElementById("inputFileProductPricing").click();
  };
  render() {
    return (
      <div>
        <ImportExportComp
          onProductPricingUpload={() => this.onProductPricingUpload()}
          onProductAttributesUpload={() => this.onProductAttributesUpload()}
          onInventoryDetailsUpload={() => this.onInventoryDetailsUpload()}
          onProductSupplierUpload={() => this.onProductSupplierUpload()}
          onBatchNumberUpload={() => this.onBatchNumberUpload()}
          onSerialNumberUpload={() => this.onSerialNumberUpload()}
          onStockOnHandUpload={() => this.onStockOnHandUpload()}
          onProductUpload={() => this.onProductUpload()}
          nav={this.state.nav}
        />
      </div>
    );
  }
}

export default ImportExport;
