import React, { Component } from "react";
import SalesOrdersComp from "@components/setting/docDesigner/salesOrders/index";

export default class SalesOrders extends Component {
  state = {
    templates: [
      { label: "Formal Order", value: "Formal Order" },
      { label: "Classic order", value: "Classic order" },
    ],
  };
  render() {
    const { templates } = this.state;
    return <SalesOrdersComp templates={templates} name="Sales Order" />;
  }
}
