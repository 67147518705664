import React from "react";
import TermsTable from "./termsTable";
import ConfirmationModal from "@components/common/confirmationModal";
import EditModal from "./editModal";
import Select from "react-select";

export default function PaymentTerms({
  name,
  terms,
  types,
  days,
  editType,
  editName,
  editDays,
  onAdd,
  onDelete,
  onUpdate,
  onChange,
  handleChange,
  handleEditChange,
  onPaymentTermSelect,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Payment Terms</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          {" "}
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="payment-terms-name">
                * Name
              </label>
              <input
                value={name}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="name"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="payment-terms-days">
                * Days
              </label>
              <input
                value={days}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="days"
              />
            </div>
            <div className="col-md-2 col-xs-9 padding-1">
              <label className="input-label" htmlFor="type">
                * Type
              </label>
              <Select isClearable onChange={handleChange} options={types} />
            </div>
            <div className="col-xs-2" id="system-add-btn">
              <button
                onClick={() => onAdd()}
                className="btn btn-success btn-sm"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <TermsTable
            terms={terms}
            onPaymentTermSelect={(data) => onPaymentTermSelect(data)}
          />
        </div>
      </div>
      <ConfirmationModal onDelete={() => onDelete()} item="Payment Term" />
      <EditModal
        editType={editType}
        editDays={editDays}
        editName={editName}
        handleEditChange={(e) => handleEditChange(e)}
        types={types}
        onChange={(e) => onChange(e)}
        onUpdate={() => onUpdate()}
      />
    </>
  );
}
