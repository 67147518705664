import { settings } from "@constants/actionTypes";
const initialState = {
  allRoles: null,
  selectedRole: null,
};

function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case settings.ROLES:
      return { ...state, allRoles: action.payload };
    case settings.SELECTED_ROLE:
      return { ...state, selectedRole: action.payload };
    default:
      return state;
  }
}
export default rolesReducer;
