import React, { Component } from "react";
import { initilizeDataTable, destroyDataTable } from "@helpers";
import ProductEnquiryComp from "@components/reports/production/productionEnquiry";

export default class ProductEnquiry extends Component {
  componentDidMount() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("sales-quotes-charge-table");
    return <ProductEnquiryComp />;
  }
}
