import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import Product from "./product";
import WarehouseTransferTable from "./warehouseTransferTable";
import { isEmptyOrNull } from "@helpers";
import ConfirmationModal from "@components/common/confirmationModal";

export default function AddWarehouseTransfer({
  warehouses,
  products,
  handleChange,
  onChange,
  stateData,
  destWarehouse,
  onClickAdd,
  onClickParkOrComplete,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-sm-6">
            <label>Add Warehouse Transfer</label>
          </div>
          {stateData &&
          !isEmptyOrNull(stateData.destinationWarehouse) &&
          !isEmptyOrNull(stateData.sourceWarehouse) ? (
            <div className="col-sm-6">
              <button
                className="btn btn-light btn-sm"
                onClick={() => onClickParkOrComplete("Parked")}
              >
                Park
              </button>
              <button
                className="btn btn-success btn-sm"
                onClick={() => onClickParkOrComplete("Completed")}
              >
                Complete
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                id="source"
                name="Source"
                searchAble={true}
                isClearable
                values={warehouses}
                onChange={(value) => handleChange(value, "sourceWarehouse")}
              />
              <InlineDropdown
                id="destination"
                name="Destination"
                searchAble={true}
                isClearable
                values={destWarehouse}
                onChange={(value) =>
                  handleChange(value, "destinationWarehouse")
                }
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="inline-form">
                <div className="field">
                  <label htmlFor="comment">Comments</label>
                  <textarea
                    rows="5"
                    type="text"
                    id="comment"
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                id="template"
                name="Template"
                searchAble={true}
                isClearable
                values={[
                  { label: "Globuss Default", value: "Globuss Default" },
                  { label: "warehouse Transfer", value: "warehouse Transfer" },
                ]}
                handleChange={(value) => handleChange(value, "template")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Product
            stateData={stateData}
            products={products}
            onChange={(e) => onChange(e)}
            onClickAdd={() => onClickAdd()}
            handleChange={(value, field) => handleChange(value, field)}
          />
          <br />
          <WarehouseTransferTable
            transferedProducts={
              stateData && stateData.transferedProducts
                ? stateData.transferedProducts
                : []
            }
          />
          <ConfirmationModal />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-xs-6"></div>
        <div className="col-md-3 col-xs-6 stat-div">
          <small className="stat-label">TOTAL QUANTITY</small>
          <div className="stat-value">0</div>

          <small className="stat-label">
            SIZE (METRE<sup>3</sup>)
          </small>
          <div className="stat-value">0</div>

          <small className="stat-label">WEIGHT (KG)</small>
          <div className="stat-value">0</div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-xs-12" style={{ textAlign: "right" }}>
          <button className="btn btn-light btn-sm">Email</button>
        </div>
      </div>
    </>
  );
}
