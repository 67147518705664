import { combineReducers } from "redux";

import authReducer from "./auth";
import systemReducer from "./settings";
import inventoryReducer from "./inventory";
import commonReducer from "./common";
import customersReducer from "./customers";
import suppliersReducer from "./suppliers";
import purchasesReducer from "./purchases";
import productionsReducer from "./productions";
import salesRedcuer from "./sales";

export default combineReducers({
  auth: authReducer,
  common: commonReducer,
  system: systemReducer,
  inventory: inventoryReducer,
  customers: customersReducer,
  suppliers: suppliersReducer,
  purchases: purchasesReducer,
  sales: salesRedcuer,
  productions: productionsReducer,
});
