import React from "react";
import Info from "@components/common/info";
import StepsInfo from "@components/common/stepsInfo";

export default function ImportSalesOrders() {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Import Sales Orders</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading=" Importing Sales Orders"
            message="If you are importing orders, then they save as 'Parked' orders. Therefore, any duplicate customer reference numbers are not recognizable as duplicates."
            link="Click for more information."
          />
          <h2 className="stock-revaluation-steps">
            Import your Sales Orders into Globuss in three easy steps
          </h2>
          <StepsInfo
            heading="Download the Sales Order template file"
            step="1"
            link="Download Sales template"
            message="Start by downloading our Sales Order Excel template file. This file has the correct column headings Unleashed needs to import your sales data."
          />
          <StepsInfo
            heading="Add your Sales Order to the template file"
            step="2"
            link="Click for Sales template (Field Definitions)"
            message="Using Excel or another spreadsheet editor, enter your sales data into the Unleashed template. Make sure the data you enter matches the column headings in the template. See the field definitions links below for details on what to enter in each column."
          />
          <StepsInfo
            heading="Upload the updated template file"
            step="3"
            message="The file you import must be a valid Excel file in .CSV format."
          />
          <button style={{ marginLeft: "5px" }} className="btn btn-success">
            Upload Sales Orders
          </button>
        </div>
      </div>
    </>
  );
}
