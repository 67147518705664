import React from "react";

import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";

export default function SerialNumber({ onSerialNumberUpload }) {
  return (
    <div id="SerialNumber" className="tab-pane fade">
      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading="Importing Serial Number"
            message="For further information on importing data, including required field definitions, please"
            link="visit our help pages."
          />
          <UploadFiles
            onFileDivClick={() => onSerialNumberUpload()}
            id="Serial Number"
          />
        </div>
      </div>
    </div>
  );
}
