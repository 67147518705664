import React from "react";
import { css } from "@emotion/core";
// First way to import
import { ScaleLoader, HashLoader } from "react-spinners";

const override = css`
  display: block;
  position: fixed;
  margin-top: 12%;
  margin-bottom: -11%;
  margin-left: 44%;
  z-index: 10001;
`;

function Loader(props) {
  return (
    <HashLoader
      css={override}
      sizeUnit={"px"}
      // height={60}
      // width={10}
      // radius={2}
      size={60}
      margin="2px"
      color={"#1c4e80"}
      loading={props.display}
    />
  );
}

export default Loader;
