import api from "@api/api";
import { settings } from "@constants/actionTypes";
import { goto } from "@helpers";
import { loaderState, selectedNav } from "@actions";
import _ from "lodash";
import swal from "sweetalert";
import moment from "moment";
import { Switch } from "react-router-dom";

export const getWarehouses = () => (dispatch) => {
  api
    .get("/warehouse")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.WAREHOUSES, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addWarehouse = (data) => (dispatch, getState) => {
  const { allWarehouses } = getState().system.warehouses;
  api
    .post("/warehouse", { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        swal("Warehouse added successfully", {
          icon: "success",
          buttons: {
            OK: {
              text: "OK",
              value: "OK",
            },
          },
        }).then((val) => {
          switch (val) {
            case "OK":
              dispatch(selectedNav("/settings/system/warehouses"));
              goto("/settings/system/warehouses");
          }
        });
        dispatch({
          type: settings.ALL_UNITS,
          payload: [...allWarehouses, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteWarehouse = (id) => (dispatch, getState) => {
  const { allWarehouses } = getState().system.warehouses;
  api
    .delete(`/warehouse/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allWarehouses, (warehouse) => {
          return Number(id) === Number(warehouse.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateWarehouse = (id, data) => (dispatch, getState) => {
  const { allWarehouses } = getState().system.warehouses;
  api
    .post(`/warehouse/update/${id}`, { ...data })
    .then((res) => {
      if (Number(res.data.code === 200)) {
        let updatedWarehouse = _.findLast(allWarehouses, (warehouse) => {
          return Number(warehouse.id) === Number(id);
        });
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            updatedWarehouse[key] = data[key];
          }
        }
        let updatedwarehouses = _.map(allWarehouses, (warehouse) => {
          return Number(warehouse.id) === Number(id)
            ? updatedWarehouse
            : warehouse;
        });

        dispatch({ type: settings.WAREHOUSES, payload: updatedwarehouses });
        dispatch(loaderState(false));
        swal("Updated successfully", {
          icon: "success",
          buttons: {
            OK: {
              text: "OK",
              value: "OK",
            },
          },
        }).then((val) => {
          switch (val) {
            case "OK":
              dispatch(selectedNav("/settings/system/warehouses"));
              goto("/settings/system/warehouses");
          }
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectWarehouse = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_WAREHOUSE, payload: data });
};
