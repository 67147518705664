import React from "react";
import InlineDropdown from "@components/common/inlineDropDown";

export default function Invoicing({ stateData, onChange, handleChange }) {
  return (
    <div id="Invoicing" className="tab-pane fade">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="inline-form">
            <div className="field">
              <label htmlFor="invoiceFooterDetails">Footer Details</label>
              <textarea
                onChange={(e) => onChange(e)}
                rows="7"
                value={
                  stateData
                    ? stateData.invoiceFooterDetails
                      ? stateData.invoiceFooterDetails
                      : ""
                    : ""
                }
                type="text"
                id="invoiceFooterDetails"
              />
            </div>
          </div>
          <InlineDropdown
            searchAble={true}
            id="defaultInvoiceDate"
            name="Default Invoice Date"
            onChange={(newValue, actionMeta) =>
              handleChange(newValue, actionMeta)
            }
            value={
              stateData
                ? stateData.defaultInvoiceDate
                  ? {
                      label: stateData.defaultInvoiceDate,
                      value: stateData.defaultInvoiceDate,
                    }
                  : null
                : null
            }
            values={[
              { label: "Created Date", value: "Created Date" },
              { label: "Order Date", value: "Order Date" },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
