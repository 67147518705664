import React, { Component } from "react";
import {
  loaderState,
  getSalesPerson,
  onSelectSalesPerson,
  addSalesPerson,
  deleteSalesPerson,
  updateSalesPerson,
} from "@actions";
import swal from "sweetalert";
import { connect } from "react-redux";
import { EMAIL_FORMAT } from "@constants/global-constant";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import SalesPersonComp from "@components/setting/system/salesPerson";

class SalesPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: null,
      email: null,
      editFullname: null,
      editEmail: null,
      obsolete: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("sales-person-table");
    this.props.loaderState(true);
    this.props.getSalesPerson();
  }

  componentDidUpdate() {
    initilizeDataTable("sales-person-table");
  }

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  onChangeEdit = ({ target }) => {
    const { name, type, value } = target;
    if (type === "checkbox") {
      this.setState({ obsolete: target.checked ? "1" : "-1" });
    } else {
      this.setState({ [name]: value });
    }
  };

  onAdd = () => {
    const { user, organization } = this.props;
    const { fullName, email } = this.state;

    if (!isEmptyOrNull(fullName) || !isEmptyOrNull(email)) {
      if (email.match(EMAIL_FORMAT)) {
        let data = {
          fullName,
          email,
          createdBy: user.id,
          updatedBy: user.id,
        };
        this.setState({ fullName: "", email: "" });
        this.props.loaderState(true);
        this.props.addSalesPerson(data);
      } else {
        swal("", "Email is not in correct format", "error");
      }
    } else {
      swal("", "Please enter a Fullname and Email", "info");
    }
  };

  onUpdate = () => {
    const { selectedSalesPerson, user } = this.props;
    const { editFullname, editEmail, obsolete } = this.state;

    if (isEmptyOrNull(editFullname) && isEmptyOrNull(editEmail)) {
    } else {
      let data = {
        obsolete,
        updatedBy: user.id,
      };
      let send = true;
      if (!isEmptyOrNull(editFullname)) {
        data.fullName = editFullname;
      }
      if (!isEmptyOrNull(editEmail)) {
        if (editEmail.match(EMAIL_FORMAT)) {
          data.email = editEmail;
        } else {
          send = false;
          swal("Email is not in correct format");
        }
      }
      if (send) {
        this.setState({ editValue: null, obsolete: null });
        this.props.loaderState(true);
        this.props.updateSalesPerson(data, selectedSalesPerson.id);
        this.setState({ editValue: null, obsolete: null });
      }
    }
  };

  onSalesPersonSelect = (data) => {
    this.props.onSelectSalesPerson(data);
  };

  onDelete = () => {
    const { selectedSalesPerson } = this.props;
    this.props.loaderState(true);
    this.props.deleteSalesPerson(selectedSalesPerson.id);
  };

  render() {
    destroyDataTable("sales-person-table");

    const { fullName, email, obsolete, editEmail, editFullname } = this.state;
    const { allSalesPerson, selectedSalesPerson } = this.props;
    return (
      <SalesPersonComp
        fullName={fullName}
        email={email}
        obsolete={obsolete}
        editEmail={
          editEmail
            ? editEmail
            : selectedSalesPerson
            ? selectedSalesPerson.email
            : null
        }
        editFullname={
          editFullname
            ? editFullname
            : selectedSalesPerson
            ? selectedSalesPerson.fullName
            : null
        }
        allSalesPerson={allSalesPerson}
        onAdd={() => this.onAdd()}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onDelete={() => this.onDelete()}
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onSalesPersonSelect={(data) => this.onSalesPersonSelect(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allSalesPerson: state.system.salesPerson.allSalesPerson,
    user: state.auth.loggedInUser,
    selectedSalesPerson: state.system.salesPerson.selectedSalesPerson,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getSalesPerson,
  onSelectSalesPerson,
  addSalesPerson,
  deleteSalesPerson,
  updateSalesPerson,
})(SalesPerson);
