import axios from 'axios';
import { API_URL , SALES_API_URL } from '../constants/global-constant';

export default axios.create({
	baseURL: API_URL,
	headers: {
		// Pragma: 'no-cache',
		'Content-Type': 'application/json',
	},
});
