import { settings } from "@constants/actionTypes";
const initialState = {
  allMethods: null,
  selectedMethod: null,
};

function deliveryMethodsReducer(state = initialState, action) {
  switch (action.type) {
    case settings.DELIVERY_METHODS:
      return { ...state, allMethods: action.payload };
    case settings.SELECTED_DELIVERY_METHODS:
      return { ...state, selectedMethod: action.payload };
    default:
      return state;
  }
}
export default deliveryMethodsReducer;
