import React from "react";
// import Logo from "../../assets/images/logo.png";
import "./index.css";

const Login = ({ email, password, onChange, onLogin, goto }) => {
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div className="login100-pic js-tilt" data-tilt>
            <span className="login100-form-title">Login</span>
            {/* <img src={Logo} alt="IMG" /> */}
            <h1>LOGO</h1>
          </div>

          <div className="login100-form ">
            <div className="wrap-input100">
              <input
                className="input100"
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                onChange={(e) => onChange(e)}
                value={email}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className="input100"
                id="password"
                type="password"
                name="pass"
                placeholder="Password"
                onChange={(e) => onChange(e)}
                value={password}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={() => onLogin()}>
                Login
              </button>
            </div>

            <div className="botton-links">
              <div className="text-center p-t-12">
                <span className="txt1">Forgot </span>
                <a className="txt2" href="#">
                  Username / Password?
                </a>
              </div>

              <div className="text-center p-t-136">
                <a className="txt2" onClick={() => goto("/signup")}>
                  Create your Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
