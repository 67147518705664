import React, { Component } from "react";
import ImportSalesQuotesComp from "@components/sales/quotes/import";

class ImportSalesQuotes extends Component {
  render() {
    return <ImportSalesQuotesComp />;
  }
}

export default ImportSalesQuotes;
