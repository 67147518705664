import React from 'react';

export default function SaveButton() {
	return (
		<div className="btn-group">
			<button type="button" className="btn btn-light btn-sm">
				Save
			</button>
			<button type="button" className="btn btn-light btn-sm dropdown-toggle" data-toggle="dropdown">
				<span className="caret"></span>
			</button>
			<ul className="dropdown-menu dropdown-menu-right" role="menu">
				<li>
					<a href="#">Parked</a>
				</li>
				<li>
					<a href="#">Costed</a>
				</li>
				
			</ul>
		</div>
	);
}
