import { users } from "@constants/actionTypes";
const initialState = {
  userInfo: null,
  loggedInUser: null,
  allUsers: null,
  selectedUser: null,
  organizationInfo: null,
  permissions: null,
  organizatoionPostalAddress: null,
  organizatoionPhysicalAddress: null,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case users.USER_INFO:
      return { ...state, userInfo: action.payload };
    case users.ORGANIZATION_INFO:
      return { ...state, organizationInfo: action.payload };
    case users.LOGGED_IN_USER_DATA:
      return { ...state, loggedInUser: action.payload };
    case users.USERS:
      return { ...state, allUsers: action.payload };
    case users.DELETE_USER:
      return { ...state, selectedUser: action.payload };
    case users.PERMISSIONS:
      return { ...state, permissions: action.payload };
    case users.ORGANIZATION_POSTAL_ADDRESSES:
      return { ...state, organizatoionPostalAddress: action.payload };
    case users.ORGANIZATION_PHYSICAL_ADDRESSES:
      return { ...state, organizatoionPhysicalAddress: action.payload };
    default:
      return state;
  }
}
export default usersReducer;
