import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getDeliveryMethods = () => (dispatch) => {
  api
    .get("/deliveryMethods")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.DELIVERY_METHODS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addDeliveryMethods = ({ methodName, createdBy }) => (
  dispatch,
  getState
) => {
  var methods = getState().system.deliveryMethods.allMethods;
  const { organizationInfo } = getState().auth;
  api
    .post("/deliveryMethods", {
      methodName,
      createdBy,
      updatedBy: createdBy,
      //   organizationId: organizationInfo.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.DELIVERY_METHODS,
          payload: [...methods, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteDeliveryMethods = (id) => (dispatch, getState) => {
  const { allMethods } = getState().system.deliveryMethods;
  api
    .delete(`/deliveryMethods/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allMethods, (method) => {
          return Number(id) === Number(method.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateDeliveryMethods = ({ methodName, id, updatedBy }) => (
  dispatch,
  getState
) => {
  var methods = getState().system.deliveryMethods.allMethods;
  api
    .post(`/deliveryMethods/update/${id}`, { methodName, updatedBy })
    .then((res) => {
      if (res.data.data) {
        let updatedMethod = _.findLast(methods, (method) => {
          return Number(method.id) === Number(id);
        });
        updatedMethod.methodName = methodName;
        let updatedMethods = _.map(methods, (method) => {
          return method.id === id ? updatedMethod : method;
        });

        dispatch({ type: settings.DELIVERY_METHODS, payload: updatedMethods });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onUpdateOrDeleteDeliveryMethods = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_DELIVERY_METHODS, payload: data });
};
