import api from "@api/api";

export function GetAppFunctionalities() {
  return new Promise((resolve, reject) => {
    api
      .get("/appFunctionalities")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function GetRolePermissions(role_id) {
  return new Promise((resolve, reject) => {
    api
      .post("/permissions/filter", { role_id })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function SaveRolePermissions(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/permissions", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
