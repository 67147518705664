import React from "react";
import StepsInfo from "@components/common/stepsInfo";

export default function Batch() {
  return (
    <div id="Batch" className="tab-pane fade">
      <div className="row">
        <div className="col-lg-4" id="info-card">
          <StepsInfo
            heading="Download Change Code template file"
            message="Start by downloading our product change codes template file."
            link="Download Product Change Codes template"
            step="1"
          />
        </div>
        <div className="col-lg-4" id="info-card">
          <StepsInfo
            heading="Add your changes to the template file"
            message="Using Excel or another spreadsheet editor enter the existing and new product codes to
						change. Make sure the data matches the column heading in the template."
            step="2"
          />
        </div>
        <div className="col-lg-4" id="info-card">
          <StepsInfo
            heading="Upload the updated template file"
            message="The file you import must be a valid Excel file in .CSV format."
            step="3"
            button="yes"
          />
        </div>
      </div>
    </div>
  );
}
