import React from 'react';

export default function Toolbox({ toolBoxData }) {
	return (
		<div id="Toolbox" className="tab-pane fade">
			<div className="panel-group" id="accordion">
				{toolBoxData ? (
					<div className="panel panel-default">
						<div
							className="panel-heading"
							data-toggle="collapse"
							data-parent="#accordion"
							href={`#${toolBoxData[0].name.replace(/\s/g, '')}`}
						>
							<h4 className="panel-title">{toolBoxData[0].name}</h4>
						</div>
						<div id={`${toolBoxData[0].name.replace(/\s/g, '')}`} className="panel-collapse collapse in">
							<div className="panel-body">
								{toolBoxData[0].data.map(d => (
									<div key={d.name} className="doc-comp draggable">
										<i className={`${d.icon}`} />
										<span>{d.name}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}
				{toolBoxData
					? toolBoxData.map((data, i) =>
							i !== 0 ? (
								<div key={i} className="panel panel-default">
									<div
										className="panel-heading"
										data-toggle="collapse"
										data-parent="#accordion"
										href={`#${data.name.replace(/\s/g, '')}`}
									>
										<h4 className="panel-title">{data.name}</h4>
									</div>
									<div id={`${data.name.replace(/\s/g, '')}`} className="panel-collapse collapse">
										<div className="panel-body">
											{data.data.map(d => (
												<div className="doc-div ui-widget-content draggable" key={d}>
													<span>{d}</span>
												</div>
											))}
										</div>
									</div>
								</div>
							) : null
					  )
					: null}
			</div>
		</div>
	);
}
