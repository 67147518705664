import React from "react";

export default function Product() {
  return (
    <div className="row" id="settings-field">
      <div className="col-md-8 padding-1-right">
        <label className="input-label" htmlFor="product">
          Product
        </label>
        <input
          type="text"
          className="form-control"
          id="product"
          name="product"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="unit">
          Unit
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          id="unit"
          name="unit"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="pack-size">
          Pack Size
        </label>
        <input
          disabled
          type="text"
          className="form-control"
          id="pack-size"
          name="pack-size"
        />
      </div>
      <div className="col-md-1 padding-1">
        <label className="input-label" htmlFor="count-qty">
          Count Quantity
        </label>
        <input
          type="text"
          className="form-control"
          id="count-qty"
          name="count-qty"
        />
      </div>

      <div className="col-md-1" id="add-btn">
        <label className="input-label">Add</label>
        <button type="button" className="btn btn-success">
          Add
        </button>
      </div>
    </div>
  );
}
