import React from "react";
import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";

export default function Products({ onProductUpload }) {
  return (
    <div id="Products" className="tab-pane active">
      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading="Importing Products"
            message="You do not need to create Product Groups in Unleashed before uploading the template, as the upload itself creates the desired groups. Also if you want to change the name of your Sell Price Tiers, then you need to do this in Unleashed and not in the template."
            link="Click for more information."
          />
          <UploadFiles onFileDivClick={() => onProductUpload()} id="Products" />
        </div>
      </div>
    </div>
  );
}
