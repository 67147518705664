import api from "@api/api";
import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getShippingCompanies = () => (dispatch) => {
  api
    .get("/shippingCompany")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.SHIPPING_COMPANIES, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addShippingCompany = ({ companyName, createdBy }) => (
  dispatch,
  getState
) => {
  const allShippings = getState().system.shippingCompanies.allShippingCompanies;
  const { organizationInfo } = getState().auth;
  api
    .post("/shippingCompany", {
      companyName,
      createdBy,
      updatedBy: createdBy,
      //   organizationId: organizationInfo.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.SHIPPING_COMPANIES,
          payload: [...allShippings, res.data.data],
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteShippingCompany = (id) => (dispatch, getState) => {
  const { allShippingCompanies } = getState().system.shippingCompanies;
  api
    .delete(`/shippingCompany/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allShippingCompanies, (company) => {
          return Number(id) === Number(company.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateShippingCompany = ({
  companyName,
  obsolete,
  id,
  updatedBy,
}) => (dispatch, getState) => {
  const {
    allShippingCompanies,
    selectedShippingCompany,
  } = getState().system.shippingCompanies;
  api
    .post(`/shippingCompany/update/${id}`, { companyName, obsolete, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedCompany = _.findLast(allShippingCompanies, (company) => {
          return Number(company.id) === Number(id);
        });
        updatedCompany.companyName = companyName
          ? companyName
          : selectedShippingCompany.companyName;
        updatedCompany.obsolete = obsolete
          ? obsolete
          : selectedShippingCompany.obsolete;
        let updatedCompanies = _.map(allShippingCompanies, (company) => {
          return company.id === id ? updatedCompany : company;
        });

        dispatch({
          type: settings.SHIPPING_COMPANIES,
          payload: updatedCompanies,
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onUpdateOrDeleteCompany = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_SHIPPING_COMPANY, payload: data });
};
