import React from "react";
import SearchCodeChanges from "@components/common/searchCodeChanges";

export default function Single({
  contents,
  onChangeCode,
  onSelectCustomerCode,
  onChange,
  oldCode,
  newCode,
  onClickSave,
}) {
  return (
    <div id="Single" className="tab-pane active">
      {/* <div className="panel panel-default">
				<div className="panel-body"> */}
      <div className="row" id="settings-field">
        <div className="col-sm-2 padding-1-right hover-dropdown">
          <label className="input-label" htmlFor="customer">
            *Customer
          </label>
          <input
            value={oldCode}
            autoComplete="off"
            type="text"
            onChange={(e) => onChangeCode(e)}
            className="form-control"
            id="customer"
            name="customer"
          />
          <SearchCodeChanges
            onClick={(code) => onSelectCustomerCode(code)}
            contents={contents}
          />
        </div>
        <div className="col-sm-2 padding-1">
          <label className="input-label" htmlFor="new-code">
            *New Customer Code
          </label>
          <input
            onChange={(e) => onChange(e.target)}
            autoComplete="off"
            type="text"
            className="form-control"
            id="newCode"
            name="newCode"
            value={newCode}
          />
        </div>
        <div
          className="col-lg-3 col-md-3 col-sm-3 col-2 padding-1"
          id="system-add-btn"
        >
          <button
            className="btn btn-success btn-sm"
            onClick={() => onClickSave()}
          >
            Save
          </button>
        </div>
      </div>
      {/* </div>
			</div> */}
    </div>
  );
}
