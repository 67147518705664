import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState } from "@actions";
import {
  initilizeDataTable,
  destroyDataTable,
  warehousesData,
  goto,
} from "@helpers";
import ViewSupplierReturnsComp from "@components/purchases/viewSupplierReturns/index";

class ViewSupplierReturns extends Component {
  componentDidMount() {
    document.title = "Supplier Returns";
    const { warehouses } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }
    initilizeDataTable("view-supplier-retutn-table");
  }
  componentDidUpdate() {
    initilizeDataTable("view-supplier-retutn-table");
  }

  render() {
    destroyDataTable("view-supplier-retutn-table");

    const { warehouses } = this.props;
    return (
      <ViewSupplierReturnsComp
        goto={(path) => goto(path)}
        warehouses={warehousesData(warehouses)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(
  ViewSupplierReturns
);
