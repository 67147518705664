import React from "react";
import Tabs from "@components/common/tabs";
import Details from "./details";
import Configuration from "./configuration";
import Address from "@components/common/address";
import Contact from "./contact";
import Images from "./images";
import Invoicing from "./invoicing";
import Purchasing from "./purchasing";

export default function Company({
  stateData,
  handleChange,
  handleChangeAddress,
  onChange,
  onClickSave,
  industries,
  timezones,
  countries,
  organizationTypes,
  updatedPostal,
  updatedPhysical,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Company</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <button className="btn btn-light btn-sm">Features</button>
            <button
              className="btn btn-success btn-sm"
              onClick={() => onClickSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={stateData.nav} />
          <div className="tab-content">
            <Details
              timezones={timezones}
              industries={industries}
              organizationTypes={organizationTypes}
              stateData={stateData ? stateData.organization : null}
              onChange={(e) => onChange(e, "organization")}
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "organization")
              }
            />
            <Configuration
              stateData={stateData ? stateData.organization : null}
              onChange={(e) => onChange(e, "organization")}
            />
            <Address
              countries={countries}
              postal={
                stateData ? (stateData.postal ? stateData.postal : null) : null
              }
              physical={
                stateData
                  ? stateData.physical
                    ? stateData.physical
                    : null
                  : null
              }
              updatedPostal={
                updatedPostal
                  ? updatedPostal.postal
                    ? updatedPostal.postal
                    : null
                  : null
              }
              updatedPhysical={
                updatedPhysical
                  ? updatedPhysical.postal
                    ? updatedPhysical.postal
                    : null
                  : null
              }
              handleChange={(newValue, actionMeta, obj) =>
                handleChange(newValue, actionMeta, obj)
              }
              onChange={(e, obj) => onChange(e, obj)}
            />
            <Contact
              stateData={stateData ? stateData.organization : null}
              onChange={(e) => onChange(e, "organization")}
            />
            <Images />
            <Invoicing
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "organization")
              }
              onChange={(e) => onChange(e, "organization")}
              stateData={stateData ? stateData.organization : null}
            />
            <Purchasing
              handleChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, "organization")
              }
              onChange={(e) => onChange(e, "organization")}
              stateData={stateData ? stateData.organization : null}
            />
          </div>
        </div>
      </div>
    </>
  );
}
