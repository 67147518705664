import { inventory } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getStockAdjustments = () => (dispatch) => {
  api
    .get("/stockAdjustments")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: inventory.STOCK_ADJUSTMENTS, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addStockAdjustment = (data) => (dispatch, getState) => {
  let { stockAdjustments } = getState().inventory.transactions;
  const { loggedInUser } = getState().auth;
  api
    .post("/stockAdjustments", {
      ...data,
      createdBy: loggedInUser.id,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: inventory.STOCK_ADJUSTMENTS,
          payload: [...stockAdjustments, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateStockAdjustment = (data) => (dispatch, getState) => {
  const {
    stockAdjustments,
    selectedstockAdjustment,
  } = getState().inventory.transactions;
  const { loggedInUser } = getState().auth;
  api
    .post(`/stockAdjustments/update/${selectedstockAdjustment.id}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedAdjustment = { ...selectedstockAdjustment, ...data };
        let updatedAdjustments = _.map(stockAdjustments, (adjustment) => {
          return adjustment.id === selectedstockAdjustment.id
            ? updatedAdjustment
            : adjustment;
        });
        dispatch({
          type: inventory.STOCK_ADJUSTMENTS,
          payload: updatedAdjustments,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteStockAdjustment = (id) => (dispatch, getState) => {
  const { stockAdjustments } = getState().inventory.transactions;
  api
    .delete(`/stockAdjustments/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(stockAdjustments, (adjustment) => {
          return adjustment.id === id;
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectAdjustment = (adjustment) => (dispatch) => {
  dispatch({ type: inventory.SELECTED_STOCK_ADJUSTMENT, payload: adjustment });
};

export const getWarehouseTransfers = () => (dispatch) => {
  api
    .get("/warehouseTransfers")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: inventory.WAREHOUSES_TRANSFERS,
          payload: res.data.data,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addWarehouseTransfer = (data) => (dispatch, getState) => {
  // let warehouseTransfers = getState().inventory.transactions.warehouseTransfers;
  const { loggedInUser } = getState().auth;
  api
    .post("/warehouseTransfers", {
      ...data,
      createdBy: loggedInUser.id,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch(
          getWarehouseTransfers()
          //   {
          //   type: inventory.WAREHOUSES_TRANSFERS,
          //   payload: [...warehouseTransfers, res.data.data],
          // }
        );
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateWarehouseTransfer = (data) => (dispatch, getState) => {
  const {
    warehouseTransfers,
    selectedWarehouseTransfer,
  } = getState().inventory.transactions;
  const { loggedInUser } = getState().auth;
  api
    .post(`/warehouseTransfers/update/${selectedWarehouseTransfer.id}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedTransfer = { ...selectedWarehouseTransfer, ...data };
        let updatedTransfers = _.map(warehouseTransfers, (transfer) => {
          return transfer.id === selectedWarehouseTransfer.id
            ? updatedTransfer
            : transfer;
        });
        dispatch({
          type: inventory.WAREHOUSES_TRANSFERS,
          payload: updatedTransfers,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteWarehouseTransfer = (id) => (dispatch, getState) => {
  const { warehouseTransfers } = getState().inventory.transactions;
  api
    .delete(`/warehouseTransfers/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(warehouseTransfers, (adjustment) => {
          return adjustment.id === id;
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectWarehouseTransfer = (warehouseTransfer) => (dispatch) => {
  dispatch({
    type: inventory.SELECTED_WAREHOUSES_TRANSFER,
    payload: warehouseTransfer,
  });
};

export const getStockCounts = () => (dispatch) => {
  api
    .get("/stockCounts")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: inventory.STOCK_COUNTS, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addStockCount = (data) => (dispatch, getState) => {
  let stockCounts = getState().inventory.transactions.stockCounts;
  const { loggedInUser } = getState().auth;
  api
    .post("/stockCounts", {
      ...data,
      createdBy: loggedInUser.id,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: inventory.STOCK_COUNTS,
          payload: [...stockCounts, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateStockCount = (data) => (dispatch, getState) => {
  const { stockCounts, selectedStockCount } = getState().inventory.transactions;
  const { loggedInUser } = getState().auth;
  api
    .post(`/stockCounts/update/${selectedStockCount.id}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedStockCount = { ...selectedStockCount, ...data };
        let updatedStockCounts = _.map(stockCounts, (transfer) => {
          return transfer.id === selectedStockCount.id
            ? updatedStockCount
            : transfer;
        });
        dispatch({ type: inventory.STOCK_COUNTS, payload: updatedStockCounts });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteStockCount = (id) => (dispatch, getState) => {
  const { stockCounts } = getState().inventory.transactions;
  api
    .delete(`/stockCounts/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(stockCounts, (count) => {
          return count.id === id;
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectStockCount = (count) => (dispatch) => {
  dispatch({ type: inventory.SELECTED_STOCK_COUNT, payload: count });
};
