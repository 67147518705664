import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getPrefixes = () => (dispatch) => {
  api
    .get("/prefixes")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.PREFIXES, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addPrefixes = (data) => (dispatch, getState) => {
  var allPrefixes = getState().system.prefixes.allPrefixes;
  const { organizationInfo } = getState().auth;
  // organizationId: organizationInfo.id
  api
    .post("/prefixes", { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.PREFIXES,
          payload: [...allPrefixes, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updatePrefixes = (data) => (dispatch) => {
  api
    .post(`/prefixes/update/1`, { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch(getPrefixes());
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};
