import React from "react";
import QuantityPriceTable from "./quantityPriceTable";
import Select from "react-select";

export default function QuantityPrices() {
  return (
    <div id="qualityprices" className="tab-pane fade">
      <div className="row">
        <div className="pull-right">
          <button
            className="btn btn-light btn-sm"
            style={{ marginRight: "5px" }}
          >
            Import
          </button>
          <button className="btn btn-light btn-sm">Export</button>
        </div>
      </div>
      <br />
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="min-qty">
            Min. Qty
          </label>
          <input
            type="number"
            className="form-control"
            id="min-qty"
            name="min-qty"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="valid-from">
            Valid From
          </label>
          <input
            type="date"
            className="form-control"
            id="valid-from"
            name="valid-from"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="valid-to">
            Valid To
          </label>
          <input
            type="date"
            className="form-control"
            id="valid-to"
            name="valid-to"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="priceDiscount">
            Price/Discount %
          </label>
          <input
            type="number"
            className="form-control"
            id="priceDiscount"
            name="priceDiscount"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="price-type">
            Price Type
          </label>
          <Select
            searchAble={true}
            isClearable
            name="Price Type"
            id="price-type"
            options={[
              { label: "Fixed Price", value: "Fixed Price" },
              { label: "Discount", value: "Discount" },
            ]}
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="showDiscount">
            Show Discount
          </label>
          <br />
          <input
            type="checkbox"
            style={{ width: "18px", height: "18px" }}
            id="showDiscount"
            name="showDiscount"
          />
        </div>
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="discountFrom">
            Discount From
          </label>
          <Select
            searchAble={true}
            isClearable
            name="discountFrom"
            id="discountFrom"
            options={[
              { label: "Customer Price Tier", value: "Customer Price Tier" },
              { label: "Default Sell Price", value: "Default Sell Price" },
            ]}
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="margin">
            Margin %
          </label>
          <input
            type="text"
            className="form-control"
            id="margin"
            name="margin"
          />
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="comments">
            Comments
          </label>
          <input
            type="text"
            className="form-control"
            id="comments"
            name="comments"
          />
        </div>
        <div className="col-md-2 padding-1" id="system-add-btn">
          <button type="button" className="btn btn-success btn-sm">
            Save
          </button>
        </div>
      </div>
      <br />
      <QuantityPriceTable />
    </div>
  );
}
