import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState } from "@actions";
import { initilizeDataTable, destroyDataTable, warehousesData } from "@helpers";
import AddCreditComp from "@components/sales/credit/add";

class AddCredit extends Component {
  componentDidMount() {
    document.title = "Add Sales Credit";
    const { warehouses } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }
    initilizeDataTable("add-credit-product-table");
    initilizeDataTable("sales-quotes-charge-table");
  }
  componentDidUpdate() {
    initilizeDataTable("add-credit-product-table");
    initilizeDataTable("sales-quotes-charge-table");
  }
  render() {
    destroyDataTable("add-credit-product-table");
    destroyDataTable("sales-quotes-charge-table");

    const { warehouses } = this.props;
    return <AddCreditComp warehouses={warehousesData(warehouses)} />;
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(
  AddCredit
);
