import React from "react";
import Logo from "../../assets/images/logo.png";

const SignUp = ({
  goto,
  onSignUp,
  onChange,
  first_name,
  last_name,
  password,
  confirm_password,
  email,
  phone_number,
}) => {
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div className="login100-pic js-tilt" data-tilt>
            <span className="login100-form-title">Sign Up</span>
            {/* <img src={Logo} alt="IMG" /> */}
            <h1>LOGO</h1>
          </div>

          <div className="login100-form">
            <div className="wrap-input100">
              <input
                className="input100"
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
                onChange={(e) => onChange(e)}
                value={first_name}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="glyphicon glyphicon-user" aria-hidden="true"></i>
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className="input100"
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                onChange={(e) => onChange(e)}
                value={last_name}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="glyphicon glyphicon-user" aria-hidden="true"></i>
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className="input100"
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                onChange={(e) => onChange(e)}
                value={email}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
            </div>

            {/* <div className="wrap-input100">
              <input
                className="input100"
                type="text"
                name="phone_number"
                id="phone_number"
                placeholder="Phone Number"
                onChange={(e) => onChange(e)}
                value={phone_number}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i
                  className="glyphicon glyphicon-earphone"
                  aria-hidden="true"
                ></i>
              </span>
            </div> */}

            <div className="wrap-input100">
              <input
                className="input100"
                id="password"
                type="password"
                name="pass"
                placeholder="Password"
                onChange={(e) => onChange(e)}
                value={password}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className="input100"
                id="confirm_password"
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={(e) => onChange(e)}
                value={confirm_password}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={() => onSignUp()}>
                Register
              </button>
            </div>

            <div className="text-center p-t-136">
              <a className="txt2" onClick={() => goto("/")}>
                Already have an account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
