import React from "react";

export default function Tabs({ nav }) {
  return (
    <div class="tabbable-panel">
      <div class="tabbable-line">
        <ul className="nav nav-tabs nav-fill" role="tablist" id="comp-tab">
          {nav.map((nav, index) => {
            return (
              <li
                className={`nav-item ${index === 0 ? "active" : ""}`}
                key={nav}
              >
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href={`#${nav.split(" ").join("")}`}
                >
                  {nav}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
