import React, { Component } from "react";
import {
  loaderState,
  getAttributeSets,
  addAttributeSet,
  onSelectedAttribute,
  deleteAttribute,
  updateAttributeSet,
} from "@actions";
import swal from "sweetalert";
import { connect } from "react-redux";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import AttributeSetsComp from "@components/setting/system/attributeSets";
class AttributeSets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeSetName: null,
      type: null,
      editAttribute: null,
      editType: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("attribute-set-table");
    this.props.loaderState(true);
    this.props.getAttributeSets();
  }

  componentDidUpdate() {
    initilizeDataTable("attribute-set-table");
  }

  onAdd = () => {
    const { user } = this.props;
    const { attributeSetName, type } = this.state;

    if (!isEmptyOrNull(attributeSetName) || !isEmptyOrNull(type)) {
      this.setState({ attributeSetName: "", type: "" });
      this.props.loaderState(true);
      this.props.addAttributeSet({
        attributeSetName,
        type,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter Attribute Name and Type", "info");
    }
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  onUpdate = () => {
    const { selectedAttribute, user } = this.props;
    const { editAttribute, editType } = this.state;

    if (isEmptyOrNull(editAttribute) && isEmptyOrNull(editType)) {
    } else {
      let data = {};
      if (!isEmptyOrNull(editAttribute)) {
        data.attributeSetName = editAttribute;
      }
      if (!isEmptyOrNull(editType)) {
        data.type = editType;
      }
      this.setState({ editAttribute: null, editType: null });
      this.props.loaderState(true);
      this.props.updateAttributeSet({
        ...data,
        id: selectedAttribute.id,
        updatedBy: user.id,
      });
    }
  };

  onDelete = () => {
    const { selectedAttribute } = this.props;
    this.props.loaderState(true);
    this.props.deleteAttribute(selectedAttribute.id);
  };

  onAttributeSelect = (data) => {
    this.props.onSelectedAttribute(data);
  };

  render() {
    destroyDataTable("attribute-set-table");

    const { attributeSetName, type, editAttribute, editType } = this.state;
    const { attributeSets, selectedAttribute } = this.props;
    return (
      <AttributeSetsComp
        attributeSets={attributeSets}
        attributeSetName={attributeSetName}
        type={type}
        name="editAttribute"
        name1="editType"
        editAttribute={
          editAttribute
            ? editAttribute
            : selectedAttribute
            ? selectedAttribute.attributeSetName
            : null
        }
        editType={
          editType
            ? editType
            : selectedAttribute
            ? selectedAttribute.type
            : null
        }
        onAdd={() => this.onAdd()}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
        onDelete={() => this.onDelete()}
        onAttributeSelect={(data) => this.onAttributeSelect(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributeSets: state.system.attributeSets.allAttributeSets,
    user: state.auth.loggedInUser,
    selectedAttribute: state.system.attributeSets.selectedAttribute,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getAttributeSets,
  addAttributeSet,
  onSelectedAttribute,
  deleteAttribute,
  updateAttributeSet,
})(AttributeSets);
