import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState } from "@actions";
import { initilizeDataTable, destroyDataTable, warehousesData } from "@helpers";
import AddSalesQuotesComp from "@components/sales/quotes/add";

class AddSalesQuotes extends Component {
  componentDidMount() {
    const { warehouses } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }
    initilizeDataTable("add-quote-product-table");
    initilizeDataTable("sales-quotes-charge-table");
  }

  componentDidUpdate() {
    initilizeDataTable("add-quote-product-table");
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("add-quote-product-table");
    destroyDataTable("sales-quotes-charge-table");

    const { warehouses } = this.props;
    return <AddSalesQuotesComp warehouses={warehousesData(warehouses)} />;
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(
  AddSalesQuotes
);
