import React from 'react';
import { Pie, Line } from 'react-chartjs-2';

export default function Probablity({ data, height, width, line }) {
	let pieLegend = { display: true, position: 'left' };
	let lineLegend = { display: true, position: 'top' };
	return (
		<div>
			{line ? (
				<Line height={height} width={width} data={data} legend={lineLegend} />
			) : (
				<Pie data={data} height={height} legend={pieLegend} />
			)}
		</div>
	);
}
