import React from 'react';
import data from '../../../../data';
export default function Configuration({ onChange, stateData }) {
	return (
		<div id="Configuration" className="tab-pane fade">
			<div className="row">
				{data.CompanyConfiguration.map(config => {
					let value = stateData ? (stateData[config.id] === 1 ? 'checked' : '') : '';
					return (
						<div className="col-md-4 col-sm-6" key={config.heading}>
							<div className="config-container">
								<span className="config-card-heading">{config.heading}</span>
								<span className="config-card-body">{config.body}</span>
								{config.tip ? (
									<span className="config-card-tip">{config.tip}</span>
								) : (
									<span className="config-card-warning">{config.warning}</span>
								)}

								<span
									className="config-card-switch"
									style={{ cursor: `${config.disable ? 'no-drop' : 'pointer'}` }}
								>
									<div className="form-inline">
										<span
											className="table-input"
											style={{ float: 'right', width: '90%', marginTop: '-5px' }}
										>
											<label className="switch">
												<input
													disabled={config.disable ? 'disabled' : ''}
													type="checkbox"
													id={config.id}
													checked={value}
													onChange={e => onChange(e)}
												/>
												<span className="slider round"></span>
											</label>
										</span>
									</div>
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
