import React, { Component } from "react";
import ChangeCodesComp from "@components/suppliers/changeCodes";
import { connect } from "react-redux";
import {
  getSuppliers,
  loaderState,
  addCodeChange,
  getCodeChanges,
  deleteCodeChange,
  onSelectCodeChange,
} from "@actions";
import {
  initilizeDataTable,
  destroyDataTable,
  prepareSearchData,
  isEmptyOrNull,
} from "@helpers";
import _ from "lodash";
import swal from "sweetalert";

class ChangeCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: ["Single", "Batch"],
      searchedData: [],
      oldCode: "",
      newCode: "",
    };
  }

  componentDidMount() {
    this.props.loaderState(true);
    this.props.getCodeChanges();
    const { products } = this.props;
    if (products && products.length === 0) {
      this.props.loaderState(true);
      this.props.getSuppliers();
    }
    initilizeDataTable("change-code-table");
  }

  componentDidUpdate() {
    initilizeDataTable("change-code-table");
  }

  onChangeCode = ({ target }) => {
    const { value } = target;
    const { suppliers } = this.props;
    this.onChange(target);
    let searchedData = [];
    if (suppliers) {
      let code = "supplierCode";
      let name = "supplierName";
      let data = prepareSearchData(suppliers, code, name);
      _.forEach(data, (d) => {
        if (d.code.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          searchedData.push(d);
        }
      });
    }

    this.setState(
      !isEmptyOrNull(value) ? { searchedData } : { searchedData: [] }
    );
  };

  onClickSave = () => {
    const { oldCode, newCode } = this.state;
    if (!isEmptyOrNull(oldCode) && !isEmptyOrNull(newCode)) {
      if (oldCode.toLocaleLowerCase() === newCode.toLocaleLowerCase()) {
        swal("", "Both codes are the same");
      } else {
        this.props.loaderState(true);
        this.props.addCodeChange({
          oldCode,
          newCode,
          module: "supplier",
        });

        this.setState({ newCode: "", oldCode: "" });
      }
    } else {
      swal(
        "",
        "Please select an old product code and add new product code",
        "error"
      );
    }
  };

  onChange = (target) => {
    this.setState({ [target.id]: target.value });
  };

  onDelete = () => {
    this.props.loaderState(true);
    this.props.deleteCodeChange();
  };

  onCodeChangeSelect = (data) => {
    this.props.onSelectCodeChange(data);
  };

  supplierChanges = () => {
    const { codeChanges } = this.props;
    let data = [];
    _.forEach(codeChanges, (code) => {
      if (code.module === "supplier") {
        data.push(code);
      }
    });

    return data;
  };

  onSelectSupplierCode = (code) => {
    this.setState({ oldCode: code, searchedData: [] });
  };

  render() {
    destroyDataTable("change-code-table");
    const { nav, searchedData, oldCode, newCode } = this.state;

    let codeChanges = this.supplierChanges();

    return (
      <ChangeCodesComp
        nav={nav}
        oldCode={oldCode}
        newCode={newCode}
        contents={searchedData}
        data={codeChanges}
        onSelectSupplierCode={(code) => this.onSelectSupplierCode(code)}
        onChange={(target) => this.onChange(target)}
        onChangeCode={(e) => this.onChangeCode(e)}
        onClickSave={() => this.onClickSave()}
        onDelete={() => this.onDelete()}
        onCodeChangeSelect={(data) => this.onCodeChangeSelect(data)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.suppliers.allSuppliers,
    codeChanges: state.common.codeChanges,
  };
};

export default connect(mapStateToProps, {
  getSuppliers,
  loaderState,
  addCodeChange,
  getCodeChanges,
  deleteCodeChange,
  onSelectCodeChange,
})(ChangeCodes);
