import React from "react";
import InlineDropdown from "@components/common/inlineDropDown";
import InlineInputForm from "@components/common/inlineInputForm";
import InLineCheckbox from "@components/common/inLineCheckbox";

export default function Document({
  templates,
  newOrDuplicate,
  onClickNewOrDuplicate,
}) {
  return (
    <div id="Document" className="tab-pane active">
      <div className="row">
        <div className="col-sm-6">
          <InlineDropdown
            searchAble={true}
            noEmpty={true}
            id="documentType"
            name="Document Type"
            values={[
              { label: "Assemblies", value: "Assemblies" },
              { label: "Credit Notes", value: "Credit Notes" },
              { label: "Invoices", value: "Invoices" },
              { label: "Labels", value: "Labels" },
              { label: "Packing Slip", value: "Packing Slip" },
              { label: "Pick List", value: "Pick List" },
              { label: "Purchase Orders", value: "Purchase Orders" },
              { label: "Sales Orders", value: "Sales Orders" },
              { label: "Sales Quotes", value: "Sales Quotes" },
              { label: "Ship Notes", value: "Ship Notes" },
              { label: "Supplier Returns", value: "Supplier Returns" },
              { label: "Warehouse Transfers", value: "Warehouse Transfers" },
            ]}
          />
          <InlineDropdown
            searchAble={true}
            noEmpty={true}
            id="templateName"
            name="Template Name"
            values={templates}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <InLineCheckbox id="default" name="Default" />
          <div style={{ display: !newOrDuplicate ? "none" : "" }}>
            <InlineInputForm id="newTemplateName" name="New Template Name" />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="doc-designer-btns">
        <div
          className="defaults"
          style={{ display: newOrDuplicate ? "none" : "" }}
        >
          <button
            className="btn btn-light btn-sm"
            onClick={() => onClickNewOrDuplicate()}
          >
            New
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => onClickNewOrDuplicate()}
          >
            Rename
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => onClickNewOrDuplicate()}
          >
            Duplicate
          </button>
          <button className="btn btn-light btn-sm">Export</button>
          <button className="btn btn-danger btn-sm">Delete</button>
        </div>
        <div
          className="on-create"
          style={{ display: !newOrDuplicate ? "none" : "" }}
        >
          <button className="btn btn-light btn-sm">Create</button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => onClickNewOrDuplicate()}
          >
            Cancel
          </button>
        </div>
      </div>
      <hr />
      <div className="doc-info">
        <i className="fa fa-info-circle" />
        <span>
          This is a read-only template. You must duplicate it to make
          modifications.
        </span>
      </div>
      <div className="template-import">
        <div className="template-import-heading">IMPORT TEMPLATE</div>
        <hr />
        <div className="doc-info">
          <i className="fa fa-info-circle" />
          <span>
            The file you import must be a valid JSON file in .json format
          </span>
          <div className="uploadFiles">
            <br />
            <div className="file-drop-area" id="template-upload">
              <input id={`inputFileTemplateUpload`} type="file" />
              <i className="fa fa-cloud-upload" aria-hidden="true"></i>
              <p className="file-name">click here to upload file.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
