import { settings } from "@constants/actionTypes";
const initialState = {
  allTaxes: null,
  selectedTax: null,
};

function taxesReducer(state = initialState, action) {
  switch (action.type) {
    case settings.TAXES:
      return { ...state, allTaxes: action.payload };
    case settings.SELECTED_TAXE:
      return { ...state, selectedTax: action.payload };
    default:
      return state;
  }
}
export default taxesReducer;
