import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import RunReport from "@components/common/runReport";
import MarginEnquiryTable from "./marginEnquiryTable";
import Stats from "./stats";

export default function MarginEnquiry({ warehouses, tiers }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Margin Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineInputForm
                id="sales-order-number"
                value=""
                name="Sales Order Number"
              />
              <InlineInputForm
                id="credit-note-number"
                value=""
                name="Credit Note Number"
              />
              <InlineDropdown
                searchAble={true}
                id="sell-price-tier"
                name="Sell Price Tier"
                values={tiers}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={[
                  { label: "Select All", value: "Select All" },
                  { ...warehouses },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="product-group"
                name="Product Group"
                values={[
                  { label: "Product Group 1", value: "Product Group 1" },
                ]}
              />
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
              <InlineDropdown
                searchAble={true}
                id="sales-order-group"
                name="Sales Order Group"
                values={[]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="customer-type"
                name="Customer Type"
                values={[{ label: "General", value: "General" }]}
              />
              <InlineInputForm
                id="customer-code"
                value=""
                name="Customer Code"
              />
              <InlineInputForm
                id="customer-name"
                value=""
                name="Customer Name"
              />
              <InlineDropdown
                searchAble={true}
                id="delivery-method"
                name="Delivery Method"
                values={[]}
              />
              <InlineInputForm
                id="delivery-address"
                value=""
                name="Delivery Address"
                disable="disabled"
              />
              <InlineDropdown
                searchAble={true}
                id="sales-persom"
                name="Sales Persom"
                values={[{ label: "name: email", value: "name: email" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <MarginEnquiryTable />
          <br />
          <RunReport />
        </div>
      </div>

      <Stats />
    </>
  );
}
