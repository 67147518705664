import { customers } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getDeliveryAddresses = (data) => (dispatch) => {
  api
    .post("/deliveryAddresses/filter", data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: customers.DELIVERY_ADDRESSES,
          payload: res.data.data,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addDeliveryAddress = (data) => (dispatch, getState) => {
  let { deliveryAddresses } = getState().customers;
  const { loggedInUser } = getState().auth;
  data.createdBy = loggedInUser.id;
  data.updatedBy = loggedInUser.id;
  if (!deliveryAddresses) {
    deliveryAddresses = [];
  }
  api
    .post("/deliveryAddresses", data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: customers.DELIVERY_ADDRESSES,
          payload: [...deliveryAddresses, res.data.data],
        });
        swal("", "Delivery Addresses added successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateDeliveryAddress = (data) => (dispatch, getState) => {
  const { deliveryAddresses, selectedDeliveryAddress } = getState().customers;
  const { loggedInUser } = getState().auth;
  data.updatedBy = loggedInUser.id;
  api
    .post(`/deliveryAddresses/update/${selectedDeliveryAddress.id}`, data)
    .then((res) => {
      if (res.data.code === 200) {
        let updatedDeliveryAddress = { ...selectedDeliveryAddress, ...data };
        let updatedDeliveryAddresss = _.map(deliveryAddresses, (address) => {
          return address.id === selectedDeliveryAddress.id
            ? updatedDeliveryAddress
            : address;
        });
        dispatch({
          type: customers.DELIVERY_ADDRESSES,
          payload: updatedDeliveryAddresss,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteDeliveryAddress = () => (dispatch, getState) => {
  const { deliveryAddresses, selectedDeliveryAddress } = getState().common;
  api
    .delete(`/deliveryAddresses/${selectedDeliveryAddress.id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(deliveryAddresses, (deliveryAddress) => {
          return deliveryAddress.id === selectedDeliveryAddress.id;
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectDeliveryAddress = (data) => (dispatch) => {
  dispatch({ type: customers.SELECTED_DELIVERY_ADDRESS, payload: data });
};
