import React from "react";
import Permissions from "./permissions";
import Select from "react-select";

export default function RolePermissions({
  roles,
  appFunctionalities,
  selectedRole,
  onChangeRole,
  handleChangePermission,
  onClickSave,
  getFunctionalityPermisson,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-md-4">
            <label>Role Permissions</label>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
            <button className="btn btn-success btn-sm" onClick={onClickSave}>
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="role-permission">
            <div className="col-md-2">
              <Select
                placeholder="Select Role..."
                options={roles}
                value={selectedRole}
                onChange={onChangeRole}
              />
            </div>
          </div>
          <br />
          <Permissions
            appFunctionalities={appFunctionalities}
            onChange={handleChangePermission}
            getFunctionalityPermisson={getFunctionalityPermisson}
          />
        </div>
      </div>
    </>
  );
}
