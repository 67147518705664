import React from "react";
import moment from "moment";
import TableActions from "@components/common/tableActions";

export default function WarehouseTable({ transfers, onClickAction }) {
  return (
    <div className="table-view table-responsive">
      <table id="warehouse-transfers-table" className="table">
        <thead>
          <tr>
            <th>Transfer Number</th>
            <th>Transfer Date</th>
            <th>Source Warehouse</th>
            <th>Destination Warehouse</th>
            <th>Comments</th>
            <th>Transfer Status</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {transfers
            ? transfers.map((transfer) => (
                <tr key={transfer.id}>
                  <td>{transfer.id}</td>
                  <td>{moment(transfer.createdAt).format("ll")}</td>
                  <td>{transfer.sourcewarehousename}</td>
                  <td>{transfer.destwarehousename}</td>
                  <td>{transfer.comment}</td>
                  <td>{transfer.status}</td>
                  <td style={{ textAlign: "center" }}>
                    <TableActions
                      data={["Email Source", "Email Destination", "Print"]}
                      icon="fa fa-cog"
                      onClick={(action) => onClickAction(action)}
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
