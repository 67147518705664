import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import {
  loaderState,
  getRolls,
  updateRole,
  deleteRole,
  onUpdateOrDeleteRole,
  addRole,
} from "@actions";
import RolesComp from "@components/setting/security/roles/index";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "test user",
      addValue: null,
      editValue: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("roles-table");
    this.props.loaderState(true);
    this.props.getRolls();
  }

  componentDidUpdate() {
    initilizeDataTable("roles-table");
  }

  onChange = ({ target }) => {
    this.setState({ addValue: target.value });
  };

  onDelete = () => {
    console.log(this.state);
  };

  onRoleUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteRole(data);
  };

  onChangeEdit = ({ target }) => {
    this.setState({ editValue: target.value });
  };

  onAdd = () => {
    const { user } = this.props;
    const { addValue } = this.state;

    if (!isEmptyOrNull(addValue)) {
      this.props.loaderState(true);
      this.props.addRole({ roleName: addValue, createdBy: user.id });
      this.setState({ addValue: "" });
    } else {
      swal("", "Please enter a role value", "info");
    }
  };

  onUpdate = () => {
    const { selectedRole, user } = this.props;
    const { editValue } = this.state;

    if (!isEmptyOrNull(editValue)) {
      this.props.loaderState(true);
      this.props.updateRole({
        roleName: editValue,
        id: selectedRole.id,
        updatedBy: user.id,
      });
    } else {
      this.setState({ editValue: null });
    }
  };

  onDelete = () => {
    const { selectedRole } = this.props;
    this.props.loaderState(true);
    this.props.deleteRole(selectedRole.id);
  };

  render() {
    destroyDataTable("roles-table");

    const { addValue, editValue } = this.state;
    const { roles, selectedRole } = this.props;
    return (
      <RolesComp
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onChange={(e) => this.onChange(e)}
        onAdd={() => this.onAdd()}
        onRoleUpdateOrDelete={(data) => this.onRoleUpdateOrDelete(data)}
        addValue={addValue}
        roles={roles}
        editValue={
          editValue ? editValue : selectedRole ? selectedRole.roleName : null
        }
        onChange={(user) => this.onChange(user)}
        onDelete={() => this.onDelete()}
        onUpdate={() => this.onUpdate()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.system.roles.allRoles,
    user: state.auth.loggedInUser,
    selectedRole: state.system.roles.selectedRole,
  };
};
export default connect(mapStateToProps, {
  loaderState,
  getRolls,
  deleteRole,
  updateRole,
  addRole,
  onUpdateOrDeleteRole,
})(Roles);
