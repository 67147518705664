import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";

export default function Contact({ stateData, onChange }) {
  return (
    <div id="Contact" className="tab-pane fade">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            value={
              stateData ? (stateData.firstName ? stateData.firstName : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="firstName"
            name="First name"
          />
          <InlineInputForm
            value={
              stateData ? (stateData.lastName ? stateData.lastName : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="lastName"
            name="Last name"
          />
          <InlineInputForm
            value={stateData ? (stateData.email ? stateData.email : "") : ""}
            onChange={(e) => onChange(e)}
            id="email"
            name="Email"
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.officePhone
                  ? stateData.officePhone
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="officePhone"
            name="Office Phone"
          />
          <InlineInputForm
            value={
              stateData ? (stateData.website ? stateData.website : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="website"
            name="Website"
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            value={
              stateData
                ? stateData.phoneNumber
                  ? stateData.phoneNumber
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="phoneNumber"
            name="Phone Number"
          />
          <InlineInputForm
            value={
              stateData ? (stateData.faxNumber ? stateData.faxNumber : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="faxNumber"
            name="Fax Number"
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.mobileNumber
                  ? stateData.mobileNumber
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="mobileNumber"
            name="Mobile Number"
          />
          <InlineInputForm
            value={
              stateData ? (stateData.DDINumber ? stateData.DDINumber : "") : ""
            }
            onChange={(e) => onChange(e)}
            id="DDINumber"
            name="DDI Number"
          />
          <InlineInputForm
            value={
              stateData
                ? stateData.tollFreeNumber
                  ? stateData.tollFreeNumber
                  : ""
                : ""
            }
            onChange={(e) => onChange(e)}
            id="tollFreeNumber"
            name="Toll Free Number"
          />
        </div>
      </div>
    </div>
  );
}
