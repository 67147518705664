import React from "react";

import Info from "@components/common/info";
import UploadFiles from "@components/common/uploadFiles";
export default function BatchNumber({ onBatchNumberUpload }) {
  return (
    <div id="BatchNumber" className="tab-pane fade">
      <div className="panel panel-default">
        <div className="panel-body">
          <Info
            heading="Importing Batch Number"
            message="For further information on importing data, including required field definitions, please"
            link="visit our help pages."
          />
          <UploadFiles
            onFileDivClick={() => onBatchNumberUpload()}
            id="Batch Number"
          />
        </div>
      </div>
    </div>
  );
}
