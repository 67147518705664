import React, { Component } from "react";
import {
  loaderState,
  addSupplierReturnReason,
  deleteSupplierReturnReason,
  onUpdateOrDeleteSupplierReturnReason,
  updateSupplierReturnReason,
  getSupplierReturnReasons,
} from "@actions";
import swal from "sweetalert";
import { connect } from "react-redux";
import { isEmptyOrNull, initilizeDataTable, destroyDataTable } from "@helpers";
import SupplierReturnReasonComp from "@components/setting/system/supplierReturnReason";

class SupplierReturnReason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addValue: null,
      editValue: null,
    };
  }

  componentDidMount() {
    initilizeDataTable("supplier-return-reason-table");
    this.props.loaderState(true);
    this.props.getSupplierReturnReasons();
  }

  componentDidUpdate() {
    initilizeDataTable("supplier-return-reason-table");
  }

  onChange = ({ target }) => {
    this.setState({ addValue: target.value });
  };

  onChangeEdit = ({ target }) => {
    this.setState({ editValue: target.value });
  };

  onUpdate = () => {
    const { selectedReason, user } = this.props;
    const { editValue } = this.state;

    if (!isEmptyOrNull(editValue)) {
      this.setState({ editValue: null });
      this.props.loaderState(true);
      this.props.updateSupplierReturnReason({
        reason: editValue,
        id: selectedReason.id,
        updatedBy: user.id,
      });
    }
  };

  onDelete = () => {
    const { selectedReason } = this.props;
    this.props.loaderState(true);
    this.props.deleteSupplierReturnReason(selectedReason.id);
  };

  onAdd = () => {
    const { user } = this.props;
    const { addValue } = this.state;

    if (!isEmptyOrNull(addValue)) {
      this.setState({ addValue: "" });
      this.props.loaderState(true);
      this.props.addSupplierReturnReason({
        reason: addValue,
        createdBy: user.id,
      });
    } else {
      swal("", "Please enter a reason value", "info");
    }
  };

  onReasonUpdateOrDelete = (data) => {
    this.props.onUpdateOrDeleteSupplierReturnReason(data);
  };

  render() {
    destroyDataTable("supplier-return-reason-table");

    const { addValue, editValue } = this.state;
    const { selectedReason, supplierReasons } = this.props;
    return (
      <SupplierReturnReasonComp
        supplierReasons={supplierReasons}
        addValue={addValue}
        editValue={
          editValue != null
            ? editValue
            : selectedReason
            ? selectedReason.reason
            : null
        }
        onChangeEdit={(e) => this.onChangeEdit(e)}
        onReasonUpdateOrDelete={(data) => this.onReasonUpdateOrDelete(data)}
        onDelete={() => this.onDelete()}
        onAdd={() => this.onAdd()}
        onChange={(e) => this.onChange(e)}
        onUpdate={() => this.onUpdate()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supplierReasons: state.system.supplierReturnReasons.allReturnReason,
    user: state.auth.loggedInUser,
    selectedReason: state.system.supplierReturnReasons.selectedReturnReason,
    organization: state.auth.organizationInfo,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  addSupplierReturnReason,
  deleteSupplierReturnReason,
  onUpdateOrDeleteSupplierReturnReason,
  updateSupplierReturnReason,
  getSupplierReturnReasons,
})(SupplierReturnReason);
