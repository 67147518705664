import React from "react";
import SplitButton from "@components/common/splitButton";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import Tabs from "@components/common/tabs";
import SalesOrdersTable from "./salesOrdersTable";
import AllTable from "./allTable";
import RunReport from "@components/common/runReport";

export default function BackOrderEnquiry({ nav, warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Back Order Enquiry</label>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-6" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export as PDF",
                "Export as CSV",
                "Export as XLSX",
                "Export as XLS",
              ]}
            />
            <button className="btn btn-success btn-sm">Run</button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                id="date-from"
                type="date"
                value=""
                name="Date From"
              />
              <InlineInputForm
                id="date-to"
                type="date"
                value=""
                name="Date To"
              />
              <InlineDropdown
                searchAble={true}
                id="order-status"
                name="Order Status"
                values={[
                  { label: "Open", value: "Open" },
                  { label: "Parked", value: "Parked" },
                  { label: "Placed", value: "Placed" },
                  { label: "Backorder", value: "Backorder" },
                ]}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="warehouse"
                name="Warehouse"
                values={[
                  { label: "Select All", value: "Select All" },
                  { ...warehouses },
                ]}
              />
              <InlineInputForm id="product-code" value="" name="Product Code" />
              <InlineInputForm
                id="product-description"
                value=""
                name="Product Description"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                id="customer-type"
                name="Customer Type"
                values={[{ label: "General", value: "General" }]}
              />
              <InlineInputForm
                id="customer-code"
                value=""
                name="Customer Code"
              />
              <InlineInputForm
                id="customer-name"
                value=""
                name="Customer Name"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={nav} />
          <div className="tab-content">
            <SalesOrdersTable />
            <AllTable />
          </div>
          <br />
          <RunReport />
        </div>
      </div>
    </>
  );
}
