import React from "react";

export default function WarehouseTransferTable({ transferedProducts }) {
  return (
    <div className="table-view table-responsive">
      <table id="add-warehouse-transfers-table" className="table">
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Product Description</th>
            <th>Bin Location</th>
            <th>
              Volume(m<sup>3</sup>)
            </th>
            <th>Weight</th>
            <th>Pack Size</th>
            <th>Trasnfer Quantity</th>
            <th>Comments</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {transferedProducts
            ? transferedProducts.map((prod) => (
                <tr key={prod.productId}>
                  <td>{prod.productCode}</td>
                  <td>{prod.productDescription}</td>
                  <td>{prod.binLocation}</td>
                  <td>{prod.volume}</td>
                  <td>{prod.weight}</td>
                  <td>{prod.packSize}</td>
                  <td>{prod.quantity}</td>
                  <td>{prod.productComment}</td>
                  <td>
                    <i
                      data-toggle="modal"
                      data-target="#confirmationModal"
                      className="fa fa-trash-o icon"
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
