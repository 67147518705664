import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import SaveButton from "@components/common/dropdownSavebtn";
import CostLines from "./costLines";
import PurchasesTable from "./purchasesTable";
import CostLinesTables from "./costLinesTables";
import Stats from "./stats";
import Product from "./product";

import Select from "react-select";

export default function AddPurchase({
  goto,
  warehouses,
  suppliersName,
  suppliersCode,
  onSupplierChange,
  stateData,
  onSupplierNameChange,
  onClickSave,
  onClickPlace,
  onWarehouseChange,
  onChange,
}) {
  console.log(stateData);
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Add Purchase Order</label>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-5" style={{ textAlign: "right" }}>
            <button
              onClick={() =>
                goto("/purchases/purchase orders/import purchases")
              }
              className="btn btn-danger btn-sm"
            >
              Delete
            </button>

            <button
              onClick={() => goto("/purchases/purchase orders/add purchase")}
              className="btn btn-sm"
            >
              Clone
            </button>

            <SaveButton />

            <button
              onClick={() => onClickPlace()}
              className="btn btn-success btn-sm"
            >
              Place
            </button>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                link={true}
                path="/suppliers/view suppliers"
                // disable="disabled" id="warehouse" name="Warehouse" values={warehouses}
                searchAble={true}
                name="* Supplier Code"
                id="supplierCode"
                placeholder={
                  stateData && stateData.supplierData.supplierCode
                    ? stateData.supplierData.supplierCode
                    : "Select Code"
                }
                values={suppliersCode}
                onChange={onSupplierChange}
              />

              <InlineDropdown
                link={true}
                path="/suppliers/view suppliers"
                // disable="disabled" id="warehouse" name="Warehouse" values={warehouses}
                searchAble={true}
                name="* Supplier Name"
                id="supplierName"
                onChange={onSupplierNameChange}
                placeholder={
                  stateData && stateData.supplierData.supplierName
                    ? stateData.supplierData.supplierName
                    : "Select Name"
                }
                values={suppliersName}
              />
              {/*<InlineInputForm
								link={true}
								path="/suppliers/view suppliers"
								id="supplier-code"
								value=""
								name="* Supplier Code"
							/> */}
              {/* <InlineDropdown
								// disable="disabled" id="warehouse" name="Warehouse" values={warehouses}
								searchAble={true}
								name="Warehouse"
								id="warehouse"
								placeholder="Select Warehouse"
								values={warehouses}
							/> */}
              {/* <InlineInputForm
								link={true}
								path="/suppliers/view suppliers"
								id="supplier-name"
								value=""
								name="* Supplier Name"
							/> */}
              <InlineInputForm
                disable="disabled"
                id="supplier-reference"
                value=""
                name="Supplier Reference"
              />
              <InlineInputForm
                disable={false}
                id="po_discount"
                value={
                  stateData && stateData.po_discount
                    ? stateData.po_discount
                    : "0.00"
                }
                onChange={(e) => onChange(e)}
                name="Discount (%)"
              />
              <InlineInputForm
                disable="disabled"
                id="supplier-invoice-date"
                type="date"
                value=""
                name="Supplier Invoice Date"
              />
              {/* <InlineDropdown
								// disable="disabled" id="warehouse" name="Warehouse" values={warehouses}
								searchAble={true}
								name="Warehouse"
								id="warehouse"
								placeholder="Select Warehouse"
								values={warehouses}
							/> */}
              <InlineDropdown
                // disable="disabled" id="warehouse" name="Warehouse" values={warehouses}
                searchAble={true}
                name="Warehouse"
                id="warehouse"
                placeholder="Select Warehouse"
                onChange={onWarehouseChange}
                values={warehouses}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                disable={false}
                onChange={(e) => onChange(e)}
                id="delivery_name"
                value={
                  stateData && stateData.delivery_name
                    ? stateData.delivery_name
                    : ""
                }
                name="Delivery Name"
              />
              <InlineInputForm
                disable="disabled"
                id="addressLine1"
                value={
                  stateData && stateData.physical.addressLine1
                    ? stateData.physical.addressLine1
                    : ""
                }
                name="Address Line 1"
              />
              <InlineInputForm
                disable="disabled"
                id="addressLine2"
                value={
                  stateData && stateData.physical.addressLine2
                    ? stateData.physical.addressLine2
                    : ""
                }
                name="Address Line 2"
              />
              <InlineInputForm
                disable="disabled"
                id="suburb"
                value={
                  stateData && stateData.physical.suburb
                    ? stateData.physical.suburb
                    : ""
                }
                name="Suburb"
              />
              <InlineInputForm
                disable="disabled"
                id="city"
                value={
                  stateData && stateData.physical.city
                    ? stateData.physical.city
                    : ""
                }
                name="City"
              />
              <InlineInputForm
                disable="disabled"
                id="state_regoin"
                value={
                  stateData && stateData.physical.state_Region
                    ? stateData.physical.state_Region
                    : ""
                }
                name="State/Region"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                disable="disabled"
                id="postalCode"
                value={
                  stateData && stateData.physical.postalCode
                    ? stateData.physical.postalCode
                    : ""
                }
                name="Postal Code"
              />
              <InlineInputForm
                disable="disabled"
                id="country"
                value={
                  stateData && stateData.physical.country
                    ? stateData.physical.country
                    : ""
                }
                name="Country"
              />

              <InlineInputForm
                id="po_date"
                value={stateData && stateData.po_date ? stateData.po_date : ""}
                type="date"
                name="Order Date"
                onChange={(e) => onChange(e)}
              />
              <InlineInputForm
                id="delivery_date"
                value={
                  stateData && stateData.delivery_date
                    ? stateData.delivery_date
                    : ""
                }
                type="date"
                name="* Delivery Date"
                onChange={(e) => onChange(e)}
              />
              {/* <InlineDropdown
								// disable="disabled"
								id="selected-template"
								searchAble={true}
								placeholder="Select Template"
								values={[
									{ value: "Globus Default", label: "Globus Default" },
									{ value: "Formal Purchase", label: "Formal Purchase" },
									{ value: "Classic Purchase", label: "Classic Purchase" },
								]}
								name="Template"
							/> */}
              <InlineInputForm
                disable="disabled"
                id="currency_cd"
                value={
                  stateData && stateData.supplierData.currencyCode
                    ? stateData.supplierData.currencyCode
                    : ""
                }
                name="Currency Code"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Product goto={(path) => goto(path)} />
          <br />
          <PurchasesTable />
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <CostLines />
          <br />
          <CostLinesTables />
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4">
              <label className="input-label" htmlFor="comments">
                Comments
              </label>
              <textarea disabled id="comments" rows="10" />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4 stat-div">
              <Stats />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
