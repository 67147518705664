import React from "react";
import UsersTable from "./usersTable";
import InviteUserModal from "./inviteUserModal";
import ConfirmationModal from "@components/common/confirmationModal";

export default function Users({
  user,
  onDelete,
  users,
  userData,
  onDeleteUser,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Users</label>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-3" style={{ textAlign: "right" }}>
            <button
              className="btn btn-success btn-sm"
              data-toggle="modal"
              data-target="#invite-user-modal"
            >
              Invite a User
            </button>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="account-owner">
            <label>
              Account Owner: {userData ? userData.first_name : null}{" "}
              {userData ? userData.last_name : null} -{" "}
              {userData ? userData.email : null}
            </label>
          </div>
          <UsersTable
            onDeleteUser={(user) => onDeleteUser(user)}
            users={users}
          />
        </div>
      </div>
      <InviteUserModal />
      <ConfirmationModal item={user} onDelete={() => onDelete()} />
    </>
  );
}
