import { common } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState, getProducts, getCustomers, getSuppliers } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getCodeChanges = () => (dispatch, getState) => {
  api
    .get("/codeChanges")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: common.CODE_CHANGES, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addCodeChange = (data) => (dispatch, getState) => {
  let codeChanges = getState().common.codeChanges;
  const { loggedInUser } = getState().auth;
  data.createdBy = loggedInUser.id;
  data.updatedBy = loggedInUser.id;

  api
    .post("/codeChanges", data)
    .then((res) => {
      console.log(res.data);
      if (res.data.code === 200) {
        dispatch({
          type: common.CODE_CHANGES,
          payload: [...codeChanges, res.data.data],
        });
        if (data.module === "product") {
          dispatch(getProducts());
        } else if (data.module === "customer") {
          dispatch(getCustomers());
        } else {
          dispatch(getSuppliers());
        }
      } else {
        if (
          typeof res.data.message === "string" &&
          res.data.message.includes("duplicate")
        ) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateCodeChange = (data) => (dispatch, getState) => {
  const { codeChanges, selectedCodeChanges } = getState().common;
  const { loggedInUser } = getState().auth;
  data.updatedBy = loggedInUser.id;
  api
    .post(`/codeChanges/update/${selectedCodeChanges.id}`, data)
    .then((res) => {
      if (res.data.code === 200) {
        let updatedCodeChange = { ...selectedCodeChanges, ...data };
        let updatedCodeChanges = _.map(codeChanges, (customer) => {
          return customer.id === selectedCodeChanges.id
            ? updatedCodeChange
            : customer;
        });
        dispatch({ type: common.CODE_CHANGES, payload: updatedCodeChanges });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteCodeChange = () => (dispatch, getState) => {
  const { codeChanges, selectedCodeChange } = getState().common;
  api
    .delete(`/codeChanges/${selectedCodeChange.id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(codeChanges, (codeChanges) => {
          return codeChanges.id === selectedCodeChange.id;
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectCodeChange = (data) => (dispatch) => {
  dispatch({ type: common.SELECTED_CODE_CHANGE, payload: data });
};
