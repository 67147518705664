import React from "react";
import TableActions from "@components/common/tableActions";

export default function SalesOrderTable() {
  return (
    <div className="table-view table-responsive">
      <table id="view-sales-order-table" className="table">
        <thead>
          <tr>
            {/* <th>
							<i style={{ fontSize: '1.5em' }} className="fa fa-cog icon" />
						</th> */}
            <th>Order No.</th>
            <th>Order Date</th>
            <th>Required Date </th>
            <th>Customer Code</th>
            <th>Customer Name</th>
            <th>Customer Ref</th>
            <th>Warehouse</th>
            <th>Status</th>
            <th>Currency</th>
            <th>Cost (GBP)</th>
            <th>Margin</th>
            <th>Sub Total (GBP)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>sad</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>asd</td>
            <td>
              <TableActions
                icon="fa fa-cog icon"
                data={[
                  "Edit",
                  "Ship",
                  "Purchase",
                  "Invoice",
                  "Complete",
                  "Print",
                  "Print Product Label",
                  "Print Packing Slip",
                  "Email",
                  "Clone",
                  "Delete",
                ]}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
