import React from 'react';

export default function SearchProduct({ contents, onClick }) {
	return (
		<div className="hover-dropdown-content" style={{ display: contents && contents.length > 0 ? 'block' : 'none' }}>
			{contents && contents.length > 0 ? (
				<div className="hover-dropdown-data">
					{contents.map(content => (
						<div key={content.code} onClick={() => onClick(content.code)}>
							<span>[{content.code}] - </span> {content.name}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
}
