import React from "react";
import InlineDropdown from "@components/common/inlineDropDown";

export default function Template() {
  return (
    <div id="Template" className="tab-pane fade">
      <div className="row">
        <div className="col-sm-6">
          <InlineDropdown
            searchAble={true}
            id="fontName"
            name="Font Name"
            values={[
              { label: "Arial Unicode MS", value: "Arial Unicode MS" },
              { label: "Cambria", value: "Cambria" },
              { label: "Time New Roman", value: "Time New Roman" },
              { label: "Verdana", value: "Verdana" },
            ]}
          />
          <InlineDropdown
            searchAble={true}
            id="fontSize"
            name="Font Size"
            values={[
              { label: "6", value: "6" },
              { label: "7", value: "7" },
              { label: "8", value: "8" },
              { label: "9", value: "9" },
              { label: "10", value: "10" },
              { label: "11", value: "11" },
              { label: "12", value: "12" },
              { label: "13", value: "13" },
              { label: "14", value: "14" },
              { label: "15", value: "15" },
              { label: "16", value: "16" },
              { label: "17", value: "17" },
              { label: "18", value: "18" },
              { label: "19", value: "19" },
              { label: "20", value: "20" },
            ]}
          />
        </div>
        <div className="col-sm-6">
          <InlineDropdown
            searchAble={true}
            id="orientation"
            name="Orientation"
            values={[
              { label: "Portrait", value: "Portrait" },
              { label: "Landscape", value: "Landscape" },
            ]}
          />
          <InlineDropdown
            searchAble
            id="pageSize"
            name="Page Size"
            values={[
              { label: "A4", value: "A4" },
              { label: "Letter", value: "Letter" },
            ]}
          />
        </div>
      </div>
      <hr />
      <div className="doc-info">
        <i className="fa fa-info-circle" />
        <span>
          These settings will define a default style for all components in the
          template.
        </span>
      </div>
    </div>
  );
}
