import React, { Component } from "react";
import {
  initilizeDataTable,
  destroyDataTable,
  warehousesData,
  isEmptyOrNull,
} from "@helpers";
import {
  loaderState,
  getWarehouses,
  getProducts,
  addWarehouseTransfer,
} from "@actions";
import { connect } from "react-redux";
import _ from "lodash";
import AddWarehouseTransferComp from "@components/inventory/warehouseTransfers/addWarehouseTransfer";
import swal from "sweetalert";

class AddWarehouseTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferedProducts: [],
    };
  }

  componentDidMount() {
    document.title = "Add Warehouse Transfers";
    initilizeDataTable("add-warehouse-transfers-table");
    this.props.loaderState(true);
    this.props.getProducts();
    this.props.getWarehouses();
  }

  componentDidUpdate() {
    initilizeDataTable("add-warehouse-transfers-table");
  }

  prepareProductsData = (products) => {
    let data = [];
    _.forEach(products, (prod) => {
      data.push({
        label: `${prod.productDescription} (${prod.productCode})`,
        value: prod.productCode,
      });
    });

    return data;
  };

  onChange = ({ target }) => {
    const { id, value } = target;
    this.setState({ [id]: value });
  };

  handleChange = (newValue, field) => {
    if (newValue) {
      this.setState({ [field]: newValue.value });
    } else {
      this.setState({ [field]: newValue });
    }
  };

  destinationWarehouses = (warehouses) => {
    const { sourceWarehouse } = this.state;
    let data = [];
    if (sourceWarehouse) {
      _.forEach(warehouses, (warehouse) => {
        if (Number(warehouse.value) != Number(sourceWarehouse)) {
          data.push(warehouse);
        }
      });
    }
    return data;
  };

  onClickAdd = () => {
    const {
      product,
      quantity,
      productComment,
      sourceWarehouse,
      destinationWarehouse,
      template,
    } = this.state;
    const { products } = this.props;
    if (isEmptyOrNull(sourceWarehouse) || isEmptyOrNull(destinationWarehouse)) {
      swal("", "Please select sourse and destination warehouses", "info");
    } else {
      let data = {
        productCode: product,
        quantity,
        productComment,
        template,
      };
      if (isEmptyOrNull(product) || isEmptyOrNull(quantity)) {
        swal(
          "",
          "Please select a product and enter quantity to transfer",
          "info"
        );
      } else {
        _.forEach(products, (prod) => {
          if (product === prod.productCode) {
            data.productId = prod.id;
            data.productDescription = prod.productDescription;
            data.packSize = prod.packSize;
            data.weight = prod.weight;
            data.volume =
              Number(prod.width) * Number(prod.height) * Number(prod.depth);
          }
        });

        let transferedProducts = this.state.transferedProducts.concat(data);
        this.setState({
          transferedProducts,
        });
      }
    }
  };

  onClickParkOrComplete = (status) => {
    const {
      sourceWarehouse,
      destinationWarehouse,
      comment,
      template,
      transferedProducts,
    } = this.state;
    let data = {
      comment,
      template,
      status,
    };
    if (!transferedProducts && transferedProducts.length <= 0) {
      swal("", "Please add some products to be transfered", "info");
    } else {
      data.sourceWarehouse = sourceWarehouse;
      data.destinationWarehouse = destinationWarehouse;
      data.transferedProducts = transferedProducts;
      this.props.loaderState(true);
      this.props.addWarehouseTransfer(data);
    }
  };

  render() {
    destroyDataTable("add-warehouse-transfers-table");

    const { warehouses, products } = this.props;

    let warehousesDDL =
      warehouses && warehouses.length > 0 ? warehousesData(warehouses) : [];
    let destWarehouse =
      warehousesDDL && warehousesDDL.length > 0
        ? this.destinationWarehouses(warehousesDDL)
        : [];

    let productsData =
      products && products.length > 0 ? this.prepareProductsData(products) : [];

    let stateData = { ...this.state };

    return (
      <AddWarehouseTransferComp
        warehouses={warehousesDDL}
        products={productsData}
        stateData={stateData}
        handleChange={(value, field) => this.handleChange(value, field)}
        onChange={(e) => this.onChange(e)}
        destWarehouse={destWarehouse}
        onClickAdd={() => this.onClickAdd()}
        onClickParkOrComplete={(status) => this.onClickParkOrComplete(status)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
    products: state.inventory.products.allProducts,
  };
};

export default connect(mapStateToProps, {
  loaderState,
  getWarehouses,
  getProducts,
  addWarehouseTransfer,
})(AddWarehouseTransfer);
