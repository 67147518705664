import api from "@api/api";
import { inventory } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getProducts = () => (dispatch, getState) => {
  const { organizationInfo } = getState().auth;
  // { organizationId: organizationInfo.id }
  api
    .post("/products/filter")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: inventory.PRODUCTS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addProduct = (data) => (dispatch) => {
  api
    .post("/products", { ...data })
    .then(async (res) => {
      if (res.data.code === 200) {
        dispatch(getProducts());
        swal("", "Product added successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateProduct = (data) => (dispatch, getState) => {
  const { allProducts, selectedProduct } = getState().inventory.products;
  const { loggedInUser } = getState().auth;
  api
    .post(`/products/update/${selectedProduct.id}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedProduct = { ...selectedProduct, ...data };
        let updatedProducts = _.map(allProducts, (product) => {
          return product.id === selectedProduct.id ? updatedProduct : product;
        });
        dispatch({ type: inventory.PRODUCTS, payload: updatedProducts });
        swal("", "Product updated successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteProduct = (id) => (dispatch, getState) => {
  const { allProducts } = getState().inventory.products;
  api
    .delete(`/products/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allProducts, (product) => {
          return product.id === id;
        });
        swal("", "Product deleted successfully", "success");
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectProduct = (product) => (dispatch) => {
  dispatch({ type: inventory.SELECTED_PRODUCT, payload: product });
};

export const addProductSupplier = (data) => (dispatch) => {
  api
    .post(`/productSupplier`, data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch(loaderState(false));
        dispatch(getProductSupplier(data.productid));
        swal("", "Product supplier added successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const getProductSupplier = (productid) => (dispatch) => {
  api
    .post(`/productSupplier/filter`, { productid })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: inventory.PRODUCT_SUPPLIERS, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteProductSupplier = (id) => (dispatch, getState) => {
  const { productSuppliers } = getState().inventory.products;
  api
    .delete(`/productSupplier/${id}`)
    .then((res) => {
      dispatch(loaderState(false));
      if (res.data.code === 200) {
        var newData = _.remove(productSuppliers, (PS) => {
          return Number(PS.id) !== Number(id);
        });
        dispatch({ type: inventory.PRODUCT_SUPPLIERS, payload: newData });
        swal("", "Product supplier deleted successfully", "success");
      } else {
        swal("", res.data.message, "error");
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};
