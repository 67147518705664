import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getAdjustments = () => (dispatch) => {
  api
    .get("/adjustmentReason")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.ADJUSTMENT_REASONS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addAdjustments = ({ reason, createdBy }) => (
  dispatch,
  getState
) => {
  let adjustments = getState().system.adjustments.allAdjustments;
  const { organizationInfo } = getState().auth;
  // organizationId: organizationInfo.id
  api
    .post("/adjustmentReason", { reason, createdBy, updatedBy: createdBy })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.ADJUSTMENT_REASONS,
          payload: [...adjustments, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteAdjustments = (id) => (dispatch, getState) => {
  const { allAdjustments } = getState().system.adjustments;
  api
    .delete(`/adjustmentReason/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allAdjustments, (adj) => {
          return Number(id) === Number(adj.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateAdjustments = ({ reason, id, updatedBy }) => (
  dispatch,
  getState
) => {
  var adjustments = getState().system.adjustments.allAdjustments;
  api
    .post(`/adjustmentReason/update/${id}`, { reason, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedReason = _.findLast(adjustments, (reason) => {
          return Number(reason.id) === Number(id);
        });
        updatedReason.reason = reason;
        let updatedAdjustments = _.map(adjustments, (adj) => {
          return adj.id === id ? updatedReason : adj;
        });
        dispatch({
          type: settings.ADJUSTMENT_REASONS,
          payload: updatedAdjustments,
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onUpdateOrDeleteAdjustment = (data) => (dispatch) => {
  dispatch({
    type: settings.UPDATE_OR_DELETE_ADJUSTMENT_REASON,
    payload: data,
  });
};
