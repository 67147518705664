import React from "react";
import ExportButton from "@components/common/exportButton";
import PurchasesTable from "./purchasesTable";
import Select from "react-select";

export default function ViewPurchases({ goto, warehouses }) {
  console.log(warehouses);
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Purchases</label>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-5" style={{ textAlign: "right" }}>
            <button
              onClick={() =>
                goto("/purchases/purchase orders/import purchases")
              }
              className="btn btn-success btn-sm"
            >
              Import
            </button>
            <ExportButton />

            <button
              onClick={() => goto("/purchases/purchase orders/add purchase")}
              className="btn btn-success btn-sm"
            >
              Add Purchase
            </button>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                ]}
              />
              {/* <select className="form-control" id="status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="order_number">
                Order Number
              </label>
              <input
                type="text"
                className="form-control"
                id="order_number"
                name="order_number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="supplier">
                Supplier
              </label>
              <input
                type="text"
                className="form-control"
                id="supplier"
                name="supplier"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="warehouse">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="reference-supplier">
                Supplier Reference
              </label>
              <input
                type="text"
                className="form-control"
                id="reference-supplier"
                name="reference-supplier"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="sales-order-no">
                Sales Order No.
              </label>
              <input
                type="text"
                className="form-control"
                id="sales-order-no"
                name="sales-order-no"
              />
            </div>
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="printed">
                Printed
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Printed"
                id="printed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {/* <select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <PurchasesTable />
          <br />
          <div className="row">
            <div className="col-xs-6"></div>
            <div className="col-xs-6 stat-div">
              <small className="stat-label">
                PURCHASE ORDER TOTAL VALUE(PKR)
              </small>
              <div className="stat-value">0.00</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
