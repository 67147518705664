import React from "react";

function Permissions({
  appFunctionalities,
  onChange,
  getFunctionalityPermisson,
}) {
  return (
    <div className="role-permissions">
      <div className="row" id="heading">
        <div className="col-xs-1"></div>
        <div className="col-xs-6">Functionality</div>
        <div className="col-xs-1">Read</div>
        <div className="col-xs-1">Create</div>
        <div className="col-xs-1">Edit</div>
        <div className="col-xs-1">Delete</div>
      </div>
      {appFunctionalities && appFunctionalities.length > 0 ? (
        appFunctionalities.map((functionality, index) => {
          let id = functionality.name.replace(/\s/g, "");
          let permission = getFunctionalityPermisson(functionality.id);
          return (
            <React.Fragment key={index}>
              <div className="row">
                <div
                  data-toggle="collapse"
                  data-target={`#${id}`}
                  className="col-xs-1 accordion-toggle"
                >
                  {functionality.childrens &&
                  functionality.childrens.length > 0 ? (
                    <div className="permission-more-icon">
                      <i className="fa fa-chevron-right" aria-hidden="true" />
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-6 permissions-body-text">
                  {functionality.name}
                </div>
                <div className="col-xs-1">
                  <input
                    type="checkbox"
                    onChange={({ target }) =>
                      onChange(functionality.id, "read", target.checked)
                    }
                    checked={permission ? permission.read : false}
                  />
                </div>
                <div className="col-xs-1">
                  <input
                    type="checkbox"
                    onChange={({ target }) =>
                      onChange(functionality.id, "create", target.checked)
                    }
                    checked={permission ? permission.create : false}
                  />
                </div>
                <div className="col-xs-1">
                  <input
                    type="checkbox"
                    onChange={({ target }) =>
                      onChange(functionality.id, "update", target.checked)
                    }
                    checked={permission ? permission.update : false}
                  />
                </div>
                <div className="col-xs-1">
                  <input
                    type="checkbox"
                    onChange={({ target }) =>
                      onChange(functionality.id, "delete", target.checked)
                    }
                    checked={permission ? permission.delete : false}
                  />
                </div>
              </div>
              {functionality.childrens && functionality.childrens.length > 0 ? (
                <div id={id} className="accordian-body collapse">
                  {functionality.childrens.map((sub) => {
                    let permission_c = getFunctionalityPermisson(sub.id);
                    return (
                      <div key={sub.id} className="row" id="sub-permissions">
                        <div className="col-xs-1"></div>
                        <div className="col-xs-6 permissions-body-text">
                          {sub.name}
                        </div>
                        <div className="col-xs-1">
                          <input
                            type="checkbox"
                            onChange={({ target }) =>
                              onChange(sub.id, "read", target.checked)
                            }
                            checked={permission_c ? permission_c.read : false}
                          />
                        </div>
                        <div className="col-xs-1">
                          <input
                            type="checkbox"
                            onChange={({ target }) =>
                              onChange(sub.id, "create", target.checked)
                            }
                            checked={permission_c ? permission_c.create : false}
                          />
                        </div>
                        <div className="col-xs-1">
                          <input
                            type="checkbox"
                            onChange={({ target }) =>
                              onChange(sub.id, "update", target.checked)
                            }
                            checked={permission_c ? permission_c.update : false}
                          />
                        </div>
                        <div className="col-xs-1">
                          <input
                            type="checkbox"
                            onChange={({ target }) =>
                              onChange(sub.id, "delete", target.checked)
                            }
                            checked={permission_c ? permission_c.delete : false}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </React.Fragment>
          );
        })
      ) : (
        <div className="no-data">No data found!</div>
      )}
    </div>
  );
}

export default Permissions;
