import { customers } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";
import { prepareCustomerData } from "@helpers";

export const getCustomers = () => (dispatch) => {
  api
    .get("/customers")
    .then(async (res) => {
      if (res.data.code === 200) {
        let response = res.data.data;
        let data = [];
        console.log(response);
        await _.forEach(response, (resdata) => {
          data.push(prepareCustomerData(resdata));
        });
        dispatch({ type: customers.CUSTOMERS, payload: data });
      } else {
        swal("", res.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addCustomer = (customerData, postal, physical) => (
  dispatch,
  getState
) => {
  let allCustomers = getState().customers.allCustomers;
  const { loggedInUser } = getState().auth;
  let userId = loggedInUser.id;
  let data = {};
  customerData.createdBy = userId;
  customerData.updatedBy = userId;
  data.customerData = customerData;

  if (postal) {
    postal.createdBy = userId;
    postal.updatedBy = userId;
    data.postalAddress = postal;
  }
  if (physical) {
    physical.createdBy = userId;
    physical.updatedBy = userId;
    data.physicalAddress = physical;
  }

  api
    .post("/customers", data)
    .then(async (res) => {
      console.log(res.data);
      if (res.code === 200) {
        let response = res.data.data;
        let data = {};
        data.customerData = { ...customerData, ...response.customerData };
        data.postal = { ...postal, ...response.postalAddress };
        data.physical = { ...physical, ...response.physicalAddress };

        await dispatch({
          type: customers.CUSTOMERS,
          payload: [...allCustomers, data],
        });
        swal("", "Customer added successfully", "success");
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      dispatch(loaderState(false));
      swal("", err.message, "error");
    });
};

export const updateCustomer = (customerData, postal, physical) => (
  dispatch,
  getState
) => {
  const { allCustomers, selectedCustomer } = getState().customers;
  const { loggedInUser } = getState().auth;
  let userId = loggedInUser.id;
  let data = {};
  if (customerData) {
    customerData.updatedBy = userId;
    data.customerData = customerData;
  }
  if (postal) {
    postal.updatedBy = userId;
    data.postalAddress = postal;
  }
  if (physical) {
    physical.updatedBy = userId;
    data.physicalAddress = physical;
  }

  api
    .post(`/customers/update/${selectedCustomer.customerData.id}`, data)
    .then((res) => {
      if (res.data.code === 200) {
        let customerData = {
          ...selectedCustomer.customerData,
          ...data.customerData,
        };
        let postal = { ...selectedCustomer.postal, ...data.postalAddress };
        let physical = {
          ...selectedCustomer.physical,
          ...data.physicalAddress,
        };
        let updatedCustomer = {
          customerData,
          physical,
          postal,
        };
        dispatch(onSelectCustomer(updatedCustomer));
        let updatedCustomers = _.map(allCustomers, (customer) => {
          return customer.customerData.id === selectedCustomer.customerData.id
            ? updatedCustomer
            : customer;
        });
        dispatch({ type: customers.CUSTOMERS, payload: updatedCustomers });
        swal("", "Customer updated successfully", "success");
      } else {
        swal("", res.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const deleteCustomer = (id) => (dispatch, getState) => {
  const { allCustomers } = getState().customers;
  api
    .delete(`/customers/${id}`)
    .then((res) => {
      if (res.code === 200) {
        _.remove(allCustomers, (customer) => {
          return customer.customerData.id === id;
        });
      } else {
        swal("", res.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectCustomer = (customer) => (dispatch) => {
  dispatch({ type: customers.SELECTED_CUSTOMER, payload: customer });
};

export * from "./deliveryAddresses";
