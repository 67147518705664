import React from "react";
import moment from "moment";

export default function CodesTable({ data, onCodeChangeSelect }) {
  return (
    <table id="change-code-table" className="table">
      <thead>
        <tr>
          <th>Change Date</th>
          <th>Old Code</th>
          <th>New Code</th>
          <th>User</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0
          ? data.map((d) => {
              return (
                <tr key={d.id}>
                  <td>{moment(d.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{d.oldCode}</td>
                  <td>{d.newCode}</td>
                  <td>
                    {d.first_name} {d.last_name}
                  </td>
                  <td onClick={() => onCodeChangeSelect(d)}>
                    <i
                      data-toggle="modal"
                      data-target="#confirmationModal"
                      className="fa fa-trash-o icon"
                    />
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
}
