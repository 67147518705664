import React from 'react';
import Purchasestable from './purchasestable';
import Select from 'react-select';

export default function Purchases() {
	return (
		<div id="Purchases" className="tab-pane fade">
			<div className="row" id="settings-field">
				<div className="col-md-2">
					<label className="input-label" htmlFor="order-status">
						Order Status
					</label>
					<Select
						isClearable
						name="Order Status"
						id="order_status"
						options={[
							{ label: 'All', value: 'All' },
							{ label: 'Open', value: 'Open' },
							{ label: 'Unapproved', value: 'Unapproved' },
							{ label: 'Parked', value: 'Parked' },
							{ label: 'Placed', value: 'Placed' },
							{ label: 'Costed', value: 'Costed' },
							{ label: 'Receipted', value: 'Receipted' },
							{ label: 'Completed', value: 'Completed' },
							{ label: 'Deleted', value: 'Deleted' },
						]}
					/>
				</div>
			</div>
			<br />
			{/* <div className="panel panel-default">
				<div className="panel-body"> */}
			<Purchasestable />
			{/* </div>
			</div> */}
		</div>
	);
}
