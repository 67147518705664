import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";

export default function Contact({ onChange, stateData, orgData }) {
  return (
    <div id="Contact" className="tab-pane fade">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="telephone"
            value={
              stateData ? (stateData.telephone ? stateData.telephone : "") : ""
            }
            name="Telephone"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="fax"
            value={stateData ? (stateData.fax ? stateData.fax : "") : ""}
            name="Fax"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="mobile"
            value={stateData ? (stateData.mobile ? stateData.mobile : "") : ""}
            name="Mobile"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="DDI"
            value={stateData ? (stateData.DDI ? stateData.DDI : "") : ""}
            name="DDI"
          />
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="tollFree"
            value={
              stateData ? (stateData.tollFree ? stateData.tollFree : "") : ""
            }
            name="TollFree"
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-4 col-sm-6">
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="purchaseEmail"
            value={
              stateData
                ? stateData.purchaseEmail
                  ? stateData.purchaseEmail
                  : ""
                : ""
            }
            name="Purchase Email"
          />
          <div className="inline-form">
            <div className="field">
              <label htmlFor="purchaseEmailComment">
                Purchase Email Comment
              </label>
              <textarea
                onChange={(e) => onChange(e)}
                rows="7"
                type="text"
                id="purchaseEmailComment"
                value={
                  stateData
                    ? stateData.purchaseEmailComment
                      ? stateData.purchaseEmailComment
                      : ""
                    : ""
                }
              />
            </div>
          </div>
          <InlineInputForm
            onChange={(e) => onChange(e)}
            id="salesEmail"
            value={
              stateData
                ? stateData.salesEmail
                  ? stateData.salesEmail
                  : ""
                : ""
            }
            name="Sell Email"
          />
          <div className="inline-form">
            <div className="field">
              <label htmlFor="salesEmailComment">Sell Email Comment</label>
              <textarea
                onChange={(e) => onChange(e)}
                rows="7"
                type="text"
                id="salesEmailComment"
                value={
                  stateData
                    ? stateData.salesEmailComment
                      ? stateData.salesEmailComment
                      : ""
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
