import React from "react";
import OrdersTable from "./ordersTable";
import Select from "react-select";

export default function Orders({ warehouses }) {
  return (
    <div id="Orders" className="tab-pane fade">
      <div className="row" id="settings-field">
        <div className="col-md-2 padding-1-right">
          <label className="input-label" htmlFor="order_status">
            Order Status
          </label>
          <Select
            isClearable
            name="Order Status"
            id="order_status"
            options={[
              { label: "Open", value: "Open" },
              { label: "Pending", value: "Pending" },
              { label: "Accepted", value: "Accepted" },
              { label: "All", value: "All" },
            ]}
          />
          {/* <select className="form-control" id="order_status">
						<option>Open</option>
						<option>Parked</option>
						<option>Accepted</option>
						<option>All</option>
					</select> */}
        </div>
        <div className="col-md-2 padding-1">
          <label className="input-label" htmlFor="warehouse">
            Warehouse
          </label>
          <Select
            isClearable
            name="Warehouse"
            id="warehouse"
            options={warehouses}
          />
          {/* <select className="form-control" id="warehouse">
						<option></option>
						<option>Head Office</option>
						<option>Main Warehouse</option>
					</select> */}
        </div>
      </div>
      <br />
      {/* <div className="panel panel-default">
				<div className="panel-body"> */}
      <OrdersTable />
      {/* </div>
			</div> */}
    </div>
  );
}
