import React from "react";
import InlineInputForm from "@components/common/inlineInputForm";
import InlineDropdown from "@components/common/inlineDropDown";
import Tabs from "@components/common/tabs";
import OrderLines from "./orderLines";
import Shipments from "./shipments";
import Invoices from "./invoices";
import Products from "./products";
import Stats from "./stats";
import SplitButton from "@components/common/splitButton";

export default function AddSalesOrder({ nav, warehouses }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Add Sales Order</label>
          </div>
          <div className="col-lg-8" style={{ textAlign: "right" }}>
            <SplitButton
              label="Order"
              type="light"
              data={[
                "Cone",
                "Create Purchase",
                "Create Invoice",
                "Create Partial Invoice",
              ]}
            />
            <SplitButton
              label="Save"
              type="light"
              data={["Backorder", "Park", "Place"]}
            />
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <InlineInputForm
                link={true}
                path="/customers/view customers"
                id="customer-code"
                value=""
                name="*Customer Code"
              />
              <InlineInputForm
                link={true}
                path="/customers/view customers"
                id="customer-name"
                value=""
                name="*Customer Name"
              />
              <InlineInputForm
                disable="disabled"
                id="customer-reference"
                value=""
                name="Customer Reference"
              />
              <InlineInputForm
                disable="disabled"
                id="discount"
                value="0.00"
                name="Discount (%)"
              />
              <InlineDropdown
                searchAble={true}
                id="tax-rate"
                name="Tax Rate"
                values={[]}
              />
              <InlineDropdown
                searchAble={true}
                disable="disabled"
                id="warehouse"
                name="Warehouse"
                values={warehouses}
              />
              <InlineDropdown
                searchAble={true}
                disable="disabled"
                id="delivery-method"
                name="Delivery Method"
                values={[
                  { label: "Delivery", value: "Delivery" },
                  { label: "Pickup", value: "Pickup" },
                ]}
              />
              <div className="inline-form">
                <div className="field">
                  <label htmlFor="delivery-instruction">
                    Delivery Instruction
                  </label>
                  <textarea
                    disabled
                    rows="4"
                    type="text"
                    id="delivery-instruction"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                link={true}
                path="/customers/view customers"
                id="delivery-contact"
                name="Delivery Contact"
                values={[]}
              />
              <InlineInputForm
                disable="disabled"
                id="delivery-name"
                value=""
                name="Delivery Name"
              />
              <InlineInputForm
                disable="disabled"
                id="address-line-1"
                value=""
                name="Address Line 1"
              />
              <InlineInputForm
                disable="disabled"
                id="adderss-line-2"
                value=""
                name="Address Line 2"
              />
              <InlineInputForm
                disable="disabled"
                id="suburb"
                value=""
                name="Suburb"
              />
              <InlineInputForm
                disable="disabled"
                id="city"
                value=""
                name="City"
              />
              <InlineInputForm
                disable="disabled"
                id="state"
                value=""
                name="State/Region"
              />
              <InlineInputForm
                disable="disabled"
                id="postal-code"
                value=""
                name="Post Code"
              />
              <InlineInputForm
                disable="disabled"
                id="country"
                value=""
                name="Country"
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <InlineDropdown
                searchAble={true}
                disable="disabled"
                id="sales-person"
                name="Sales Person"
                values={[
                  { label: "name: email", value: "name: email" },
                  { label: "name: email1", value: "name: email1" },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                disable="disabled"
                id="sales-order-group"
                name="Sales Order Group"
                values={[
                  { label: "Catalog", value: "Catalog" },
                  { label: "test", value: "test" },
                ]}
              />
              <InlineInputForm
                disable="disabled"
                id="quote-date"
                value=""
                name="Quote Date"
                type="date"
              />
              <InlineInputForm
                disable="disabled"
                id="order-date"
                value=""
                name="Order Date"
                type="date"
              />
              <InlineInputForm
                disable="disabled"
                id="required-date"
                value=""
                name="Required Date"
                type="date"
              />
              <InlineDropdown
                searchAble={true}
                id="sales-order-template"
                name="Sales Order Template"
                values={[
                  { label: "Globus Default", value: "Globus Default" },
                  { label: "Formal Order", value: "Formal Order" },
                  { label: "Classic Order", value: "Classic Order" },
                ]}
              />
              <InlineDropdown
                searchAble={true}
                id="packing-slip-template"
                name="Packing Slip Template"
                values={[
                  { label: "Globus Default", value: "Globus Default" },
                  {
                    label: "Formal Packing Slip",
                    value: "Formal Packing Slip",
                  },
                  {
                    label: "Classic Packing Slip",
                    value: "Classic Packing Slip",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <Tabs nav={nav} />
          <div className="tab-content">
            <OrderLines />
            <Shipments />
            <Invoices />
            <Products />
          </div>
        </div>
      </div>
      <div className="grid-container1">
        <div className="grid-item1">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="row" id="settings-field">
                <div className="col-sm-5 padding-1-right">
                  <label className="input-label" htmlFor="charge">
                    Charge
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="charge"
                    name="charge"
                  />
                </div>
                <div className="col-sm-2 padding-1">
                  <label className="input-label" htmlFor="price">
                    Price
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="price"
                    name="price"
                  />
                </div>
                <div className="col-sm-2 padding-1">
                  <label className="input-label" htmlFor="tax-rate">
                    Tax Rate
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="tax-rate"
                    name="tax-rate"
                  />
                </div>
                <div className="col-sm-2" id="add-btn">
                  <label className="input-label">Add</label>
                  <button
                    disabled
                    type="button"
                    className="btn btn-success btn-sm"
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="table-view table-responsive">
                <table id="sales-quotes-charge-table" className="table">
                  <thead>
                    <tr>
                      <th>Charge</th>
                      <th>Price</th>
                      <th>Tax Rate</th>
                      <th>Invoiced</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="grid-item1">
              <Stats />
              <br />
              <div style={{ textAlign: "right" }}>
                <SplitButton
                  label="Print"
                  type="light"
                  data={["Order", "Packing Slip"]}
                />
                <SplitButton
                  label="Email"
                  type="light"
                  data={["Order", "Packing Slip"]}
                />
                <button
                  style={{ marginLeft: "5px" }}
                  className="btn btn-light btn-sm"
                >
                  Print Label
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-4">
          <label className="input-label" htmlFor="comments">
            Comments
          </label>
          <textarea disabled id="comments" rows="10" />
        </div>
      </div>
    </>
  );
}
