import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getCreditReasons = () => (dispatch) => {
  api
    .get("/creditReasons")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.CREDIT_REASONS, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addCredit = ({ reason, createdBy }) => (dispatch, getState) => {
  var creditReasons = getState().system.creditReasons.allCreditReasons;
  const { organizationInfo } = getState().auth;
  api
    .post("/creditReasons", {
      reason,
      createdBy,
      updatedBy: createdBy,
      //   organizationId: organizationInfo.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.CREDIT_REASONS,
          payload: [...creditReasons, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          if (res.data.message.includes("duplicate")) {
            swal("", "Duplicate entry", "error");
          } else {
            swal("", res.data.message, "error");
          }
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteCredit = (id) => (dispatch, getState) => {
  const { allCreditReasons } = getState().system.creditReasons;
  api
    .delete(`/creditReasons/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allCreditReasons, (adj) => {
          return Number(id) === Number(adj.id);
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateCredit = ({ reason, id, updatedBy }) => (
  dispatch,
  getState
) => {
  var creditReasons = getState().system.creditReasons.allCreditReasons;
  api
    .post(`/creditReasons/update/${id}`, { reason, updatedBy })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedReason = _.findLast(creditReasons, (reason) => {
          return Number(reason.id) === Number(id);
        });
        updatedReason.reason = reason;
        let updatedCreditsReasons = _.map(creditReasons, (adj) => {
          return adj.id === id ? updatedReason : adj;
        });

        dispatch({
          type: settings.CREDIT_REASONS,
          payload: updatedCreditsReasons,
        });
        dispatch(loaderState(false));
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
        dispatch(loaderState(false));
      }
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onUpdateOrDeleteCredit = (data) => (dispatch) => {
  dispatch({ type: settings.UPDATE_OR_DELETE_CREDIT_REASON, payload: data });
};
