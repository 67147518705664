import React from "react";
import TaxTable from "./taxTable";
import ConfirmationModal from "@components/common/confirmationModal";
import EditModal from "./editModal";

export default function Taxes({
  stateData,
  onChange,
  onUpdate,
  onAdd,
  taxes,
  onTaxSelect,
  onDelete,
  selectedTax,
}) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>Taxes</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-sm-2 padding-1-right">
              <label className="input-label" htmlFor="defaultSalesTax">
                Default Sales Tax
              </label>
              <select className="form-control" id="defaultSalesTax">
                <option>No Tax (0%)</option>
              </select>
            </div>
            <div className="col-sm-2 padding-1">
              <label className="input-label" htmlFor="defaultPurchaseTax">
                Default Purchase Tax
              </label>
              <select className="form-control" id="defaultPurchaseTax">
                <option>No Tax (0%)</option>
              </select>
            </div>
          </div>
          <br />
          <div className="row" id="settings-field">
            <div className="col-md-3 padding-1-right">
              <label className="input-label" htmlFor="taxName">
                *Tax Description/Name
              </label>
              <input
                value={stateData.taxName}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="taxName"
              />
            </div>
            <div className="col-md-1 padding-1">
              <label className="input-label" htmlFor="taxCode">
                *Tax Code
              </label>
              <input
                value={stateData.taxCode}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="taxCode"
              />
            </div>
            <div className="col-md-1 padding-1">
              <label className="input-label" htmlFor="taxRate">
                *Tax Rate
              </label>
              <input
                value={stateData.taxRate}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="taxRate"
              />
            </div>

            <div className="col-md-1 col-xs-4 padding-1">
              <label className="input-label" htmlFor="salesTax">
                *Sales Tax
              </label>
              <br />
              <input
                checked={stateData.salesTax === "1" ? true : false}
                onChange={(e) => onChange(e)}
                type="checkbox"
                style={{ width: "18px", height: "18px" }}
                id="salesTax"
              />
            </div>
            <div className="col-md-1 col-xs-4 padding-1">
              <label className="input-label" htmlFor="purchaseTax">
                *Purchase Tax
              </label>
              <br />
              <input
                checked={stateData.purchaseTax === "1" ? true : false}
                onChange={(e) => onChange(e)}
                type="checkbox"
                style={{ width: "18px", height: "18px" }}
                id="purchaseTax"
              />
            </div>
            <div className="col-xs-4" id="system-add-btn">
              <button
                onClick={() => onAdd()}
                className="btn btn-success btn-sm"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <TaxTable onTaxSelect={(data) => onTaxSelect(data)} taxes={taxes} />
        </div>
      </div>
      <ConfirmationModal onDelete={() => onDelete()} item="Tax" />
      <EditModal
        data={stateData}
        selectedTax={selectedTax}
        onChange={(e) => onChange(e)}
        onUpdate={() => onUpdate()}
      />
    </>
  );
}
