import { settings } from "@constants/actionTypes";
const initialState = {
  allReturnReason: null,
  selectedReturnReason: null,
};

function supplierReturnReasonsReducer(state = initialState, action) {
  switch (action.type) {
    case settings.SUPPLIER_RETURN_REASONS:
      return { ...state, allReturnReason: action.payload };
    case settings.SELECTED_SUPPLIER_RETURN_REASONS:
      return { ...state, selectedReturnReason: action.payload };
    default:
      return state;
  }
}
export default supplierReturnReasonsReducer;
