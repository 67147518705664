import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getWarehouses, loaderState } from "@actions";
import { initilizeDataTable, destroyDataTable, warehousesData } from "@helpers";
import AddSalesOrderComp from "@components/sales/order/add";

class AddSalesOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: ["Order Lines", "Products", "Shipments", "Invoices"],
    };
  }

  componentDidMount() {
    document.title = "Add Sales Order";
    const { warehouses } = this.props;
    if (warehouses && warehouses.length === 0) {
      this.props.loaderState(true);
      this.props.getWarehouses();
    }
    initilizeDataTable("view-product-table");
    initilizeDataTable("order-lines-table");
    initilizeDataTable("shipments-table");
    initilizeDataTable("invoices-table");
    initilizeDataTable("sales-quotes-charge-table");
  }

  onChange(e) {}

  componentDidUpdate() {
    initilizeDataTable("view-product-table");
    initilizeDataTable("order-lines-table");
    initilizeDataTable("shipments-table");
    initilizeDataTable("invoices-table");
    initilizeDataTable("sales-quotes-charge-table");
  }

  render() {
    destroyDataTable("view-product-table");
    destroyDataTable("order-lines-table");
    destroyDataTable("shipments-table");
    destroyDataTable("invoices-table");
    destroyDataTable("sales-quotes-charge-table");

    const { nav } = this.state;
    const { warehouses } = this.props;
    return (
      <AddSalesOrderComp nav={nav} warehouses={warehousesData(warehouses)} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warehouses: state.system.warehouses.allWarehouses,
  };
};

export default connect(mapStateToProps, { loaderState, getWarehouses })(
  AddSalesOrder
);
