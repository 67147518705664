import React from "react";

export default function DeliveryAddressesTable({ deliveryAddresses, edit }) {
  return (
    <div className="table-view table-responsive">
      <table id="delivery-address-table" className="table">
        <thead>
          <tr>
            <th>Address Name</th>
            <th>Address Line 1</th>
            <th>Address Line 2</th>
            <th>Suburb</th>
            <th>City</th>
            <th>Region</th>
            <th>Country</th>
            <th>Postal Code</th>
            <th>Delivery Instruction</th>
            <th>Obsolete</th>
          </tr>
        </thead>
        <tbody>
          {edit && deliveryAddresses
            ? deliveryAddresses.map((address) => (
                <tr key={address.id}>
                  <td>{address.addressName}</td>
                  <td>{address.addressLine1}</td>
                  <td>{address.addressLine2}</td>
                  <td>{address.suburb}</td>
                  <td>{address.city}</td>
                  <td>{address.state_Region}</td>
                  <td>{address.country}</td>
                  <td>{address.postalCode}</td>
                  <td>{address.deliveryInstruction}</td>
                  <td style={{ textAlign: "center" }}>
                    <div className="checkbox">
                      <input
                        disabled
                        checked={address.obsolete === 1 ? "checked" : ""}
                        type="checkbox"
                      />
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
