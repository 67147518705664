import React from "react";
import SplitButton from "@components/common/splitButton";
import SupplierReturnsTable from "./supplierReturnsTable";
import Select from "react-select";

export default function ViewSupplierReturns({ warehouses, goto }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-lg-4">
            <label>View Supplier Returns</label>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-5" style={{ textAlign: "right" }}>
            <SplitButton
              label="Export"
              type="light"
              data={[
                "Export to PDF",
                "Export to CSV",
                "Export to XLSX",
                "Export to XLS",
              ]}
            />
            <button
              onClick={() =>
                goto("/purchases/supplier returns/add supplier return")
              }
              className="btn btn-success btn-sm"
            >
              Add Return
            </button>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row" id="settings-field">
            <div className="col-md-2 padding-1-right">
              <label className="input-label" htmlFor="status">
                Status
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Status"
                id="status"
                options={[
                  { label: "All", value: "All" },
                  { label: "Parked", value: "Parked" },
                  { label: "Completed", value: "Completed" },
                ]}
              />
              {/* <select className="form-control" id="status">
								<option>All</option>
								<option>Parked</option>
								<option>Completed</option>
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="supplier-return_number">
                Supplier Return Number
              </label>
              <input
                type="text"
                className="form-control"
                id="supplier-return_number"
                name="supplier-return_number"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="supplier">
                Supplier
              </label>
              <input
                type="text"
                className="form-control"
                id="supplier"
                name="supplier"
              />
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="warehouse">
                Warehouse
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Warehouse"
                id="warehouse"
                options={warehouses}
              />
              {/* <select className="form-control" id="warehouse">
								<option></option>
								{warehouses
									? warehouses.map(warehouse => <option key={warehouse}>{warehouse}</option>)
									: null}
							</select> */}
            </div>
            <div className="col-md-2 padding-1">
              <label className="input-label" htmlFor="printed">
                Printed
              </label>
              <Select
                searchAble={true}
                isClearable
                name="Printed"
                id="printed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              {/* <select className="form-control" id="printed">
								<option></option>
								<option>Yes</option>
								<option>No</option>
							</select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <SupplierReturnsTable />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-sm-6"></div>
        <div className="col-md-3 col-sm-6" style={{ paddingRight: "0" }}>
          <div className="panel panel-default" id="panel-margin-right">
            <div className="panel-body">
              <div className="border-bottom">
                <span className="receipt-stat-label">Return Count</span>
                <span className="pull-right receipt-stat-value">0</span>
              </div>
              <div className="border-bottom">
                <span className="receipt-stat-label">Total Return Value</span>
                <span className="pull-right receipt-stat-value">0.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
