import { common, users } from "@constants/actionTypes";
import api from "@api/api";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getAddresses = () => (dispatch) => {
  api
    .get("/addresses")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: common.ADDRESSES, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

// export const addPurchase = data => (dispatch, getState) => {
// 	let allPurchases = getState().purchases.allPurchases;
// 	const { loggedInUser, organizationInfo } = getState().auth;
// 	data.organizationId = organizationInfo.id;
// 	api.post('/purchases', { ...data, createdBy: loggedInUser.id, updatedBy: loggedInUser.id })
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				dispatch({ type: purchases.PURCHASES, payload: [...allPurchases, res.data.data] });
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			dispatch(loaderState(false));
// 			swal('', err.message, 'error');
// 		});
// };

export const updateOrganizationAddress = (data) => (dispatch, getState) => {
  const { organizationInfo, loggedInUser } = getState().auth;
  api
    .post(`/addresses/update/${organizationInfo.addressId}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: users.ORGANIZATION_ADDRESSES,
          payload: res.data.data,
        });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

export const updateAddress = (data, obj) => (dispatch, getState) => {
  const { loggedInUser } = getState().auth;
  let type = users.ORGANIZATION_POSTAL_ADDRESSES;
  if (obj === "physical") {
    type = users.ORGANIZATION_PHYSICAL_ADDRESSES;
  }
  api
    .post(`/addresses/update/${data.id}`, {
      ...data,
      updatedBy: loggedInUser.id,
    })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type, payload: res.data.data });
      } else {
        swal("", res.data.message, "error");
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
    });
};

// export const deletePurchase = id => (dispatch, getState) => {
// 	const { allPurchases } = getState().purchases;
// 	api.delete(`/purchases/${id}`)
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				_.remove(allPurchases, purchase => {
// 					return purchase.id === id;
// 				});
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			swal('', err.message, 'error');
// 			dispatch(loaderState(false));
// 		});
// };

// export const getSupplierReturns = () => dispatch => {
// 	api.get('/purchases/supplierReturns')
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				dispatch({ type: purchases.SUPPLIER_RETURNS, payload: res.data.data });
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			swal('', err.message, 'error');
// 			dispatch(loaderState(false));
// 		});
// };

// export const addSupplierReturn = data => (dispatch, getState) => {
// 	let supplierReturns = getState().purchases.supplierReturns;
// 	const { loggedInUser, organizationInfo } = getState().auth;
// 	data.organizationId = organizationInfo.id;
// 	api.post('/purchases/supplierReturns', { ...data, createdBy: loggedInUser.id, updatedBy: loggedInUser.id })
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				dispatch({ type: purchases.SUPPLIER_RETURNS, payload: [...supplierReturns, res.data.data] });
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			dispatch(loaderState(false));
// 			swal('', err.message, 'error');
// 		});
// };

// export const updateSupplierReturn = data => (dispatch, getState) => {
// 	const { supplierReturns, selectedSupplierRerurn } = getState().purchases;
// 	const { loggedInUser } = getState().auth;
// 	api.post(`/purchases/supplierReturns/update/${selectedSupplierRerurn.id}`, { ...data, updatedBy: loggedInUser.id })
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				let updatedSupplierReturn = { ...selectedSupplierRerurn, ...data };
// 				let updatedSupplierReturns = _.map(supplierReturns, supplierReturn => {
// 					return supplierReturn.id === selectedSupplierRerurn.id ? updatedSupplierReturn : supplierReturn;
// 				});
// 				dispatch({ type: purchases.PURCHASES, payload: updatedSupplierReturns });
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			swal('', err.message, 'error');
// 		});
// };

// export const deleteSupplierReturn = id => (dispatch, getState) => {
// 	const { supplierReturns } = getState().purchases;
// 	api.delete(`/purchases/supplierReturns/${id}`)
// 		.then(res => {
// 			if (res.data.code === 200) {
// 				_.remove(supplierReturns, returns => {
// 					return returns.id === id;
// 				});
// 			} else {
// 				swal('', res.data.message, 'error');
// 			}
// 			dispatch(loaderState(false));
// 		})
// 		.catch(err => {
// 			swal('', err.message, 'error');
// 			dispatch(loaderState(false));
// 		});
// };

// export const onSelectPurchase = purchase => dispatch => {
// 	dispatch({ type: purchases.SELECTED_PURCHASE, payload: purchase });
// };

// export const onSelectSupplierReturn = supplierReturn => dispatch => {
// 	dispatch({ type: purchases.SELECTED_SUPPLIER_RETURN, payload: supplierReturn });
// };
