import api from "@api/api";

import { settings } from "@constants/actionTypes";
import { loaderState } from "@actions";
import _ from "lodash";
import swal from "sweetalert";

export const getTaxes = () => (dispatch) => {
  api
    .get("/taxes")
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: settings.TAXES, payload: res.data.data });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const addTax = (data) => (dispatch, getState) => {
  var alltaxes = getState().system.taxes.allTaxes;
  const { organizationInfo } = getState().auth;
  //   organizationId: organizationInfo.id
  api
    .post("/taxes", { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({
          type: settings.TAXES,
          payload: [...alltaxes, res.data.data],
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const deleteTax = (id) => (dispatch, getState) => {
  const { allTaxes } = getState().system.taxes;
  api
    .delete(`/taxes/${id}`)
    .then((res) => {
      if (res.data.code === 200) {
        _.remove(allTaxes, (tax) => {
          return Number(id) === Number(tax.id);
        });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(onSelectTax(null));
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const updateTax = (data) => (dispatch, getState) => {
  var allTaxes = getState().system.taxes.allTaxes;
  api
    .post(`/taxes/update/${data.id}`, { ...data })
    .then((res) => {
      if (res.data.code === 200) {
        let updatedTax = _.findLast(allTaxes, (tax) => {
          return Number(tax.id) === Number(data.id);
        });
        if (data.taxCode) {
          updatedTax.taxCode = data.taxCode;
        }
        if (data.taxName) {
          updatedTax.taxName = data.taxName;
        }
        if (data.obsolete) {
          updatedTax.obsolete = data.obsolete;
        }
        if (data.taxRate) {
          updatedTax.taxRate = data.taxRate;
        }
        if (data.salesTax) {
          updatedTax.salesTax = data.salesTax;
        }
        if (data.purchaseTax) {
          updatedTax.purchaseTax = data.purchaseTax;
        }
        let updatedTaxes = _.map(allTaxes, (tax) => {
          return tax.id === data.id ? updatedTax : tax;
        });

        dispatch({ type: settings.TAXES, payload: updatedTaxes });
      } else {
        if (res.data.message.includes("duplicate")) {
          swal("", "Duplicate entry", "error");
        } else {
          swal("", res.data.message, "error");
        }
      }
      dispatch(onSelectTax(null));
      dispatch(loaderState(false));
    })
    .catch((err) => {
      swal("", err.message, "error");
      dispatch(loaderState(false));
    });
};

export const onSelectTax = (data) => (dispatch) => {
  dispatch({ type: settings.SELECTED_TAXE, payload: data });
};
