import React from "react";
import UploadFiles from "@components/common/uploadFiles";

export default function ImportStockCounts({ onCountsUpload }) {
  return (
    <>
      <div className="comp-heading">
        <div className="row">
          <div className="col-xs-12">
            <label>Import Stock Counts</label>
          </div>
        </div>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">
          <UploadFiles
            onFileDivClick={() => onCountsUpload()}
            id="Stock Count"
          />
        </div>
      </div>
    </>
  );
}
